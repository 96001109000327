import { useState } from "react";

import { Calendar } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Button, Divider, Grid, Skeleton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { getDate } from "date-fns";

import type { InfoModalProps } from "../../Modal";
import type { TypographyProps } from "@mui/material";

import { Alert } from "../../Alert";
import { InfoModal } from "../../Modal";
import { Surface } from "../../Surface";
import { TextField } from "../../TextField";

export interface BillingDateModalProps {
  /** Determines whether the modal is open or closed */
  open: InfoModalProps["open"];
  /** The ID of the subscription */
  subscriptionId: string;
  /** The current billing date E.g. 2023-09-11 */
  currentBillingDate?: Date;
  /** The contract end date E.g. 2026-09-11 */
  contractEndDate?: Date;
  /** Function to be called when the user accepts the billing date */
  onAccept: ({
    subscriptionId,
    nextBillingDay,
  }: {
    subscriptionId: string;
    nextBillingDay: number;
  }) => Promise<void>;
  /** Function to be called to calculate contract end date based on a new billing day */
  onEstimateBilling: ({
    subscriptionId,
    nextBillingDay,
  }: {
    subscriptionId: string;
    nextBillingDay: number;
  }) => Promise<{
    /** The new contract end date */
    estimatedContractEndDate: string;
    estimatedNextBillingDate: string;
  }>;
  /** Triggered when modal is closed on the secondary action button that resembles a cancel action. */
  onCancel: () => void;
  /** Translations for the modal */
  translations: {
    /** The title of the modal */
    title: string;
    /** The description of the modal */
    description: string;
    /** E.g. "Something went wrong. Please try again." */
    commonError: string;
    /** E.g. Continue */
    continueButton: string;
    /** The text for the accept button */
    acceptButton: string;
    /** The text for the cancel button */
    cancelButton: string;
    /** E.g. Billing day  */
    billingDay: string;
    /** E.g. Next billing  */
    nextBilling: string;
    /** E.g. Minimum term ends */
    minimumTermEnds: string;
    /** E.g. Current */
    current: string;
    /** E.g. After */
    after: string;
    /** E.g. "This subscription billing date cannot be changed." */
    billingSubscriptionError: string;
  };
}

const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => {
    if (prop === "bold") {
      return false;
    }
    return true;
  },
})<TypographyProps & { bold?: boolean }>((props) => ({
  ...(props.bold ? props.theme.typography.captionBold : props.theme.typography.caption),
  [props.theme.breakpoints.up("tablet")]: {
    ...(props.bold ? props.theme.typography.bodyTextBold : props.theme.typography.bodyText),
  },
}));

/** Billing date modal component that allows the user change the billing date of a subscription.
 */
export const BillingDateModal = (props: BillingDateModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loadingBillingEstimation, setLoadingBillingEstimation] = useState(false);
  const [nextBillingDate, setNextBillingDate] = useState(props.currentBillingDate);
  const [newContractEndDate, setNewContractEndDate] = useState(props.contractEndDate);
  const currentBillingDay = props.currentBillingDate ? getDate(props.currentBillingDate) : null;

  const [nextBillingDay, setNextBillingDay] = useState<number | null>(currentBillingDay);
  const theme = useTheme();

  const onChangeDayHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const nextBillingDayValue = Number(value);

    if (value === "") {
      setNextBillingDay(null);
    } else if (/^\d+$/.test(value) && nextBillingDayValue >= 1 && nextBillingDayValue <= 31) {
      setNewContractEndDate(
        nextBillingDayValue !== currentBillingDay ? undefined : props.contractEndDate
      );
      setNextBillingDate(
        nextBillingDayValue !== currentBillingDay ? undefined : props.currentBillingDate
      );
      setNextBillingDay(nextBillingDayValue);
    }
  };

  /**
   * Handles errors that occur during the execution of a function.
   * If the error is a known error, a specific error message is set.
   * Otherwise, a generic error message is set.
   * @param error - The error object that occurred.
   */
  const errorHandler = (error: unknown) => {
    // These errors should not be shown to user in detail because they should not happen
    const knownErrors = [
      "billing_day_out_of_range",
      "subscription_billing_cannot_changed",
      "billing_day_missmatch",
      "invalid_contract_term",
    ];

    if (error instanceof Error && knownErrors.includes(error.name)) {
      setErrorMessage(props.translations.billingSubscriptionError);
    } else {
      setErrorMessage(props.translations.commonError);
    }
  };

  /**
   * Updates the billing date with the selected date and time.
   * @returns {Promise<void>} A Promise that resolves when the billing date has been successfully updated.
   */
  const updateBillingHandler = async () => {
    try {
      if (nextBillingDay) {
        setErrorMessage("");
        setLoading(true);
        await props.onAccept({
          subscriptionId: props.subscriptionId,
          nextBillingDay,
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const billingEstimationHandler = async () => {
    try {
      if (nextBillingDay) {
        setErrorMessage("");
        setLoadingBillingEstimation(true);
        const result = await props.onEstimateBilling({
          subscriptionId: props.subscriptionId,
          nextBillingDay,
        });
        setNewContractEndDate(new Date(result.estimatedContractEndDate));
        setNextBillingDate(new Date(result.estimatedNextBillingDate));
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoadingBillingEstimation(false);
    }
  };

  const onCloseHandler = () => {
    setErrorMessage("");
    setNextBillingDay(currentBillingDay);
    setNewContractEndDate(props.contractEndDate);
    setNextBillingDate(props.currentBillingDate);
    props.onCancel();
  };

  return (
    <InfoModal
      sx={{ maxWidth: "600px", overflowX: "hidden" }}
      open={props.open}
      icon={<Calendar sx={{ fontSize: "48px", mb: theme.spacing(1) }} />}
      title={props.translations.title}
      description={
        <>
          {/* Title, description, input and validation  */}
          <Grid container>
            <Typography variant="bodyText" sx={{ mb: theme.spacing(2) }}>
              {props.translations.description}
            </Typography>
            <Grid container mb={theme.spacing(3)} spacing={theme.spacing(2)} rowGap={1}>
              <Grid item mobile={12} tablet={4} textAlign={{ mobile: "center", tablet: "right" }}>
                <TextField
                  id="outlined-number"
                  // same translation is used elsewhere, where soft hyphen is needed but not here
                  label={props.translations.billingDay.replace("&shy;", "")}
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  value={nextBillingDay}
                  onChange={onChangeDayHandler}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  fullWidth
                />
              </Grid>
              <Grid item mobile={12} tablet={8} textAlign={{ mobile: "center", tablet: "left" }}>
                <LoadingButton
                  loading={loadingBillingEstimation}
                  disabled={!nextBillingDay || !!newContractEndDate}
                  onClick={billingEstimationHandler}
                  fullWidth
                >
                  {props.translations.continueButton}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ width: "100%", mt: -1, mb: 1, display: { tablet: "none" } }} />

          <Surface
            sx={{
              bgcolor: theme.palette.secondary[200],
              border: { tablet: `1px solid ${theme.palette.text.primary}` },
              padding: { tablet: 2, mobile: 0.5 },
              marginBottom: { tablet: 1 },
              width: "100%",
            }}
          >
            <StyledText bold sx={{ textAlign: { mobile: "center", tablet: "left" }, mb: 2 }}>
              ID: {props.subscriptionId}
            </StyledText>
            <Grid container spacing={{ mobile: 1.5, tablet: 2 }}>
              <Grid item mobile={5}>
                <Typography align="center" mb={-0.5}>
                  &nbsp;
                </Typography>
              </Grid>
              <Grid item mobile={3.5}>
                <StyledText bold align="center" mb={-0.5}>
                  {props.translations.current}
                </StyledText>
              </Grid>
              <Grid item mobile={3.5}>
                <StyledText bold align="center" mb={-0.5}>
                  {props.translations.after}
                </StyledText>
              </Grid>
              <Grid item mobile={5}>
                <StyledText
                  bold
                  dangerouslySetInnerHTML={{ __html: props.translations.billingDay }}
                />
              </Grid>
              <Grid item mobile={3.5}>
                <StyledText align="center">{currentBillingDay}</StyledText>
              </Grid>
              <Grid item mobile={3.5}>
                <StyledText align="center">
                  {nextBillingDay && nextBillingDay !== currentBillingDay ? nextBillingDay : "-"}
                </StyledText>
              </Grid>
              <Grid item mobile={5}>
                <StyledText bold>{props.translations.nextBilling}</StyledText>
              </Grid>
              <Grid item mobile={3.5}>
                <StyledText align="center">
                  {props.currentBillingDate ? props.currentBillingDate.toLocaleDateString() : "-"}
                </StyledText>
              </Grid>
              <Grid item mobile={3.5}>
                {loadingBillingEstimation ? (
                  <Skeleton
                    width={50}
                    sx={{
                      margin: "auto",
                      height: {
                        mobile: theme.typography.caption.fontSize,
                        tablet: theme.typography.bodyText.fontSize,
                      },
                    }}
                  />
                ) : (
                  <StyledText align="center">
                    {nextBillingDate &&
                    nextBillingDate.toISOString() !== props.currentBillingDate?.toISOString()
                      ? new Date(nextBillingDate).toLocaleDateString()
                      : "-"}
                  </StyledText>
                )}
              </Grid>
              <Grid item mobile={5}>
                <StyledText
                  bold
                  dangerouslySetInnerHTML={{ __html: props.translations.minimumTermEnds }}
                />
              </Grid>
              <Grid item mobile={3.5}>
                <StyledText align="center">
                  {props.contractEndDate ? props.contractEndDate.toLocaleDateString() : "-"}
                </StyledText>
              </Grid>
              <Grid item mobile={3.5}>
                {loadingBillingEstimation ? (
                  <Skeleton
                    width={50}
                    sx={{
                      margin: "auto",
                      height: {
                        mobile: theme.typography.caption.fontSize,
                        tablet: theme.typography.bodyText.fontSize,
                      },
                    }}
                  />
                ) : (
                  <StyledText align="center">
                    {newContractEndDate &&
                    newContractEndDate.toISOString() !== props.contractEndDate?.toISOString()
                      ? new Date(newContractEndDate).toLocaleDateString()
                      : "-"}
                  </StyledText>
                )}
              </Grid>
            </Grid>
          </Surface>

          {errorMessage && (
            <Alert severity="warning" sx={{ mt: 2, width: "100%" }} description={errorMessage} />
          )}

          <Grid container pt={2} columnSpacing={2} rowSpacing={1}>
            <Grid item mobile={6} tablet={4}>
              <Button fullWidth color="secondary" onClick={onCloseHandler}>
                {props.translations.cancelButton}
              </Button>
            </Grid>
            <Grid item mobile={6} tablet={8}>
              <LoadingButton
                data-testid="acceptBillingDate"
                loading={loading}
                fullWidth
                onClick={updateBillingHandler}
                disabled={
                  !nextBillingDay || currentBillingDay === nextBillingDay || !newContractEndDate
                }
              >
                {props.translations.acceptButton}
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};
