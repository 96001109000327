import { useEffect, useState } from "react";

import { Box, Radio, Stack, Typography } from "@mui/material";

import type { RadioGroupCardProps } from "./RadioGroupCardBase";

import { Card, CardContent, RadioContainer } from "./RadioGroupCardBase";
import { RadioGroupCardTag } from "./RadioGroupCardTag";

export type RadioGroupCardColumnProps<T> = RadioGroupCardProps<
  T,
  { action?: React.ReactNode; title?: string }
> & {
  /** If provided, radio icon next to title won't be rendered */
  hideRadioIcon?: boolean;
  /** Override default 16px spacing between cards */
  spacing?: number;
};

/**
 * Create and render RadioGroup options to column.
 */
export function RadioGroupCardColumn<T>(props: React.PropsWithRef<RadioGroupCardColumnProps<T>>) {
  const [selectedValue, setSelectedValue] = useState(
    typeof props.initialValue === "undefined" ? props.options[0].value : props.initialValue
  );
  const [isPristine, setIsPristine] = useState(true);

  /**
   * Only update the selected value if the user hasn't manually changed it
   */
  useEffect(() => {
    if (props.initialValue && isPristine) {
      setSelectedValue(props.initialValue);
    }
  }, [props.initialValue, isPristine]);

  const handleChange = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    newValue: T,
    disabled?: boolean
  ) => {
    // Only set value if not disabled and the event did not originate from button
    if ((event.target as HTMLButtonElement)?.nodeName !== "BUTTON" && !disabled) {
      setIsPristine(false);
      if (props.onChangeAsync) {
        try {
          await props.onChangeAsync(newValue);
          setSelectedValue(newValue);
        } catch (error) {
          // fail silently
        }
      } else {
        setSelectedValue(newValue);
        props.onChange(newValue);
      }
    }
  };

  return (
    <Stack direction="column" spacing={props.spacing || 2}>
      {props.options.map((option, index) => (
        <Card
          key={index}
          checked={
            props.isEqualValue
              ? props.isEqualValue(option.value, selectedValue)
              : option.value === selectedValue
          }
          disabled={option.disabled}
          hoverDisabled={option.hoverDisabled}
          onClick={(event) => handleChange(event, option.value, option.disabled)}
          data-testid="radioGroupCard"
          data-testid-hover-disabled={!!option.hoverDisabled}
        >
          {option.title ? (
            // If title is provided, render the radio and title to the top-left corner
            <Stack direction="column" padding={1.5} sx={{ position: "relative" }}>
              <RadioContainer>
                <Stack direction="row" alignContent="center" spacing={1}>
                  {!props.hideRadioIcon && (
                    <Radio
                      checked={
                        props.isEqualValue
                          ? props.isEqualValue(option.value, selectedValue)
                          : option.value === selectedValue
                      }
                      disabled={option.disabled}
                      sx={{ padding: 0 }}
                    />
                  )}
                  <Typography variant="largeTextBold" alignSelf="center">
                    {option.title}
                  </Typography>
                </Stack>
              </RadioContainer>
              <CardContent hasAction={!!option.action} sx={{ padding: 0 }}>
                {option.content}
              </CardContent>
              {/* If tag is provided, render the tag to the top-right corner */}
              {option.tag && <RadioGroupCardTag value={option.tag} />}
            </Stack>
          ) : (
            // (default/no title is provided) render the radio to the top-right corner
            <Box display="flex">
              <CardContent hasAction={!!option.action}>{option.content}</CardContent>
              <RadioContainer>
                <Radio
                  checked={
                    props.isEqualValue
                      ? props.isEqualValue(option.value, selectedValue)
                      : option.value === selectedValue
                  }
                  disabled={option.disabled}
                />
              </RadioContainer>
            </Box>
          )}
          {option.action && <div>{option.action}</div>}
        </Card>
      ))}
    </Stack>
  );
}
