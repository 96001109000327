import { Box, Button, Stack } from "@mui/material";

import type { BoxProps, Breakpoint, ButtonProps } from "@mui/material";

type Direction = "row" | "column";

export interface ActionButtonsProps {
  /** Array of call-to-action buttons; e.g. "Subscribe now" with a link */
  buttons: Omit<ButtonProps, "sx">[];
  /** Direction in which buttons are laid out */
  direction: Direction | Record<Breakpoint, Direction>;
  /** Buttons take all horizontal space */
  grow: boolean | Record<Breakpoint, boolean>;
  /** Buttons are centered in their container; in direction "row" only */
  centered?: boolean;
  display?: BoxProps["display"];
  /** Container properties, e.g. when passed in bottom content of <HeadingContent/> */
  boxProps?: BoxProps;
}

export const ActionButtons = (props: ActionButtonsProps) => {
  if (props.buttons.length === 0) {
    return null;
  }

  let alignButtons: "stretch" | "flex-start" | Record<Breakpoint, "stretch" | "flex-start"> = {
    mobile: "stretch",
    tablet: "stretch",
    laptop: "stretch",
  };

  let buttonsGrow: 0 | 1 | Record<Breakpoint, 0 | 1>;

  // align-items property of the buttons container
  if (typeof props.direction === "string" && typeof props.grow === "boolean") {
    if (props.direction === "column" && !props.grow) {
      alignButtons = "flex-start";
    } else {
      // default ok
    }
  } else if (typeof props.direction === "string" && typeof props.grow === "object") {
    // default ok for direction row
    alignButtons = {
      mobile: props.direction === "column" && !props.grow.mobile ? "flex-start" : "stretch",
      tablet: props.direction === "column" && !props.grow.tablet ? "flex-start" : "stretch",
      laptop: props.direction === "column" && !props.grow.laptop ? "flex-start" : "stretch",
    };
  } else if (typeof props.direction === "object" && typeof props.grow === "boolean") {
    alignButtons = {
      mobile: props.direction.mobile === "column" && !props.grow ? "flex-start" : "stretch",
      tablet: props.direction.tablet === "column" && !props.grow ? "flex-start" : "stretch",
      laptop: props.direction.laptop === "column" && !props.grow ? "flex-start" : "stretch",
    };
  } else if (typeof props.direction === "object" && typeof props.grow === "object") {
    alignButtons = {
      mobile: props.direction.mobile === "column" && !props.grow.mobile ? "flex-start" : "stretch",
      tablet: props.direction.tablet === "column" && !props.grow.tablet ? "flex-start" : "stretch",
      laptop: props.direction.laptop === "column" && !props.grow.laptop ? "flex-start" : "stretch",
    };
  }

  // buttons' grow property
  if (typeof props.grow === "object") {
    buttonsGrow = {
      mobile: props.buttons.length > 1 && props.grow.mobile ? 1 : 0,
      tablet: props.buttons.length > 1 && props.grow.tablet ? 1 : 0,
      laptop: props.buttons.length > 1 && props.grow.laptop ? 1 : 0,
    };
  } else {
    buttonsGrow = props.buttons.length > 1 && props.grow ? 1 : 0;
  }

  return (
    <Box {...props.boxProps} display={props.display}>
      <Stack
        spacing={2}
        direction={typeof props.direction === "string" ? props.direction : { ...props.direction }}
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: props.centered ? "center" : "stretch",
          alignItems: alignButtons,
        }}
      >
        {props.buttons.map((button, index) => {
          const { children, ...buttonProps } = button;
          return (
            <Button
              key={index}
              {...buttonProps}
              sx={{ flexGrow: buttonsGrow, whiteSpace: "nowrap" }}
            >
              {children}
            </Button>
          );
        })}
      </Stack>
    </Box>
  );
};
