import { createSvgIcon } from "@mui/material/utils";

export const Twitter = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.9095 8.6592L11.8699 8.00755C11.7511 6.31869 12.7938 4.77608 14.4438 4.17771C15.051 3.96492 16.0805 3.93832 16.7537 4.12452C17.0176 4.20431 17.5193 4.47023 17.8756 4.70962L18.5224 5.14847L19.2351 4.92242C19.6311 4.80268 20.1591 4.60323 20.3966 4.47023C20.621 4.35057 20.819 4.28403 20.819 4.32397C20.819 4.55003 20.3307 5.32133 19.9215 5.74684C19.3671 6.34529 19.5255 6.39849 20.6475 5.9995C21.3206 5.77344 21.3338 5.77344 21.2018 6.0261C21.1226 6.15909 20.7134 6.62454 20.2778 7.05006C19.5387 7.7815 19.4991 7.8613 19.4991 8.473C19.4991 9.41716 19.0503 11.3853 18.6015 12.4624C17.77 14.4838 15.9881 16.5715 14.2062 17.6221C11.6984 19.0982 8.35889 19.4705 5.54747 18.6062C4.61033 18.3136 3 17.5689 3 17.436C3 17.396 3.48834 17.3428 4.08236 17.3296C5.32307 17.303 6.56379 16.9572 7.61973 16.3455L8.33255 15.92L7.51414 15.6407C6.3526 15.2417 5.30987 14.3242 5.04592 13.4598C4.96666 13.1805 4.99308 13.1672 5.73224 13.1672L6.49782 13.1539L5.85105 12.8481C5.08547 12.4624 4.38593 11.8108 4.04273 11.1459C3.79192 10.6672 3.47521 9.45702 3.5676 9.36396C3.59394 9.3241 3.87117 9.40382 4.18795 9.51022C5.09868 9.84267 5.21748 9.76288 4.68951 9.20437C3.69953 8.19375 3.39596 6.691 3.87117 5.26813L4.09557 4.62982L4.96666 5.49419C6.74864 7.23625 8.84731 8.27355 11.2496 8.5794L11.9095 8.6592Z"
  />,
  "Twitter"
);
