import { createSvgIcon } from "@mui/material/utils";

export const IOSArrowDown = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M11.3599 15.768C11.5449 15.923 11.7719 16 11.9999 16C12.2219 16 12.4439 15.926 12.6269 15.779L18.6269 10.951C19.0569 10.604 19.1249 9.97498 18.7789 9.54498C18.4329 9.11498 17.8029 9.04698 17.3729 9.39298L12.0109 13.708L6.63991 9.23198C6.21491 8.87898 5.58491 8.93598 5.23191 9.35998C4.87791 9.78398 4.93591 10.415 5.35991 10.768L11.3599 15.768Z"
  />,
  "IOSArrowDown"
);
