import { useState } from "react";

import { Globe, IOSArrowDown } from "@augment-frontend/mui-icons";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { LocaleSelectorProps } from "../Selector/Selector";
import type { SvgIconProps, SxProps } from "@mui/material";

import { ProgrammaticModal } from "../../Modal";
import { LocaleSelector } from "../Selector/Selector";

export interface LocaleViewerProps<T extends React.ElementType> {
  /** Country flag */
  flag?: React.ElementType<SvgIconProps>;
  /** Country name */
  country: string;
  /** Language */
  language: string;
  localeSelector: Omit<LocaleSelectorProps<T>, "logo">;
  /** Styles for locale viewer labels; this applies to text and the selector arrow icon */
  labelSx?: SxProps;
}

export const LocaleViewer = <T extends React.ElementType>(props: LocaleViewerProps<T>) => {
  const [open, setOpen] = useState(false);
  const Flag = props.flag || Globe;
  const theme = useTheme();

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ cursor: "pointer" }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Flag sx={{ color: theme.palette.text.primary }} />
        <Typography sx={props.labelSx} variant="captionBold" ml={1}>
          {props.country || "International"}
        </Typography>
        <Typography sx={props.labelSx} variant="caption">
          &nbsp;/&nbsp;{props.language}
        </Typography>
        <IOSArrowDown sx={{ ...props.labelSx, ml: 1 }} />
      </Stack>
      <ProgrammaticModal
        open={open}
        index={0}
        onClose={() => setOpen(false)}
        views={[<LocaleSelector key={0} {...props.localeSelector} />]}
        sx={{ bgcolor: theme.palette.primary[600] }}
      />
    </>
  );
};
