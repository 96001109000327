import type { FC } from "react";

import { User } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, SwipeableDrawer, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { LocaleViewerProps } from "../Locale";
import type { Link, LinkProps, SwipeableDrawerProps } from "@mui/material";

import { LocaleViewer } from "../Locale";

export interface DrawerProps extends SwipeableDrawerProps {
  /** Callback for logout */
  onLogout: () => Promise<void>;
  /** Component for the My Account/Login button, e.g. Gatsby Link */
  myAccountLinkComponent: React.ElementType;
  /** Props to pass on for the LocaleViewer */
  localeViewer: LocaleViewerProps<typeof Link>;
  /** Component for the call to action button, e.g. Gatsby Link */
  ctaLinkComponent?: React.ElementType;
  /** Current user username */
  username?: string;
  /**
   * Navigation links to render on drawer. The first 5 links will not be rendered on laptop,
   * because they will be rendered to the Header instead.
   */
  links?: LinkProps[];
  /** Loading state to pass on for the Drawer's internal components, e.g. to the LoadingButton */
  loading?: boolean;
  translations: {
    /** To be used if username prop isn't given, e.g. "Login" */
    loginButton: string;
    /** To be used if username prop is given, e.g. "Logout" */
    logoutButton: string;
    /** To be used if also the ctaLinkComponent is given, e.g. "Subscribe" */
    ctaButton?: string;
  };
}

export const Drawer: FC<DrawerProps> = ({
  onLogout,
  links,
  localeViewer,
  myAccountLinkComponent,
  ctaLinkComponent,
  loading,
  ...props
}) => {
  const theme = useTheme();

  return (
    <SwipeableDrawer
      {...props}
      anchor="right"
      PaperProps={{
        sx: { backgroundColor: theme.palette.secondary.A200 },
      }}
    >
      <Box
        sx={{
          width: "300px",
          height: "100%",
          paddingX: theme.spacing(3),
          paddingY: theme.spacing(1.5),
        }}
        data-testid="drawer"
      >
        <Stack direction="column" spacing={theme.spacing(3)}>
          {/* Static height to mitigate layout shift caused by logout button */}
          <Box sx={{ height: "120px" }}>
            <Stack
              direction="row"
              sx={{
                marginX: theme.spacing(-2),
                height: "72px",
                wordBreak: "break-word",
                overflow: "hidden",
              }}
            >
              <Button variant="text" color="inherit" component={myAccountLinkComponent}>
                <User sx={{ marginRight: theme.spacing(2), color: theme.palette.text.secondary }} />
                <Box
                  sx={{
                    // 3 rows = ~50 characters fits nicely with center alignment, longer usernames
                    // will be aligned on start so that the start of the username is visible
                    alignSelf: !props.username || props.username.length <= 50 ? "center" : "start",
                    color: theme.palette.text.secondary,
                  }}
                >
                  {props.username || props.translations.loginButton}
                </Box>
              </Button>
            </Stack>
            {props.username && (
              <LoadingButton
                fullWidth
                variant="text"
                color="error"
                loading={loading}
                onClick={async () => {
                  await onLogout();
                }}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginLeft: theme.spacing(-2),
                  textAlign: "left",
                }}
                data-testid="logout"
              >
                {props.translations.logoutButton}
              </LoadingButton>
            )}
          </Box>
          {links && (
            <nav>
              {links.map((link, index) => (
                <Typography
                  key={index}
                  {...link}
                  variant="bodyTextSemibold"
                  sx={{
                    // exclude first 5 links on laptop because they will be rendered to the Header
                    display: { mobile: "block", laptop: index < 5 ? "none" : "block" },
                    color: theme.palette.text.secondary,
                    marginBottom: theme.spacing(3),
                    ":last-child": { marginBottom: 0 },
                    textDecoration: "none",
                    ":hover": {
                      textDecoration: "underline",
                    },
                  }}
                />
              ))}
            </nav>
          )}
          <Box>
            <LocaleViewer
              {...localeViewer}
              labelSx={{ color: theme.palette.text.secondary }}
              localeSelector={{
                ...localeViewer.localeSelector,
                boxProps: { paddingBottom: { mobile: theme.spacing(6), tablet: 0 } },
              }}
            />
          </Box>
          {ctaLinkComponent && props.translations.ctaButton && (
            <Button variant="contained" component={ctaLinkComponent}>
              {props.translations.ctaButton}
            </Button>
          )}
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};
