import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { BoxProps } from "@mui/material";

/** MUI Box with customized scrollbar
 * @param ignoreMobile - Ignore maxHeight on mobile, so no scrollbar is shown on mobile
 */
export const ScrollbarBox = styled(Box, {
  shouldForwardProp: (prop) => {
    if (prop === "ignoreMobile") {
      return false;
    }
    return true;
  },
})<BoxProps & { ignoreMobile?: boolean }>((props) => ({
  [props.theme.breakpoints.only("mobile")]: {
    maxHeight: props.ignoreMobile ? "none" : "auto",
  },
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    width: "12px",
    backgroundColor: props.theme.palette.secondary[400],
    borderRadius: "8px",
    border: "solid 4px transparent",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    backgroundColor: props.theme.palette.secondary[600],
    borderRadius: "8px",
    border: "solid 4px transparent",
    backgroundClip: "padding-box",
  },
}));
