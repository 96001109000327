import { useState } from "react";

import { ENABLED_LOCALES } from "@augment-frontend/constants";
import { Grid, Link, Typography } from "@mui/material";

import type { DropdownNavigationProps } from "../../Menu";
import type { HeadingContentProps } from "../HeadingContent";

import { DropdownNavigation } from "../../Menu";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

type Contract = {
  /** E.g. "fi-fi" */
  locale: string;
  /** E.g. Valid in Finland from 1.1.2022. */
  caption: string;
  /** URL of the file in Internet. */
  fileUrl: string;
  /** E.g. 202221018-escooter-general-contract.pdf */
  fileName: string;
};
export interface BlockContractProps {
  headingContent: HeadingContentProps;
  categories: {
    /** E.g. E-scooter */
    title: string;
    contracts: Contract[];
  }[];
  translations: {
    all: string;
    notAvailable: string;
  };
  locale: string | null;
}

export const BlockContract = (props: BlockContractProps) => {
  const [localeSelected, setLocaleSelected] = useState(props.locale ?? "all");
  const [categorySelected, setCategorySelected] = useState("all");

  const categoryOptions: DropdownNavigationProps["options"] = [
    {
      active: categorySelected === "all",
      label: props.translations.all,
      value: "all",
      onClick: () => setCategorySelected("all"),
    },
    ...props.categories.map((category) => ({
      active: categorySelected === category.title,
      label: category.title,
      value: category.title,
      onClick: () => setCategorySelected(category.title),
    })),
  ];

  const countryOptions: DropdownNavigationProps["options"] = [
    {
      active: localeSelected === "all",
      label: props.translations.all,
      value: "all",
      onClick: () => setLocaleSelected("all"),
    },
    // "en" serves only as a fallback for edge cases and is not be shown otherwise
    ...ENABLED_LOCALES.filter((locale) => locale.code !== "en").map((locale) => {
      const localeObject =
        ENABLED_LOCALES.find((l) => l.code === locale.code) ?? ENABLED_LOCALES[0];

      return {
        label: `${localeObject.country} (${localeObject.language})`,
        value: localeObject.code,
        onClick: () => setLocaleSelected(localeObject.code),
        active: localeSelected === localeObject.code,
      };
    }),
  ];

  const contractsFilteredByCategory = (contracts: Contract[]) => {
    return contracts
      .filter((contract) => localeSelected === "all" || contract.locale === localeSelected)
      .map((contract, j) => (
        <div key={j}>
          <Typography variant="captionBold" component="p" my="1rem">
            {contract.caption}
          </Typography>
          <Link rel="noopener noreferrer" target="_blank" href={contract.fileUrl}>
            {contract.fileName}
          </Link>
        </div>
      ));
  };

  return (
    <GridContainer>
      <Grid item mobile={12}>
        <HeadingContent {...props.headingContent} />
      </Grid>

      <Grid container spacing={1} mt={1} ml={2} mr={-1} justifyContent="flex-start">
        <Grid item mobile={12} tablet={6} laptop={4}>
          <DropdownNavigation options={countryOptions} />
        </Grid>
        <Grid item mobile={12} tablet={6} laptop={4}>
          <DropdownNavigation options={categoryOptions} />
        </Grid>
      </Grid>

      <Grid item mobile={12}>
        {props.categories
          .filter((category) => categorySelected === "all" || category.title === categorySelected)
          .map((category, i) => (
            <div key={i}>
              <Typography
                variant="subheadingBold"
                component="h3"
                mb="2rem"
                mt={i === 0 ? 0 : "2rem"}
              >
                {category.title}
              </Typography>
              {contractsFilteredByCategory(category.contracts).length ? (
                contractsFilteredByCategory(category.contracts)
              ) : (
                <Typography variant="captionBold" component="p" my="1rem">
                  {props.translations.notAvailable}
                </Typography>
              )}
            </div>
          ))}
      </Grid>
    </GridContainer>
  );
};
