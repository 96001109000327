import { HOUSE_NUMBER_COUNTRIES, STATES_BY_COUNTRY } from "@augment-frontend/constants";

import type { ShippingAddressInfoProps } from "../AddressAndContactInfo/ShippingAddressInfo";
import type { CountryCode } from "@augment-frontend/constants";

/** Minimum fields google validator should find, otherwise we show warning */
export const WARNING_VALIDATOR_FIELDS = ["route", "locality", "street_number"];
export const NON_MAINLAND_SPAIN_PROVINCES = ["CE", "GC", "ME", "PM", "TF"];

type GoogleSuggestionData = {
  status: google.maps.GeocoderStatus;
  response: google.maps.GeocoderResult;
};

/**
 * Validate address with Google Maps API
 * @param geocoderInstance - Google Maps Geocoder instance
 * @param address - Address to validate
 * @returns Promise with GoogleSuggestionData
 */
export const validateAddressWithGoogle = async (
  geocoderInstance: google.maps.Geocoder,
  address: string
): Promise<GoogleSuggestionData> => {
  return new Promise((resolve, reject) => {
    geocoderInstance.geocode({ address }, (results, status) => {
      if (status === "OK" && results?.length) {
        resolve({ status, response: results[0] });
        return;
      }
      reject(status);
    });
  });
};

/**
 * Formats original user shipping address as a short string without double spaces.
 * Suitable to send to Google API and for displaying in the address validation modal.
 * @param address - Original user shipping address
 * @param forValidation - If true, then the address will be formatted for validation with Google API (removing extra house number, etc.)
 */
export const formatOriginalAddress = (
  address: ShippingAddressInfoProps<CountryCode>["shippingInformation"],
  forValidation = false
): string => {
  const allBeforeNumberIncludedRegex = /^[^\d]*\d+/;
  const extraSpacesRegex = /\s+/g;
  const spacesBeforeCommaRegex = /\s*,/g;

  let streetName = "";
  let streetNumber = "";
  let street2Name = "";

  if (forValidation) {
    // Street name cleanup: E.g. Urhehilukatu 22 B32 -> Urhehilukatu 22
    if (address.street1) {
      streetName = address.street1.match(allBeforeNumberIncludedRegex)?.[0] || address.street1;
    }

    // Street number cleanup: E.g. 58 Top 4 -> 58
    if (address.houseNumber) {
      streetNumber =
        address.houseNumber.match(allBeforeNumberIncludedRegex)?.[0] || address.houseNumber;
    }
  } else {
    streetName = address.street1 || "";
    streetNumber = address.houseNumber || "";
    street2Name = address.street2 || "";
  }
  const { city, postalCode } = address;
  const state = address.state ? `, ${address.state}` : "";

  const shortAddress = `${streetName} ${streetNumber} ${street2Name}, ${postalCode} ${city} ${state}`;
  return shortAddress.replace(extraSpacesRegex, " ").replace(spacesBeforeCommaRegex, ",").trim();
};

/**
 * Returns additional house number from the original address
 * @param street1 - Street name
 * @param houseNumber - House number
 * */
export const getAdditionalHouseNumber = (street1?: string, houseNumber?: string): string => {
  const allAfterNumberRegex = /\d+\s*(.*)/;
  let additionalHouseNumber = "";
  // Countries with house/street number in the streetName field E.g. "Urheilukatu 22 B 32" => "B 32"
  const match = street1?.match(allAfterNumberRegex);
  additionalHouseNumber = match ? match[1] : "";

  // Countries with house/street number in the houseNumber field E.g. "58 Top 4" => "Top 4"
  if (houseNumber) {
    const matchNumber = houseNumber.match(allAfterNumberRegex);
    const houseNumberInStreetNumber = matchNumber ? matchNumber[1] : "";
    additionalHouseNumber += ` ${houseNumberInStreetNumber}`;
  }
  return additionalHouseNumber.trim();
};

/**
 * Returns the long name of the state code passed, Odoo compliant.
 * - E.g. getStateName("MA", "ES") => "Málaga"
 * */
export const getStateLongName = (stateCode: string, countryCode: CountryCode): string => {
  return STATES_BY_COUNTRY[countryCode].find((state) => state.code === stateCode)?.name || "";
};

/**
 * Formats suggested shipping address as a short string without double spaces to display it in the address validation modal.
 * @street1 If google maps doesn't return it, it will be taken from the original address.
 * @street2 This is the original street2, not sent to google maps.
 * @streetNumber If google maps doesn't return it, it will be taken from the original address.
 * @state Only if original address has state then it will be taken from google maps.
 */
export const formatSuggestedAddress = (
  suggested: google.maps.GeocoderResult,
  original: ShippingAddressInfoProps<CountryCode>["shippingInformation"],
  countryCode: CountryCode
): string => {
  const spacesBeforeCommaRegex = /\s*,/g;
  const extraSpacesRegex = /\s+/g;

  const addressComponents = suggested.address_components;

  const suggestedStreet1 = addressComponents.find((component) =>
    component.types.includes("route")
  )?.long_name;
  const streetName = suggestedStreet1 ?? original.street1;
  const streetNumber =
    addressComponents.find((component) => component.types.includes("street_number"))?.short_name ??
    (original.houseNumber || "");

  // If we got a street name suggested then we get the additional house number (because Google doesn't return it)
  const additionalHouseNumber = suggestedStreet1
    ? getAdditionalHouseNumber(original.street1, original.houseNumber)
    : "";

  const stateReplacement =
    getStateLongName(
      addressComponents?.find((a) => a.types.includes("administrative_area_level_2"))?.short_name ||
        "",
      countryCode
    ) || "";
  const street2Name = original.street2 || "";
  const postalCode =
    addressComponents.find((component) => component.types.includes("postal_code"))?.short_name ??
    original.postalCode;
  const city =
    addressComponents.find((component) => component.types.includes("locality"))?.long_name ??
    addressComponents.find((component) => component.types.includes("postal_town"))?.long_name ??
    original.city;
  const state = original.state && stateReplacement ? `, ${stateReplacement}` : "";
  const shortAddress = `${streetName} ${streetNumber} ${additionalHouseNumber} ${street2Name}, ${postalCode} ${city} ${state}`;
  return shortAddress.replace(extraSpacesRegex, " ").replace(spacesBeforeCommaRegex, ",").trim();
};

/**
 * Validate entered address before Google Maps validation
 *
 * - House number is missing (as input or inside street) => Warning modal
 * - City is Melilla or Ceuta => Warning modal
 */
export const preSuggestionChecked = (
  street?: string,
  city?: string,
  houseNumber?: string
): "nonMainland" | "noHouseNumber" | null => {
  if (city?.toLowerCase() === "melilla" || city?.toLowerCase() === "ceuta") {
    return "nonMainland";
  } else if (street && !/\d/.test(street) && !houseNumber) {
    return "noHouseNumber";
  }
  return null;
};

/**
 * Validate Google Maps matching address
 *
 * - Country is different than current one => Warning modal
 * - Province belong to a non-mainland => Warning modal
 */
export const postSuggestionChecked = (
  countryCode: CountryCode,
  suggestedCountry?: string,
  suggestedProvinceCode?: string
): "nonMainland" | "countryIsDifferent" | "unsupportedProvince" | null => {
  if (suggestedCountry !== countryCode) {
    return "countryIsDifferent";
  }
  if (suggestedProvinceCode) {
    if (suggestedCountry === "ES" && NON_MAINLAND_SPAIN_PROVINCES.includes(suggestedProvinceCode)) {
      return "nonMainland";
    } else if (
      STATES_BY_COUNTRY[countryCode].length &&
      !STATES_BY_COUNTRY[countryCode].find((state) => state.code === suggestedProvinceCode)
    ) {
      return "unsupportedProvince";
    }
  }

  return null;
};

export const replaceAddressWithSuggestion = (
  countryCode: CountryCode,
  previousAddress: ShippingAddressInfoProps<CountryCode>["shippingInformation"],
  suggestedFullAddress?: google.maps.GeocoderAddressComponent[]
) => {
  const extraInfoStreet1 = previousAddress?.street1?.match(/\d+.*/)?.[0] ?? ""; // "Urheilukatu 22 B 32" => "B 32"
  const extraInfoHouseNumber = previousAddress?.houseNumber?.match(/\d+\s*(.*)/)?.[1] ?? ""; // "58 Top 4" => "Top 4"
  const stateSuggested = suggestedFullAddress?.find((a) =>
    a.types.includes("administrative_area_level_2")
  );

  const suggestedStreetNumber = suggestedFullAddress?.find((a) =>
    a.types.includes("street_number")
  )?.short_name;
  const longSuggestedStreetNumber = suggestedStreetNumber
    ? `${suggestedStreetNumber} ${extraInfoHouseNumber}`.trim()
    : "";

  // Get long state name if it's available
  const stateReplacement =
    previousAddress?.state && stateSuggested
      ? getStateLongName(stateSuggested.short_name, countryCode)
      : previousAddress.state;

  const shippingInformationUpdated = {
    ...previousAddress,
    street1: suggestedFullAddress?.find((a) => a.types.includes("route"))
      ? `${
          suggestedFullAddress?.find((a) => a.types.includes("route"))?.long_name
        } ${extraInfoStreet1}`.trim()
      : previousAddress?.street1,
    houseNumber: HOUSE_NUMBER_COUNTRIES.includes(countryCode)
      ? longSuggestedStreetNumber || previousAddress.houseNumber
      : undefined,
    postalCode:
      suggestedFullAddress?.find((a) => a.types.includes("postal_code"))?.short_name ||
      previousAddress?.postalCode,
    city:
      suggestedFullAddress?.find((a) => a.types.includes("locality"))?.long_name ||
      suggestedFullAddress?.find((a) => a.types.includes("postal_town"))?.long_name ||
      previousAddress?.city,
    state: STATES_BY_COUNTRY[countryCode] ? stateReplacement : undefined,
  };
  return shippingInformationUpdated;
};
