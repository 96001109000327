import { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { maskString } from "../../../utils";
import { TextField } from "../../TextField";

export interface IdentityDocumentNumberInputProps {
  /** National Indentity Number */
  identityDocumentNumber?: string;
  /** Sets all inputs in immutable state, i.e. values cannot be changed if they pass validation */
  immutable?: boolean;
  /** Sets all inputs in disabled state, e.g. when parent is loading */
  disabled?: boolean;
  /** Callback for when a value of an input changes */
  onChange: ({ valid, value, errorMsg }: IdentityDocumentNumberDetail) => void;
  required?: boolean;
  translations: {
    /** First name input label */
    identityNumberLabel: string;
    /** Error message for invalid number */
    errorInvalidIdentityNumber: string;
  };
}

export type IdentityDocumentNumberDetail = {
  valid: boolean;
  value: string;
  errorMsg?: string;
};

/**
 * Renders Identity number field. Needs a parent <Grid container> component.
 * It will return valid for Italian and Spanish identity numbers.
 */
export const IdentityDocumentNumberInput = (props: IdentityDocumentNumberInputProps) => {
  const [isPristine, setIsPristine] = useState(
    props.identityDocumentNumber !== undefined ? Boolean(!props.identityDocumentNumber) : true
  );

  /**
   * Check if identity number is valid
   * - It will validate based on the following Italy/Spain regex: /^[A-Z0-9]{9}$/
   * */
  const getIdentityNumberDetails = (value: string): IdentityDocumentNumberDetail => {
    const valueFormatted = value?.trim().toUpperCase() || "";
    const valid = valueFormatted ? /^[A-Z0-9]{9}$/.test(valueFormatted) : false;
    const errorMsg = valid ? "" : props.translations.errorInvalidIdentityNumber;

    return {
      valid,
      value: valueFormatted,
      errorMsg,
    };
  };

  const [identityNumberState, setIdentityNumberState] = useState(() => {
    return getIdentityNumberDetails(props.identityDocumentNumber || "");
  });

  // If initial value set immutable does not pass validation, allow editing it, and
  // set immutable state false on edit to control input's disabled state correctly
  const [immutable, setImmutable] = useState(props.immutable || false);
  const [disabled, setDisabled] = useState(props.disabled || false);

  useEffect(() => {
    props.onChange(identityNumberState);
  }, [identityNumberState]);

  useEffect(() => {
    setDisabled(props.disabled || false);
  }, [props.disabled]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdentityNumberState(getIdentityNumberDetails(event.target.value));
    setImmutable(false);
    setIsPristine(false);
  };

  return (
    <>
      <Grid item mobile={12}>
        <TextField
          fullWidth
          id="identityDocumentNumber"
          inputProps={{ "data-testid": "identityDocumentNumber" }}
          label={props.translations.identityNumberLabel}
          value={
            immutable && !!identityNumberState.valid
              ? maskString(identityNumberState.value)
              : identityNumberState.value
          }
          error={!isPristine && !identityNumberState.valid}
          helperText={!isPristine && identityNumberState.errorMsg}
          success={identityNumberState.valid && !isPristine}
          onChange={handleChange}
          disabled={disabled || (immutable && !!identityNumberState.valid)}
          required={props.required}
        />
      </Grid>
    </>
  );
};
