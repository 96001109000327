import type { FC } from "react";
import { useEffect, useState } from "react";

import { Calendar } from "@augment-frontend/mui-icons";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MobileDatePicker } from "@mui/x-date-pickers";

import type { PossibleLocales } from "../../utils/locale";
import type { DateValidationError, MobileDatePickerProps } from "@mui/x-date-pickers";

import { TextField } from "../TextField";
import { ActionBar } from "./ActionBar";

export interface DatePickerProps extends Omit<MobileDatePickerProps<Date, boolean>, "slots"> {
  translations: {
    /** E.g. "Start Date" */
    label: string;
    /** E.g. "The date is not valid" */
    invalidDateError?: string;
    /** E.g. "You can't select a date in the future" */
    maxDateError?: string;
  };
  /**
   * If the given locale matches predefined locales, the dates will be
   * formatted as "yyyy.MM.dd". Otherwise default to "dd.MM.yyyy".
   */
  dateFormatLocale?: PossibleLocales;
  /** Date will be display as "15 May, 1997" instead of "15.05.1997" */
  longFormatDate?: boolean;
  /* Callback to pass validation date status to parent component */
  onValid?: (valid: boolean) => void;
  /** Pass fullWidth prop to the TextField input */
  fullWidth?: boolean;
  /** Pass required prop to the TextField input */
  required?: boolean;
  /** If true, days and months will be masked with "*" on input value and only year is displayed normally */
  maskDaysAndMonths?: boolean;
}

export const DatePicker: FC<DatePickerProps> = (props) => {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (props.value) {
      setSuccess(true);
    }
    if (errorMsg) {
      setSuccess(false);
    }
  }, [props.value, errorMsg]);

  /** Handle error messages */
  const handleError = (reasonKey: DateValidationError) => {
    if (!reasonKey) {
      setErrorMsg(null);
    } else {
      const reason =
        reasonKey === "maxDate" || reasonKey === "disableFuture"
          ? props.translations.maxDateError || null
          : props.translations.invalidDateError || null;

      setErrorMsg(reason);
    }
    props.onValid?.(Boolean(!reasonKey));
  };

  /**
   *  Returns the date format based on the component props. By default, the returned format is "dd.MM.yyyy".
   *
   *  - If dateFormatLocale is present and included in the list of yearFirstLocales, the format will start with the year.
   *  - If longFormatDate is true, the long date format will be used.
   *  - If maskDaysAndMonths is true, all characters except the "y" (year) will be replaced with "*".
   */
  const getFormat = () => {
    const yearFirstLocales = ["DE", "DA", "SV"];

    let format;
    if (
      props.dateFormatLocale &&
      yearFirstLocales.includes(props.dateFormatLocale.toLocaleUpperCase())
    ) {
      format = props.longFormatDate ? "yyyy, MMMM d" : "yyyy.MM.dd";
    } else {
      format = props.longFormatDate ? "d MMMM, yyyy" : "dd.MM.yyyy";
    }

    if (props.maskDaysAndMonths) {
      return format.replace(/[^y]/g, "*");
    }

    return format;
  };

  const isOpenable = () => {
    return !props.disabled && !props.readOnly;
  };

  return (
    <MobileDatePicker
      {...props}
      disableHighlightToday
      format={getFormat()}
      label={props.translations.label}
      onError={handleError}
      slots={{
        textField: (textFieldProps) => (
          <TextField
            {...textFieldProps}
            fullWidth={props.fullWidth}
            required={props.required}
            success={success}
            helperText={errorMsg}
            InputProps={{
              inputProps: { ...textFieldProps.inputProps, "data-testid": "datePicker" },
              endAdornment: (
                <IconButton sx={{ padding: 0 }}>
                  <Calendar sx={{ color: theme.palette.text.primary }} />
                </IconButton>
              ),
            }}
            sx={{
              "*": {
                cursor: isOpenable() ? "pointer" : "default",
              },
            }}
          />
        ),
        actionBar: ActionBar,
      }}
    />
  );
};
