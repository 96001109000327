import type { FC } from "react";

import getYouTubeId from "get-youtube-id";
import ReactYouTube from "react-youtube";

import type { YouTubeProps as ReactYouTubeProps } from "react-youtube";

export interface YouTubeProps extends ReactYouTubeProps {
  url: string;
}

export const YouTube: FC<YouTubeProps> = (props) => {
  const { url, ...rest } = props;
  const id = getYouTubeId(url);
  const videoId = id ?? undefined;
  return <ReactYouTube videoId={videoId} {...rest} />;
};
