import { createSvgIcon } from "@mui/material/utils";

export const Remove = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM7 13H17V11H7V13Z"
  />,
  "Remove"
);
