import { useEffect, useState } from "react";

import { VisibilityOff, VisibilityOn } from "@augment-frontend/mui-icons";
import { InputAdornment } from "@mui/material";

import { validatePassword } from "../../../utils";
import { TextField } from "../../TextField";

export interface PasswordInputProps {
  /** Show success/error validation status, e.g. enabled for register, disabled for login */
  showValidationStatus?: boolean;
  /** Sets input in disabled state, e.g. when parent is loading */
  disabled?: boolean;
  /** Callback for when a value of an input changes */
  onChange: ({ value, valid }: { value: string; valid: boolean }) => void;
  translations: {
    /** Password input label */
    passwordLabel: string;
    /** Password helper text */
    passwordHelperText?: string;
  };
}

/**
 * Renders password input.
 */
export const PasswordInput = (props: PasswordInputProps) => {
  const [passwordState, setPasswordState] = useState<{
    value: string;
    valid?: boolean;
    show: boolean;
  }>({
    value: "",
    valid: undefined,
    show: false,
  });
  // Error and success states control the rendered validation status. They are used to avoid nested
  // ternary operators in the input component. Initial undefined hides validation status, when
  // input is first rendered empty, and never gets updated if showValidationStatus prop is falsey.
  const [error, setError] = useState<boolean | undefined>(undefined);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    props.onChange({
      value: passwordState.value,
      valid: !!passwordState.valid,
    });

    if (props.showValidationStatus) {
      setError(passwordState.valid === undefined ? undefined : !passwordState.valid);
      setSuccess(passwordState.valid === undefined ? undefined : passwordState.valid);
    }
  }, [passwordState]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Trim whitespace from start (Cognito supports whitespace in the middle of password)
    const value = event.target.value.trimStart();

    setPasswordState((prev) => ({
      ...prev,
      value,
      valid: validatePassword(value),
    }));
  };

  return (
    <TextField
      fullWidth
      id="password"
      type={passwordState.show ? "text" : "password"}
      value={passwordState.value}
      error={error}
      success={success}
      label={props.translations.passwordLabel}
      helperText={props.showValidationStatus && props.translations.passwordHelperText}
      onChange={onChangeHandler}
      InputProps={{
        endAdornment: !props.disabled && (
          <InputAdornment
            position="end"
            onClick={() => setPasswordState((prev) => ({ ...prev, show: !prev.show }))}
            sx={{ cursor: "pointer" }}
          >
            {passwordState.show ? (
              <VisibilityOff sx={(theme) => ({ color: theme.palette.text.primary })} />
            ) : (
              <VisibilityOn sx={(theme) => ({ color: theme.palette.text.primary })} />
            )}
          </InputAdornment>
        ),
      }}
      inputProps={{
        "data-testid": "password",
      }}
      disabled={props.disabled}
    />
  );
};
