import type { SSNHandler, SSNResult } from "./types";

/**
 * Calculate the validity of a French SSN (NIR / Number of Carte Vitale).
 * Based on {@link https://gist.github.com/jauneau/1206760}.
 * @returns Validity of SSN and -1 for age (not available for French SSN).
 */
export const frenchSSN: SSNHandler = (ssn) => {
  const result: SSNResult = {
    valid: false,
    age: -1,
    standardizedSSN: null,
  };

  if (ssn.length !== 15) {
    return result;
  }

  const gender = ssn.substring(0, 1);
  const year = ssn.substring(1, 3);
  const month = ssn.substring(3, 5);
  let department = ssn.substring(5, 7);
  let city = ssn.substring(7, 10);
  const certificate = ssn.substring(10, 13);
  const controlKey = ssn.substring(13, 15);

  if (!/^[1278]$/.test(gender)) {
    return result;
  }
  if (!/^\d{2}$/.test(year)) {
    return result;
  }
  if (!/^0[1-9]|1[0-2]|20$/.test(month)) {
    return result;
  }
  if (!/^\d{2}|2[AB]$/.test(department)) {
    return result;
  }
  if (!/^\d{3}$/.test(city)) {
    return result;
  }
  if (!/^\d{3}$/.test(certificate) || /^000$/.test(certificate)) {
    return result;
  }
  if (!/^\d{2}$/.test(controlKey) || parseInt(controlKey, 10) > 97) {
    return result;
  }

  // Finer validation for department and city
  if (department === "20" || department === "00") {
    return result;
  }
  if (department === "97") {
    department = `${department}${city.substring(0, 1)}`;
    if (parseInt(department, 10) < 971 || parseInt(department, 10) > 975) {
      return result;
    }
    city = city.substring(1);
    if (parseInt(city, 10) < 1 || parseInt(city, 10) > 90) {
      return result;
    }
  } else if (parseInt(city, 10) < 1 || parseInt(city, 10) > 990) {
    return result;
  }

  // Final check
  const insee = parseInt(
    `${gender}${year}${month}${department.replace(/A|B/g, "0")}${city}${certificate}`,
    10
  );

  result.valid = 97 - (insee % 97) === parseInt(controlKey, 10);
  result.standardizedSSN = result.valid ? ssn : null;

  return result;
};
