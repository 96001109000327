import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import type { TableCellProps } from "@mui/material";

export interface TableProps {
  /** Top title, spans all columns in the 1st row */
  topTitle?: string;
  /** Column titles in the 2nd row if top title, or the in 1st row if no top title, is given */
  columnTitles?: string[];
  /** Table rows */
  rows: {
    /** Value */
    value: string;
    /** Has a highlighted style */
    highlighted?: boolean;
    /** Tag shown in the upper right-hand corner of the cell */
    tag?: string;
  }[][];
  /** Allows overwriting styles for the left-most column; this applies to thead and tbody */
  leftColumnSx?: TableCellProps["sx"];
}

export const TableTopTitle = styled(TableCell)<TableCellProps>((props) => ({
  ...props.theme.typography.largeTextBold,
}));

export const StyledTableTitleCell = styled(TableCell)<TableCellProps>((props) => ({
  ...props.theme.typography.captionBold,
  [props.theme.breakpoints.up("tablet")]: {
    ...props.theme.typography.bodyTextBold,
  },
}));

export const StyledTableCell = styled(TableCell)<TableCellProps>((props) => ({
  ...props.theme.typography.caption,
  [props.theme.breakpoints.up("tablet")]: {
    ...props.theme.typography.bodyText,
  },
}));

export const Table = (props: TableProps) => {
  const theme = useTheme();

  return (
    <TableContainer>
      <MuiTable>
        <TableHead>
          {props.topTitle && (
            <TableRow>
              <TableTopTitle
                colSpan={props.rows[0].length}
                sx={{
                  color: "text.800",
                  backgroundColor: theme.palette.secondary.A200,
                  p: {
                    mobile: `${theme.spacing(2)} ${theme.spacing(1)}`,
                    tablet: theme.spacing(2),
                  },
                  borderBottom: `${theme.spacing(0.5)} solid ${theme.palette.background.default}`,
                  borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
                }}
              >
                {props.topTitle}
              </TableTopTitle>
            </TableRow>
          )}
          {props.columnTitles && (
            <TableRow
              sx={{
                "&>th:first-of-type": {
                  borderRadius: props.topTitle ? 0 : `${theme.spacing(1)} 0 0 0`,
                },
                "&>th:last-of-type": {
                  borderRadius: props.topTitle ? 0 : `0 ${theme.spacing(1)} 0 0`,
                },
              }}
            >
              {props.columnTitles.map((columnTitle, columnTitleIndex) => {
                const sx = columnTitleIndex === 0 && props.leftColumnSx ? props.leftColumnSx : {};

                return (
                  <StyledTableTitleCell
                    key={columnTitleIndex}
                    sx={{
                      bgcolor: props.topTitle
                        ? theme.palette.primary[400]
                        : theme.palette.secondary.A200,
                      color: props.topTitle ? "inherit" : theme.palette.text.secondary,
                      borderBottom: `${theme.spacing(0.5)} solid ${
                        theme.palette.background.default
                      }`,
                      p: {
                        mobile: `${theme.spacing(2.5)} ${theme.spacing(1)}`,
                        tablet: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
                        laptop: theme.spacing(2),
                      },
                      width: `${(1 / props.rows[0].length) * 100}%`,
                      textAlign: "center",
                      ...sx,
                    }}
                  >
                    {columnTitle}
                  </StyledTableTitleCell>
                );
              })}
            </TableRow>
          )}
        </TableHead>
        <TableBody
          sx={{
            "&>tr:first-of-type": {
              "&>td:first-of-type": {
                borderRadius:
                  !props.topTitle && !props.columnTitles ? `${theme.spacing(1)} 0 0 0` : 0,
              },
              "&>td:last-of-type": {
                borderRadius:
                  !props.topTitle && !props.columnTitles ? `0 ${theme.spacing(1)} 0 0` : 0,
              },
            },
            "&>tr:last-of-type": {
              "&>td:first-of-type": { borderRadius: `0 0 0 ${theme.spacing(1)}` },
              "&>td:last-of-type": { borderRadius: `0 0 ${theme.spacing(1)} 0` },
            },
          }}
        >
          {props.rows.map((row, index) => (
            <TableRow key={index} sx={{ "&:last-of-type>td": { borderBottom: "none" } }}>
              {row.map((cell, cellIndex) => {
                const sx = cellIndex === 0 && props.leftColumnSx ? props.leftColumnSx : {};

                return (
                  <StyledTableCell
                    key={cellIndex}
                    sx={{
                      position: cell.tag ? "relative" : undefined,
                      lineHeight: 1.5,
                      p: {
                        mobile: `${theme.spacing(2.5)} ${theme.spacing(1)}`,
                        tablet: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
                        laptop: theme.spacing(2),
                      },
                      borderBottom: `${theme.spacing(0.5)} solid ${
                        theme.palette.background.default
                      }`,
                      width: `${(1 / props.rows[0].length) * 100}%`,
                      textAlign: "center",
                      backgroundColor: cell.highlighted
                        ? theme.palette.primary[100]
                        : theme.palette.primary[400],
                      ...sx,
                    }}
                  >
                    {cell.value}
                    {cell.tag && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: `0 0 0 ${theme.spacing(0.5)}`,
                          padding: `0 ${theme.spacing(0.5)}`,
                          lineHeight: 1,
                        }}
                      >
                        <Typography variant="captionSemibold">{cell.tag}</Typography>
                      </Box>
                    )}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
