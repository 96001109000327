/**
 * The website is developed or fully enabled in these locales.
 * @note Sort alphabetically by country name in English.
 */
const LOCALES = [
  { code: "de-at", country: "Austria", countryCode: "AT", language: "German", enabled: true },
  { code: "fr-be", country: "Belgium", countryCode: "BE", language: "French", enabled: true },
  { code: "nl-be", country: "Belgium", countryCode: "BE", language: "Dutch", enabled: true },
  { code: "da-dk", country: "Denmark", countryCode: "DK", language: "Danish", enabled: true },
  { code: "fi-fi", country: "Finland", countryCode: "FI", language: "Finnish", enabled: true },
  { code: "fr-fr", country: "France", countryCode: "FR", language: "French", enabled: true },
  { code: "it-it", country: "Italy", countryCode: "IT", language: "Italian", enabled: true },
  { code: "fr-lu", country: "Luxembourg", countryCode: "LU", language: "French", enabled: true },
  { code: "es-es", country: "Spain", countryCode: "ES", language: "Spanish", enabled: true },
  { code: "ca-es", country: "Spain", countryCode: "ES", language: "Catalan", enabled: false },
  { code: "en-es", country: "Spain", countryCode: "ES", language: "English", enabled: false },
  { code: "sv-se", country: "Sweden", countryCode: "SE", language: "Swedish", enabled: true },
  { code: "de-ch", country: "Switzerland", countryCode: "CH", language: "German", enabled: true },
  { code: "fr-ch", country: "Switzerland", countryCode: "CH", language: "French", enabled: true },
  { code: "it-ch", country: "Switzerland", countryCode: "CH", language: "Italian", enabled: true },
  {
    code: "en",
    country: "International",
    countryCode: undefined,
    language: "English",
    enabled: true,
  },
] as const;

type Locale = (typeof LOCALES)[number];
type EnabledLocale = Extract<Locale, { enabled: true }>;
type DisabledLocale = Extract<Locale, { enabled: false }>;

/**
 * These are all development locale codes.
 */
export const LOCALE_CODES = LOCALES.map((locale) => locale.code);
export type EnabledLocaleCode = EnabledLocale["code"];
/**
 * These locale codes are _included_ in website navigation and sitemap.
 */
export const ENABLED_LOCALE_CODES = LOCALES.filter((locale) => locale.enabled).map(
  (locale) => locale.code
) as EnabledLocaleCode[];

/**
 * These locale codes are _excluded_ from website navigation and sitemap.
 */
export const DISABLED_LOCALE_CODES = LOCALES.filter((locale) => !locale.enabled).map(
  (locale) => locale.code
) as DisabledLocale["code"][];

export const ENABLED_LOCALES = LOCALES.filter((locale) => locale.enabled) as EnabledLocale[];

/**
 * Country code in ISO 3166-1 alpha-2 format
 */
export type CountryCode = "AT" | "BE" | "DK" | "FI" | "FR" | "IT" | "LU" | "ES" | "SE" | "CH";

/**
 * Country name in English
 */
export type CountryName = Locale["country"];

export type Country = {
  /** ISO 3166-1 alpha-2 */
  code: CountryCode;
  /** Country name */
  name?: CountryName;
};

export type State = {
  /** State code E.g. B */
  code?: string;
  /** Odoo compliant state name E.g. Alacant (Alicante) */
  name: string;
};

/**
 * Countries list with ISO 3166-1 alpha-2 code and name
 */
export const COUNTRIES = LOCALES.map(({ countryCode, country }) => ({
  code: countryCode,
  name: country,
})).filter(
  (value, index, self) =>
    self.findIndex((value2) => value2.code === value.code) === index &&
    // filter out original countryCode undefined i.e. "International"
    value.code !== undefined
) as Country[];

/** Sendcloud allows max 30 characters */
export const SENDCLOUD_COUNTRIES: CountryCode[] = ["AT", "CH", "ES", "IT", "FR", "BE", "LU"];

/** Countries that have line2 input enabled */
export const LINE_2_COUNTRIES: CountryCode[] = ["BE", "CH", "ES", "FR", "LU"];

/** Countries that have house number input enabled */
export const HOUSE_NUMBER_COUNTRIES: CountryCode[] = ["AT", "BE", "CH", "ES", "IT", "FR", "LU"];

/** Countries that have line1 and house number reversed */
export const REVERSED_HOUSE_NUMBER_COUNTRIES: (CountryCode | CountryName)[] = [
  "FR",
  "LU",
  "France",
  "Luxembourg",
];

/** Countries that require SSN */
export const SSN_COUNTRIES: CountryCode[] = ["DK", "ES", "FI", "IT", "SE"];
/** Countries that require birthday */
export const BIRTHDAY_COUNTRIES: CountryCode[] = ["AT", "BE", "CH", "ES", "FR", "LU"];
/** Countries that require identity number */
export const IDENTITY_NUMBER_COUNTRIES: CountryCode[] = ["IT"];

/** States by Countries to choose from */
export const STATES_BY_COUNTRY: Record<CountryCode, State[]> = {
  AT: [],
  BE: [],
  CH: [],
  DK: [],
  FI: [],
  FR: [],
  LU: [],
  SE: [],
  ES: [
    { code: "C", name: "A Coruña (La Coruña)" },
    { code: "A", name: "Alacant (Alicante)" },
    { code: "AB", name: "Albacete" },
    { code: "AL", name: "Almería" },
    { code: "VI", name: "Araba/Álava" },
    { code: "O", name: "Asturias" },
    { code: "AV", name: "Ávila" },
    { code: "BA", name: "Badajoz" },
    { code: "B", name: "Barcelona" },
    { code: "BI", name: "Bizkaia (Vizcaya)" },
    { code: "BU", name: "Burgos" },
    { code: "CC", name: "Cáceres" },
    { code: "CA", name: "Cádiz" },
    { code: "S", name: "Cantabria" },
    { code: "CS", name: "Castelló (Castellón)" },
    { code: "CR", name: "Ciudad Real" },
    { code: "CO", name: "Córdoba" },
    { code: "CU", name: "Cuenca" },
    { code: "SS", name: "Gipuzkoa (Guipúzcoa)" },
    { code: "GI", name: "Girona (Gerona)" },
    { code: "GR", name: "Granada" },
    { code: "GU", name: "Guadalajara" },
    { code: "H", name: "Huelva" },
    { code: "HU", name: "Huesca" },
    { code: "J", name: "Jaén" },
    { code: "LO", name: "La Rioja" },
    { code: "LE", name: "León" },
    { code: "L", name: "Lleida (Lérida)" },
    { code: "LU", name: "Lugo" },
    { code: "M", name: "Madrid" },
    { code: "MA", name: "Málaga" },
    { code: "MU", name: "Murcia" },
    { code: "NA", name: "Navarra (Nafarroa)" },
    { code: "OR", name: "Ourense (Orense)" },
    { code: "P", name: "Palencia" },
    { code: "PO", name: "Pontevedra" },
    { code: "SA", name: "Salamanca" },
    { code: "SG", name: "Segovia" },
    { code: "SE", name: "Sevilla" },
    { code: "SO", name: "Soria" },
    { code: "T", name: "Tarragona" },
    { code: "TE", name: "Teruel" },
    { code: "TO", name: "Toledo" },
    { code: "V", name: "València (Valencia)" },
    { code: "VA", name: "Valladolid" },
    { code: "ZA", name: "Zamora" },
    { code: "Z", name: "Zaragoza" },
  ],
  IT: [
    { code: "AG", name: "Agrigento" },
    { code: "AL", name: "Alessandria" },
    { code: "AN", name: "Ancona" },
    { code: "AO", name: "Aosta" },
    { code: "AR", name: "Arezzo" },
    { code: "AP", name: "Ascoli Piceno" },
    { code: "AT", name: "Asti" },
    { code: "AV", name: "Avellino" },
    { code: "BA", name: "Bari" },
    { code: "BT", name: "Barletta-Andria-Trani" },
    { code: "BL", name: "Belluno" },
    { code: "BN", name: "Benevento" },
    { code: "BG", name: "Bergamo" },
    { code: "BI", name: "Biella" },
    { code: "BO", name: "Bologna" },
    { code: "BZ", name: "Bolzano" },
    { code: "BS", name: "Brescia" },
    { code: "BR", name: "Brindisi" },
    { code: "CA", name: "Cagliari" },
    { code: "CL", name: "Caltanissetta" },
    { code: "CB", name: "Campobasso" },
    { code: "CI", name: "Carbonia-Iglesias" },
    { code: "CE", name: "Caserta" },
    { code: "CT", name: "Catania" },
    { code: "CZ", name: "Catanzaro" },
    { code: "CH", name: "Chieti" },
    { code: "CO", name: "Como" },
    { code: "CS", name: "Cosenza" },
    { code: "CR", name: "Cremona" },
    { code: "KR", name: "Crotone" },
    { code: "CN", name: "Cuneo" },
    { code: "EN", name: "Enna" },
    { code: "FM", name: "Fermo" },
    { code: "FE", name: "Ferrara" },
    { code: "FI", name: "Firenze" },
    { code: "FG", name: "Foggia" },
    { code: "FC", name: "Forlì-Cesena" },
    { code: "FR", name: "Frosinone" },
    { code: "GE", name: "Genova" },
    { code: "GO", name: "Gorizia" },
    { code: "GR", name: "Grosseto" },
    { code: "IM", name: "Imperia" },
    { code: "IS", name: "Isernia" },
    { code: "AQ", name: "L'Aquila" },
    { code: "SP", name: "La Spezia" },
    { code: "LT", name: "Latina" },
    { code: "LE", name: "Lecce" },
    { code: "LC", name: "Lecco" },
    { code: "LI", name: "Livorno" },
    { code: "LO", name: "Lodi" },
    { code: "LU", name: "Lucca" },
    { code: "MC", name: "Macerata" },
    { code: "MN", name: "Mantova" },
    { code: "MS", name: "Massa-Carrara" },
    { code: "MT", name: "Matera" },
    { code: "VS", name: "Medio Campidano" },
    { code: "ME", name: "Messina" },
    { code: "MI", name: "Milano" },
    { code: "MO", name: "Modena" },
    { code: "MB", name: "Monza e Brianza" },
    { code: "NA", name: "Napoli" },
    { code: "NO", name: "Novara" },
    { code: "NU", name: "Nuoro" },
    { code: "OG", name: "Ogliastra" },
    { code: "OT", name: "Olbia-Tempio" },
    { code: "OR", name: "Oristano" },
    { code: "PD", name: "Padova" },
    { code: "PA", name: "Palermo" },
    { code: "PR", name: "Parma" },
    { code: "PV", name: "Pavia" },
    { code: "PG", name: "Perugia" },
    { code: "PU", name: "Pesaro e Urbino" },
    { code: "PE", name: "Pescara" },
    { code: "PC", name: "Piacenza" },
    { code: "PI", name: "Pisa" },
    { code: "PT", name: "Pistoia" },
    { code: "PN", name: "Pordenone" },
    { code: "PZ", name: "Potenza" },
    { code: "PO", name: "Prato" },
    { code: "RG", name: "Ragusa" },
    { code: "RA", name: "Ravenna" },
    { code: "RC", name: "Reggio Calabria" },
    { code: "RE", name: "Reggio Emilia" },
    { code: "RI", name: "Rieti" },
    { code: "RN", name: "Rimini" },
    { code: "RM", name: "Roma" },
    { code: "RO", name: "Rovigo" },
    { code: "SA", name: "Salerno" },
    { code: "SS", name: "Sassari" },
    { code: "SV", name: "Savona" },
    { code: "SI", name: "Siena" },
    { code: "SR", name: "Siracusa" },
    { code: "SO", name: "Sondrio" },
    { code: "SU", name: "Sud Sardegna" },
    { code: "TA", name: "Taranto" },
    { code: "TE", name: "Teramo" },
    { code: "TR", name: "Terni" },
    { code: "TO", name: "Torino" },
    { code: "TP", name: "Trapani" },
    { code: "TN", name: "Trento" },
    { code: "TV", name: "Treviso" },
    { code: "TS", name: "Trieste" },
    { code: "UD", name: "Udine" },
    { code: "VA", name: "Varese" },
    { code: "VE", name: "Venezia" },
    { code: "VB", name: "Verbano-Cusio-Ossola" },
    { code: "VC", name: "Vercelli" },
    { code: "VR", name: "Verona" },
    { code: "VV", name: "Vibo Valentia" },
    { code: "VI", name: "Vicenza" },
    { code: "VT", name: "Viterbo" },
  ],
};
