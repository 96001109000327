import type { FC } from "react";
import { useState } from "react";

import { Done, IOSArrowDown } from "@augment-frontend/mui-icons";
import { Box, Divider, MenuItem, Select, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { format, subDays } from "date-fns";

import type { MileagePeriodSelectorProps } from "../Modals/MileagePeriodSelector";

import { LinearProgress } from "../../../../LinearProgress";
import { MileagePeriodSelector } from "../Modals/MileagePeriodSelector";

export interface MileageProps {
  /**
   * Callback for submitting mileage by selection, triggered if mileage isn't known.
   * Should return mileage amount & distance unit for Device by given dates.
   */
  onSubmit: (value: { startDate: Date; endDate: Date }) => Promise<string>;
  items: {
    /** E.g. "month" */
    value: string;
    /** E.g. "This month" */
    label: string;
    /** E.g. "350km" */
    mileage: string;
    /** Initial selected item */
    initial?: boolean;
  }[];
  translations: {
    /** E.g. "Mileage statistics" */
    description: string;
    /** E.g. "Results for the selected period" */
    resultsForPeriod: string;
    mileagePeriodSelector: MileagePeriodSelectorProps["translations"];
  };
}

const StyledMenuItem = styled(MenuItem)((props) => ({
  margin: props.theme.spacing(1),
  padding: props.theme.spacing(1),
  borderRadius: props.theme.shape.borderRadius,
}));

export const Mileage: FC<MileageProps> = (props) => {
  const initialItem = props.items.find((item) => item.initial === true) || props.items[0];
  const [items, setItems] = useState(props.items);
  const [selectedItem, setSelectedItem] = useState(initialItem);
  const [selectedDates, setSelectedDates] = useState({
    startDateValue: null as Date | null,
    endDateValue: null as Date | null,
    formattedStartDate: "",
    formattedEndDate: "",
  });
  const [periodModalOpen, setPeriodModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const handleMenuItemClick = (value: string) => {
    if (value === "period") {
      setPeriodModalOpen(true);
    }
  };

  const handleSubmit = async (startDate: Date, endDate: Date, selected: typeof selectedItem) => {
    const mileageBySelection = await props.onSubmit({ startDate, endDate });
    setSelectedItem({ ...selected, mileage: mileageBySelection });
    if (mileageBySelection !== "-") {
      setItems((prev) => {
        // save mileage to items-state so it wont be fetched again
        const itemToUpdate = prev.find((item) => item.value === selected.value);
        if (itemToUpdate) {
          itemToUpdate.mileage = mileageBySelection;
        }
        const updatedItems = [...prev];
        return updatedItems;
      });
    }
    setSelectedDates({
      startDateValue: startDate,
      endDateValue: endDate,
      formattedStartDate: format(startDate, "dd.MM.yyyy"),
      formattedEndDate: format(endDate, "dd.MM.yyyy"),
    });
  };

  const handleChange = async (value: string) => {
    const selected = items.find((item) => item.value === value);
    if (selected) {
      if (selected.mileage !== "") {
        // mileage is known, no need to submit
        setSelectedItem(selected);
      } else {
        // mileage isn't known, submit dates to get mileage
        setLoading(true);
        const today = new Date();
        today.setHours(23, 59, 59, 999);

        switch (selected.value) {
          case "week": {
            const startDate = new Date(subDays(today, 7));
            startDate.setHours(0, 0, 0, 0);
            await handleSubmit(startDate, today, selected);
            break;
          }
          case "month": {
            const startDate = new Date(subDays(today, 30));
            startDate.setHours(0, 0, 0, 0);
            await handleSubmit(startDate, today, selected);
            break;
          }
          case "year": {
            const startDate = new Date(subDays(today, 365));
            startDate.setHours(0, 0, 0, 0);
            await handleSubmit(startDate, today, selected);
            break;
          }
          case "total": {
            // should be always known and initial value
            setSelectedItem(selected);
            break;
          }
          case "period": {
            // has own handler/modal
            setSelectedItem(selected);
            break;
          }
          default:
            break;
        }
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Divider
        sx={{
          borderColor: theme.palette.text[200],
          marginBottom: theme.spacing(2),
        }}
      />
      <Stack
        direction={{ mobile: "column", tablet: "row" }}
        justifyContent="space-between"
        alignItems={{ mobile: "start", tablet: "center" }}
        marginBottom={theme.spacing(2)}
      >
        <Typography variant="bodyText" marginBottom={{ mobile: theme.spacing(2), tablet: 0 }}>
          {props.translations.description}
        </Typography>
        {loading ? (
          <LinearProgress
            sx={{
              width: { mobile: "100%", tablet: "25%" },
              marginY: 1.25, // To prevent layout shift
            }}
          />
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            spacing={theme.spacing(1.25)}
            justifyContent={{ mobile: "space-between", tablet: "normal" }}
            sx={{ width: { mobile: "100%", tablet: "auto" } }}
          >
            <Typography variant="subheadingBold">{selectedItem.mileage}</Typography>
            <Select
              variant="standard"
              disableUnderline
              value={selectedItem.value}
              onChange={(event) => handleChange(event.target.value)}
              IconComponent={() => null}
              renderValue={() => (
                <Stack direction="row" spacing={theme.spacing(0.5)}>
                  <Typography variant="bodyTextBold">{selectedItem.label}</Typography>
                  <IOSArrowDown sx={{ color: "inherit" }} />
                </Stack>
              )}
              SelectDisplayProps={{
                style: { paddingBottom: theme.spacing(0.5), paddingTop: theme.spacing(0.5) },
              }}
            >
              {props.items.map((item) => (
                <StyledMenuItem
                  key={item.value}
                  value={item.value}
                  onClick={() => handleMenuItemClick(item.value)}
                >
                  <Stack direction="row" alignItems="center" spacing={theme.spacing(3)}>
                    <Typography variant="bodyTextBold">{item.label}</Typography>
                    {item.value === selectedItem.value && (
                      <Done sx={{ color: theme.palette.text.primary }} />
                    )}
                  </Stack>
                </StyledMenuItem>
              ))}
            </Select>
          </Stack>
        )}
      </Stack>
      {selectedItem.value === "period" && selectedItem.mileage !== "" && (
        <Box textAlign={{ mobile: "start", tablet: "end" }} marginTop={-2.5} marginBottom={2}>
          <Typography variant="caption" color={theme.palette.text[600]}>
            {props.translations.resultsForPeriod}{" "}
            <Typography variant="captionSemibold" component="span">
              {selectedDates.formattedStartDate} - {selectedDates.formattedEndDate}
            </Typography>
          </Typography>
        </Box>
      )}
      <Divider sx={{ borderColor: theme.palette.text[200] }} />
      {periodModalOpen && (
        <MileagePeriodSelector
          onClose={() => setPeriodModalOpen(false)}
          onSubmit={async (value) => {
            const periodSelection = items.find((item) => item.value === "period");
            if (periodSelection) {
              await handleSubmit(value.startDate, value.endDate, periodSelection);
            }
          }}
          open={periodModalOpen}
          value={
            selectedDates.startDateValue && selectedDates.endDateValue
              ? { startDate: selectedDates.startDateValue, endDate: selectedDates.endDateValue }
              : undefined
          }
          translations={{ ...props.translations.mileagePeriodSelector }}
        />
      )}
    </>
  );
};
