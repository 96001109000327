import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Title = styled(Typography)((props) => ({
  ...props.theme.typography.largeTextBold,
  marginBottom: props.theme.spacing(2),
  [props.theme.breakpoints.up("tablet")]: {
    ...props.theme.typography.subheadingBold,
    marginBottom: props.theme.spacing(4),
  },
}));
