import type { FC } from "react";

import { Grid, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { Surface } from "../../../Surface";

export const Title = styled(Typography)((props) => ({
  ...props.theme.typography.largeTextBold,
  marginBottom: props.theme.spacing(0.75),
  [props.theme.breakpoints.up("tablet")]: {
    ...props.theme.typography.subheadingBold,
    marginBottom: props.theme.spacing(2),
  },
}));

export interface AddressProps {
  name: string | null;
  street: string | null;
  street2: string | null;
  houseNumber: string | null;
  zip: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  /** ISO 3166-1 alpha-2 */
  countryCode: string | null;
  translations: {
    title: string;
    nameTitle: string;
    addressTitle: string;
  };
}

export const Address: FC<AddressProps> = (props) => {
  const theme = useTheme();

  return (
    <Surface
      sx={{
        bgcolor: theme.palette.secondary[200],
      }}
    >
      <Title>{props.translations.title}</Title>
      <Grid container spacing={2}>
        <Grid item mobile={12} tablet={4}>
          <Typography variant="bodyTextBold">{props.translations.nameTitle}</Typography>
          <Typography variant="bodyText">{props.name}</Typography>
        </Grid>
        <Grid item mobile={12} tablet={8}>
          <Typography variant="bodyTextBold">{props.translations.addressTitle}</Typography>
          <Typography variant="bodyText">
            {props.countryCode === "FR"
              ? `${props.houseNumber ?? ""} ${props.street ?? ""}`.trim()
              : `${props.street ?? ""} ${props.houseNumber ?? ""}`.trim()}
          </Typography>
          <Typography variant="bodyText">{props.street2}</Typography>
          <Typography variant="bodyText">
            {props.zip} {props.city}
          </Typography>
          <Typography variant="bodyText">{props.state}</Typography>
          <Typography variant="bodyText">{props.country}</Typography>
        </Grid>
      </Grid>
    </Surface>
  );
};
