import { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import { stripDiacritics } from "../../../utils/diacritics";
import { TextField } from "../../TextField";

export type NameResult = {
  firstName?: string;
  lastName?: string;
  valid: boolean;
};

export interface NameInputProps {
  /** First name */
  firstName?: string;
  /** Last name */
  lastName?: string;
  /** Sets inputs in immutable state, i.e. values cannot be changed if they pass validation */
  immutable?: boolean;
  /** Sets all inputs in disabled state, e.g. when parent is loading */
  disabled?: boolean;
  /** Callback for when a value of an input changes */
  onChange: (result: NameResult) => void;
  translations: {
    /** First name input label */
    firstNameLabel: string;
    /** Last name input label */
    lastNameLabel: string;
  };
}

/**
 * Validates first and last name.
 * @param field Input id
 * @param value Input value
 * @returns Boolean
 */
export const validateName = (field: "firstName" | "lastName", value: string): boolean => {
  const regex = /^(?![.’'/-])[a-zøæ .’'/-]{2,30}$/gi;
  const charactersNotAllowedLast = " .’'/-";

  switch (field) {
    case "firstName":
      return !(
        !regex.test(stripDiacritics(value)) ||
        // Dot (.) is allowed as last character when first name is e.g. "John F."
        charactersNotAllowedLast.replace(".", "").includes(value.charAt(value.length - 1))
      );
    case "lastName":
      return !(
        !regex.test(stripDiacritics(value)) ||
        charactersNotAllowedLast.includes(value.charAt(value.length - 1))
      );
    default:
      return false;
  }
};

/**
 * Renders first and last name inputs. Needs a parent <Grid container> component.
 */
export const NameInput = (props: NameInputProps) => {
  const [nameState, setNameState] = useState(() => {
    const firstNameValid =
      props.firstName === undefined ? undefined : validateName("firstName", props.firstName);
    const lastNameValid =
      props.lastName === undefined ? undefined : validateName("lastName", props.lastName);

    return {
      firstName: {
        value: props.firstName ?? "",
        valid: firstNameValid,
      },
      lastName: {
        value: props.lastName ?? "",
        valid: lastNameValid,
      },
      valid: firstNameValid && lastNameValid,
    };
  });
  const [disabled, setDisabled] = useState(props.disabled || false);

  useEffect(() => {
    props.onChange({
      firstName: nameState.firstName.value,
      lastName: nameState.lastName.value,
      // Validate also on initial load
      valid: !!nameState.firstName.valid && !!nameState.lastName.valid,
    });
  }, [nameState]);

  useEffect(() => {
    setDisabled(props.disabled || false);
  }, [props.disabled]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target.id as "firstName" | "lastName";
    const value = event.target.value.trimStart().replace("  ", " ").replace("..", ".");

    setNameState((prev) => ({
      ...prev,
      [field]: {
        value: value.charAt(0).toUpperCase() + value.slice(1),
        valid: validateName(field, value),
      },
    }));
  };

  return (
    <>
      <Grid item mobile={5}>
        <TextField
          fullWidth
          id="firstName"
          inputProps={{ "data-testid": "firstName" }}
          label={props.translations.firstNameLabel}
          value={nameState.firstName.value}
          error={
            nameState.firstName.valid === undefined && !props.lastName
              ? undefined
              : !nameState.firstName.valid
          }
          onChange={handleChange}
          disabled={disabled || (props.immutable && !!nameState.valid)}
        />
      </Grid>
      <Grid item mobile={7}>
        <TextField
          fullWidth
          id="lastName"
          inputProps={{ "data-testid": "lastName" }}
          label={props.translations.lastNameLabel}
          value={nameState.lastName.value}
          error={
            nameState.lastName.valid === undefined && !props.firstName
              ? undefined
              : !nameState.lastName.valid
          }
          onChange={handleChange}
          disabled={disabled || (props.immutable && !!nameState.valid)}
        />
      </Grid>
    </>
  );
};
