import type { FC } from "react";
import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { PaymentMethodModalHeaderProps } from "../PaymentMethodModalHeader";

import { Alert } from "../../../Alert";
import { ProgrammaticModal } from "../../../Modal";
import { PaymentMethodModalHeader } from "../PaymentMethodModalHeader";

export type DeletePaymentMethodModalProps = {
  /** Listener for on delete -event */
  onSubmit: () => Promise<void>;
  /** Listener for modal on close -event */
  onClose: () => void;
  /** Toggle modal open/closed */
  open: boolean;
  translations: {
    paymentMethodModalHeader: PaymentMethodModalHeaderProps["translations"];
    /** E.g. "Delete payment method?" */
    description: string;
    /** E.g. "Cancel" */
    cancelButton: string;
    /** E.g. "Delete" */
    deleteButton: string;
  };
} & Omit<PaymentMethodModalHeaderProps, "translations">;

export const DeletePaymentMethodModal: FC<DeletePaymentMethodModalProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [onDeleteError, setOnDeleteError] = useState("");
  const theme = useTheme();

  const handleClick = async () => {
    setLoading(true);
    try {
      await props.onSubmit();
      props.onClose();
      setOnDeleteError("");
    } catch (error) {
      setOnDeleteError(error instanceof Error ? error.message : "Unknown error");
    }
    setLoading(false);
  };

  return (
    <ProgrammaticModal
      index={0}
      open={props.open}
      onClose={() => {
        props.onClose();
        setOnDeleteError("");
      }}
      views={[
        <Box
          key={"deletePaymentMethod"}
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <PaymentMethodModalHeader
            paymentMethod={props.paymentMethod}
            icon={props.icon}
            translations={props.translations.paymentMethodModalHeader}
          />
          <Typography
            variant="bodyTextBold"
            marginBottom={theme.spacing(3)}
            marginTop={theme.spacing(2)}
            textAlign="center"
          >
            {props.translations.description}
          </Typography>
          {onDeleteError && (
            <Alert
              severity="error"
              description={onDeleteError}
              sx={{ marginBottom: theme.spacing(2), alignSelf: "stretch" }}
            />
          )}
          <Stack direction="row" spacing={theme.spacing(2)}>
            <Button
              variant="outlined"
              onClick={() => {
                props.onClose();
                setOnDeleteError("");
              }}
            >
              {props.translations.cancelButton}
            </Button>
            <LoadingButton loading={loading} onClick={handleClick} data-testid="confirmDelete">
              {props.translations.deleteButton}
            </LoadingButton>
          </Stack>
        </Box>,
      ]}
    />
  );
};
