import type { SSNHandler, SSNResult } from "./types";

/**
 * Calculate the validity of a Spanish NIE.
 */
export const spanishNIE = (ssn: string): boolean => {
  const NIE_REGEX = /^[MXYZ]{1}[0-9]{7,8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/;
  const NIE_CONTROL_CAHARCTERS = "TRWAGMYFPDXBNJZSQVHLCKET";

  if (!NIE_REGEX.test(ssn)) {
    return false;
  }

  // control character validation
  const letter = ssn.substring(ssn.length - 1);
  const replaced = ssn.replace(/^[X]/, "0").replace(/^[Y]/, "1").replace(/^[Z]/, "2");
  const number = replaced.substring(0, replaced.length === 9 ? 8 : 9);

  return NIE_CONTROL_CAHARCTERS.charAt(parseInt(number, 10) % 23) === letter;
};

/**
 * Calculate the validity of a Spanish NIF.
 */
export const spanishNIF = (ssn: string): boolean => {
  const NIF_REGEX = /((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)/;
  const NIF_CONTROL_CHARACTERS = "TRWAGMYFPDXBNJZSQVHLCKE";

  if (!NIF_REGEX.test(ssn)) {
    return false;
  }

  // control character validation
  if (/^[0-9]{8}[A-Z]{1}$/.test(ssn)) {
    return NIF_CONTROL_CHARACTERS.charAt(parseInt(ssn.substring(8, 0), 10) % 23) === ssn.charAt(8);
  }

  // specials NIFs (starts with K, L or M)
  if (/^[KLM]{1}/.test(ssn)) {
    return NIF_CONTROL_CHARACTERS.charAt(parseInt(ssn.substring(8, 1), 10) % 23) === ssn.charAt(8);
  }

  return false;
};

/**
 * Calculate the validity of a Spanish SSN (NIE or NIF).
 * @returns Validity of SSN and -1 for age (not available for Spanish SSN).
 */
export const spanishSSN: SSNHandler = (ssn) => {
  const valid = spanishNIE(ssn) || spanishNIF(ssn);
  const result: SSNResult = {
    valid,
    age: -1,
    standardizedSSN: valid ? ssn : null,
  };

  return result;
};
