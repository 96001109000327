import type { ChangeEvent, FC, FormEvent } from "react";
import { useState } from "react";

import { CloseRound, VisibilityOff, VisibilityOn } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Grid, IconButton, InputAdornment, Link, Snackbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import isEmail from "validator/es/lib/isEmail";

import { Alert } from "../../Alert";
import { TextField } from "../../TextField";
import { Container } from "../Container";

export interface AuthLoginProps {
  onSubmit: (username: string, password: string) => Promise<Error | void>;
  /** E.g. Gatsby Link. */
  registerLinkComponent: React.ElementType;
  /** E.g. Gatsby Link. */
  forgotPasswordLinkComponent: React.ElementType;
  translations: {
    /** E.g. Sign in */
    title: string;
    /** E.g. "Email" */
    emailLabel: string;
    /** E.g. "Password" */
    passwordLabel: string;
    /** E.g. Sign in */
    loginButton: string;
    /** E.g. Register */
    register: string;
    /** E.g. Forgot passoword? */
    forgotPassword: string;
    /** E.g. No account? */
    noAccount: string;
    /** E.g. Oh no */
    errorTitle: string;
  };
}

const credentialsInitialState = {
  username: "",
  usernameError: false,
  password: "",
  passwordError: false,
};

export const AuthLogin: FC<AuthLoginProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [credentials, setCredentials] =
    useState<typeof credentialsInitialState>(credentialsInitialState);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [onSubmitError, setOnSubmitError] = useState<string>("");
  const [displaySnackbar, setDisplaySnackbar] = useState<boolean>(false);
  const theme = useTheme();

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value =
      event.target.name === "username"
        ? event.target.value.toLowerCase().replace(/\s/g, "")
        : event.target.value.trimStart();

    const error =
      event.target.name === "username" && !isEmail(value, { domain_specific_validation: true });

    setCredentials((prev) => ({
      ...prev,
      [event.target.name]: value,
      [`${event.target.name}Error`]: error,
    }));
  };

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnSubmitError("");
    setLoading(true);
    try {
      await props.onSubmit(credentials.username, credentials.password);
    } catch (e: unknown) {
      setOnSubmitError(e instanceof Error ? e.message : "Unknown error");
      setLoading(false);
      setDisplaySnackbar(true);
    }
  };

  const handleClose = () => {
    setDisplaySnackbar(false);
  };

  return (
    <>
      <Container>
        <Typography variant="subheadingBold" mb={4}>
          {props.translations.title}
        </Typography>
        <Grid container spacing={2} direction="column" component="form" onSubmit={onSubmitHandler}>
          <Grid item>
            <TextField
              fullWidth
              type="text"
              inputProps={{ inputMode: "email" }}
              name="username"
              label={props.translations.emailLabel}
              value={credentials.username}
              onChange={onChangeHandler}
              disabled={loading}
              data-testid="username"
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type={passwordVisible ? "text" : "password"}
              name="password"
              label={props.translations.passwordLabel}
              value={credentials.password}
              onChange={onChangeHandler}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setPasswordVisible((prev) => !prev)}
                    sx={{ cursor: "pointer" }}
                  >
                    {passwordVisible ? (
                      <VisibilityOff sx={{ color: theme.palette.text.primary }} />
                    ) : (
                      <VisibilityOn sx={{ color: theme.palette.text.primary }} />
                    )}
                  </InputAdornment>
                ),
              }}
              data-testid="password"
            />
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              fullWidth
              disabled={
                credentials.usernameError ||
                !credentials.username ||
                credentials.passwordError ||
                !credentials.password
              }
              loading={loading}
              data-testid="loginButton"
            >
              {props.translations.loginButton}
            </LoadingButton>
          </Grid>
          <Grid item alignSelf="center">
            <Typography variant="bodyText" mb={2} sx={{ textAlign: "center" }}>
              {props.translations.noAccount}{" "}
              <Link
                component={props.registerLinkComponent}
                sx={{ color: theme.palette.text.primary, textDecoration: "underline" }}
              >
                {props.translations.register}
              </Link>
            </Typography>
            <Typography variant="bodyText" sx={{ textAlign: "center" }}>
              <Link
                component={props.forgotPasswordLinkComponent}
                sx={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
              >
                {props.translations.forgotPassword}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={displaySnackbar} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          severity="error"
          title={props.translations.errorTitle}
          description={onSubmitError}
          action={
            <IconButton sx={{ color: theme.palette.text.primary }} onClick={handleClose}>
              <CloseRound />
            </IconButton>
          }
          forceActionRight
          sx={{
            m: 1,
            width: {
              mobile: "100%",
              tablet: "unset",
            },
          }}
        />
      </Snackbar>
    </>
  );
};
