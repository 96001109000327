import type { FC } from "react";

import { Box } from "@mui/material";

import type { AddressesProps } from "./Addresses";
import type { CredentialsProps } from "./Credentials";

import { Addresses } from "./Addresses";
import { Credentials } from "./Credentials";

export interface MyAccountSettingsProps {
  credentials: Omit<CredentialsProps, "translations">;
  addresses: Omit<AddressesProps, "translations">;
  translations: {
    credentials: CredentialsProps["translations"];
    addresses: AddressesProps["translations"];
  };
}

export const MyAccountSettings: FC<MyAccountSettingsProps> = (props) => (
  <div>
    <Box sx={{ mb: 2 }}>
      <Credentials {...props.credentials} translations={props.translations.credentials} />
    </Box>
    <Addresses {...props.addresses} translations={props.translations.addresses} />
  </div>
);
