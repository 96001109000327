import type { FC } from "react";
import { useState } from "react";

import { Edit, Link as LinkIcon } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { InfoGridProps } from "./InfoGrid";
import type { MileageProps } from "./Mileage";
import type { NameModalProps } from "./Modals";
import type { SharingProps } from "./Modals/Sharing";
import type { CancelSharingProps } from "./Modals/Sharing/CancelSharing";
import type { LinkProps } from "./Modals/Sharing/Link";

import { Surface } from "../../../Surface";
import { Header } from "./Header";
import { InfoGrid } from "./InfoGrid";
import { Mileage } from "./Mileage";
import { NameModal } from "./Modals";
import { Sharing } from "./Modals/Sharing";
import { CancelSharing } from "./Modals/Sharing/CancelSharing";
import { Link as ShareLinkModal } from "./Modals/Sharing/Link";

export interface DeviceProps {
  /** For both device owner and sub user, callback for mileage selection change */
  onMileageSelectionSubmit: (btMac: string, startDate: Date, endDate: Date) => Promise<string>;
  /**
   * For both device owner and sub user, callback for catched error. Can be used e.g. to show
   * error message on snackbar
   */
  onError: (error: string) => void;
  /** For device owner, callback for device name change */
  onNameChangeSubmit?: (btMac: string, name: string) => Promise<Error | void>;
  /**
   * For device owner, callback for device sharing change.
   * Triggered error is catched under the hood
   */
  onSharingChangeSubmit?: SharingProps["onSubmit"];
  /**
   * For device owner, callback for Share Link generation.
   * Should return generated Share Link for the device
   */
  onGenerateShareLink?: (btMac: string) => Promise<string>;
  /**
   * For device sub user, callback for cancelling the right to use device.
   * Triggered error is catched under the hood
   */
  onCancelSharingSubmit?: (btMac: string) => Promise<void | Error>;
  /** Triggered when {@link onCancelSharingSubmit} succeeded */
  onCancelSharingSuccess?: CancelSharingProps["onCompleted"];
  /** Bluetooth MAC address of the device */
  btMac: string;
  /** Name of the device, e.g. "My scooter 1" */
  name: string;
  /** Props for InfoGrid and its items, such as "Current speed limit" with icon and value */
  infoGrid: InfoGridProps;
  /** Props for device mileage items */
  mileage: Omit<MileageProps, "translations" | "onSubmit" | "btMac">;
  /** For device owner, array of sharing receivers */
  sharingReceivers?: SharingProps["receivers"];
  /** For device owner, text to display in the Header with name, e.g. "Shared with 3 people" */
  shareInfo?: string;
  translations: {
    nameModal: NameModalProps["translations"];
    mileage: MileageProps["translations"];
    /** E.g. "Edit sharing" */
    editSharingButton: string;
    /** E.g. "Share e-scooter" */
    shareLinkButton: string;
    /** E.g. "Cancel sharing" */
    cancelSharingButton: string;
    sharing: SharingProps["translations"];
    shareLinkModal: LinkProps["translations"];
    cancelSharingModal: CancelSharingProps["translations"];
  };
}

export const Device: FC<DeviceProps> = (props) => {
  const [sharingReceivers, setSharingReceivers] = useState(props.sharingReceivers || []);
  const [nameModalOpen, setNameModalOpen] = useState(false);
  const [sharingModalOpen, setSharingModalOpen] = useState(false);
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false);
  const [cancelSharingModalOpen, setCancelSharingModalOpen] = useState(false);
  const [generatedShareLink, setGeneratedSharelink] = useState("");
  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const handleShareLinkButtonClick = async () => {
    setLoading(true);
    try {
      const link = (await props.onGenerateShareLink?.(props.btMac)) || "";
      setGeneratedSharelink(link);
      setShareLinkModalOpen(true);
    } catch (error) {
      props.onError(error instanceof Error ? error.message : "Unknown error");
    }
    setLoading(false);
  };

  const handleSharingChangeSubmit = async (
    newReceiversValues: { id: string; endDate: Date | null }[]
  ) => {
    if (props.onSharingChangeSubmit) {
      await props.onSharingChangeSubmit(newReceiversValues);

      const filteredReceivers = newReceiversValues.filter((receiver) => {
        if (receiver.endDate && receiver.endDate < new Date()) {
          return false;
        }
        return true;
      });

      const updatedReceivers = filteredReceivers.map((receiver) => {
        const match = sharingReceivers.filter((item) => receiver.id === item.id);
        return {
          id: receiver.id,
          startDate: match[0].startDate,
          endDate: receiver.endDate,
          name: match[0].name,
        };
      });
      setSharingReceivers(updatedReceivers);
      setSharingModalOpen(false);
    }
  };

  /**
   * Handle mileage selection change, return fetched mileage value.
   * If error is thrown, catch it and return "-" as a mileage value.
   */
  const handleMileageSelectionSubmit = async (startDate: Date, endDate: Date) => {
    try {
      return await props.onMileageSelectionSubmit(props.btMac, startDate, endDate);
    } catch (error) {
      props.onError(error instanceof Error ? error.message : "Unknown error");
      return "-";
    }
  };

  return (
    <Surface
      sx={{
        bgcolor: theme.palette.secondary[200],
        border: `1px solid ${theme.palette.text.primary}`,
        padding: { mobile: theme.spacing(2), tablet: theme.spacing(3) },
      }}
      data-testid="device"
      data-testid-btmac={props.btMac}
    >
      <Stack direction="column" spacing={theme.spacing(2)}>
        {props.onNameChangeSubmit ? (
          <Header
            name={props.name}
            onEditClick={() => setNameModalOpen(true)}
            shareInfo={props.shareInfo}
          />
        ) : (
          <Header name={props.name} />
        )}
        <InfoGrid {...props.infoGrid} />
        <Mileage
          {...props.mileage}
          onSubmit={({ startDate, endDate }) => handleMileageSelectionSubmit(startDate, endDate)}
          translations={props.translations.mileage}
        />
        {props.onGenerateShareLink &&
          (sharingReceivers.length > 0 && props.onSharingChangeSubmit ? (
            <LoadingButton
              onClick={() => setSharingModalOpen(true)}
              fullWidth
              variant="outlined"
              endIcon={<Edit />}
              sx={{ justifyContent: "space-between" }}
              data-testid="openEditSharingModal"
            >
              {props.translations.editSharingButton}
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={handleShareLinkButtonClick}
              loading={loading}
              fullWidth
              variant="outlined"
              endIcon={<LinkIcon />}
              sx={{ justifyContent: "space-between" }}
              data-testid="openShareLinkModal"
            >
              {props.translations.shareLinkButton}
            </LoadingButton>
          ))}
        {props.onCancelSharingSubmit && props.onCancelSharingSuccess && (
          <LoadingButton
            fullWidth
            variant="outlined"
            loading={loading}
            onClick={() => setCancelSharingModalOpen(true)}
            data-testid="openCancelSharingModal"
          >
            {props.translations.cancelSharingButton}
          </LoadingButton>
        )}
      </Stack>
      {props.onNameChangeSubmit && (
        <NameModal
          name={props.name}
          open={nameModalOpen}
          onSubmit={async (value) => props.onNameChangeSubmit?.(props.btMac, value)}
          onCompleted={() => setNameModalOpen(false)}
          onClose={() => setNameModalOpen(false)}
          translations={props.translations.nameModal}
        />
      )}
      {props.onGenerateShareLink && (
        <ShareLinkModal
          open={shareLinkModalOpen}
          onClose={() => setShareLinkModalOpen(false)}
          onCancel={() => setShareLinkModalOpen(false)}
          shareLink={generatedShareLink}
          translations={props.translations.shareLinkModal}
        />
      )}
      {props.onGenerateShareLink && props.onSharingChangeSubmit && (
        <Sharing
          open={sharingModalOpen}
          onClose={() => setSharingModalOpen(false)}
          onSubmit={(receivers) => handleSharingChangeSubmit(receivers)}
          onGenerateShareLink={async () => props.onGenerateShareLink?.(props.btMac) || ""}
          deviceName={props.name}
          receivers={sharingReceivers}
          translations={props.translations.sharing}
        />
      )}
      {props.onCancelSharingSubmit && props.onCancelSharingSuccess && (
        <CancelSharing
          open={cancelSharingModalOpen}
          onClose={() => setCancelSharingModalOpen(false)}
          onCancel={() => setCancelSharingModalOpen(false)}
          onCompleted={props.onCancelSharingSuccess}
          onSubmit={async () => props.onCancelSharingSubmit?.(props.btMac)}
          name={props.name}
          translations={props.translations.cancelSharingModal}
        />
      )}
    </Surface>
  );
};
