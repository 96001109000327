import { useEffect, useState } from "react";

import Mailcheck from "mailcheck";
import isEmail from "validator/es/lib/isEmail";

import { TextField } from "../../TextField";

Mailcheck.defaultDomains.push("augment.eco");

export interface EmailInputProps {
  /** Email initial value */
  email?: string;
  /** Sets input in immutable state, i.e. values cannot be changed if they pass validation */
  immutable?: boolean;
  /** Show success/error validation status, e.g. enabled for register, disabled for login */
  showValidationStatus?: boolean;
  /** Sets input in disabled state, e.g. when parent is loading */
  disabled?: boolean;
  /** Callback for when a value of an input changes */
  onChange: ({ email, valid }: { email: string; valid: boolean }) => void;
  translations: {
    /** Email input label */
    emailLabel: string;
    /** Prefix for suggested suggested email, e.g. "Did you mean" */
    didYouMean: string;
  };
}

/**
 * Renders email input.
 */
export const EmailInput = (props: EmailInputProps) => {
  const [emailState, setEmailState] = useState({
    value: props.email ?? "",
    valid:
      props.email === undefined
        ? undefined
        : isEmail(props.email, { domain_specific_validation: true }),
  });
  const [emailSuggestion, setEmailSuggestion] = useState("");
  // Error and success states control the rendered validation status. They are used to avoid nested
  // ternary operators in the input component. Initial undefined hides validation status, when
  // input is first rendered empty, and never gets updated if showValidationStatus prop is falsey.
  const [error, setError] = useState<boolean | undefined>(undefined);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const getEmailSuggestion = (email: string): string => {
    const result = Mailcheck.run({ email });
    return result?.full || "";
  };

  // If initial value set immutable does not pass validation, allow editing it, and
  // set immutable state false on edit to control input's disabled state correctly
  const [immutable, setImmutable] = useState(props.immutable || false);

  useEffect(() => {
    props.onChange({
      email: emailState.value,
      // Validate also on initial load
      valid: !!emailState.valid,
    });

    if (props.showValidationStatus) {
      setError(emailState.valid === undefined ? undefined : !emailState.valid);
      setSuccess(emailState.valid === undefined ? undefined : emailState.valid);
    }
  }, [emailState]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase().replace(/\s/g, "");

    setImmutable(false);

    setEmailState({
      value: value.toLowerCase(),
      valid: isEmail(value, { domain_specific_validation: true }),
    });
  };

  const onBlurHandler = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    setEmailSuggestion(getEmailSuggestion(event.target.value));
  };

  return (
    <TextField
      fullWidth
      id="email"
      type="text"
      inputProps={{
        inputMode: "email",
        onBlur: onBlurHandler,
        "data-testid": "email",
      }}
      value={emailState.value}
      error={error}
      success={success && emailSuggestion === ""}
      label={props.translations.emailLabel}
      onChange={onChangeHandler}
      helperText={emailSuggestion ? `${props.translations.didYouMean} ${emailSuggestion}?` : ""}
      FormHelperTextProps={
        emailSuggestion && !props.disabled
          ? {
              sx: (theme) => ({
                cursor: "pointer",
                textDecoration: "underline",
                color: `${theme.palette.error.main} !important`,
              }),
              onClick: () => {
                setEmailState(() => ({
                  value: emailSuggestion,
                  valid: true,
                }));
                setEmailSuggestion("");
              },
            }
          : undefined
      }
      disabled={props.disabled || (immutable && !!emailState.valid)}
    />
  );
};
