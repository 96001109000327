import { createSvgIcon } from "@mui/material/utils";

export const Trash = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 6H3V9C4.10457 9 5 9.89543 5 11V15C5 17.8284 5 19.2426 5.87868 20.1213C6.75736 21 8.17157 21 11 21H13C15.8284 21 17.2426 21 18.1213 20.1213C19 19.2426 19 17.8284 19 15V11C19 9.89543 19.8954 9 21 9V6ZM10.5 11C10.5 10.4477 10.0523 10 9.5 10C8.94772 10 8.5 10.4477 8.5 11V16C8.5 16.5523 8.94772 17 9.5 17C10.0523 17 10.5 16.5523 10.5 16V11ZM15.5 11C15.5 10.4477 15.0523 10 14.5 10C13.9477 10 13.5 10.4477 13.5 11V16C13.5 16.5523 13.9477 17 14.5 17C15.0523 17 15.5 16.5523 15.5 16V11Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4057 2.12123C12.9817 2.0399 12.489 2 11.9998 2C11.5105 2 11.0178 2.0399 10.5939 2.12123C10.3819 2.16189 10.1744 2.21529 9.98642 2.28562C9.81566 2.34951 9.58278 2.45558 9.38574 2.63942C8.98193 3.01618 8.96 3.64897 9.33676 4.05278C9.69229 4.43384 10.2758 4.47484 10.6797 4.16168C10.6821 4.16078 10.6846 4.15982 10.6873 4.15881C10.7406 4.13884 10.8333 4.11176 10.9707 4.08542C11.2453 4.03274 11.6085 4 11.9998 4C12.3911 4 12.7543 4.03274 13.0289 4.08542C13.1663 4.11176 13.2589 4.13884 13.3123 4.1588C13.315 4.15982 13.3175 4.16078 13.3198 4.16168C13.7238 4.47484 14.3073 4.43384 14.6628 4.05278C15.0396 3.64897 15.0176 3.01618 14.6138 2.63942C14.4168 2.45558 14.1839 2.34951 14.0132 2.28562C13.8252 2.21529 13.6177 2.16189 13.4057 2.12123Z"
    />
  </>,
  "Trash"
);
