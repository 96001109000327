import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { TypographyProps } from "@mui/material";

export type ListProps = React.HTMLAttributes<"ul">;

export const List = styled("ul")({
  padding: "0px 0px 0px 20px",
  listStyleType: "disc",
  listStylePosition: "outside",
});

const ListItemStyled = styled(Typography)({
  margin: "0px 0px 8px 0px",
  display: "list-item",
});

export type ListItemProps = TypographyProps;

export const ListItem = (props: TypographyProps) => <ListItemStyled component="li" {...props} />;
