import type { FC } from "react";

import { styled } from "@mui/material/styles";

import type { NavigationProps } from "./Navigation.types";

import { DropdownNavigation } from "./DropdownNavigation";
import { HorizontalNavigation } from "./HorizontalNavigation";

const MobileContainer = styled("div")((props) => ({
  display: "block",
  width: "100%",
  [props.theme.breakpoints.up("tablet")]: { display: "none" },
}));

const TabletContainer = styled("div")((props) => ({
  display: "none",
  width: "100%",
  [props.theme.breakpoints.up("tablet")]: {
    display: "block",
  },
}));

export type HeaderNavigationProps = NavigationProps;

export const HeaderNavigation: FC<HeaderNavigationProps> = (props) => (
  <div>
    <MobileContainer>
      <DropdownNavigation {...props} />
    </MobileContainer>
    <TabletContainer>
      <HorizontalNavigation {...props} />
    </TabletContainer>
  </div>
);
