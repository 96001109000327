import { useEffect, useState } from "react";

import { Grid, Typography, useTheme } from "@mui/material";
import isMobilePhone from "validator/es/lib/isMobilePhone";

import type { NameInputProps, PhoneNumberInputProps } from "../../../../Inputs";
import type { UserInformation } from "../../types";
import type { CountryCode } from "@augment-frontend/constants";

import { NameInput, PhoneNumberInput } from "../../../../Inputs";

type ContactInformationTranslations = {
  contactInformationTitle: string;
  nameComponent: NameInputProps["translations"];
  phoneNumberComponent: PhoneNumberInputProps["translations"];
};

export interface ContactInformationProps<T extends CountryCode> {
  countryCode: T;
  nameIsImmutable?: boolean;
  allDisabled?: boolean;
  contactInformation: Partial<UserInformation["contactInformation"]>;
  onChangeNameHandler: NameInputProps["onChange"];
  onChangePhoneHandler: PhoneNumberInputProps["onChange"];
  translations: ContactInformationTranslations;
}

/** Contact information fields */
export const ContactInfo = <T extends CountryCode>(props: ContactInformationProps<T>) => {
  const [allDisabled, setAllDisabled] = useState(props.allDisabled);

  useEffect(() => {
    setAllDisabled(props.allDisabled);
  }, [props.allDisabled]);

  const theme = useTheme();
  return (
    <>
      <Typography variant="largeTextBold" mb={1}>
        {props.translations.contactInformationTitle}
      </Typography>
      <Grid container spacing={theme.spacing(1)}>
        <NameInput
          translations={props.translations.nameComponent}
          firstName={props.contactInformation?.firstName}
          lastName={props.contactInformation?.lastName}
          onChange={props.onChangeNameHandler}
          immutable={props.nameIsImmutable}
          disabled={allDisabled}
        />
        <PhoneNumberInput
          translations={props.translations.phoneNumberComponent}
          onChange={props.onChangePhoneHandler}
          initialCountryCode={props.countryCode}
          phoneNumber={props.contactInformation?.phoneNumber}
          immutable={Boolean(props.contactInformation?.phoneNumber)}
          disabled={allDisabled}
          onValidate={(phoneNumber) => isMobilePhone(phoneNumber, undefined, { strictMode: true })}
        />
      </Grid>
    </>
  );
};
