import type { FC, ForwardedRef } from "react";
import { forwardRef } from "react";

import { alertClasses, Box, Alert as MaterialAlert, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { AlertProps as MaterialAlertProps } from "@mui/material";

export interface AlertProps extends Omit<MaterialAlertProps, "variant" | "color" | "icon"> {
  severity: "error" | "warning" | "success";
  /** Title for the alert. */
  title?: string;
  /** Description for the alert. */
  description: string;
  /** Force action-component to right side of the body */
  forceActionRight?: boolean;
  /** Action in the top-right corner, typically used for closing. Does not apply with forceActionRight. */
  topRightCornerAction?: React.ReactNode;
}

const IconLine = styled("div")<Pick<AlertProps, "severity">>((props) => {
  const colors: Record<AlertProps["severity"], string> = {
    error: props.theme.palette.error.main,
    warning: props.theme.palette.error.light,
    success: props.theme.palette.primary.main,
  };
  return { borderLeft: `4px solid ${colors[props.severity]}`, borderRadius: "4px" };
});

const AlertUnstyled: FC<AlertProps & { alertRef: ForwardedRef<HTMLDivElement> }> = (props) => {
  const { alertRef, action, title, description, forceActionRight, topRightCornerAction, ...rest } =
    props;

  return (
    <MaterialAlert
      ref={alertRef}
      icon={<IconLine severity={props.severity} />}
      // Top-right corner action and an action forced to right cannot co-exist because
      // the action forced to right is rendered right-most in Mui's action element.
      // eslint-disable-next-line no-nested-ternary
      action={topRightCornerAction ? (forceActionRight ? action : undefined) : action}
      {...rest}
    >
      <Stack direction="row" justifyContent="space-between">
        {title && (
          <Typography sx={{ mb: 0.5 }} variant="largeText">
            {title}
          </Typography>
        )}
        {!forceActionRight && topRightCornerAction}
      </Stack>
      <Typography>{description}</Typography>
      {action && (
        <Box
          sx={{
            display: {
              mobile: forceActionRight ? "none" : "flex",
              // eslint-disable-next-line no-nested-ternary
              tablet: forceActionRight ? "none" : topRightCornerAction ? "flex" : "none",
            },
            mt: 1,
          }}
        >
          {props.action}
        </Box>
      )}
    </MaterialAlert>
  );
};

export const Alert = styled(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
    <AlertUnstyled alertRef={ref} {...props} />
  ))
)((props) => {
  const backgroundColors: Record<AlertProps["severity"], string> = {
    error: props.theme.palette.warning.light,
    warning: props.theme.palette.warning.light,
    success: props.theme.palette.success.main,
  };
  return {
    backgroundColor: backgroundColors[props.severity],
    boxShadow: "0px 4px 36px rgba(83, 83, 83, 0.2)",
    [`.${alertClasses.action}`]: {
      alignItems: "center",
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: 8,
      display: "flex",
      [props.theme.breakpoints.down("tablet")]: {
        display: props.forceActionRight ? "flex" : "none",
      },
    },
  };
});
