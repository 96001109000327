import type { FC } from "react";

import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export interface BlockLegalProps {
  /** Title of the component, e.g. "Privacy Policy" */
  title: string;
  /** Content of the component under the title */
  content: React.ReactNode;
}

const StyledWrapper = styled("div")((props) => ({
  padding: props.theme.spacing(4),
  backgroundColor: props.theme.palette.secondary.light,
  [props.theme.breakpoints.up("tablet")]: {
    borderRadius: props.theme.shape.borderRadius,
  },
}));

export const BlockLegal: FC<BlockLegalProps> = (props) => (
  <Container
    component="section"
    sx={(theme) => ({
      marginY: { mobile: theme.spacing(0), tablet: theme.spacing(9) },
      paddingX: 0,
      maxWidth: {
        mobile: "100%",
        tablet: `${theme.breakpoints.values.tablet - 2 * 105}px`,
        laptop: `${theme.breakpoints.values.laptop - 2 * 328}px`,
      },
    })}
  >
    <StyledWrapper>
      <Typography variant="h2Bold" marginBottom={(theme) => theme.spacing(4)}>
        {props.title}
      </Typography>
      {props.content}
    </StyledWrapper>
  </Container>
);
