import type { ChangeEvent, FC, FormEvent } from "react";
import { useState } from "react";

import { CloseRound, VisibilityOff, VisibilityOn } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Grid, IconButton, InputAdornment, Link, Snackbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Mailcheck from "mailcheck";
import isEmail from "validator/es/lib/isEmail";

import { validatePassword } from "../../../utils/password";
import { Alert } from "../../Alert";
import { TextField } from "../../TextField";
import { Container } from "../Container";

Mailcheck.defaultDomains.push("augment.eco");

export interface AuthRegisterProps {
  onSubmit: (username: string, password: string) => Promise<Error | void>;
  /** E.g. Gatsby Link. */
  loginLinkComponent: React.ElementType;
  translations: {
    /** E.g. Register */
    title: string;
    /** E.g. "Email" */
    emailLabel: string;
    /** E.g. "Password" */
    passwordLabel: string;
    /** E.g. Register */
    registerButton: string;
    /** E.g. Login */
    login: string;
    /** E.g. Already registered? */
    alreadyRegistered: string;
    /** E.g. Oh no */
    errorTitle: string;
    /** E.g. Password must be 6 characters long */
    passwordInstructions: string;
    /** E.g Did you mean */
    didYouMean: string;
  };
}

const credentialsInitialState = {
  username: "",
  usernameError: false,
  password: "",
  passwordError: false,
};

const emailSuggestionInitialState = "";

export const AuthRegister: FC<AuthRegisterProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [credentials, setCredentials] =
    useState<typeof credentialsInitialState>(credentialsInitialState);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [emailSuggestion, setEmailSuggestion] = useState<string>(emailSuggestionInitialState);
  const [onSubmitError, setOnSubmitError] = useState<string>("");
  const [displaySnackbar, setDisplaySnackbar] = useState<boolean>(false);
  const theme = useTheme();

  const getEmailSuggestion = (email: string): string => {
    const result = Mailcheck.run({ email });
    return result?.full || emailSuggestionInitialState;
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmailSuggestion(emailSuggestionInitialState);
    setCredentials((prev) => {
      const value =
        event.target.name === "username"
          ? event.target.value.toLowerCase().replace(/\s/g, "")
          : event.target.value.trimStart();

      let error;
      if (event.target.name === "username") {
        error = !isEmail(value, { domain_specific_validation: true });
      } else {
        error = !validatePassword(value);
      }

      return {
        ...prev,
        [event.target.name]: value,
        [`${event.target.name}Error`]: error,
      };
    });
  };

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnSubmitError("");
    setLoading(true);
    try {
      await props.onSubmit(credentials.username, credentials.password);
    } catch (e: unknown) {
      setOnSubmitError(e instanceof Error ? e.message : "Unknown error");
      setLoading(false);
      setDisplaySnackbar(true);
    }
  };

  const handleClose = () => {
    setDisplaySnackbar(false);
  };

  const handleOnBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    setEmailSuggestion(getEmailSuggestion(event.target.value));
  };

  return (
    <>
      <Container>
        <Typography variant="subheadingBold" mb={4}>
          {props.translations.title}
        </Typography>
        <Grid container spacing={2} direction="column" component="form" onSubmit={onSubmitHandler}>
          <Grid item>
            <TextField
              fullWidth
              type="text"
              inputProps={{
                inputMode: "email",
                onBlur: handleOnBlur,
              }}
              name="username"
              error={credentials.usernameError}
              success={!credentials.usernameError && credentials.username.length > 0}
              label={props.translations.emailLabel}
              value={credentials.username}
              onChange={onChangeHandler}
              helperText={
                emailSuggestion.length > 1
                  ? `${props.translations.didYouMean} ${emailSuggestion}?`
                  : emailSuggestionInitialState
              }
              FormHelperTextProps={
                emailSuggestion.length > 1 && !loading
                  ? {
                      sx: () => ({ cursor: "pointer", textDecoration: "underline" }),
                      onClick: () => {
                        setCredentials((prev) => ({
                          ...prev,
                          username: emailSuggestion,
                          usernameError: false,
                        }));
                        setEmailSuggestion(emailSuggestionInitialState);
                      },
                    }
                  : undefined
              }
              disabled={loading}
              data-testid="usernameInput"
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type={passwordVisible ? "text" : "password"}
              name="password"
              error={credentials.passwordError}
              success={!credentials.passwordError && credentials.password.length > 0}
              label={props.translations.passwordLabel}
              value={credentials.password}
              onChange={onChangeHandler}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setPasswordVisible((prev) => !prev)}
                    sx={{ cursor: "pointer" }}
                  >
                    {passwordVisible ? (
                      <VisibilityOff sx={{ color: theme.palette.text.primary }} />
                    ) : (
                      <VisibilityOn sx={{ color: theme.palette.text.primary }} />
                    )}
                  </InputAdornment>
                ),
              }}
              data-testid="passwordInput"
            />
          </Grid>
          <Grid item>
            <Typography variant="bodyText">{props.translations.passwordInstructions}</Typography>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              fullWidth
              disabled={
                credentials.usernameError ||
                !credentials.username ||
                credentials.passwordError ||
                !credentials.password
              }
              loading={loading}
              data-testid="registerButton"
            >
              {props.translations.registerButton}
            </LoadingButton>
          </Grid>
          <Grid item alignSelf="center">
            <Typography variant="bodyText" sx={{ textAlign: "center" }}>
              {props.translations.alreadyRegistered}{" "}
              <Link
                component={props.loginLinkComponent}
                sx={{ color: theme.palette.text.primary, textDecoration: "underline" }}
              >
                {props.translations.login}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={displaySnackbar} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          severity="error"
          title={props.translations.errorTitle}
          description={onSubmitError}
          action={
            <IconButton sx={{ color: theme.palette.text.primary }} onClick={handleClose}>
              <CloseRound />
            </IconButton>
          }
          forceActionRight
          sx={{
            m: 1,
            width: {
              mobile: "100%",
              tablet: "unset",
            },
          }}
        />
      </Snackbar>
    </>
  );
};
