import type { FC } from "react";

import { Box, Button, Grid, Container as MuiContainer, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { AlertProps } from "../Alert";
import type { HeaderNavigationProps } from "../Menu";

import { Alert } from "../Alert";
import { LinearProgress } from "../LinearProgress";
import { HeaderNavigation } from "../Menu";

export interface MyAccountContainerProps {
  loading: boolean;
  error: boolean;
  navigation: HeaderNavigationProps;
  content: React.ReactNode;
  aside: React.ReactNode;
  attentionAction: AlertProps[] | null;
  translations: {
    title: string;
    errorTitle: string;
    errorDescription: string;
    errorActionTitle: string;
  };
}

export const MyAccountContainer: FC<MyAccountContainerProps> = (props) => {
  const theme = useTheme();

  return (
    <MuiContainer
      maxWidth="laptop"
      disableGutters
      sx={{
        my: { mobile: theme.spacing(3), tablet: theme.spacing(4), laptop: theme.spacing(9) },
        px: { mobile: theme.spacing(3), tablet: theme.spacing(8) },
      }}
    >
      <Typography variant="h3Bold">{props.translations.title}</Typography>
      <Box
        sx={{
          mt: { mobile: theme.spacing(2), laptop: theme.spacing(2.5) },
          mb: { mobile: theme.spacing(2), laptop: theme.spacing(4) },
        }}
      >
        <HeaderNavigation {...props.navigation} />
      </Box>

      {props.loading && <LinearProgress />}

      {props.error && (
        <>
          <Alert
            severity="error"
            title={props.translations.errorTitle}
            description={props.translations.errorDescription}
            action={
              <Button variant="outlined" fullWidth onClick={() => window.location.reload()}>
                {props.translations.errorActionTitle}
              </Button>
            }
          />
        </>
      )}

      {!props.loading && !props.error && (
        <Grid container spacing={{ mobile: 2, laptop: 4 }}>
          <Grid item mobile={12} laptop={9}>
            {props.attentionAction &&
              props.attentionAction.map((action, index) => (
                <Alert key={index} {...action} sx={{ mb: 2 }} />
              ))}
            {props.content}
          </Grid>
          <Grid item mobile={12} laptop={3}>
            {props.aside}
          </Grid>
        </Grid>
      )}
    </MuiContainer>
  );
};
