import { styled } from "@mui/material/styles";

interface RadioGroupCardBase<T, Options> {
  /**
   * Use this prop to set which radio button should be checked on initial render.
   * If not provided, the first value from options array will be used.
   * If null provided, then no radio is selected by default.
   */
  initialValue?: T | null;
  /**
   * Possible options to choose from. Action is used mostly for a CTA button.
   */
  options: ({
    value: T;
    content: React.ReactNode;
    disabled?: boolean;
    /** If true the element looks like normal, but all hover effects are disabled. */
    hoverDisabled?: boolean;
    /** Render tag with given value to top-right corner of the Radio Card.
     *
     * Note:
     * - With RadioGroupCardRow, mobile, and with > 1 option, this doesn't have enough space unless title is omitted
     * - With RadioGroupCardColumn, this will be renderd only if title is also given (without title, the radio-icon is on top-right corner)
     */
    tag?: string;
  } & Options)[];
  /**
   * Override how equality is checked between option value and selected values.
   * E.g. if array of objects are provided as option values, and object keys change,
   * you may use this function to check if the new value is equal to the old value.
   *
   * If not provided, equality is checked using `===`.
   *
   * Example:
   *
   * 1. options: [{value: { id: 1, name: "John" }}] is provided.
   * 2. Current selected value is {id: 1, name: "Doe"}.
   * 3. Option value does not equal to selected value.
   * 4. Providing callback of (a, b) => a.id === b?.id
   * will make the option value equal to selected value.
   *
   * NOTE: selectedValue may be null if initialValue is null.
   */
  isEqualValue?: (optionValue: T, selectedValue: T | null) => boolean;
}

interface RadioGroupCardSync<T, Options> extends RadioGroupCardBase<T, Options> {
  /**
   * You may listen changes in the selection with this function.
   * Value will be updated from options array on user interaction.
   */
  onChange: (value: T) => void;
  onChangeAsync?: never;
}

interface RadioGroupCardAsync<T, Options> extends RadioGroupCardBase<T, Options> {
  onChange?: never;
  /**
   * You may listen changes in the selection with this function.
   * Provided Promise will be awaited and value will be updated from options array
   * if the Promise resolves. Errors are catched and and will prevent the value to be updated.
   */
  onChangeAsync: (value: T) => Promise<Error | void>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type RadioGroupCardProps<T, Options = {}> =
  | RadioGroupCardSync<T, Options>
  | RadioGroupCardAsync<T, Options>;

export const Card = styled("div", {
  shouldForwardProp: (prop) => {
    if (prop === "hoverDisabled" || prop === "width") {
      return false;
    }
    return true;
  },
})<
  React.HTMLProps<HTMLDivElement> & {
    checked?: boolean;
    disabled?: boolean;
    hoverDisabled?: boolean;
  }
>((props) => {
  const backgroundColor = props.checked
    ? props.theme.palette.primary[400]
    : props.theme.palette.secondary[200];
  const hoverBackgroundColor = props.hoverDisabled
    ? backgroundColor
    : (props.checked && props.theme.palette.primary[400]) || props.theme.palette.primary[600];
  const cursor = props.disabled === true || props.hoverDisabled === true ? "default" : "pointer";
  return {
    backgroundColor,
    cursor,
    opacity: props.disabled === true ? 0.5 : 1,
    display: "flex",
    flexDirection: "column",
    borderRadius: props.theme.shape.borderRadius,
    border: `1px solid ${props.theme.palette.secondary.A200}`,
    width: props.width,
    transition: `background-color 250ms ${props.theme.transitions.easing.easeInOut}`,
    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      backgroundColor: hoverBackgroundColor,
    },
  };
});

export const CardContent = styled("div", {
  shouldForwardProp: (prop) => {
    if (prop === "hasAction") {
      return false;
    }
    return true;
  },
})<React.HTMLProps<HTMLDivElement> & { hasAction?: boolean }>((props) => ({
  padding: props.theme.spacing(1.5),
  paddingBottom: props.hasAction ? 0 : props.theme.spacing(1.5),
  flexGrow: 1,
}));

export const RadioContainer = styled("div")<React.HTMLProps<HTMLDivElement>>(() => ({
  display: "flex",
  alignItems: "flex-start",
}));
