import type { FC } from "react";
import { createContext, useContext } from "react";

import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";

import type { PageProps } from "gatsby";
import type { Resource } from "i18next";

const I18nContext = createContext<Omit<Queries.SitePageContext, "i18nResources">>({
  locale: "fi",
  languageCode: "fi",
  languageName: "Finnish",
  languageLocaleName: "suomi",
  countryCode: "FI",
  countryName: "Finland",
  countryLocaleName: "Suomi",
  alternateLinks: [],
  localeLinks: [],
  noindex: false,
});

export type I18nProviderProps = PageProps<null, Queries.SitePageContext>;

/**
 * Provides i18n context for the whole application.
 */
export const I18nProvider: FC<I18nProviderProps> = ({ pageContext, children }) => {
  const i18n = i18next
    .createInstance({
      lng: pageContext.languageCode,
      interpolation: { escapeValue: false },
      initImmediate: false,
      resources: pageContext.i18nResources as Resource,
    })
    .use(initReactI18next);
  i18n.init();

  return (
    <I18nextProvider i18n={i18n}>
      <I18nContext.Provider
        value={{
          // TODO provide i18n & t in the useI18n hook, once parser upgraded to v6,
          // See: https://github.com/i18next/i18next-parser/pull/512
          // i18n,
          // t: i18n.t,
          locale: pageContext.locale,
          languageCode: pageContext.languageCode,
          languageName: pageContext.languageName,
          languageLocaleName: pageContext.languageLocaleName,
          countryCode: pageContext.countryCode,
          countryName: pageContext.countryName,
          countryLocaleName: pageContext.countryLocaleName,
          alternateLinks: pageContext.alternateLinks || [],
          localeLinks: pageContext.localeLinks || [],
          noindex: pageContext.noindex || false,
        }}
      >
        {children}
      </I18nContext.Provider>
    </I18nextProvider>
  );
};

export const useI18n = () => {
  const context = useContext(I18nContext);
  if (typeof window !== "undefined" && context === undefined) {
    throw new Error("`useI18n` must be within a `I18nProvider`");
  }
  return context;
};
