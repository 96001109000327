import { createSvgIcon } from "@mui/material/utils";

export const Bank = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M21.5 11C21.78 11 22 10.78 22 10.5V9.25997C22 9.09997 21.92 8.93997 21.79 8.84997L12.57 2.39997C12.23 2.15997 11.77 2.15997 11.42 2.39997L2.21 8.84997C2.08 8.93997 2 9.09997 2 9.25997V10.5C2 10.78 2.22 11 2.5 11H4V20H3C2.45 20 2 20.45 2 21C2 21.55 2.45 22 3 22H21C21.55 22 22 21.55 22 21C22 20.45 21.55 20 21 20H20V11H21.5ZM9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12V19C7 19.5523 7.44772 20 8 20C8.55228 20 9 19.5523 9 19V12ZM13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V12ZM16 11C16.5523 11 17 11.4477 17 12V19C17 19.5523 16.5523 20 16 20C15.4477 20 15 19.5523 15 19V12C15 11.4477 15.4477 11 16 11Z"
  />,
  "Bank"
);
