import type { FC } from "react";
import { useState } from "react";

import { ArrowRight } from "@augment-frontend/mui-icons";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { keyframes, useTheme } from "@mui/material/styles";

import type { ActionButtonsProps } from "../ActionButtons";
import type { HeadingContentProps } from "../HeadingContent";

import { ScrollbarBox } from "../../Layout/ScrollbarBox";
import { ActionButtons } from "../ActionButtons";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";
import { Stars } from "./Stars";

const slideToPosition = (
  startPosition: number,
  startScale: number,
  startZindex: number,
  startOpacity: number,
  endPosition: number,
  endScale: number,
  endZindex: number,
  endOpacity: number
) => keyframes`
  0% {
    transform: translateX(${startPosition}%) scale(${startScale});
    z-index: ${startZindex};
    opacity: ${startOpacity};
  }
  25% {
    z-index: ${endZindex};
    opacity: ${endOpacity};
  }
  100% {
    transform: translateX(${endPosition}%) scale(${endScale});
    z-index: ${endZindex};
    opacity: ${endOpacity};
  }`;

/**
 * Resolve the initial transform, z-index and opacity styles for the first three visible testimonials.
 */
const resolveInitialStyles = (index: number, lastIndex: number) => {
  if (index === 0) {
    return { transform: "translateX(-50%)" };
  } else if (index === 1) {
    return { transform: "translateX(-115%) scale(0.6)", zIndex: -1, opacity: 0.5 };
  } else if (index === lastIndex) {
    return { transform: "translateX(15%) scale(0.6)", zIndex: -1, opacity: 0.5 };
  }
  return {};
};

/**
 * Resolve if the testimonial index is within 1 position of the current middle index
 * and thus should be displayed
 */
const resolveDisplay = (index: number, lastIndex: number, middleTestimonialIndex: number) => {
  if (
    index === middleTestimonialIndex - 1 ||
    index === middleTestimonialIndex ||
    index === middleTestimonialIndex + 1 ||
    (middleTestimonialIndex === 0 && index === lastIndex) ||
    (middleTestimonialIndex === lastIndex && index === 0)
  ) {
    return true;
  }
  return false;
};

/**
 * Resolve which animation each visible testimonial should use.
 * Note that the statements order matters.
 */
const resolveAnimation = (index: number, lastIndex: number, middleTestimonialIndex: number) => {
  if (middleTestimonialIndex === 0 && index === lastIndex) {
    return 0; // from middle to end
  } else if (middleTestimonialIndex === lastIndex && index === 0) {
    return 1; // from end to start
  } else if (index < middleTestimonialIndex) {
    return 0; // from middle to end
  } else if (index > middleTestimonialIndex) {
    return 1; // from end to start
  }
  return 2; // from start to middle
};

export interface BlockTestimonialsProps {
  headingContent: HeadingContentProps;
  /**
   * Testimonials to render on infinite loop. At least 3 testimonials are required.
   */
  testimonials: {
    /** Image of the testimonial author */
    avatar: React.ReactNode;
    /** Name of the testimonial author */
    name: string;
    /** Text content of the testimonial */
    textContent: React.ReactNode;
    /** Number of stars given on testimonial */
    stars: number;
  }[];
  callToAction?: Pick<ActionButtonsProps, "buttons">;
}

export const BlockTestimonials: FC<BlockTestimonialsProps> = (props) => {
  const [isCarouselClicked, setIsCarouselClicked] = useState(false);
  const [middleTestimonialIndex, setMiddleTestimonialIndex] = useState(0);
  const [isCarouselAnimating, setIsCarouselAnimating] = useState(false);

  const theme = useTheme();

  const animations = [
    slideToPosition(-50, 1, 1, 1, 15, 0.6, -1, 0.5), // from middle to end
    slideToPosition(15, 0.6, -1, 0.5, -115, 0.6, -1, 0.5), // from end to start
    slideToPosition(-115, 0.6, -1, 0.5, -50, 1, 1, 1), // from start to middle
  ];

  return (
    <GridContainer>
      <Grid item mobile={12}>
        <HeadingContent
          {...props.headingContent}
          centered
          boxProps={{
            width: {
              laptop: "40%",
              tablet: "80%",
              mobile: "100%",
            },
          }}
        />
      </Grid>
      <Grid item mobile={12}>
        <Stack
          direction="column"
          paddingTop={{ laptop: "48px", mobile: "24px" }}
          alignItems="center"
          sx={{ width: "100%", overflowX: "hidden", position: "relative" }}
        >
          <Box height="300px" marginBottom="16px">
            {props.testimonials.map((testimonial, index) => (
              <Box
                key={index}
                sx={{
                  ...resolveInitialStyles(index, props.testimonials.length - 1),
                  position: "absolute",
                  width: { laptop: "460px", tablet: "327px", mobile: "100%" },
                  height: "300px",
                  display: {
                    tablet: resolveDisplay(
                      index,
                      props.testimonials.length - 1,
                      middleTestimonialIndex
                    )
                      ? "flex"
                      : "none",
                    mobile:
                      index === middleTestimonialIndex ||
                      (index === middleTestimonialIndex - 1 && isCarouselAnimating)
                        ? "flex"
                        : "none",
                  },
                  flexDirection: "column",
                  background: "#2E3130",
                  paddingX: "16px",
                  borderRadius: "8px",
                  animation: isCarouselClicked
                    ? `${
                        animations[
                          resolveAnimation(
                            index,
                            props.testimonials.length - 1,
                            middleTestimonialIndex
                          )
                        ]
                      } 1.5s forwards`
                    : "",
                }}
              >
                <Box
                  sx={{
                    alignSelf: "center",
                    marginTop: { laptop: "-44px", mobile: "-24px" },
                    width: { laptop: "88px", mobile: "48px" },
                    height: { laptop: "144.25px", mobile: "70.95px" },
                  }}
                >
                  {testimonial.avatar}
                </Box>
                <ScrollbarBox sx={{ height: "100%", overflow: "auto" }}>
                  {testimonial.textContent}
                </ScrollbarBox>
                <Divider sx={{ bgcolor: "#D7D7D7", marginTop: "16px" }} />
                <Stack direction="row" justifyContent="space-between" marginY="16px">
                  <Typography sx={{ color: theme.palette.text.secondary }}>
                    {testimonial.name}
                  </Typography>
                  <Stars count={testimonial.stars} sx={{ color: "#DBC500" }} />
                </Stack>
              </Box>
            ))}
          </Box>
          <Stack direction="row" spacing="4px" marginBottom="24px">
            {props.testimonials.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: index === middleTestimonialIndex ? "24px" : "8px",
                  height: "8px",
                  borderRadius: index === middleTestimonialIndex ? "11px" : "11px",
                  backgroundColor:
                    index === middleTestimonialIndex
                      ? theme.palette.primary[100]
                      : theme.palette.secondary.A200,
                }}
              />
            ))}
          </Stack>
          <Button
            variant="outlined"
            onClick={() => {
              if (isCarouselAnimating) {
                return;
              }
              setIsCarouselAnimating(true);
              setMiddleTestimonialIndex((prev) =>
                prev + 1 > props.testimonials.length - 1 ? 0 : prev + 1
              );
              setIsCarouselClicked(true);
              setTimeout(() => {
                setIsCarouselAnimating(false);
                // should be same as animation length, we could set it to animation listener as well
                // instead of timeout but that feels like overkill
              }, 1500);
            }}
            sx={{
              mb: 3,
              p: "12px",
              minWidth: "auto",
            }}
          >
            {<ArrowRight />}
          </Button>
          {props.callToAction && (
            <ActionButtons
              {...props.callToAction}
              direction="column"
              grow={true}
              boxProps={{ sx: { width: { mobile: "100%", tablet: "auto" } } }}
            />
          )}
        </Stack>
      </Grid>
    </GridContainer>
  );
};
