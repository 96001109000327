/**
 * A helper function for converting any value to an Error.
 * @param value Value to be converted to an Error, if it is not
 * @returns Provided value as is, if it is an Error. Otherwise an Error with stringified value as message.
 */
export const toError = (value: unknown): Error => {
  if (value instanceof Error) return value;
  try {
    return new Error(`This value was thrown as is, not through an Error: ${JSON.stringify(value)}`);
  } catch {
    return new Error("[Unable to stringify the thrown value]");
  }
};
