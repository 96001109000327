import type { FC } from "react";

import { Avatar, Box, Container, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import type { ActionButtonsProps } from "../../ActionButtons";
import type { TypographyProps } from "@mui/material";

import { ActionButtons } from "../../ActionButtons";

export interface BlockSingleBlogProps {
  /** Image shown first in the blog */
  image: React.ReactNode;
  /** Author of the blog, e.g. "John Doe" */
  author: string;
  /** Source for the avatar image of the author */
  avatarSrc: string;
  /** Date of the blog, e.g. "03/07/2021" */
  date: string;
  /** Title of the blog */
  title: string;
  /** Content/body of the blog */
  content: React.ReactNode;
  callToAction?: Pick<ActionButtonsProps, "buttons">;
}

const StyledTitle = styled(Typography)<TypographyProps>((props) => ({
  marginBottom: props.theme.spacing(4),
  ...props.theme.typography.h3Bold,
  [props.theme.breakpoints.up("laptop")]: {
    ...props.theme.typography.h2Bold,
  },
}));

const StyledWrapper = styled("div")((props) => ({
  [props.theme.breakpoints.up("tablet")]: {
    padding: props.theme.spacing(4),
    backgroundColor: props.theme.palette.primary[600],
    borderRadius: props.theme.shape.borderRadius,
  },
}));

export const BlockSingleBlog: FC<BlockSingleBlogProps> = (props) => {
  const theme = useTheme();

  return (
    <Container
      component="section"
      sx={{
        marginY: { mobile: theme.spacing(4), tablet: theme.spacing(8), laptop: theme.spacing(9) },
        paddingX: { mobile: theme.spacing(3), tablet: 0 },
        maxWidth: {
          mobile: "100%",
          tablet: `${theme.breakpoints.values.tablet - 2 * 105}px`,
          laptop: `${theme.breakpoints.values.laptop - 2 * 328}px`,
        },
      }}
    >
      <StyledWrapper>
        <Box marginBottom={theme.spacing(4)}>{props.image}</Box>
        <Stack direction="row" spacing={theme.spacing(1)} marginBottom={theme.spacing(4)}>
          <Avatar
            src={props.avatarSrc}
            sx={{ width: "48px", height: "48px" }}
            alt={`Author: ${props.author}`}
          />
          <Stack direction="column">
            <Typography variant="largeTextBold">{props.author}</Typography>
            <Typography variant="bodyText">{props.date}</Typography>
          </Stack>
        </Stack>
        <StyledTitle>{props.title}</StyledTitle>
        <Box marginBottom={theme.spacing(4)}>{props.content}</Box>
        {props.callToAction && (
          <ActionButtons
            {...props.callToAction}
            direction={{ mobile: "column", tablet: "row", laptop: "row" }}
            grow={true}
          />
        )}
      </StyledWrapper>
    </Container>
  );
};
