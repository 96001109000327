import type { ChangeEvent, FC } from "react";
import { useState } from "react";

import { CloseRound, VisibilityOff, VisibilityOn } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Grid, IconButton, InputAdornment, Link, Snackbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { validatePassword } from "../../../utils";
import { Alert } from "../../Alert";
import { TextField } from "../../TextField";
import { Container } from "../Container";

export interface AuthResetPasswordProps {
  onSubmit: (username: string, password: string, confirmationCode: string) => Promise<Error | void>;
  /** E.g. Gatsby Link. */
  loginLinkComponent: React.ElementType;
  translations: {
    /** E.g. Reset password */
    title: string;
    /** E.g. Confirmation code is sent to phone number xxx... */
    confirmationCodeInstructions: string;
    /** E.g. Confirmation code */
    confirmationCodeLabel: string;
    /** E.g. New password */
    passwordLabel: string;
    /** E.g. Password must be at least 6 characters
     * long and contain lowercase letters and numbers */
    passwordInstructions: string;
    /** E.g. Change password */
    confirmButton: string;
    /** E.g. Log in */
    login: string;
    /** E.g. Oh no */
    errorTitle: string;
  };
}

const credentialsInitialState = {
  confirmationCode: "",
  confirmationCodeError: false,
  password: "",
  passwordError: false,
  username: "",
};

export const AuthResetPassword: FC<AuthResetPasswordProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<typeof credentialsInitialState>({
    ...credentialsInitialState,
    confirmationCode:
      typeof window !== "undefined"
        ? new URLSearchParams(window.location.search)
            .get("confirmationcode")
            ?.replace(/\D/g, "")
            .substring(0, 6) || ""
        : "",
    username:
      typeof window !== "undefined"
        ? decodeURIComponent(new URLSearchParams(window.location.search).get("username") || "")
        : "",
  });
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [onSubmitError, setOnSubmitError] = useState<string>("");
  const [displaySnackbar, setDisplaySnackbar] = useState<boolean>(false);
  const theme = useTheme();

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const trimmed =
      event.target.name === "confirmationCode"
        ? event.target.value.trim().replace(/\D/g, "")
        : event.target.value.trimStart();

    setCredentials((prev) => {
      const value =
        event.target.name === "confirmationCode" && trimmed.length > 6
          ? prev.confirmationCode
          : trimmed;

      const error =
        event.target.name === "confirmationCode"
          ? !/^\d{6}$/.test(value)
          : !validatePassword(value);

      return {
        ...prev,
        [event.target.name]: value,
        [`${event.target.name}Error`]: error,
      };
    });
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnSubmitError("");
    setLoading(true);
    try {
      await props.onSubmit(
        credentials.username,
        credentials.password,
        credentials.confirmationCode
      );
    } catch (e: unknown) {
      setOnSubmitError(e instanceof Error ? e.message : "Unknown error");
      setDisplaySnackbar(true);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setDisplaySnackbar(false);
  };

  return (
    <>
      <Container>
        <Typography variant="subheadingBold" mb={4}>
          {props.translations.title}
        </Typography>
        <Grid container spacing={2} direction="column" component="form" onSubmit={onSubmitHandler}>
          {props.translations.confirmationCodeInstructions && (
            <Grid item>
              <Typography variant="bodyText">
                {props.translations.confirmationCodeInstructions}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <TextField
              fullWidth
              error={credentials.confirmationCodeError}
              success={
                !credentials.confirmationCodeError && credentials.confirmationCode.length === 6
              }
              type="text"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              name="confirmationCode"
              label={props.translations.confirmationCodeLabel}
              value={credentials.confirmationCode}
              onChange={onChangeHandler}
              disabled={loading}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type={passwordVisible ? "text" : "password"}
              name="password"
              error={credentials.passwordError}
              success={!credentials.passwordError && credentials.password.length > 0}
              label={props.translations.passwordLabel}
              value={credentials.password}
              onChange={onChangeHandler}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => setPasswordVisible((prev) => !prev)}
                    sx={{ cursor: "pointer" }}
                  >
                    {passwordVisible ? (
                      <VisibilityOff sx={{ color: theme.palette.text.primary }} />
                    ) : (
                      <VisibilityOn sx={{ color: theme.palette.text.primary }} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="bodyText">{props.translations.passwordInstructions}</Typography>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              fullWidth
              disabled={
                !credentials.confirmationCode ||
                !credentials.password ||
                credentials.confirmationCodeError ||
                credentials.passwordError
              }
              loading={loading}
            >
              {props.translations.confirmButton}
            </LoadingButton>
          </Grid>
          <Grid item alignSelf="center">
            <Typography variant="bodyText" sx={{ textAlign: "center" }}>
              <Link
                component={props.loginLinkComponent}
                sx={{ color: theme.palette.text.primary, textDecoration: "underline" }}
              >
                {props.translations.login}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={displaySnackbar} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          severity="error"
          title={props.translations.errorTitle}
          description={onSubmitError}
          action={
            <IconButton sx={{ color: theme.palette.text.primary }} onClick={handleClose}>
              <CloseRound />
            </IconButton>
          }
          forceActionRight
          sx={{
            m: 1,
            width: {
              mobile: "100%",
              tablet: "unset",
            },
          }}
        />
      </Snackbar>
    </>
  );
};
