import type { FC } from "react";

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { BoxProps } from "@mui/material";

const StyledSurface = styled(Box)<BoxProps>((props) => ({
  padding: props.theme.spacing(2),
  backgroundColor: props.theme.palette.primary[600],
  borderRadius: props.theme.shape.borderRadius,
}));

export type SurfaceProps = Pick<BoxProps, "component" | "children" | "sx">;

export const Surface: FC<SurfaceProps> = (props) => {
  const { children, ...rest } = props;

  return <StyledSurface {...rest}>{children}</StyledSurface>;
};
