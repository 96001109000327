import type { FC } from "react";
import { useState } from "react";

import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { NavigationOnClickProps, NavigationProps } from "./Navigation.types";
import type { ButtonProps } from "@mui/material";

const Item = styled(Button, {
  shouldForwardProp: (prop) => {
    if (prop === "active") {
      return false;
    }
    return true;
  },
})<ButtonProps & { active?: boolean }>((props) => ({
  color: props.active === true ? props.theme.palette.text.primary : props.theme.palette.text[200],
  borderBottom: props.active === true ? `2px solid ${props.theme.palette.text.primary}` : undefined,
  borderRadius: 0,
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 2,
  paddingBottom: 2,
  marginLeft: 4,
  marginRight: 4,
}));

export type HorizontalNavigationProps = NavigationProps;

export const HorizontalNavigation: FC<HorizontalNavigationProps> = (props) => {
  const [selectedValue, setSelectedValue] = useState(
    (props.options as NavigationOnClickProps["options"]).find((option) => option.active === true)
      ?.value || props.options[0].value
  );

  return (
    <div>
      {props.options.map((option) => (
        <Item
          key={option.label}
          variant="text"
          active={option.onClick ? option.value === selectedValue : option.active}
          onClick={() => {
            setSelectedValue(option.value);
            if (option.onClick) {
              option.onClick(option.value);
            }
          }}
          component={option.component}
        >
          {option.label}
        </Item>
      ))}
    </div>
  );
};
