import { useEffect, useState } from "react";

import { Radio, Stack, Typography } from "@mui/material";

import type { RadioGroupCardProps } from "./RadioGroupCardBase";

import { Card, CardContent, RadioContainer } from "./RadioGroupCardBase";
import { RadioGroupCardTag } from "./RadioGroupCardTag";

export type RadioGroupCardRowProps<T> = RadioGroupCardProps<T, { title?: string }> & {
  /** Override default 8px spacing between cards for laptop */
  laptopCardSpacing?: number;
  /** Use largeTextBold instead of bodyTextSemibold on title */
  largerOptionTitle?: boolean;
};

/**
 * Create and distribute RadioGroup options evenly to row.
 */
export function RadioGroupCardRow<T>(props: React.PropsWithRef<RadioGroupCardRowProps<T>>) {
  const [selectedValue, setSelectedValue] = useState(
    typeof props.initialValue === "undefined" ? props.options[0].value : props.initialValue
  );

  /*
   * Change initial value on demand, e.g. if the options change.
   */
  useEffect(() => {
    if (props.initialValue) {
      setSelectedValue(props.initialValue);
    }
  }, [props.initialValue]);

  const handleChange = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    newValue: T,
    disabled?: boolean
  ) => {
    // Only set value if not disabled and the event did not originate from button
    if ((event.target as HTMLButtonElement)?.nodeName !== "BUTTON" && !disabled) {
      if (props.onChangeAsync) {
        try {
          await props.onChangeAsync(newValue);
          setSelectedValue(newValue);
        } catch (error) {
          // fail silently
        }
      } else {
        setSelectedValue(newValue);
        props.onChange(newValue);
      }
    }
  };

  return (
    <Stack direction="row" spacing={{ mobile: 1, laptop: props.laptopCardSpacing || 1 }}>
      {props.options.map((option, index) => (
        <Card
          key={index}
          checked={
            props.isEqualValue
              ? props.isEqualValue(option.value, selectedValue)
              : option.value === selectedValue
          }
          disabled={option.disabled}
          hoverDisabled={option.hoverDisabled}
          onClick={(event) => handleChange(event, option.value, option.disabled)}
          width={`${100 / props.options.length}%`}
          data-testid={`radioGroupCardOption-${index}`}
        >
          <CardContent sx={{ position: "relative" }}>
            <RadioContainer>
              <Stack direction="row" alignContent="center" spacing={1.25} marginBottom={0.75}>
                <Radio
                  checked={
                    props.isEqualValue
                      ? props.isEqualValue(option.value, selectedValue)
                      : option.value === selectedValue
                  }
                  disabled={option.disabled}
                  sx={(theme) => ({ margin: `${theme.spacing(-1.25)} !important` })}
                  inputProps={{ "aria-label": `item ${option.title}` }}
                />
                <Typography
                  variant={props.largerOptionTitle ? "largeTextBold" : "bodyTextSemibold"}
                  alignSelf="center"
                >
                  {option.title}
                </Typography>
                {option.tag && <RadioGroupCardTag value={option.tag} />}
              </Stack>
            </RadioContainer>
            {option.content}
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
}
