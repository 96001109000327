import type { FC, ReactNode, SyntheticEvent } from "react";
import { useState } from "react";

import { Box, Tab as MaterialTab, Tabs as MaterialTabs } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";

import type {
  BoxProps,
  TabProps as MaterialTabProps,
  TabsProps as MaterialTabsProps,
} from "@mui/material";

interface TabPanelProps {
  children: ReactNode;
  index: number;
  value: number;
  boxProps?: BoxProps;
}

export const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, boxProps, ...rest } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...rest}
    >
      {value === index && (
        <Box sx={{ p: { mobile: 2, tablet: 4 } }} {...boxProps}>
          {children}
        </Box>
      )}
    </div>
  );
};

/**
 * { selected: boolean } has been extended here since it is controlled by parent Tabs component, see: https://mui.com/api/tab/#css
 */
const Tab = styled(MaterialTab)<MaterialTabProps & { selected?: boolean }>((props) => {
  const backgroundColor = props.selected ? props.theme.palette.primary[400] : "transparent";

  return {
    opacity: 1,
    backgroundColor,
    color: props.selected
      ? `${props.theme.palette.text.primary}!important`
      : props.theme.palette.text[200],
    borderTopLeftRadius: props.theme.shape.borderRadius,
    borderTopRightRadius: props.theme.shape.borderRadius,
    textTransform: "unset",
    ...props.theme.typography.largeTextSemibold,
  };
});

const Swipeable = styled(SwipeableViews)((props) => ({
  backgroundColor: props.theme.palette.primary[400],
  borderBottomLeftRadius: props.theme.shape.borderRadius,
  borderBottomRightRadius: props.theme.shape.borderRadius,
}));

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export interface SwipeableTabsProps {
  tabs: { label: string; component: React.ReactNode }[];
  tabProps?: MaterialTabProps;
  tabIndicatorProps?: MaterialTabsProps["TabIndicatorProps"];
  tabPanelBoxProps?: TabPanelProps["boxProps"];
}

export const SwipeableTabs: FC<SwipeableTabsProps> = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div>
      <MaterialTabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        variant="fullWidth"
        TabIndicatorProps={{
          // Hide indicator by default, allow override
          style: {
            display: "none",
          },
          ...props.tabIndicatorProps,
        }}
      >
        {props.tabs.map((tab, i) => (
          <Tab
            key={tab.label}
            label={tab.label}
            {...props.tabProps}
            {...a11yProps(i)}
            data-testid="swipeableTab"
            data-testid-tab={`swipeableTab${i}`}
          />
        ))}
      </MaterialTabs>
      <Swipeable
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {props.tabs.map((tab, i) => {
          const { component } = tab;
          return (
            <TabPanel key={tab.label} value={value} index={i} boxProps={props.tabPanelBoxProps}>
              {component}
            </TabPanel>
          );
        })}
      </Swipeable>
    </div>
  );
};
