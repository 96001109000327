import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { Info } from "@augment-frontend/mui-icons";
import {
  CardComponent,
  CardCVV,
  CardExpiry,
  CardNumber,
} from "@chargebee/chargebee-js-react-wrapper";
import { Grid, Skeleton } from "@mui/material";

import type { ChargebeeFieldProps } from "./ChargebeeField";
import type {
  CardComponentProps,
  CardElementType,
  CardInputElement,
} from "@chargebee/chargebee-js-react-wrapper";

import { ChargebeeField } from "./ChargebeeField";

export type ChargebeeCardProps<Field = CardElementType> = Pick<
  CardComponentProps<Field>,
  "locale" | "onChange"
> &
  Pick<ChargebeeFieldProps, "disabled"> & {
    tooltipImages: {
      cardNumber: React.ReactNode;
      cardExpiry: React.ReactNode;
      cardCVC: React.ReactNode;
    };
    translations: {
      /** E.g. "Card number" */
      cardNumberLabel: string;
      /** Tooltip for locating the card number */
      cardNumberTooltip: string;
      /** E.g. "Expiry" */
      cardExpiryLabel: string;
      /** Tooltip for locating the card expiry */
      cardExpiryTooltip: string;
      /** E.g. "CVC" */
      cardCVCLabel: string;
      /** Tooltip for locating the card CVC */
      cardCVCTooltip: string;
    };
  };

/**
 * Load and render Chargebee Cards PCI compliant way with Chargebee iframes.
 */
function Card(props: ChargebeeCardProps, ref: React.ForwardedRef<CardInputElement>) {
  const [ready, setReady] = useState(false);
  const cardRef = useRef<CardInputElement>(null);

  /**
   * Expose CardComponent methods to parent.
   * Please note that this is unusual design pattern.
   */
  useImperativeHandle(ref, () => ({
    authorizeWith3ds: async (...args) =>
      (cardRef.current as CardInputElement).authorizeWith3ds(...args),
  }));

  return (
    <>
      {!ready && <Skeleton sx={{ transform: "none", width: "100%", height: 120 }} />}
      <CardComponent
        ref={cardRef}
        onReady={() => setReady(true)}
        locale={props.locale || "en"}
        onChange={props.onChange}
      >
        {/* Display property is used while skeleton is shown to keep the card fields mounted */}
        <Grid container spacing={2} sx={{ display: !ready ? "none" : "flex" }}>
          <Grid id="card-number-container" item mobile={12}>
            <ChargebeeField
              component={CardNumber}
              label={props.translations.cardNumberLabel}
              disabled={props.disabled}
              customEndAdornment={<Info />}
              tooltipTitle={props.translations.cardNumberTooltip}
              tooltipContent={props.tooltipImages.cardNumber}
              data-testid="card-number"
            />
          </Grid>
          <Grid id="card-expiry-container" item mobile={6}>
            <ChargebeeField
              component={CardExpiry}
              label={props.translations.cardExpiryLabel}
              disabled={props.disabled}
              customEndAdornment={<Info />}
              tooltipTitle={props.translations.cardExpiryTooltip}
              tooltipContent={props.tooltipImages.cardExpiry}
              data-testid="card-expiry"
            />
          </Grid>
          <Grid id="card-cvc-container" item mobile={6}>
            <ChargebeeField
              component={CardCVV}
              label={props.translations.cardCVCLabel}
              disabled={props.disabled}
              customEndAdornment={<Info />}
              tooltipTitle={props.translations.cardCVCTooltip}
              tooltipContent={props.tooltipImages.cardCVC}
              data-testid="card-cvc"
            />
          </Grid>
        </Grid>
      </CardComponent>
    </>
  );
}

export const ChargebeeCard = forwardRef(Card);
