import { useEffect } from "react";
import type { FC } from "react";

import * as Sentry from "@sentry/gatsby";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "react-use";

import type { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby";

import { AuthWrapper } from "./components/AuthWrapper/AuthWrapper";
import { type SessionConfig } from "./components/Checkout/types";
import { I18nProvider } from "./contexts/I18nContext";
import { FullSizeError } from "./storybook";

const PageElementWrapper: FC<WrapPageElementNodeArgs | WrapPageElementBrowserArgs> = ({
  element,
  props,
}) => {
  const [, setQueryParamCouponId] = useSessionStorage<SessionConfig["couponIds"] | null>(
    "queryParamCouponId",
    null
  );

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const coupon = params.get("coupon");
    if (coupon) {
      setQueryParamCouponId([coupon]);
      // remove coupon from query params to avoid showing it in the URL
      params.delete("coupon");
      const newSearch = params.toString();
      const newUrl = newSearch
        ? `${props.location.pathname}?${newSearch}`
        : props.location.pathname;
      window.history.replaceState(null, "", newUrl);
    }
  }, []);

  return (
    <I18nProvider {...props}>
      {/* @ts-expect-error TODO fix typings */}
      <AuthWrapper>{element}</AuthWrapper>
    </I18nProvider>
  );
};

export default Sentry.withErrorBoundary(PageElementWrapper, {
  beforeCapture: (scope, error) => {
    // Add tag external_translation to Sentry
    const translatedExternally =
      !!document.querySelector(
        "html.translated-ltr, head.translated-rtl, ya-tr-span, *[_msttexthash], *[data-moz-translations-id]"
      ) || document.documentElement.lang !== window.location.pathname.split("/")[1];

    scope.setTag("external_translation", translatedExternally);

    // Add fingerprint to Sentry to group these errors
    const err = error as Error;
    const boundaryError =
      err?.message?.includes("insertBefore") ||
      err?.message?.includes("removeChild") ||
      err?.message?.includes("object can not be found");

    if (boundaryError && translatedExternally) {
      scope.setFingerprint(["translation-boundary-error"]);
    }
  },
  fallback: () => {
    const { t } = useTranslation("app");

    return (
      <FullSizeError
        title={t("common:ohNo")}
        description={t("common:somethingWentWrong")}
        buttonText={t("common:tryAgain")}
        boxProps={{ sx: { mt: 2 } }}
        buttonProps={{ onClick: () => window?.location.reload() }}
      />
    );
  },
  showDialog: true,
});
