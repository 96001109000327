/**
 * Sanitizes address fields
 * Removes leading and trailing spaces and capitalizes first letter
 * @param value Input value
 * @returns string
 * */
export const sanitizeValue = (value: string) => {
  const valueWithoutSpaces = value.replace(/^\s+|\s+(?=\s)|\s+$/g, "");
  return valueWithoutSpaces.charAt(0).toUpperCase() + valueWithoutSpaces.slice(1);
};
