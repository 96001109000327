import type { FC } from "react";
import { useEffect, useRef, useState } from "react";

import { AddRound } from "@augment-frontend/mui-icons";
import { Box, Button } from "@mui/material";

import type { PaymentMethodListProps } from "../../PaymentMethods";
import type { AddPaymentMethodModalProps } from "./AddPaymentMethodModal/AddPaymentMethodModal";
import type { ChangePaymentMethodModalProps } from "./ChangePaymentMethodModal/ChangePaymentMethodModal";
import type { DeletePaymentMethodModalProps } from "./DeletePaymentMethodModal/DeletePaymentMethodModal";
import type { SepaCreditTransferProps } from "./SepaCreditTransfer";
import type { PaymentIntent } from "@chargebee/chargebee-js-react-wrapper";

import { DeferredPromise } from "../../../utils";
import { getPaymentMethodLogo, PaymentMethodList } from "../../PaymentMethods";
import { Surface } from "../../Surface";
import { Title } from "../Title";
import { AddPaymentMethodModal } from "./AddPaymentMethodModal/AddPaymentMethodModal";
import { ChangePaymentMethodModal } from "./ChangePaymentMethodModal/ChangePaymentMethodModal";
import { DeletePaymentMethodModal } from "./DeletePaymentMethodModal/DeletePaymentMethodModal";
import { SepaCreditTransfer } from "./SepaCreditTransfer";

export type MyAccountPaymentMethodsProps = {
  /** Will be rendered as list. */
  paymentMethods: PaymentMethodListProps["paymentMethods"];
  /** If provided, SEPA Credit Transfer is rendered below payment methods list */
  sepaCreditTransfer?: Omit<SepaCreditTransferProps, "translations">;
  onChangeRole: ChangePaymentMethodModalProps["onChangeRole"];
  /**
   * Whether to allow adding new payment methods.
   */
  enableAdd?: boolean;
  cbInstance: AddPaymentMethodModalProps["cbInstance"];
  onAdd: (
    paymentIntentPaymentMethodType: PaymentIntent["payment_method_type"]
  ) => Promise<PaymentIntent>;
  onAddCompleted: AddPaymentMethodModalProps["onPaymentSuccess"];
  /** Listener on delete event */
  onDelete: (paymentMethodId: string) => Promise<void>;
  onUnknownError?: AddPaymentMethodModalProps["onUnknownPaymentError"];
  translations: {
    /** E.g. Payment methods */
    title: string;
    /** E.g. Primary */
    primary: string;
    /** E.g. Backup */
    backup: string;
    /** E.g. Expiration */
    expiration: string;
    /** E.g. Add new */
    addPaymentMethod: string;
    /** E.g. Delete */
    deleteButton: string;
    changePaymentMethodModal: Omit<
      ChangePaymentMethodModalProps["translations"],
      "primary" | "backup"
    >;
    addPaymentMethodModal: AddPaymentMethodModalProps["translations"];
    deletePaymentMethodModal: DeletePaymentMethodModalProps["translations"];
    sepaCreditTransfer: SepaCreditTransferProps["translations"];
  };
} & Pick<AddPaymentMethodModalProps, "tooltipImages" | "locale">;

export const MyAccountPaymentMethods: FC<MyAccountPaymentMethodsProps> = (props) => {
  const [changePaymentMethodModalOpen, setChangePaymentMethodModalOpen] = useState<boolean>(false);
  const [addPaymentMethodModalOpen, setAddPaymentMethodModalOpen] = useState<boolean>(false);
  const [deletePaymentMethodModalOpen, setDeletePaymentMethodModalOpen] = useState<boolean>(false);
  const [openedPaymentMethod, setOpenedPaymentMethod] = useState<
    PaymentMethodListProps["paymentMethods"][number] | null
  >(null);
  const promiseRef = useRef<DeferredPromise | null>(null);

  useEffect(() => {
    return () => {
      promiseRef.current?.reject();
      promiseRef.current = null;
    };
  }, []);

  return (
    <>
      {changePaymentMethodModalOpen && openedPaymentMethod && (
        <ChangePaymentMethodModal
          open={changePaymentMethodModalOpen}
          paymentMethod={openedPaymentMethod}
          icon={getPaymentMethodLogo(
            openedPaymentMethod.type,
            openedPaymentMethod.brand || "OTHER"
          )}
          onChangeRole={props.onChangeRole}
          onClose={() => {
            setChangePaymentMethodModalOpen(false);
            promiseRef.current?.reject();
            promiseRef.current = null;
          }}
          onCompleted={(role) => {
            setChangePaymentMethodModalOpen(false);
            if (role === "primary") {
              promiseRef.current?.resolve();
            } else {
              promiseRef.current?.reject();
            }
            promiseRef.current = null;
          }}
          onCancel={() => {
            setChangePaymentMethodModalOpen(false);
            promiseRef.current?.reject();
            promiseRef.current = null;
          }}
          translations={{
            ...props.translations.changePaymentMethodModal,
            primary: props.translations.primary,
            backup: props.translations.backup,
          }}
        />
      )}
      {deletePaymentMethodModalOpen && openedPaymentMethod && (
        <DeletePaymentMethodModal
          open={deletePaymentMethodModalOpen}
          onSubmit={async () => {
            await props.onDelete(openedPaymentMethod.id);
            setOpenedPaymentMethod(null);
          }}
          onClose={() => {
            setDeletePaymentMethodModalOpen(false);
            setOpenedPaymentMethod(null);
          }}
          paymentMethod={openedPaymentMethod}
          icon={getPaymentMethodLogo(
            openedPaymentMethod.type,
            openedPaymentMethod.brand || "OTHER"
          )}
          translations={props.translations.deletePaymentMethodModal}
        />
      )}
      {props.enableAdd && (
        <AddPaymentMethodModal
          cbInstance={props.cbInstance}
          open={addPaymentMethodModalOpen}
          onSubmit={(_, newPaymentMethod) =>
            props.onAdd(newPaymentMethod as NonNullable<typeof newPaymentMethod>)
          }
          onPaymentSuccess={async (...args) => {
            await props.onAddCompleted(...args);
            setAddPaymentMethodModalOpen(false);
          }}
          onSecondaryButtonClick={() => {
            setAddPaymentMethodModalOpen(false);
          }}
          onClose={() => {
            setAddPaymentMethodModalOpen(false);
          }}
          onUnknownPaymentError={(err) => {
            if (props.onUnknownError) {
              props.onUnknownError(err);
            }
          }}
          tooltipImages={props.tooltipImages}
          locale={props.locale}
          translations={props.translations.addPaymentMethodModal}
        />
      )}
      <Surface
        sx={(theme) => ({
          bgcolor: theme.palette.secondary[200],
          p: 2,
          py: { mobile: 2, tablet: 0 },
        })}
        data-testid="paymentMethods"
        data-testid-payment-method-count={props.paymentMethods.length}
      >
        <Title>{props.translations.title}</Title>
        <PaymentMethodList
          paymentMethods={props.paymentMethods}
          translations={props.translations}
          onChange={async (paymentMethod) => {
            if (!paymentMethod.isPrimary) {
              setOpenedPaymentMethod(paymentMethod);
              setChangePaymentMethodModalOpen(true);
              const deferred = new DeferredPromise();
              promiseRef.current = deferred;
              return deferred.promise;
            }
            return Promise.resolve();
          }}
          onDeleteClick={(paymentMethod) => {
            setOpenedPaymentMethod(paymentMethod);
            setDeletePaymentMethodModalOpen(true);
          }}
        />
        {props.enableAdd && (
          <Box display="flex" justifyContent="center" sx={{ mt: { mobile: 2, tablet: 4 } }}>
            <Button
              variant="outlined"
              onClick={() => setAddPaymentMethodModalOpen(true)}
              sx={{ flexGrow: { mobile: 1, tablet: 0 }, justifyContent: "space-between" }}
              endIcon={<AddRound />}
              data-testid="openAddPaymentMethodModal"
            >
              {props.translations.addPaymentMethod}
            </Button>
          </Box>
        )}
        {props.sepaCreditTransfer?.iban &&
          props.sepaCreditTransfer?.swift &&
          props.sepaCreditTransfer?.bankName && (
            <Box mt={{ mobile: 2, tablet: 4 }}>
              <SepaCreditTransfer
                iban={props.sepaCreditTransfer.iban}
                swift={props.sepaCreditTransfer.swift}
                bankName={props.sepaCreditTransfer.bankName}
                routingNumber={props.sepaCreditTransfer.routingNumber}
                translations={{ ...props.translations.sepaCreditTransfer }}
              />
            </Box>
          )}
      </Surface>
    </>
  );
};
