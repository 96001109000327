import * as Sentry from "@sentry/gatsby";

import { hostingEnv } from "./src/config/env";

Sentry.init({
  dsn: "https://49384d3cb8e644c896763088233c28e8@o374899.ingest.sentry.io/5650012",
  sampleRate: 0.5,
  environment: hostingEnv || "development",
  enabled: !!hostingEnv,
});
