import { Grid, Typography, useTheme } from "@mui/material";

import type { AddressInputProps } from "../../../../Inputs";
import type { UserInformation } from "../../types";
import type { CountryCode } from "@augment-frontend/constants";

import { AddressInput } from "../../../../Inputs";

type ShippingAddressInfoTranslations = {
  shippingInformationTitle: string;
  addressComponent: AddressInputProps["translations"];
};

export interface ShippingAddressInfoProps<T extends CountryCode> {
  countryCode: T;
  allDisabled?: boolean;
  translations: ShippingAddressInfoTranslations;
  shippingInformation: Partial<UserInformation["shippingInformation"]>;
  onChangeShippingAddressHandler: AddressInputProps["onChange"];
}

/**
 * Shipping address information form
 * */
export const ShippingAddressInfo = <T extends CountryCode>(props: ShippingAddressInfoProps<T>) => {
  const theme = useTheme();
  return (
    <>
      <Typography variant="largeTextBold">{props.translations.shippingInformationTitle}</Typography>
      <Grid mt={0} container spacing={theme.spacing(1)} data-testid="shippingAddressContainer">
        <AddressInput
          translations={props.translations.addressComponent}
          address={{
            line1: props.shippingInformation?.street1,
            line2: props.shippingInformation?.street2,
            houseNumber: props.shippingInformation?.houseNumber,
            city: props.shippingInformation?.city,
            state: {
              name: props.shippingInformation?.state || "",
            },
            postalCode: props.shippingInformation?.postalCode,
            country: { code: props.countryCode },
          }}
          disabled={props.allDisabled}
          countryDisabled={true}
          onChange={props.onChangeShippingAddressHandler}
        />
      </Grid>
    </>
  );
};
