import type { SSNHandler, SSNResult } from "./types";

import { ageInYears, daysInMonth } from "./util";

/**
 * Calculate the validity of a Finnish SSN and the age of the person.
 * @returns Validity and age, or -1 as age if the SSN is invalid.
 */
export const finnishSSN: SSNHandler = (ssn) => {
  const SSN_REGEX = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d-|[012]\dA)\d{3}[\dA-Z]$/;
  const CHECKSUM_ARRAY = "0123456789ABCDEFHJKLMNPRSTUVWXY".split("");
  const CENTURY_MAP = new Map([
    ["A", 2000],
    ["-", 1900],
    ["+", 1800],
  ]);

  const result: SSNResult = {
    valid: false,
    age: -1,
    standardizedSSN: null,
  };

  // Regex validate
  if (!SSN_REGEX.test(ssn)) {
    return result;
  }

  const day = parseInt(ssn.substring(0, 2), 10);
  const month = parseInt(ssn.substring(2, 4), 10);
  const century = ssn.charAt(6);
  const year = parseInt(ssn.substring(4, 6), 10) + (CENTURY_MAP.get(century) || 0);
  const daysInSSNMonth = daysInMonth(year, month - 1);

  if (day > daysInSSNMonth) {
    return result;
  }

  const checksum = ssn.substring(10, 11);
  const checksumBase = parseInt(`${ssn.substring(0, 6)}${ssn.substring(7, 10)}`, 10);

  result.valid = checksum === CHECKSUM_ARRAY[checksumBase % 31];
  result.age = result.valid ? ageInYears(new Date(year, month - 1, day, 0, 0, 0, 0)) : -1;
  result.standardizedSSN = result.valid ? ssn : null;

  return result;
};
