import type { FC } from "react";
import { useState } from "react";

import { Grid, Typography } from "@mui/material";

import type { CartProduct } from "../../types";
import type { StepProps } from "../Step";
import type { ConfigurationProps } from "./Configuration/Configuration";
import type { ConfigurationItemOption, Model } from "./types";

import { Alert } from "../../../Alert";
import { Step } from "../Step";
import { Configuration } from "./Configuration";
import { generateCartProductsFromSelection, getInitialAddons, getLastCartModel } from "./utils";

export type StepModelSelectionProps = Omit<ConfigurationProps, "onChange"> & {
  /** E.g. "We are low on Stock in the model ES 210" */
  warningText?: string;
  /* Initial Cart products (E.g. from session storage) */
  initialCartProducts?: CartProduct[];
  /* Initial history of each category selections (e.g. from session storage) */
  initialCategoryHistory?: ConfigurationProps["initialCategoryHistory"];
  onForward: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cartProducts: CartProduct[],
    categoryHistory: ConfigurationProps["initialCategoryHistory"]
  ) => void;
  translations: {
    /** E.g. "Tailor your e-scooter" */
    title: string;
    /** E.g. "Continue" */
    forwardButtonLabel: string;
    stepTranslations: StepProps["translations"];
  };
} & Pick<StepProps["bottomNavigation"], "onBackward" | "width">;

/**
 * Renders a step for tailor an e-scooter.
 */
export const StepModelSelection: FC<StepModelSelectionProps> = (props) => {
  const { translations, warningText, initialCartProducts, onForward } = props;
  const [cartProducts, setCartProducts] = useState(initialCartProducts || []);
  const [categoryHistory, setCategoryHistory] = useState(props.initialCategoryHistory || []);

  const missingData =
    !props.productCategories[0].models.length ||
    !props.configurationItems[props.productCategories[0].value].filter((c) => c.type === "config")
      .length;

  const onChangeHandler = (
    activeModel: Model,
    activeOptions: ConfigurationItemOption[],
    latestCategoryHistory: typeof categoryHistory
  ) => {
    setCartProducts(generateCartProductsFromSelection(activeModel, activeOptions));
    setCategoryHistory(latestCategoryHistory);
  };

  const lastCartModel =
    props.initialCartProducts &&
    getLastCartModel(
      props.initialCartProducts,
      props.productCategories.flatMap((category) => category.models)
    );

  return !missingData ? (
    <Step
      title={translations.title}
      stepComponent={{
        viewports: { laptop: 12, tablet: 12 },
        component: (
          <>
            <Typography
              variant="subheadingBold"
              display={{ mobile: "none", tablet: "block" }}
              mb={warningText ? 2 : 3}
              textAlign="center"
            >
              {translations.title}
            </Typography>
            {warningText && (
              <Alert
                sx={{
                  marginBottom: "13px",
                }}
                severity="warning"
                description={warningText}
              />
            )}
            <Grid container>
              <Grid item mobile={12}>
                <Configuration
                  {...props}
                  onChange={onChangeHandler}
                  initialModel={lastCartModel}
                  initialConfiguration={
                    props.initialCartProducts &&
                    getInitialAddons(
                      props.initialCartProducts,
                      props.configurationItems[
                        lastCartModel?.productCategory?.toLowerCase() ||
                          props.productCategories[0].value
                      ].filter((c) => c.type === "addon")
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        ),
      }}
      bottomNavigation={{
        hideBackward: true,
        onForward: (event) => onForward(event, cartProducts, categoryHistory),
        forwardButton: translations.forwardButtonLabel,
        width: props.width,
      }}
      translations={translations.stepTranslations}
    />
  ) : null;
};
