import type { FC } from "react";
import { useState } from "react";

import { Edit } from "@augment-frontend/mui-icons";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { EmailModalProps, PasswordModalProps, PhoneModalProps } from "./Modals";

import { Alert } from "../../../Alert";
import { Surface } from "../../../Surface";
import { TextField } from "../../../TextField";
import { Title } from "../../Title";
import { EmailModal, PasswordModal, PhoneModal } from "./Modals";

export interface CredentialsProps {
  email: Omit<EmailModalProps, "onClose" | "onCompleted" | "open" | "translations">;
  phone: Omit<PhoneModalProps, "onClose" | "onCompleted" | "open" | "translations">;
  password: Omit<PasswordModalProps, "onClose" | "onCompleted" | "open" | "translations">;
  generalConsentEmail: {
    onChange: (checked: boolean) => Promise<void>;
    value?: boolean | null;
    isDisabled?: boolean;
  };
  generalConsentPhone: {
    onChange: (checked: boolean) => Promise<void>;
    value?: boolean | null;
    isDisabled?: boolean;
  };
  translations: {
    title: string;
    mediumUnverified: string;
    email: EmailModalProps["translations"];
    phone: PhoneModalProps["translations"];
    password: PasswordModalProps["translations"] & {
      /** E.g. "Password" */
      passwordLabel: string;
    };
    consent: {
      /** E.g. "I don't want to receive Augment special offers" */
      generalConsentDecline: string;
      /** E.g. "Email" */
      email: string;
      /** E.g. "SMS" */
      sms: string;
    };
  };
}

export const Credentials: FC<CredentialsProps> = (props) => {
  const [emailModalOpen, setEmailModalOpen] = useState<boolean>(false);
  const [phoneModalOpen, setPhoneModalOpen] = useState<boolean>(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(false);
  const [emailConsent, setEmailConsent] = useState(props.generalConsentEmail.value);
  const [emailConsentError, setEmailConsentError] = useState("");
  const [emailConsentLoading, setEmailConsentLoading] = useState(false);
  const [phoneConsent, setPhoneConsent] = useState(props.generalConsentPhone.value);
  const [phoneConsentError, setPhoneConsentError] = useState("");
  const [phoneConsentLoading, setPhoneConsentLoading] = useState(false);
  const theme = useTheme();

  return (
    <>
      <EmailModal
        {...props.email}
        onClose={() => setEmailModalOpen(false)}
        onCompleted={() => setEmailModalOpen(false)}
        open={emailModalOpen}
        translations={props.translations.email}
      />
      <PhoneModal
        {...props.phone}
        onClose={() => setPhoneModalOpen(false)}
        onCompleted={() => setPhoneModalOpen(false)}
        open={phoneModalOpen}
        translations={props.translations.phone}
      />
      <PasswordModal
        {...props.password}
        onClose={() => setPasswordModalOpen(false)}
        onCompleted={() => setPasswordModalOpen(false)}
        open={passwordModalOpen}
        translations={props.translations.password}
      />
      <Surface sx={{ bgcolor: theme.palette.primary[400] }}>
        <Title>{props.translations.title}</Title>
        <Grid container spacing={2}>
          <Grid item mobile={12} tablet={6}>
            <TextField
              fullWidth
              label={props.translations.email.mediumLabel}
              helperText={props.email.mediumVerified ? "" : props.translations.mediumUnverified}
              value={props.email.mediumValue}
              error={!props.email.mediumVerified}
              InputProps={{
                inputProps: {
                  disabled: true,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: theme.palette.text.primary }}
                      onClick={() => setEmailModalOpen(true)}
                    >
                      <Edit sx={{ color: theme.palette.text.primary }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item mobile={12} tablet={6}>
            <TextField
              fullWidth
              label={props.translations.phone.mediumLabel}
              helperText={props.phone.mediumVerified ? "" : props.translations.mediumUnverified}
              value={props.phone.mediumValue}
              error={!props.phone.mediumVerified}
              InputProps={{
                inputProps: {
                  disabled: true,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: theme.palette.text.primary }}
                      onClick={() => setPhoneModalOpen(true)}
                    >
                      <Edit sx={{ color: theme.palette.text.primary }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item mobile={12} tablet={6}>
            <TextField
              fullWidth
              label={props.translations.password.passwordLabel}
              value="********"
              InputProps={{
                inputProps: {
                  disabled: true,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: theme.palette.text.primary }}
                      onClick={() => setPasswordModalOpen(true)}
                    >
                      <Edit sx={{ color: theme.palette.text.primary }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* Opt-out checkboxes */}
        <Grid item mobile={12} tablet={6}>
          <FormControl sx={{ mt: 3 }} component="fieldset" variant="standard">
            <FormLabel component="legend">
              {props.translations.consent.generalConsentDecline}
            </FormLabel>
            <FormGroup sx={{ marginTop: { mobile: 1, tablet: "auto" } }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checkedIcon={emailConsentLoading ? <CircularProgress size={24} /> : undefined}
                    icon={emailConsentLoading ? <CircularProgress size={24} /> : undefined}
                    checked={!emailConsent}
                    onChange={async (_event, checked) => {
                      setEmailConsentLoading(true);
                      setEmailConsentError("");
                      try {
                        await props.generalConsentEmail.onChange(checked);
                        setEmailConsent((prev) => !prev);
                      } catch (error) {
                        setEmailConsentError(
                          error instanceof Error ? error.message : "Unknown error"
                        );
                      }
                      setEmailConsentLoading(false);
                    }}
                    disabled={props.generalConsentEmail.isDisabled}
                  />
                }
                label={props.translations.consent.email}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checkedIcon={phoneConsentLoading ? <CircularProgress size={24} /> : undefined}
                    icon={phoneConsentLoading ? <CircularProgress size={24} /> : undefined}
                    checked={!phoneConsent}
                    onChange={async (_event, checked) => {
                      setPhoneConsentLoading(true);
                      setPhoneConsentError("");
                      try {
                        await props.generalConsentPhone.onChange(checked);
                        setPhoneConsent((prev) => !prev);
                      } catch (error) {
                        setPhoneConsentError(
                          error instanceof Error ? error.message : "Unknown error"
                        );
                      }
                      setPhoneConsentLoading(false);
                    }}
                    disabled={props.generalConsentPhone.isDisabled}
                  />
                }
                label={props.translations.consent.sms}
              />
            </FormGroup>
          </FormControl>
          {(emailConsentError || phoneConsentError) && (
            <Alert
              severity="error"
              description={emailConsentError || phoneConsentError}
              sx={{ alignSelf: "stretch", marginTop: 1 }}
            />
          )}
        </Grid>
      </Surface>
    </>
  );
};
