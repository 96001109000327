module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Augment.Eco","short_name":"Augment","start_url":"/","background_color":"#1a1d1c","theme_color":"#1a1d1c","icon":"src/images/augment-icon-charcoal-snow.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3baa496abeb995061227cc41cc872db2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#f3f3f3","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TKQRNKD","selfHostedOrigin":"https://gtm.augment.eco","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"()=>{let e=null;try{e=window.localStorage.getItem(\"cookies_consent\")}catch{}return{platform:\"gatsby\",...e?JSON.parse(e):null}}"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
