import type { FC } from "react";

import { Box, Typography } from "@mui/material";

import type { SubscriptionProps } from "./Subscription";

import { Subscription } from "./Subscription";

export interface SubscriptionsProps {
  subscriptions: Omit<SubscriptionProps, "translations">[];
  translations: SubscriptionProps["translations"] & {
    subscriptionsTitle: string;
    noSubscriptions: string;
  };
}

export const Subscriptions: FC<SubscriptionsProps> = (props) => {
  if (props.subscriptions.length === 0) {
    return <Typography>{props.translations.noSubscriptions}</Typography>;
  }

  return (
    <>
      {props.subscriptions.map((subscription, index) => (
        <Box
          key={index}
          sx={{ mb: index === props.subscriptions.length - 1 ? 0 : 2 }}
          data-testid="subscription"
        >
          <Subscription {...subscription} translations={props.translations} />
        </Box>
      ))}
    </>
  );
};
