import type { FC } from "react";

import { Edit } from "@augment-frontend/mui-icons";
import { IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { TypographyProps } from "@mui/material";

export interface HeaderProps {
  /** Callback for onClick event, triggered when edit-button is clicked */
  onEditClick?: () => void;
  name: string;
  /** E.g. "Shared with 3 people" */
  shareInfo?: string;
}

export const Header: FC<HeaderProps> = (props) => {
  const theme = useTheme();

  const ShareInfo = (componentProps: TypographyProps) => (
    <Typography variant="largeTextBold" color={theme.palette.success.main} {...componentProps}>
      &bull; {props.shareInfo}
    </Typography>
  );

  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: theme.palette.secondary.light,
          padding: 0,
          borderRadius: `${theme.shape.borderRadius}px`,
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="largeTextBold" data-testid="deviceName">
            {props.name}
          </Typography>
          {props.onEditClick && (
            <IconButton onClick={props.onEditClick}>
              <Edit sx={{ color: theme.palette.text[200] }} data-testid="openEditNameModal" />
            </IconButton>
          )}
        </Stack>
        {props.shareInfo && <ShareInfo sx={{ display: { mobile: "none", tablet: "block" } }} />}
      </Stack>
      {props.shareInfo && <ShareInfo sx={{ display: { mobile: "block", tablet: "none" } }} />}
    </>
  );
};
