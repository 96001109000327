import { useState } from "react";

import { Box, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { ActionButtonsProps } from "../ActionButtons";
import type { HeadingContentProps } from "../HeadingContent";
import type { InfoContentProps } from "./InfoContent";
import type { TogglePointProps } from "./TogglePoint";

import { ActionButtons } from "../ActionButtons";
import { HeadingContent } from "../HeadingContent";
import { Stepper } from "./Stepper";
import { Tooltip } from "./Tooltip";

export interface BlockProductProps {
  title: HeadingContentProps["title"];
  subtitle: HeadingContentProps["description"];
  /** Product photo overlaid with info toggles */
  image: React.ReactNode;
  /** Orientation; landscape is wider than tall, portrait is taller than wide. Preferred aspect ratio is 4:3. */
  imageOrientation: "landscape" | "portrait";
  /** Array of info items */
  items: {
    /** Info toggle placement; percentages from the upper left-hand corner */
    hotspot: TogglePointProps["hotspot"];
    /** Info item contents */
    content: Omit<InfoContentProps, "onClose">;
  }[];
  callToAction?: Pick<ActionButtonsProps, "buttons">;
}

export const BlockProduct = (props: BlockProductProps) => {
  const theme = useTheme();
  const [activeInfo, setActiveInfo] = useState<number | undefined>(undefined);
  const titleSx = { color: theme.palette.text.secondary };

  return (
    <Box
      component="section"
      sx={{
        display: { mobile: "flex", tablet: "block" },
        flexDirection: { mobile: "column", tablet: undefined },
        position: "relative",
        my: { mobile: 10, tablet: 8, laptop: 14 }, // Same as in GridContainer
        pt: { mobile: 4.5, tablet: 0 }, // Less than pb due to title line height that adds up
        pb: { mobile: 5.5, tablet: 0 },
        background: {
          mobile: "linear-gradient(90deg, #717171 0%, #6C6C6C 50%, #696868 100%)",
          tablet: "radial-gradient(transparent -90%, #717171)",
        },
      }}
    >
      {/* Heading content on mobile */}
      <Container sx={{ display: { mobile: "block", tablet: "none" }, px: 3, mb: 2 }}>
        <HeadingContent title={props.title} description={props.subtitle} titleSx={titleSx} />
      </Container>

      <Container
        disableGutters
        sx={{
          maxWidth: {
            mobile: "100%",
            tablet: `${theme.breakpoints.values.tablet - 2 * 64}px`,
            laptop: `${
              theme.breakpoints.values.laptop -
              (props.imageOrientation === "landscape" ? 2 : 4.5) * 144
            }px`,
          },
          position: "relative",
        }}
      >
        {/* Stepper on mobile */}
        {typeof activeInfo !== "undefined" && (
          <Stepper
            steps={props.items.map((item) => item.content)}
            activeStep={activeInfo}
            onClose={() => setActiveInfo(undefined)}
            onBackClick={() => {
              setActiveInfo(activeInfo === 0 ? props.items.length - 1 : activeInfo - 1);
            }}
            onNextClick={() => {
              setActiveInfo(activeInfo === props.items.length - 1 ? 0 : activeInfo + 1);
            }}
            boxProps={{
              sx: {
                display: { tablet: "none" },
                position: "absolute",
                top: 0,
                left: theme.spacing(3),
                right: theme.spacing(3),
                zIndex: 2,
              },
            }}
          />
        )}
        <div>
          {/* The image sets the size here; everything else uses absolute positioning */}
          {props.image}
          {props.items.map((item, index) => (
            // Toggle points within tooltips are shown on all viewport
            // Tooltips are shown on tablet & laptop only; mobile uses Stepper
            <Tooltip
              key={index}
              hotspot={item.hotspot}
              infoContent={item.content}
              index={index}
              activeIndex={activeInfo}
              onClick={() => setActiveInfo(activeInfo === index ? undefined : index)}
            />
          ))}
          {/* Heading content on tablet & laptop */}
          <Box
            sx={{
              display: { mobile: "none", tablet: "flex" },
              flexDirection: "column",
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              height: "100%",
              width: { mobile: "100%", tablet: "50%", laptop: "45%" },
              justifyContent: "center",
              mt: { tablet: "-6%" },
            }}
          >
            <HeadingContent title={props.title} description={props.subtitle} titleSx={titleSx} />
          </Box>
        </div>
      </Container>

      {/* Action buttons on mobile */}
      {props.callToAction && (
        <Container sx={{ display: { mobile: "block", tablet: "none" }, px: 3 }}>
          <ActionButtons
            {...props.callToAction}
            direction="column"
            grow={true}
            boxProps={{ mt: 2 }}
          />
        </Container>
      )}
    </Box>
  );
};
