import type { FC } from "react";

import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { InfoGridItemProps } from "./InfoGridItem";

import { InfoGridItem } from "./InfoGridItem";

export interface InfoGridProps {
  infoGridItems: InfoGridItemProps[];
}

export const InfoGrid: FC<InfoGridProps> = (props) => {
  const theme = useTheme();

  return (
    <Box>
      <Grid
        container
        direction="row"
        columnSpacing={{ mobile: theme.spacing(2), tablet: theme.spacing(4) }}
        rowSpacing={theme.spacing(2)}
      >
        {props.infoGridItems.map((item, index) => (
          <InfoGridItem key={index} icon={item.icon} title={item.title} value={item.value} />
        ))}
      </Grid>
    </Box>
  );
};
