/* eslint-disable react/display-name */

import { forwardRef } from "react";

import { keyframes } from "@emotion/react";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export interface TogglePointProps {
  /** Hotspot within a parent that has `position: relative` */
  hotspot: {
    /** Placement on the x-axis; percentage from the left edge of the parent */
    x: number;
    /** Placement on the y-axis; percentage from the top of the parent */
    y: number;
  };
  /** Toggle's index in the original array of toggles */
  index: number;
  /** On click handler */
  onClick: (index: number) => void;
}

export const TogglePoint = forwardRef<HTMLButtonElement, TogglePointProps>((props, ref) => {
  const { hotspot, index, onClick, ...rest } = props;
  const theme = useTheme();

  return (
    <IconButton
      ref={ref}
      {...rest}
      sx={{
        position: "absolute",
        // negative offset is half of the height/width below
        left: {
          mobile: `calc(${hotspot.x}% - ${theme.spacing(1)})`,
          tablet: `calc(${hotspot.x}% - ${theme.spacing(1.5)})`,
        },
        top: {
          mobile: `calc(${hotspot.y}% - ${theme.spacing(1)})`,
          tablet: `calc(${hotspot.y}% - ${theme.spacing(1.5)})`,
        },
        m: theme.spacing(-1),
        zIndex: { tablet: 2 },
      }}
      onClick={() => onClick(index)}
    >
      <Box
        sx={{
          height: { mobile: theme.spacing(2), tablet: theme.spacing(3) },
          width: { mobile: theme.spacing(2), tablet: theme.spacing(3) },
          borderRadius: "50%",
          bgcolor: theme.palette.primary.main,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1,
          animation: `${keyframes`
              0% {transform: scale(1.0)}
              50% {transform: scale(1.25)}
              100% {transform: scale(1.0)}
            `} 2s ease-out infinite`,
          animationDelay: `${index * 350}ms`,
        }}
      >
        <Box
          sx={{
            height: { mobile: theme.spacing(0.75), tablet: theme.spacing(1) },
            width: { mobile: theme.spacing(0.75), tablet: theme.spacing(1) },
            bgcolor: theme.palette.primary[200],
            borderRadius: "50%",
          }}
        />
      </Box>
    </IconButton>
  );
});
