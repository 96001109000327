import type { FC, ReactNode } from "react";
import { useState } from "react";

import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { BoxProps, GridProps } from "@mui/material";

/** Style for images */
const commonImgStyles = (borderRadius: number) => ({
  display: "block",
  width: "auto",
  height: "auto",
  maxWidth: "100%",
  maxHeight: "100%",
  borderRadius: `${borderRadius / 2}px`,
});

/** Style for containers */
const commonContainerStyles = (borderRadius: number) => ({
  borderRadius: `${borderRadius / 2}px`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  "& *": {
    ...commonImgStyles(borderRadius),
  },
});

/** Styled Container for main image
 * @backgroundColor Background color for transparent images or unfit images
 */
const ImageContainer = styled(Box)<BoxProps>((props) => ({
  ...commonContainerStyles(props.theme.shape.borderRadius),
}));

/** Styled Container for thumbnail images
 *
 * @param active - if true, the thumbnail is active and will be highlighted
 */
const ThumbnailContainer = styled(Box, {
  shouldForwardProp: (prop) => {
    if (prop === "active") {
      return false;
    }
    return true;
  },
})<BoxProps & { active?: boolean }>((props) => ({
  ...commonContainerStyles(props.theme.shape.borderRadius),
  cursor: props.active ? "default" : "pointer",
  "& *": {
    ...commonImgStyles(props.theme.shape.borderRadius),
    opacity: props.active ? 1 : 0.7,
    transition: "opacity 0.3s",
    "&:hover": {
      opacity: 1,
    },
  },
}));

export interface GalleryProps {
  images: ReactNode[];
  mainImageContainerProps?: BoxProps;
  thumbnailContainerProps?: BoxProps;
  thumbnailsGridProps?: GridProps;
}

/**
 * Render collection of images in Gallery.
 * If more than one image is provided, a thumbnail gallery is rendered.
 * The height and width of the gallery will be determined by the componet grid wrapping it.
 */
export const Gallery: FC<GalleryProps> = (props) => {
  const [mainImageIndex, setMainImageIndex] = useState<number>(0);

  return (
    <>
      <ImageContainer {...props.mainImageContainerProps}>
        {props.images[mainImageIndex]}
      </ImageContainer>
      {props.images.length > 1 && (
        <Grid
          container
          direction="row"
          spacing={1}
          marginTop={0}
          justifyContent="center"
          {...props.thumbnailsGridProps}
        >
          {props.images.map((_, index) => (
            <Grid item key={index} mobile={12 / props.images.length} flexBasis="auto !important">
              <ThumbnailContainer
                {...props.thumbnailContainerProps}
                active={mainImageIndex === index}
                onClick={() => setMainImageIndex(index)}
              >
                {props.images[index]}
              </ThumbnailContainer>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};
