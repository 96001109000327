import type { SSNHandler, SSNResult } from "./types";

import { luhn } from "./luhn";
import { ageInYears, daysInMonth } from "./util";

/**
 * Calculate the validity of a Swedish SSN and the age of the person.
 * @note The age may be slightly incorrect, because of a limited amount of birth numbers per day (1000), a person might not receive the exact day as the day part. It can be a few days later or earlier, but always within the same month.
 * @returns Validity and age, or -1 as age if the SSN is invalid.
 */
export const swedishSSN: SSNHandler = (ssn) => {
  let value = ssn;

  switch (ssn.length) {
    case 10:
      // 2-digit year, without dash
      value = `${ssn.substring(0, 6)}-${ssn.substring(6)}`;
      break;
    case 12:
      // 4-digit year, without dash
      value = `${ssn.substring(2, 8)}-${ssn.substring(8)}`;
      break;
    case 13:
      // 4-digit year, with dash
      value = `${ssn.substring(2)}`;
      break;
    default:
      value = ssn;
      break;
  }

  const SSN_REGEX = /^\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(\+|-)\d{4}$/;

  const result: SSNResult = {
    valid: false,
    age: -1,
    standardizedSSN: null,
  };

  if (!SSN_REGEX.test(value)) {
    return result;
  }

  const today = new Date();
  const day = parseInt(value.substring(4, 6), 10);
  const month = parseInt(value.substring(2, 4), 10);
  const over100 = value.charAt(6) === "+";
  let year = 2000 + parseInt(value.substring(0, 2), 10);

  if (year > today.getFullYear()) {
    year -= 100;
  }
  if (over100) {
    year -= 100;
  }

  const daysInSSNMonth = daysInMonth(year, month - 1);

  if (day > daysInSSNMonth) {
    return result;
  }

  result.valid = luhn(value.replace(/\+|-/g, ""));
  result.age = result.valid ? ageInYears(new Date(year, month - 1, day, 0, 0, 0, 0), today) : -1;
  result.standardizedSSN = result.valid ? value : null;

  return result;
};
