import type { FC } from "react";

import { Typography } from "@mui/material";

import type { PaymentTermsProps } from "../../../PaymentTerms";
import type { InfoBoxProps } from "../../Info";
import type { SummaryProps } from "../../Summary";
import type { StepProps } from "../Step";

import { PaymentTerms } from "../../../PaymentTerms";
import { Summary } from "../../Summary";
import { SummaryRow } from "../../Summary/SummaryRow";
import { Step } from "../Step";

export type StepPaymentTermsProps = {
  summaryItems: Pick<SummaryProps["items"][number], "title" | "value" | "addedItems">[];
  summaryTotal: NonNullable<SummaryProps["summaryRow"]>;
  /** Render a informational box on a side with optional modal opened by icon  */
  infoBox1: InfoBoxProps;
  /** Render a informational box on a side with optional modal opened by icon  */
  infoBox2?: InfoBoxProps;
  /**  Loading status of the component passed on for the summary, payment terms and contract terms */
  loading?: boolean;
  translations: {
    /** E.g. "Choose payment term" */
    title: StepProps["title"];
    /** E.g. "Summary" */
    summaryTitle: string;
    /** E.g. "Continue" */
    forwardButtonLabel: NonNullable<StepProps["bottomNavigation"]["forwardButton"]>;
    stepTranslations: StepProps["translations"];
  };
} & PaymentTermsProps<string> &
  Pick<
    StepProps["bottomNavigation"],
    "onBackward" | "onForward" | "disableForward" | "loadingForward" | "width"
  >;

/**
 * Renders a summarized payment terms step for plan selection.
 */
export const StepPaymentTerms: FC<StepPaymentTermsProps> = (props) => {
  const summaryProps = {
    title: props.translations.summaryTitle,
    items: props.summaryItems,
    loading: props.loading,
  };

  return (
    <Step
      title={props.translations.title}
      stepComponent={{
        component: (
          <>
            <Typography
              variant="subheadingBold"
              display={{ mobile: "none", tablet: "block" }}
              marginBottom={2}
            >
              {props.translations.title}
            </Typography>
            <PaymentTerms {...props} />
          </>
        ),
      }}
      leftPanel={{
        summary: <Summary {...summaryProps} summaryRow={props.summaryTotal} />,
      }}
      rightPanel={{
        infoBox1: {
          ...props.infoBox1,
        },
        infoBox2: props.infoBox2
          ? {
              ...props.infoBox2,
            }
          : undefined,
      }}
      bottomNavigation={{
        mobileAlwaysVisibleContent: (
          <SummaryRow dividerPosition="hidden" {...props.summaryTotal} loading={props.loading} />
        ),
        mobileAdditionalContent: <Summary {...summaryProps} />,
        mobileBackward: true,
        onBackward: props.onBackward,
        onForward: props.onForward,
        forwardButton: props.translations.forwardButtonLabel,
        loadingForward: props.loadingForward,
        disableForward: props.disableForward,
        width: props.width,
      }}
      translations={props.translations.stepTranslations}
    />
  );
};
