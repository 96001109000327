import type { FC } from "react";

import { Button, DialogActions } from "@mui/material";
import { type PickersActionBarProps, usePickersTranslations } from "@mui/x-date-pickers";

export const ActionBar: FC<PickersActionBarProps> = (props) => {
  const { onAccept, onClear, onCancel, onSetToday, actions, ...other } = props;
  const translations = usePickersTranslations();

  if (actions == null || actions.length === 0) {
    return null;
  }

  const buttons = actions.map((actionType) => {
    switch (actionType) {
      case "clear":
        return (
          <Button
            variant="text"
            onClick={onClear}
            key={actionType}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {translations.clearButtonLabel}
          </Button>
        );
      case "cancel":
        return (
          <Button
            variant="text"
            onClick={onCancel}
            key={actionType}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {translations.cancelButtonLabel}
          </Button>
        );
      case "accept":
        return (
          <Button
            variant="text"
            onClick={onAccept}
            key={actionType}
            sx={{ color: (theme) => theme.palette.text.primary }}
            data-testid="confirmDateButton"
          >
            {translations.okButtonLabel}
          </Button>
        );
      case "today":
        return (
          <Button
            variant="text"
            onClick={onSetToday}
            key={actionType}
            sx={{ color: (theme) => theme.palette.text.primary }}
          >
            {translations.todayButtonLabel}
          </Button>
        );
      default:
        return null;
    }
  });

  return <DialogActions {...other}>{buttons}</DialogActions>;
};
