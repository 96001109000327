import { createSvgIcon } from "@mui/material/utils";

export const Info = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM12 11C12.5523 11 13 11.4477 13 12V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V12C11 11.4477 11.4477 11 12 11ZM12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
  />,
  "Info"
);
