import type { FC, ReactNode } from "react";

import { CheckRound, CloseRound } from "@augment-frontend/mui-icons";
import {
  IconButton,
  InputAdornment,
  TextField as MaterialTextField,
  outlinedInputClasses,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import type { TextFieldProps as MaterialTextFieldProps, OutlinedInputProps } from "@mui/material";

export type TextFieldProps = MaterialTextFieldProps & {
  /** Indicates if the TextField input is in success state */
  success?: boolean;
  /**
   * The custom node to be used as the end adornment instead of success or error state, e.g. Info icon.
   * Will be displayed if the field isn't fully filled, if the field is fully filled, the end adornment
   * will be indicating success (CheckRound) or error (CloseRound)
   */
  customEndAdornment?: React.ReactNode;
  /**
   * The title text for the end adornment tooltip, e.g. "CVC can be found from the back of the card".
   * Will be displayed on end adornment hover.
   */
  tooltipTitle?: string;
  /**
   * The content for the end adornment tooltip, e.g. image of the payment card.
   * Will be displayed on end adornment hover.
   */
  tooltipContent?: ReactNode;
};

const TextFieldUnstyled: FC<TextFieldProps> = (props) => {
  const { success, InputProps, customEndAdornment, tooltipTitle, tooltipContent, ...rest } = props;

  const endAdornment = (
    <InputAdornment position="end">
      {props.error ? <CloseRound sx={(theme) => ({ color: theme.palette.error.main })} /> : null}
      {success ? <CheckRound sx={(theme) => ({ color: theme.palette.success.main })} /> : null}
      {!props.error && !success && customEndAdornment ? customEndAdornment : null}
    </InputAdornment>
  );

  const endAdornmentWithTooltip = (
    <Tooltip
      title={
        <>
          <Typography variant="bodyTextSemibold" marginBottom={tooltipContent ? 1 : 0}>
            {tooltipTitle}
          </Typography>
          {tooltipContent}
        </>
      }
      placement="top-end"
      enterTouchDelay={0}
      leaveTouchDelay={4000}
      arrow
    >
      <IconButton sx={(theme) => ({ cursor: "default", paddingY: theme.spacing(2) })}>
        {endAdornment}
      </IconButton>
    </Tooltip>
  );

  return (
    <MaterialTextField
      InputProps={
        {
          endAdornment: tooltipTitle || tooltipContent ? endAdornmentWithTooltip : endAdornment,
          notched: props.variant === "outlined" ? true : undefined,
          ...InputProps,
        } as Partial<OutlinedInputProps>
      }
      inputProps={{
        "data-testid": "text-field",
      }}
      {...rest}
    />
  );
};

export const TextField = styled(TextFieldUnstyled)((props) => ({
  [`.${outlinedInputClasses.root}`]: {
    ...(props.success === true && {
      boxShadow: `inset 0px 0px 0px 2px ${props.theme.palette.success.main}`,
    }),
  },
}));
