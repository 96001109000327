import type { FC } from "react";

import { Box, Typography } from "@mui/material";

import type { InvoiceProps } from "./Invoice";

import { Invoice } from "./Invoice";

export interface InvoicesProps {
  invoices: Omit<InvoiceProps, "translations">[];
  translations: InvoiceProps["translations"] & { invoicesTitle: string; noInvoices: string };
}

export const Invoices: FC<InvoicesProps> = (props) => {
  if (props.invoices.length === 0) {
    return <Typography>{props.translations.noInvoices}</Typography>;
  }

  return (
    <>
      {props.invoices.map((invoice, index) => (
        <Box key={index} sx={{ mb: index === props.invoices.length - 1 ? 0 : 2 }}>
          <Invoice {...invoice} translations={props.translations} />
        </Box>
      ))}
    </>
  );
};
