import { Tooltip as MuiTooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { InfoContentProps } from "../InfoContent";
import type { TogglePointProps } from "../TogglePoint";

import { InfoContent } from "../InfoContent";
import { TogglePoint } from "../TogglePoint";

export interface TooltipProps {
  hotspot: TogglePointProps["hotspot"];
  infoContent: Omit<InfoContentProps, "onClose">;
  /** Tooltip's index in the original array of tooltips */
  index: TogglePointProps["index"];
  /** Currently active tooltip's index */
  activeIndex: number | undefined;
  /** On click handler */
  onClick: TogglePointProps["onClick"];
}

export const Tooltip = (props: TooltipProps) => {
  const theme = useTheme();

  return (
    <MuiTooltip
      title={<InfoContent {...props.infoContent} />}
      enterTouchDelay={0}
      leaveTouchDelay={6000}
      componentsProps={{
        tooltip: {
          sx: {
            ...theme.typography.bodyText,
            // @ts-expect-error TODO TS does not see allVariants on typography
            fontFamily: theme.typography.allVariants.fontFamily,
            m: 0,
            p: 0,
            display: { mobile: "none", tablet: "block" },
          },
        },
      }}
    >
      <TogglePoint hotspot={props.hotspot} index={props.index} onClick={props.onClick} />
    </MuiTooltip>
  );
};
