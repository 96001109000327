import type { CartProduct, LocaleInformation } from "../../types";
import type { CategoryHistoryItem } from "./Configuration";
import type {
  ConfigurationItem,
  ConfigurationItemOption,
  ConfigurationItems,
  Model,
} from "./types";

import { currencyFormat } from "../../utils";

/**
 * Generates a list of products for the cart based on the selected/active configuration options.
 * The cart will include model and 'addon' type options with a price, each represented as a individual product.
 *
 * @param selectedModel - The selected/active model
 * @param selectedConfigurationItemOptions - Selected/active options, including both types "config" and "addon"
 * @returns An array of cart products, including the selected/active model and any selected, priced addons as individual cart items
 */
export const generateCartProductsFromSelection = (
  selectedModel: Model,
  selectedConfigurationItemOptions: ConfigurationItemOption[]
): CartProduct[] => {
  // selected model's supported options which will be displayed on Details, type of "config"
  const modelDetails: Model["supportedConfigurationItemOptions"] = selectedConfigurationItemOptions
    .filter((option) => option.type === "config")
    .map((selectedOption) => ({
      key: selectedOption.key,
      value: selectedOption.value,
      type: selectedOption.type,
    }));

  const productsInCart: CartProduct[] = [
    {
      id: selectedModel.id,
      value: "model",
      type: "PLAN",
      name: selectedModel.name,
      quantity: 1,
      price: selectedModel.price,
      details: modelDetails,
    },
  ];

  // filter to include only the addons, which are selected and have a price, because
  // we want to add only those addons to the cart which have a price (yes/no choices)
  const selectedAddons = selectedConfigurationItemOptions.filter(
    (selectedOption) => selectedOption.type === "addon" && selectedOption.price > 0
  );

  selectedAddons.forEach((selectedAddon) => {
    productsInCart.push({
      id: selectedAddon.key,
      value: selectedAddon.value,
      type: selectedAddon.isSinglePayment ? "CHARGE" : "ADDON",
      name: selectedAddon.optionTitle,
      quantity: 1,
      price: selectedAddon.price,
      isSinglePayment: selectedAddon.isSinglePayment,
    });
  });

  return productsInCart;
};

/**
 * Get initial addons from cart
 * @param cartItems The cart items
 * It adds cart missing addons as free to recover user selection.
 */
export const getInitialAddons = (
  cartItems: CartProduct[],
  allAddons: ConfigurationItem[]
): Model["supportedConfigurationItemOptions"] => {
  const cartAddons = cartItems.filter((item) => item.value !== "model");

  return allAddons.map((addon) => {
    const cartAddonValue = cartAddons.find((cartAddon) => cartAddon.id === addon.id)?.value;

    // Add cart addon
    if (cartAddonValue) {
      return { key: addon.id, value: cartAddonValue, type: "addon" };
    }
    // Add missing cart addon as free (because it wasn't in the cart)
    const freeOption = addon.itemOptions.find((itemOption) => !itemOption.price);
    return { key: addon.id, value: freeOption?.value || "", type: "addon" };
  });
};

/**
 * Get last model stored on cart
 * @param cartItems The cart items
 * @param allModels The full list of all models te be used to get the model details
 */
export const getLastCartModel = (
  cartItems: CartProduct[],
  allModels: Model[]
): Model | undefined => {
  const cartModels = cartItems.filter((item) => item.value === "model");
  const lastModel = cartModels[cartModels.length - 1];
  return allModels.find((model) => model.id === lastModel?.id);
};

/** Update the config options with changed option */
export const updateOptions = (
  optionNew: ConfigurationItemOption,
  options: ConfigurationItemOption[]
): ConfigurationItemOption[] => [
  ...options.filter((option) => option.key !== optionNew.key),
  optionNew,
];

/**
 * Get the total price of the model and its selected options, formatted as currency
 */
export const getTotalPrice = (
  options: ConfigurationItemOption[],
  model: Model,
  localeInformation: LocaleInformation
): string => {
  const totalPrice = options.reduce((total, config) => {
    return total + config.price;
  }, model.price);

  return currencyFormat(totalPrice, localeInformation);
};

/**
 * Extracts and returns active configuration options for a given model
 *
 * It prioritizes the previous active options if available, otherwise defaults to the supported configuration.
 * If neither is available, it falls back to the first available option (which shouldn't happen, only for type safety).
 *
 * @param allConfigurationItems - All configuration items available
 * @param modelSupportedConfigurations - Configuration items supported by the model
 * @param previousSelectedConfigurations - The previously selected configuration items for the model, if any
 * @returns An array of the options for the model configuration, which should be active
 */
export const getActiveOptions = (
  allConfigurationItems: ConfigurationItems,
  modelSupportedConfigurations: Model["supportedConfigurationItemOptions"],
  activeCategory: string,
  previousSelectedConfigurations?: Model["supportedConfigurationItemOptions"]
): ConfigurationItemOption[] => {
  // filter configuration items to include only those supported by the model
  const filteredConfigurationItems = allConfigurationItems[activeCategory]?.filter(
    (configurationItem) =>
      modelSupportedConfigurations.find(
        (modelConfiguration) => configurationItem.id === modelConfiguration.key
      )
  );

  return (
    filteredConfigurationItems?.map((configurationItem) => {
      // try to find a previously selected option
      const maybePreviousSelection = configurationItem.itemOptions.find((itemOption) =>
        previousSelectedConfigurations?.some(
          (previousSelection) =>
            itemOption.key === previousSelection.key && itemOption.value === previousSelection.value
        )
      );
      // default to the supported option if no previous selection is found
      const defaultSelection = maybePreviousSelection
        ? undefined
        : configurationItem.itemOptions.find((itemOption) =>
            modelSupportedConfigurations.some(
              (supportedConfiguration) =>
                supportedConfiguration.key === itemOption.key &&
                supportedConfiguration.value === itemOption.value
            )
          );

      // return the selected option or the default selection (first option as a fallback/type safety)
      return maybePreviousSelection || defaultSelection || configurationItem.itemOptions[0];
    }) || []
  );
};

/**
 * Get the model matching the passed configs based on its supportedConfigs.
 * If full match can't be returned, then return the model matching the most options including the last changed option.
 * If not even partially matched model is found, return undefined for the model.
 *
 * @param configToMatch - The full configuration that, ideally should match the model.
 * @param lastChangedOption - The last option changed by the user. The picked model will match, at least, this option.
 * @param allModels - All the models from which the selection will be made.
 *
 * */
export const getMatchingModel = (
  configToMatch: ConfigurationItemOption[],
  lastChangedOption: ConfigurationItemOption,
  allModels: Model[]
): { model?: Model; fullMatch: boolean } => {
  const modelsWithSupportedConfigs = allModels.filter(
    (model) => model.supportedConfigurationItemOptions.length > 0
  );

  // [Full match] Look for a model with ALL the configs matching (configToMatch)
  const matchingModel = modelsWithSupportedConfigs.find((model) => {
    return model.supportedConfigurationItemOptions
      .filter((modelConfig) => modelConfig.type === "config")
      .every((config) => {
        return configToMatch.find((conf) => config.key === conf.key && config.value === conf.value);
      });
  });
  if (matchingModel) {
    return { model: matchingModel, fullMatch: true };
  }

  // [Partial match] No model found => Look for the model that matches the most configs (including the changed option)
  // 1. Get the models that match the changed option
  const modelsMatchingChangedOption = modelsWithSupportedConfigs.filter((model) =>
    Boolean(
      model.supportedConfigurationItemOptions.filter(
        (c) => c.key === lastChangedOption.key && c.value === lastChangedOption.value
      ).length
    )
  );

  if (modelsMatchingChangedOption.length === 0) {
    // no model found that matches the changed option
    return { model: undefined, fullMatch: false };
  }

  // 2. Find the model that matches the most options from the current Form selection
  let partiallyMatchingModel = modelsMatchingChangedOption[0];
  let highestMatchCount = 0;
  modelsMatchingChangedOption.forEach((model) => {
    const matchCount = model.supportedConfigurationItemOptions
      .filter((modelConfig) => modelConfig.type === "config")
      .reduce((count, config) => {
        return (
          count +
          (configToMatch.some((conf) => config.key === conf.key && config.value === conf.value)
            ? 1
            : 0)
        );
      }, 0);

    if (matchCount > highestMatchCount) {
      partiallyMatchingModel = model;
      highestMatchCount = matchCount;
    }
  });
  return { model: partiallyMatchingModel, fullMatch: false };
};

/**
 * Create a new category history based on the arguments provided. Either the existing entry is updated,
 * or a new one is added if that category doesn't exist. The (provided) active category is marked as active.
 *
 * @param activeCategoryValue Value of currently active product category
 * @param activeModel Currently selected model within the active category
 * @param activeOptions Currently selected configuration options for the model
 * @param categoryHistory Current state of the category history
 */
export const createNewCategoryHistory = (
  activeCategoryValue: string,
  activeModel: CategoryHistoryItem["model"],
  activeOptions: CategoryHistoryItem["options"],
  categoryHistory: CategoryHistoryItem[]
): CategoryHistoryItem[] => {
  const updatedCategoryHistory = categoryHistory.map((item) => ({
    ...item,
    isActiveCategory: false,
  }));

  // create a new history item to store
  const newHistoryItem: CategoryHistoryItem = {
    categoryValue: activeCategoryValue,
    model: activeModel,
    options: activeOptions,
    isActiveCategory: true,
  };

  // find index of the current category from the history
  const currentCategoryIndex = categoryHistory.findIndex(
    (history) => history.categoryValue === activeCategoryValue
  );

  if (currentCategoryIndex !== -1) {
    // previous history item for this category is found, replace it with the new one
    updatedCategoryHistory[currentCategoryIndex] = newHistoryItem;
  } else {
    // no previous history item found for this category, add a new one
    updatedCategoryHistory.push(newHistoryItem);
  }
  return updatedCategoryHistory;
};
