import type { FC } from "react";

import { AppStoreBadge, GooglePlayBadge } from "@augment-frontend/mui-icons";
import { IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export interface AppBadgesProps {
  /** E.g. "Download our application" */
  title: string;
  /** Override title color */
  titleColor?: string;
  /** Display compact view of component */
  compact?: boolean;
}

export const AppBadges: FC<AppBadgesProps> = (props) => {
  const theme = useTheme();

  return (
    <div>
      <Typography
        variant={props.compact ? "captionBold" : "largeTextBold"}
        sx={{
          color: props.titleColor ?? theme.palette.text.secondary,
          textTransform: props.compact ? "uppercase" : "none",
          marginBottom: props.compact ? 0 : 1,
        }}
      >
        {props.title}
      </Typography>
      <Stack direction="row">
        <IconButton
          sx={{ marginLeft: theme.spacing(-1) }}
          rel="noopener noreferrer"
          target="_blank"
          href="https://apps.apple.com/app/augment-scooters/id1558978120"
        >
          <AppStoreBadge sx={{ color: "#000000" }} />
        </IconButton>
        <IconButton
          rel="noopener noreferrer"
          target="_blank"
          href="https://play.google.com/store/apps/details?id=eco.augment.production"
        >
          <GooglePlayBadge />
        </IconButton>
      </Stack>
    </div>
  );
};
