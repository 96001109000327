import type { FC } from "react";

import { Divider, Stack, Typography } from "@mui/material";

type SubHeaderProps = {
  /** Title of the SubHeader */
  title: string;
  /** For e2e testing purposes, used to set data-testId attribute to allow targeting dynamic testData */
  dataTestId?: string;
  /** For e2e testing purposes, used to set dynamic data to data-testId attribute */
  testData?: string;
};

/** Renders a centered title with faint dividers on each side of it */
export const SubHeader: FC<SubHeaderProps> = (props) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      data-testid={props.dataTestId}
    >
      <Divider sx={{ borderColor: "#0000001F", flex: 1 }} />
      <Typography variant="largeTextSemibold" textAlign="center" data-testid={props.testData}>
        {props.title}
      </Typography>
      <Divider sx={{ borderColor: "#0000001F", flex: 1 }} />
    </Stack>
  );
};
