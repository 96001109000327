/** The environment, e.g. differing URL endpoints, being used. */
export const env = process.env.GATSBY_ENVIRONMENT === "production" ? "production" : "development";

const getHostingEnvironment = (): "staging" | "production" | undefined => {
  if (
    process.env.GATSBY_HOSTING_ENVIRONMENT === "staging" ||
    process.env.GATSBY_HOSTING_ENVIRONMENT === "production"
  ) {
    return process.env.GATSBY_HOSTING_ENVIRONMENT;
  }
  return undefined;
};
/** The hosting environment in which Gatsby is running currently. Will be undefined, while developing locally. */
export const hostingEnv = getHostingEnvironment();

// Cognito
const UserPoolId = { production: "eu-west-1_Nxh7pHw6Y", development: "eu-west-1_gaPWWOczr" };
export const userPoolId = UserPoolId[env];

const UserPoolWebClientId = {
  production: "65k9n5crr56kb76jffnu571qdk",
  development: "3j7g3j5g73gqbeifejcichq17e",
};
export const userPoolWebClientId = UserPoolWebClientId[env];

// Graphql
const GraphqlEndpoint = {
  production: "https://frbc72oc4h.execute-api.eu-west-1.amazonaws.com/prod/graphql",
  development: "https://hbgg35nwqf.execute-api.eu-west-1.amazonaws.com/dev/graphql",
};
export const graphqlEndpoint = process.env.GATSBY_GRAPHQL_API_PATH || GraphqlEndpoint[env];

const GraphqlPublicEndpoint = {
  production: "https://frbc72oc4h.execute-api.eu-west-1.amazonaws.com/prod/graphql-public",
  development: "https://hbgg35nwqf.execute-api.eu-west-1.amazonaws.com/dev/graphql-public",
};
export const graphqlPublicEndpoint =
  process.env.GATSBY_GRAPHQL_PUBLIC_API_PATH || GraphqlPublicEndpoint[env];

// Sanity
const SanityConfig = {
  production: {
    projectId: "yecdxfqq",
    dataset: "production-v2",
  },

  development: {
    projectId: "yecdxfqq",
    dataset: "production-v2",
  },
};
export const sanityConfig = SanityConfig[env];

const IntercomAppId = {
  production: "btfyqyk5",
  development: "iibx76o1",
};
export const intercomAppId = IntercomAppId[env];

const GoogleApiKey = {
  production: "AIzaSyCbHXzCDw5HxmrDgmtrn2iipnrw9TbZII0",
  development: "AIzaSyCbHXzCDw5HxmrDgmtrn2iipnrw9TbZII0",
};
export const googleApiKey = GoogleApiKey[env];

const ChargebeeConfig = {
  production: {
    site: "augment-eco",
    publishableKey: "live_Lcu3d9229RacdcdCPg4lmBhtRzacuJr7FFfB",
  },
  development: {
    site: "augment-eco-test",
    publishableKey: "test_OhlTgR7teFS5yLsqTD0c6k7rOH6xwVgU",
  },
};
export const chargebeeConfig = ChargebeeConfig[env];
