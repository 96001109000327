import type { Theme } from "@mui/material";

export enum StatusType {
  Positive,
  Negative,
  Neutral,
}

export const getStatusColor = (status: StatusType, theme: Theme) => {
  if (status === StatusType.Positive) {
    return theme.palette.success.main;
  }
  if (status === StatusType.Negative) {
    return theme.palette.error.main;
  }
  return theme.palette.text.primary;
};
