import { createSvgIcon } from "@mui/material/utils";

export const CarePlan = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0001 10.0584C22.0001 10.2667 21.9668 10.6917 22.0001 10.8917C22.0001 13.3916 21.5168 15.6165 19.3085 17.1082C18.4919 17.6665 18.6542 18.0971 18.6669 19.2248C18.6795 20.3524 18.6669 20.333 17.8669 20.333C16.167 20.3331 14.5003 20.333 13.1337 20.3247C12.8337 20.333 12.8296 20.0965 12.8337 19.9164C12.867 18.4414 12.8587 16.8081 12.8337 15.3332C12.8337 14.9165 12.8337 15.3332 12.8337 14.9165C12.8338 14.1593 15.0087 12.2166 16.042 11.1667C16.6419 10.5667 17.3086 10.3917 17.9252 11.025C18.5502 11.675 18.3169 12.3333 17.7002 12.9083C17.2752 13.3083 16.8753 13.7249 16.4586 14.1416C16.1586 14.4416 16.0086 14.7749 16.3586 15.1166C16.6836 15.4416 16.9753 15.2249 17.2586 14.9916C18.7169 13.7833 19.5002 12.975 19.5002 10.8917C19.5002 9.64175 19.5085 7.95847 19.5002 6.72517C19.4919 5.90853 19.9168 5.46688 20.7501 5.47521C21.5751 5.48355 22.0084 5.92519 22.0001 6.72516C22.0001 7.97512 22.0001 8.82509 22.0001 10.0584Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00038 10.0584C2.00038 10.2667 2.03371 10.6917 2.00038 10.8917C2.00038 13.3916 2.48372 15.6165 4.69198 17.1082C5.50862 17.6665 5.34628 18.0971 5.33361 19.2248C5.32094 20.3524 5.33362 20.333 6.13359 20.333C7.83353 20.3331 9.50015 20.333 10.8668 20.3247C11.1668 20.333 11.1709 20.0965 11.1668 19.9164C11.1335 18.4414 11.1418 16.8081 11.1668 15.3332C11.1668 14.9165 11.1668 15.3332 11.1668 14.9165C11.1667 14.1593 8.99183 12.2166 7.95853 11.1667C7.35855 10.5667 6.69192 10.3917 6.07528 11.025C5.45029 11.675 5.68361 12.3333 6.30026 12.9083C6.72525 13.3083 7.12523 13.7249 7.54188 14.1416C7.84187 14.4416 7.99186 14.7749 7.64187 15.1166C7.31688 15.4416 7.02524 15.2249 6.74191 14.9916C5.28362 13.7833 4.5003 12.975 4.5003 10.8917C4.5003 9.64175 4.49197 7.95847 4.5003 6.72517C4.50864 5.90853 4.08365 5.46688 3.25034 5.47521C2.42537 5.48355 1.99205 5.92519 2.00038 6.72516C2.00038 7.97512 2.00038 8.82509 2.00038 10.0584Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6669 4.5003C13.6669 4.04008 13.2939 3.66699 12.8336 3.66699L11.167 3.66699C10.7068 3.66699 10.3337 4.04008 10.3337 4.5003V5.75026H9.0838C8.62357 5.75026 8.25049 6.12335 8.25049 6.58357V8.25018C8.25049 8.71041 8.62357 9.08349 9.0838 9.08349H10.3337V10.3335C10.3337 10.7937 10.7068 11.1668 11.167 11.1668H12.8336C13.2939 11.1668 13.6669 10.7937 13.6669 10.3335V9.08349H14.9169C15.3772 9.08349 15.7503 8.71041 15.7503 8.25018V6.58357C15.7503 6.12335 15.3772 5.75026 14.9169 5.75026H13.6669V4.5003Z"
    />
  </>,
  "CarePlan"
);
