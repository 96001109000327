import { createSvgIcon } from "@mui/material/utils";

export const Calendar = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2C7.55228 2 8 2.44772 8 3L8 5H16L16 3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V5C19.8856 5 20.8284 5 21.4142 5.58579C22 6.17157 22 7.11438 22 9C22 9.4714 22 9.70711 21.8536 9.85355C21.7071 10 21.4714 10 21 10H3C2.5286 10 2.29289 10 2.14645 9.85355C2 9.70711 2 9.4714 2 9C2 7.11438 2 6.17157 2.58579 5.58579C3.17157 5 4.11438 5 6 5L6 3C6 2.44772 6.44772 2 7 2ZM2.58579 21.4142C2 20.8284 2 19.8856 2 18V13C2 12.5286 2 12.2929 2.14645 12.1464C2.29289 12 2.5286 12 3 12H21C21.4714 12 21.7071 12 21.8536 12.1464C22 12.2929 22 12.5286 22 13V18C22 19.8856 22 20.8284 21.4142 21.4142C20.8284 22 19.8856 22 18 22H6C4.11438 22 3.17157 22 2.58579 21.4142ZM7.14645 14.1464C7 14.2929 7 14.5286 7 15C7 15.4714 7 15.7071 7.14645 15.8536C7.29289 16 7.5286 16 8 16H10C10.4714 16 10.7071 16 10.8536 15.8536C11 15.7071 11 15.4714 11 15C11 14.5286 11 14.2929 10.8536 14.1464C10.7071 14 10.4714 14 10 14H8C7.5286 14 7.29289 14 7.14645 14.1464ZM7 19C7 18.5286 7 18.2929 7.14645 18.1464C7.29289 18 7.5286 18 8 18H10C10.4714 18 10.7071 18 10.8536 18.1464C11 18.2929 11 18.5286 11 19C11 19.4714 11 19.7071 10.8536 19.8536C10.7071 20 10.4714 20 10 20H8C7.5286 20 7.29289 20 7.14645 19.8536C7 19.7071 7 19.4714 7 19ZM13.1464 14.1464C13 14.2929 13 14.5286 13 15C13 15.4714 13 15.7071 13.1464 15.8536C13.2929 16 13.5286 16 14 16H16C16.4714 16 16.7071 16 16.8536 15.8536C17 15.7071 17 15.4714 17 15C17 14.5286 17 14.2929 16.8536 14.1464C16.7071 14 16.4714 14 16 14H14C13.5286 14 13.2929 14 13.1464 14.1464ZM13 19C13 18.5286 13 18.2929 13.1464 18.1464C13.2929 18 13.5286 18 14 18H16C16.4714 18 16.7071 18 16.8536 18.1464C17 18.2929 17 18.5286 17 19C17 19.4714 17 19.7071 16.8536 19.8536C16.7071 20 16.4714 20 16 20H14C13.5286 20 13.2929 20 13.1464 19.8536C13 19.7071 13 19.4714 13 19Z"
    />
  </>,
  "Calendar"
);
