import type { FC } from "react";
import { Fragment, useState } from "react";

import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { AddressProps } from "../Address";
import type { StatusType } from "../util";
import type { BillingDateModalProps } from "./BillingDateModal";

import { Surface } from "../../Surface";
import { Address } from "../Address";
import { getStatusColor } from "../util";
import { BillingDateModal } from "./BillingDateModal";

export interface SubscriptionProps {
  id: string;
  status: string;
  statusType: StatusType;
  startedOn: string;
  contractEndDate?: Date;
  billingInterval?: string;
  endedOn?: string;
  nextBillingOn?: Date;
  nextBillingCanChange?: boolean;
  subscriptionItems: {
    product: string;
    quantity: number;
    price: string;
  }[];
  renewalEstimate?: {
    discounts?: {
      amount?: string;
      entityId?: string;
    }[];
    total?: string;
    subTotal?: string;
  };
  onEstimateBillingDate: ({
    subscriptionId,
    nextBillingDay,
  }: {
    subscriptionId: string;
    nextBillingDay: number;
  }) => Promise<{
    estimatedContractEndDate: string;
    estimatedNextBillingDate: string;
  }>;
  onUpdateBillingDate: ({
    subscriptionId,
    nextBillingDay,
  }: {
    subscriptionId: string;
    nextBillingDay: number;
  }) => Promise<void>;
  primaryPaymentMethod?: string;
  shippingAddress?: AddressProps;
  translations: BillingDateModalProps["translations"] & {
    typeTitle: string;
    typeContent: string;
    startedOnTitle: string;
    endedOnTitle: string;
    nextBillingOnTitle: string;
    primaryPaymentMethodTitle: string;
    billingIntervalTitle: string;
    productTitle: string;
    quantityTitle: string;
    priceTitle: string;
    changeTitle: string;
    subtotalTitle: string;
  };
}

export const Subscription: FC<SubscriptionProps> = (props) => {
  const theme = useTheme();
  const [billingModalOpen, setBillingModalOpen] = useState(false);
  const typographyColor = theme.palette.text.primary;
  const showTotal = props.renewalEstimate?.total || props.renewalEstimate?.discounts;
  return (
    <>
      <Surface
        sx={{
          bgcolor: theme.palette.secondary[200],
          border: `1px solid ${theme.palette.text.primary}`,
          padding: {
            tablet: theme.spacing(3),
          },
        }}
      >
        <Surface
          sx={{
            bgcolor: {
              mobile: theme.palette.secondary.main,
              tablet: theme.palette.secondary.light,
            },
            padding: 0,
            mb: 2,
          }}
        >
          {/* mobile */}
          <Box
            sx={{
              width: "100%",
              display: { mobile: "block", tablet: "none" },
              bgcolor: theme.palette.secondary[200],
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="bodyTextBold">ID:</Typography>
              <Typography
                variant="bodyTextBold"
                color={getStatusColor(props.statusType, theme)}
                sx={{
                  "&:before": {
                    content: '"\\2022"',
                  },
                }}
              >
                &nbsp;
                {props.status}
              </Typography>
            </Box>
            <Typography variant="bodyTextBold">{props.id}</Typography>
          </Box>
          {/* tablet & laptop */}
          <Box
            sx={{
              width: "100%",
              display: { mobile: "none", tablet: "flex" },
              justifyContent: "space-between",
            }}
          >
            <Typography variant="bodyTextBold">ID: {props.id}</Typography>
            <Typography
              variant="bodyTextBold"
              color={getStatusColor(props.statusType, theme)}
              sx={{
                "&:before": {
                  content: '"\\2022"',
                },
              }}
            >
              &nbsp;
              {props.status}
            </Typography>
          </Box>
        </Surface>
        <Grid container spacing={{ mobile: 1.5, tablet: 2 }}>
          <Grid
            item
            mobile={12}
            tablet={4}
            display="flex"
            component="div"
            sx={{ justifyContent: "space-between", flexDirection: { tablet: "column" } }}
          >
            <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
              {props.translations.typeTitle}
            </Typography>
            <Typography variant="bodyTextBold">{props.translations.typeContent}</Typography>
          </Grid>
          <Grid
            item
            mobile={12}
            tablet={4}
            display="flex"
            component="div"
            sx={{ justifyContent: "space-between", flexDirection: { tablet: "column" } }}
          >
            <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
              {props.translations.startedOnTitle}
            </Typography>
            <Typography variant="bodyTextBold">{props.startedOn}</Typography>
          </Grid>
          <Grid
            item
            mobile={12}
            tablet={4}
            display="flex"
            component="div"
            sx={{ justifyContent: "space-between", flexDirection: { tablet: "column" } }}
          >
            <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
              {props.translations.endedOnTitle}
            </Typography>
            <Typography variant="bodyTextBold">{props.endedOn ? props.endedOn : "-"}</Typography>
          </Grid>
          <Grid
            item
            mobile={12}
            tablet={2}
            display="flex"
            component="div"
            sx={{ justifyContent: "space-between", flexDirection: { tablet: "column" } }}
          >
            <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
              {props.translations.nextBillingOnTitle}
            </Typography>
            <Typography variant="bodyTextBold">
              {props.nextBillingOn ? new Date(props.nextBillingOn).toLocaleDateString() : "-"}
            </Typography>
          </Grid>
          <Grid
            item
            mobile={12}
            tablet={2}
            display="flex"
            component="div"
            sx={{ justifyContent: "end", flexDirection: { tablet: "column" }, m: 0, p: 0 }}
          >
            <Button
              data-testid="change-billing-date"
              onClick={() => setBillingModalOpen(true)}
              size="small"
              variant="outlined"
              disabled={!props.nextBillingCanChange}
              sx={{ display: "flex", justifyContent: "space-evenly" }}
            >
              {props.translations.changeTitle}
            </Button>
          </Grid>
          {/* TODO payment method */}
          {props.primaryPaymentMethod && (
            <Grid
              item
              mobile={12}
              tablet={4}
              display="flex"
              component="div"
              sx={{
                justifyContent: "space-between",
                flexDirection: { tablet: "column" },
              }}
            >
              <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
                {props.translations.primaryPaymentMethodTitle}
              </Typography>
              <Typography
                variant="bodyTextBold"
                sx={{ textAlign: { mobile: "right", tablet: "left" } }}
              >
                {props.primaryPaymentMethod}
              </Typography>
            </Grid>
          )}
          {props.billingInterval && (
            <Grid
              item
              mobile={12}
              tablet={4}
              display="flex"
              component="div"
              sx={{ justifyContent: "space-between", flexDirection: { tablet: "column" } }}
            >
              <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
                {props.translations.billingIntervalTitle}
              </Typography>
              <Typography variant="bodyTextBold">{props.billingInterval}</Typography>
            </Grid>
          )}
        </Grid>
        <Divider color={typographyColor} sx={{ my: { mobile: 1.5, tablet: 2 } }} />
        <Grid container spacing={{ mobile: 1.5, tablet: 2 }}>
          <Grid
            item
            mobile={5}
            tablet={4}
            component="div"
            sx={{ justifyContent: "space-between", flexDirection: { tablet: "column" } }}
          >
            <Typography variant="bodyTextBold">{props.translations.productTitle}</Typography>
          </Grid>
          <Grid
            item
            mobile={3}
            tablet={2}
            component="div"
            sx={{
              justifyContent: "space-between",
              flexDirection: { tablet: "column" },
              textAlign: "right",
            }}
          >
            <Typography variant="bodyTextBold">{props.translations.quantityTitle}</Typography>
          </Grid>
          <Grid
            item
            mobile={4}
            tablet={3}
            component="div"
            sx={{
              justifyContent: "space-between",
              flexDirection: { tablet: "column" },
              textAlign: "right",
            }}
          >
            <Typography variant="bodyTextBold">{props.translations.priceTitle}</Typography>
          </Grid>
          <Grid item display={{ mobile: "none" }} tablet={3}></Grid>
          {props.subscriptionItems.map((item, index) => (
            <Fragment key={index}>
              <Grid
                item
                mobile={5}
                tablet={4}
                component="div"
                sx={{ justifyContent: "space-between", flexDirection: { tablet: "column" } }}
              >
                <Typography variant="caption" color={typographyColor}>
                  {item.product}
                </Typography>
              </Grid>
              <Grid
                item
                mobile={3}
                tablet={2}
                component="div"
                sx={{
                  justifyContent: "space-between",
                  flexDirection: { tablet: "column" },
                  textAlign: "right",
                }}
              >
                <Typography variant="caption" color={typographyColor}>
                  {item.quantity}
                </Typography>
              </Grid>
              <Grid
                item
                mobile={4}
                tablet={3}
                component="div"
                sx={{
                  justifyContent: "space-between",
                  flexDirection: { tablet: "column" },
                  textAlign: "right",
                }}
              >
                <Typography variant="caption" color={typographyColor}>
                  {item.price}
                </Typography>
              </Grid>
              <Grid item display={{ mobile: "none" }} tablet={3}></Grid>
            </Fragment>
          ))}
        </Grid>
        {showTotal && (
          <>
            <Divider color={typographyColor} sx={{ my: { mobile: 1.5, tablet: 2 } }} />
            <Grid container spacing={{ mobile: 1.5, tablet: 2 }}>
              {/* Subtotal */}
              {props.renewalEstimate?.subTotal && props.renewalEstimate.discounts && (
                <>
                  <Grid
                    item
                    mobile={8}
                    tablet={7}
                    component="div"
                    sx={{
                      justifyContent: "flex-end",
                      flexDirection: { tablet: "column" },
                      textAlign: "right",
                    }}
                  >
                    <Typography variant="caption" color={typographyColor}>
                      {props.translations.subtotalTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    mobile={4}
                    tablet={2}
                    component="div"
                    sx={{
                      justifyContent: "flex-end",
                      flexDirection: { tablet: "column" },
                      textAlign: "right",
                    }}
                  >
                    <Typography variant="caption" color={typographyColor}>
                      {props.renewalEstimate.subTotal}
                    </Typography>
                  </Grid>
                  <Grid item display={{ mobile: "none" }} tablet={3}></Grid>
                </>
              )}
              {/* Discounts */}
              {props.renewalEstimate?.discounts &&
                props.renewalEstimate.discounts.map(
                  (discount, index) =>
                    discount.entityId &&
                    discount.amount && (
                      <Fragment key={index}>
                        <Grid
                          item
                          mobile={8}
                          tablet={7}
                          component="div"
                          sx={{
                            justifyContent: "flex-end",
                            flexDirection: { tablet: "column" },
                            textAlign: "right",
                          }}
                        >
                          <Typography variant="caption" color={typographyColor}>
                            {discount.entityId}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          mobile={4}
                          tablet={2}
                          component="div"
                          sx={{
                            justifyContent: "flex-end",
                            flexDirection: { tablet: "column" },
                            textAlign: "right",
                          }}
                        >
                          <Typography variant="caption" color={typographyColor}>
                            - {discount.amount}
                          </Typography>
                        </Grid>
                        <Grid item display={{ mobile: "none" }} tablet={3}></Grid>
                      </Fragment>
                    )
                )}
              {/* Total */}
              {props.renewalEstimate?.total && (
                <>
                  <Grid
                    item
                    mobile={8}
                    tablet={7}
                    component="div"
                    sx={{
                      justifyContent: "flex-end",
                      flexDirection: { tablet: "column" },
                      textAlign: "right",
                    }}
                  >
                    <Typography variant="bodyTextBold" color={typographyColor}>
                      {props.translations.nextBillingOnTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    mobile={4}
                    tablet={2}
                    display="flex"
                    component="div"
                    sx={{
                      justifyContent: "flex-end",
                      flexDirection: { tablet: "column" },
                      textAlign: "right",
                    }}
                  >
                    <Typography variant="bodyTextBold" color={typographyColor}>
                      {props.renewalEstimate.total}
                    </Typography>
                  </Grid>
                  <Grid item display={{ mobile: "none" }} tablet={3}></Grid>
                </>
              )}
            </Grid>
          </>
        )}
        {props.shippingAddress && (
          <>
            <Divider color={typographyColor} sx={{ my: { mobile: 1.5, tablet: 2 } }} />
            <Box sx={{ my: { mobile: 1.5, tablet: 2 } }}>
              <Address {...props.shippingAddress} />
            </Box>
          </>
        )}
        {/* TODO implement button below */}
        {/* <Grid container spacing={1.5}>
          <Grid
            item
            mobile={12}
            tablet={6}
            display="flex"
            component="div"
            sx={{ justifyContent: "space-between", flexDirection: { tablet: "column" } }}
          >
            <Button
              variant="outlined"
              fullWidth
              sx={{ display: "flex", justifyContent: "space-between" }}
              endIcon={<PriceIcon />}
            >
              Open invoice
            </Button>
          </Grid>
        </Grid> */}
      </Surface>
      {props.nextBillingCanChange && (
        <BillingDateModal
          contractEndDate={props.contractEndDate}
          currentBillingDate={props.nextBillingOn}
          subscriptionId={props.id}
          open={billingModalOpen}
          onCancel={() => setBillingModalOpen(false)}
          onAccept={props.onUpdateBillingDate}
          onEstimateBilling={props.onEstimateBillingDate}
          translations={props.translations}
        />
      )}
    </>
  );
};
