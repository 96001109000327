import { useEffect, useState } from "react";

import { validateSSN } from "@augment-frontend/ssn";
import { Grid } from "@mui/material";

import type { SSNResult } from "@augment-frontend/ssn";

import { maskString } from "../../../utils/maskString";
import { TextField } from "../../TextField";

export interface SocialSecurityInputProps {
  /** SSN */
  ssn?: string;
  /** Country code */
  countryCode: string;
  /** Sets all inputs in immutable state, i.e. values cannot be changed if they pass validation */
  immutable?: boolean;
  /** Sets all inputs in disabled state, e.g. when parent is loading */
  disabled?: boolean;
  /** Callback for when a value of an input changes */
  onChange: ({ value, age, valid }: SSNDetail) => void;
  required?: boolean;
  translations: {
    /** First name input label */
    ssnLabel: string;
    /** Error message for invalid SSN */
    errorInvalidSSN: string;
    /** Error message for minimum age */
    errorUnderAge: string;
    /** Helper text describing why SSN is asked for */
    ssnHelperText: string;
  };
}

export type SSNDetail = SSNResult & {
  value: string;
  errorMsg?: string;
};

/**
 * Renders social security number field. Needs a parent <Grid container> component.
 * It will return valid if the SSN is correct and age is above 18.
 */
export const SocialSecurityInput = (props: SocialSecurityInputProps) => {
  const errorChecker = (ssnData?: SSNResult) => {
    let error = "";
    if (ssnData) {
      if (!ssnData.valid) {
        error = props.translations.errorInvalidSSN;
      } else if (ssnData.age > -1 && ssnData.age < 18) {
        error = props.translations.errorUnderAge;
      }
    }

    return error;
  };
  const [isPristine, setIsPristine] = useState(
    props.ssn !== undefined ? Boolean(!props.ssn) : true
  );
  const [ssnState, setSSNState] = useState((): SSNDetail => {
    const ssnData = props.ssn === undefined ? undefined : validateSSN(props.ssn, props.countryCode);
    const errorMsg = errorChecker(ssnData);
    return {
      value: props.ssn || "",
      age: ssnData?.age ?? -1,
      valid: !isPristine && Boolean(!errorMsg),
      standardizedSSN: ssnData?.standardizedSSN ?? null,
      errorMsg,
    };
  });

  // If initial value set immutable does not pass validation, allow editing it, and
  // set immutable state false on edit to control input's disabled state correctly
  const [immutable, setImmutable] = useState(props.immutable || false);
  const [disabled, setDisabled] = useState(props.disabled || false);

  useEffect(() => {
    props.onChange(ssnState);
  }, [ssnState]);

  useEffect(() => {
    setDisabled(props.disabled || false);
  }, [props.disabled]);

  const SSNValidation = (value: string) => {
    const valueFormatted = value.toUpperCase();
    const ssnInfo = validateSSN(valueFormatted, props.countryCode);
    const errorMsg = errorChecker(ssnInfo);

    setSSNState({
      ...ssnInfo,
      valid: Boolean(valueFormatted) && Boolean(!errorMsg),
      value: valueFormatted,
      errorMsg,
    });
  };

  useEffect(() => {
    SSNValidation(ssnState.value);
  }, [props.countryCode]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    SSNValidation(event.target.value);
    setImmutable(false);
    setIsPristine(false);
  };

  return (
    <>
      <Grid item mobile={12}>
        <TextField
          fullWidth
          id="ssn"
          inputProps={{ "data-testid": "ssn" }}
          label={props.translations.ssnLabel}
          value={immutable && !!ssnState.valid ? maskString(ssnState.value) : ssnState.value}
          error={!isPristine && !ssnState.valid}
          helperText={(!isPristine && ssnState.errorMsg) || props.translations.ssnHelperText}
          success={ssnState.valid}
          onChange={handleChange}
          disabled={disabled || (immutable && !!ssnState.valid)}
          required={props.required}
        />
      </Grid>
    </>
  );
};
