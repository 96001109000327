import type { FC } from "react";
import { forwardRef } from "react";

import { useTheme } from "@mui/material/styles";

import type { CardBaseProps } from "@chargebee/chargebee-js-react-wrapper";
import type { InputBaseComponentProps } from "@mui/material";

export type ChargebeeInputProps<Field> = Omit<InputBaseComponentProps, "onBlur"> &
  CardBaseProps<Field> & {
    component: FC<CardBaseProps<Field>>;
  };

/**
 * Chargebee hosted field integrated to MUI input.
 */
function Input<Field>(
  props: ChargebeeInputProps<Field>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  // Chargebee hosted page will break if input type passed

  const { component: Component, type, ...rest } = props;
  const theme = useTheme();

  return (
    <Component
      ref={ref}
      styles={{
        base: {
          color: theme.palette.text.primary,
          fontFamily: theme.typography.fontFamily,
          fontSize: `${theme.typography.htmlFontSize}px`,
        },
      }}
      {...rest}
      placeholder=" "
    />
  );
}

export const ChargebeeInput = forwardRef(Input);
