import { createSvgIcon } from "@mui/material/utils";

export const IOSArrowLeft = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.0487 18.627C13.2467 18.873 13.5367 19 13.8287 19C14.0487 19 14.2707 18.928 14.4547 18.779C14.8857 18.433 14.9537 17.803 14.6077 17.373L10.2927 12.011L14.7677 6.63998C15.1217 6.21598 15.0647 5.58498 14.6407 5.23198C14.2157 4.87898 13.5847 4.93598 13.2317 5.35998L8.23166 11.36C7.92666 11.726 7.92266 12.256 8.22066 12.627L13.0487 18.627Z"
  />,
  "IOSArrowLeft"
);
