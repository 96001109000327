/**
 * Check if value satisfies the Luhn algorithm.
 * See {@link https://en.wikipedia.org/wiki/Luhn_algorithm} for more details.
 */
export const luhn = (value: string): boolean => {
  if (!value) {
    // On empty string
    return false;
  }

  let sum = 0;
  const parity = value.length % 2;

  for (let i = 0; i < value.length; i += 1) {
    let digit = parseInt(value.charAt(i), 10);
    if (i % 2 === parity) digit *= 2;
    if (digit > 9) digit -= 9;
    sum += digit;
  }

  return sum % 10 === 0;
};
