import { createSvgIcon } from "@mui/material/utils";

export const Copy = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.93417 3C7.95604 3 7.97799 3 8 3L11.032 3C11.4706 2.99999 11.8491 2.99998 12.1624 3.02135C12.4922 3.04386 12.8221 3.09336 13.1481 3.22837C13.8831 3.53285 14.4672 4.11687 14.7716 4.85195C14.9066 5.17788 14.9562 5.50779 14.9787 5.83762C15 6.15088 15 6.52937 15 6.96802V7H13C13 6.52038 12.9995 6.21074 12.9833 5.97376C12.9677 5.74577 12.9411 5.65894 12.9239 5.61732C12.8224 5.37229 12.6277 5.17762 12.3827 5.07613C12.3411 5.05889 12.2542 5.03227 12.0262 5.01672C11.7893 5.00055 11.4796 5 11 5H8C7.02893 5 6.40122 5.00213 5.93871 5.06431C5.50497 5.12263 5.36902 5.21677 5.2929 5.2929C5.21677 5.36902 5.12263 5.50497 5.06431 5.93871C5.00213 6.40122 5 7.02893 5 8V11C5 11.4796 5.00055 11.7893 5.01672 12.0262C5.03227 12.2542 5.05889 12.3411 5.07613 12.3827C5.17762 12.6277 5.37229 12.8224 5.61732 12.9239C5.65894 12.9411 5.74577 12.9677 5.97376 12.9833C6.21074 12.9995 6.52038 13 7 13V15H6.96802C6.52937 15 6.15088 15 5.83762 14.9787C5.50779 14.9562 5.17788 14.9066 4.85195 14.7716C4.11687 14.4672 3.53285 13.8831 3.22837 13.1481C3.09336 12.8221 3.04386 12.4922 3.02135 12.1624C2.99998 11.8491 2.99999 11.4706 3 11.032L3 8C3 7.97799 3 7.95604 3 7.93417C2.99995 7.04769 2.99991 6.28387 3.08215 5.67221C3.17028 5.0167 3.36902 4.38835 3.87868 3.87868C4.38835 3.36902 5.0167 3.17028 5.67221 3.08215C6.28387 2.99991 7.04769 2.99995 7.93417 3Z"
    />
    <path d="M10 14C10 12.1144 10 11.1716 10.5858 10.5858C11.1716 10 12.1144 10 14 10H16C17.8856 10 18.8284 10 19.4142 10.5858C20 11.1716 20 12.1144 20 14V16C20 17.8856 20 18.8284 19.4142 19.4142C18.8284 20 17.8856 20 16 20H14C12.1144 20 11.1716 20 10.5858 19.4142C10 18.8284 10 17.8856 10 16V14Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9342 9C13.956 9 13.978 9 14 9H16C16.022 9 16.044 9 16.0658 9C16.9523 8.99995 17.7161 8.99991 18.3278 9.08215C18.9833 9.17028 19.6117 9.36902 20.1213 9.87868C20.631 10.3883 20.8297 11.0167 20.9179 11.6722C21.0001 12.2839 21.0001 13.0477 21 13.9342V16.0658C21.0001 16.9523 21.0001 17.7161 20.9179 18.3278C20.8297 18.9833 20.631 19.6117 20.1213 20.1213C19.6117 20.631 18.9833 20.8297 18.3278 20.9179C17.7161 21.0001 16.9523 21.0001 16.0658 21H13.9342C13.0477 21.0001 12.2839 21.0001 11.6722 20.9179C11.0167 20.8297 10.3883 20.631 9.87868 20.1213C9.36902 19.6117 9.17028 18.9833 9.08215 18.3278C8.99991 17.7161 8.99995 16.9523 9 16.0658C9 16.044 9 16.022 9 16V14C9 13.978 9 13.956 9 13.9342C8.99995 13.0477 8.99991 12.2839 9.08215 11.6722C9.17028 11.0167 9.36902 10.3883 9.87868 9.87868C10.3883 9.36902 11.0167 9.17028 11.6722 9.08215C12.2839 8.99991 13.0477 8.99995 13.9342 9ZM11.9387 11.0643C11.505 11.1226 11.369 11.2168 11.2929 11.2929C11.2168 11.369 11.1226 11.505 11.0643 11.9387C11.0021 12.4012 11 13.0289 11 14V16C11 16.9711 11.0021 17.5988 11.0643 18.0613C11.1226 18.495 11.2168 18.631 11.2929 18.7071C11.369 18.7832 11.505 18.8774 11.9387 18.9357C12.4012 18.9979 13.0289 19 14 19H16C16.9711 19 17.5988 18.9979 18.0613 18.9357C18.495 18.8774 18.631 18.7832 18.7071 18.7071C18.7832 18.631 18.8774 18.495 18.9357 18.0613C18.9979 17.5988 19 16.9711 19 16V14C19 13.0289 18.9979 12.4012 18.9357 11.9387C18.8774 11.505 18.7832 11.369 18.7071 11.2929C18.631 11.2168 18.495 11.1226 18.0613 11.0643C17.5988 11.0021 16.9711 11 16 11H14C13.0289 11 12.4012 11.0021 11.9387 11.0643Z"
    />
  </>,
  "Copy"
);
