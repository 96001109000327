import { daDK, deDE, enUS, esES, fiFI, frFR, itIT, nlNL, svSE } from "@mui/x-date-pickers/locales";
import { type Locale } from "date-fns";
import {
  da as dateFnsDa,
  de as dateFnsDe,
  enUS as dateFnsEnUs,
  es as dateFnsEs,
  fi as dateFnsFi,
  fr as dateFnsFr,
  it as dateFnsIt,
  nl as dateFnsNl,
  sv as dateFnsSv,
} from "date-fns/locale";

import type { LocalizationProviderProps } from "@mui/x-date-pickers";
import type { LiteralUnion } from "type-fest";

/**
 * Locales supported by the util.
 */
type Locales = "da" | "de" | "en" | "fi" | "es" | "fr" | "it" | "nl" | "sv";

/**
 * All the possible locales supported by the util, with a string fallback, which will be resolved to "en".
 */
export type PossibleLocales = LiteralUnion<Locales, string>;

/**
 * Resolve given string to correct date-fns Locale
 *
 * @param locale string to resolve
 * @returns corresponding date-fns Locale
 */
export const resolveDateFnsLocale = (locale: PossibleLocales): Locale => {
  switch (locale) {
    case "da":
      return dateFnsDa;
    case "de":
      return dateFnsDe;
    case "en":
      return dateFnsEnUs;
    case "fi":
      return dateFnsFi;
    case "es":
      return dateFnsEs;
    case "fr":
      return dateFnsFr;
    case "it":
      return dateFnsIt;
    case "nl":
      return dateFnsNl;
    case "sv":
      return dateFnsSv;
    default:
      return dateFnsEnUs;
  }
};

/**
 * Resolve given locale string to localized texts for LocalizationProvider.
 *
 * @param locale string to resolve
 * @returns corresponding localized texts
 */
export const resolveLocaleText = (
  locale: PossibleLocales
): LocalizationProviderProps<Date, string>["localeText"] => {
  switch (locale) {
    case "da":
      return daDK.components.MuiLocalizationProvider.defaultProps.localeText;
    case "de":
      return deDE.components.MuiLocalizationProvider.defaultProps.localeText;
    case "en":
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
    case "fi":
      return fiFI.components.MuiLocalizationProvider.defaultProps.localeText;
    case "es":
      return esES.components.MuiLocalizationProvider.defaultProps.localeText;
    case "fr":
      return frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    case "it":
      return itIT.components.MuiLocalizationProvider.defaultProps.localeText;
    case "nl":
      return nlNL.components.MuiLocalizationProvider.defaultProps.localeText;
    case "sv":
      return svSE.components.MuiLocalizationProvider.defaultProps.localeText;
    default:
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
  }
};

/**
 * Resolve given PossibleLocales string to correct Apple Pay wallet locale
 *
 * @param locale locale to resolve
 * @returns corresponding locale for the Apple Pay wallet
 */
export const resolveApplePayLocale = (locale: PossibleLocales) => {
  switch (locale) {
    case "da":
      return "da-DK";
    case "de":
      return "de-DE";
    case "en":
      return "en-GB";
    case "fi":
      return "fi-FI";
    case "es":
      return "es-ES";
    case "fr":
      return "fr-FR";
    case "it":
      return "it-IT";
    case "nl":
      return "nl-NL";
    case "sv":
      return "sv-SE";
    default:
      return "en-GB";
  }
};
