import type { FC } from "react";

import { Phone } from "@augment-frontend/mui-icons";

import type { MediumModalProps } from "./MediumModal";

import { MediumModal } from "./MediumModal";

export type PhoneModalProps = Omit<
  MediumModalProps,
  "mediumInputMode" | "mediumIcon" | "validateMediumValue"
>;

export const PhoneModal: FC<PhoneModalProps> = (props) => (
  <MediumModal
    {...props}
    mediumInputMode="tel"
    mediumIcon={Phone}
    validateMediumValue={(phone) => /^\+\d{9,13}$/.test(phone)}
  />
);
