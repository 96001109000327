/**
 * Calculate whether the birthday has passed relative to a reference date by taking account only the month and day.
 * @param dateOfBirth DOB
 * @param referenceDate Date to calculate against
 * @returns Whether the birthday has passed by taking account only the month and day
 */
export const birthdayPassed = (dateOfBirth: Date, referenceDate: Date): boolean => {
  return (
    dateOfBirth.getMonth() < referenceDate.getMonth() ||
    (dateOfBirth.getMonth() === referenceDate.getMonth() &&
      dateOfBirth.getDate() <= referenceDate.getDate())
  );
};

/**
 * Calculate age in years relative to a reference date.
 * @param dateOfBirth DOB
 * @param {Date} [referenceDate=new Date()] Defaults to current Date
 * @returns Age in years
 */
export const ageInYears = (dateOfBirth: Date, referenceDate: Date = new Date()): number => {
  return (
    referenceDate.getFullYear() -
    dateOfBirth.getFullYear() -
    (birthdayPassed(dateOfBirth, referenceDate) ? 0 : 1)
  );
};

/**
 * Calculate if given year is leap year.
 */
export const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

/**
 * Calculate number of days in a month.
 * This method avoids issues with Date object's timezone limitations,
 * as the calculation is performed using only numeric values.
 * @param year Year e.g. 2000
 * @param month Zero index month e.g. 0 for January
 * @returns Number of days in the month or -1 if invalid
 */
export const daysInMonth = (year: number, month: number): number => {
  const DAYS_IN_MONTH_MAP = new Map([
    [0, 31],
    [1, isLeapYear(year) ? 29 : 28],
    [2, 31],
    [3, 30],
    [4, 31],
    [5, 30],
    [6, 31],
    [7, 31],
    [8, 30],
    [9, 31],
    [10, 30],
    [11, 31],
  ]);

  return DAYS_IN_MONTH_MAP.get(month) || -1;
};
