import type { FC } from "react";
import { useEffect, useRef, useState } from "react";

import { CircularProgress, InputAdornment } from "@mui/material";

import { TextField } from "../../TextField";

export type CouponInputProps = {
  /**
   * Callback called to validate the input when > 4 chars are entered.
   * Returns a promise which resolves to a boolean depending on the value validation logic result.
   */
  onValueValidation: (value: string) => Promise<boolean>;
  /** If true, component is disabled */
  disabled?: boolean;
  /**
   * Initial value of the input, which also behaves as a "fake" success code.
   *
   * If input matches with this, it will be considered as valid but onValueValidation will not be called
   * and the helperTextSuccess will not be displayed.
   */
  placeholder?: string;
  /**
   * If true, the placeholder will not be displayed as a initial value.
   *
   * Use case: we want to hide placeholder when user has typed valid code and this component is re-rendered. Then the input should be empty,
   * but typing the placeholder again should yield to success state.
   */
  hidePlaceholder?: boolean;
  translations: {
    /** Label for the input TextField, e.g. "Discount" */
    label: string;
    /** Helper text to display, if the onValueValidation returns true e.g. "Discount applied!" */
    helperTextSuccess: string;
    /** Helper text to display, if the onValueValidation returns false e.g. "Invalid code" */
    helperTextError: string;
  };
};

const helperTextInitialState = " "; // whitespace to prevent layout shift

export const CouponInput: FC<CouponInputProps> = (props) => {
  const [inputValue, setInputValue] = useState(
    (!props.hidePlaceholder && props.placeholder?.toUpperCase()) || ""
  );
  const [validationStatus, setValidationStatus] = useState({
    success: !!props.placeholder,
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const [helperText, setHelperText] = useState(helperTextInitialState);

  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    setLoading(true);
    setValidationStatus({ success: false, error: false });
    setHelperText(helperTextInitialState);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    const validateInputValue = async () => {
      if (inputValue === props.placeholder?.toUpperCase()) {
        // matches with placeholder, do not validate nor show helper text
        setValidationStatus({ success: true, error: false });
      } else {
        const isValid = await props.onValueValidation(inputValue);
        if (isValid) {
          setValidationStatus({ success: true, error: false });
          setHelperText(props.translations.helperTextSuccess);
        } else {
          setValidationStatus({ success: false, error: true });
          setHelperText(props.translations.helperTextError);
        }
      }
    };

    timerRef.current = setTimeout(async () => {
      if (inputValue.length > 4) {
        await validateInputValue();
      } else if (inputValue.length > 0) {
        // "fake" validation if 1-4 chars typed -> as timeout finishes, set error without validation
        setValidationStatus({ success: false, error: true });
        setHelperText(props.translations.helperTextError);
      }
      setLoading(false);
    }, 500);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [inputValue]);

  return (
    <TextField
      fullWidth
      value={inputValue}
      label={props.translations.label}
      success={validationStatus.success}
      error={validationStatus.error}
      helperText={helperText}
      onChange={(event) => setInputValue(event.target.value.trim().toUpperCase())}
      disabled={props.disabled}
      FormHelperTextProps={{
        sx: (theme) => ({
          color: validationStatus.success ? `${theme.palette.text.primary} !important` : undefined,
        }),
      }}
      InputProps={
        loading
          ? {
              endAdornment: (
                <InputAdornment position="end">{<CircularProgress size={24} />}</InputAdornment>
              ),
            }
          : undefined
      }
      inputProps={{ "data-testid": "couponInput" }}
    />
  );
};
