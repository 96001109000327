import { createSvgIcon } from "@mui/material/utils";

export const AlertRound = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12ZM12 13C11.4477 13 11 12.5523 11 12L11 7C11 6.44771 11.4477 6 12 6C12.5523 6 13 6.44771 13 7L13 12C13 12.5523 12.5523 13 12 13ZM12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z"
  />,
  "AlertRound"
);
