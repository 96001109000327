import type { FC } from "react";

import type { AddressProps } from "./Address";

import { Surface } from "../../../Surface";
import { Address } from "./Address";

export interface AddressesProps {
  billingAddress: Omit<AddressProps, "translations">;
  translations: AddressProps["translations"];
}

export const Addresses: FC<AddressesProps> = (props) => (
  <Surface
    sx={(theme) => ({
      bgcolor: theme.palette.secondary[200],
      border: `1px solid ${theme.palette.text.primary}`,
      p: 0,
    })}
  >
    <Address {...props.billingAddress} translations={props.translations} />
  </Surface>
);
