import type { FC } from "react";
import { useRef, useState } from "react";

import { Done, IOSArrowDown, IOSArrowUp } from "@augment-frontend/mui-icons";
import {
  ListItemText,
  Button as MaterialButton,
  Menu as MaterialMenu,
  MenuItem as MaterialMenuItem,
  menuClasses,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import type {
  NavigationOnClickOption,
  NavigationOnClickProps,
  NavigationProps,
} from "./Navigation.types";
import type { MenuItemProps } from "@mui/material";

const Header = styled(MaterialButton)((props) => ({
  justifyContent: "space-between",
  backgroundColor: props["aria-expanded"]
    ? props.theme.palette.primary[400]
    : props.theme.palette.secondary[200],
  border: `2px solid ${
    props["aria-expanded"] ? props.theme.palette.secondary[600] : props.theme.palette.secondary.dark
  } !important`,
  ":hover": {
    backgroundColor: `${props.theme.palette.secondary[200]} !important`,
    border: `2px solid ${props.theme.palette.text[200]} !important`,
  },
}));

const Menu = styled(MaterialMenu)((props) => ({
  [`.${menuClasses.paper}`]: {
    width: "100%", // Will be overriden dynamically via JS
    maxWidth: "100%",
    marginTop: props.theme.spacing(1),
    backgroundColor: props.theme.palette.secondary[200],
  },
  [`.${menuClasses.list}`]: {
    padding: 0,
  },
}));

const MenuItem = styled(MaterialMenuItem, {
  shouldForwardProp: (prop) => {
    if (prop === "active") {
      return false;
    }
    return true;
  },
})<MenuItemProps & { active?: boolean }>((props) => ({
  margin: props.theme.spacing(1),
  padding: props.theme.spacing(1),
  backgroundColor: props.active === true ? props.theme.palette.primary[400] : undefined,
  borderRadius: props.theme.shape.borderRadius,
}));

export type DropdownNavigationProps = NavigationProps;

export const DropdownNavigation: FC<DropdownNavigationProps> = (props) => {
  const [selectedValue, setSelectedValue] = useState(
    (props.options as NavigationOnClickProps["options"]).find((option) => option.active === true)
      ?.value || props.options[0].value
  );
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  return (
    <div ref={containerRef} data-testid="dropdownNavigation">
      <Header
        fullWidth
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={() => {
          setOpen(true);
        }}
        endIcon={
          open ? (
            <IOSArrowUp sx={{ color: theme.palette.primary[600] }} />
          ) : (
            <IOSArrowDown sx={{ color: theme.palette.text.primary }} />
          )
        }
      >
        {(props.options as Partial<NavigationOnClickOption>[]).find((option) => {
          if (option.onClick) {
            return option.value === selectedValue;
          }
          return option.active;
        })?.label || ""}
      </Header>
      <Menu
        id="basic-menu"
        anchorEl={containerRef.current}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        // Calculate width dynamically so that the Popover does not expand wider than the parent
        sx={{ width: containerRef.current ? containerRef.current.offsetWidth : undefined }}
      >
        {props.options.map((option) => (
          <MenuItem
            key={option.label}
            active={option.onClick ? option.value === selectedValue : option.active}
            onClick={() => {
              setSelectedValue(option.value);
              setOpen(false);
              if (option.onClick) {
                option.onClick(option.value);
              }
            }}
            component={option.component}
          >
            <ListItemText primaryTypographyProps={{ variant: "bodyTextBold" }}>
              {option.label}
            </ListItemText>
            {(option.onClick ? option.value === selectedValue : option.active) && (
              <Done color="inherit" />
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
