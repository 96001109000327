import type { FC } from "react";

import { Typography } from "@mui/material";

export interface AddressProps {
  street: string | null;
  street2: string | null;
  houseNumber: string | null;
  zip: string | null;
  city: string | null;
  state: string | null;
  /** ISO 3166-1 alpha-2 */
  countryCode: string | null;
  translations: {
    title: string;
  };
}

export const Address: FC<AddressProps> = (props) => (
  <>
    <Typography variant="largeTextBold">{props.translations.title}</Typography>
    <Typography variant="bodyText">
      {props.street} {props.houseNumber}
    </Typography>
    <Typography variant="bodyText">
      {props.zip} {props.city}
    </Typography>
    <Typography variant="bodyText">{props.state}</Typography>
    <Typography variant="bodyText">{props.countryCode}</Typography>
  </>
);
