
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ChargebeeEstimate": [
      "ChargebeeEstimateCreateSubscription",
      "ChargebeeEstimateFutureSubscription",
      "ChargebeeEstimateRenewalSubscription"
    ],
    "ChargebeeInvoiceInterface": [
      "ChargebeeEstimateInvoice",
      "ChargebeeInvoice"
    ],
    "ChargebeePaymentSource": [
      "ChargebeePaymentSourceApplePay",
      "ChargebeePaymentSourceCard",
      "ChargebeePaymentSourceDirectDebit",
      "ChargebeePaymentSourceGeneric",
      "ChargebeePaymentSourceGooglePay",
      "ChargebeePaymentSourcePayPal"
    ],
    "ChargebeePaymentSourceInterface": [
      "ChargebeePaymentSourceApplePay",
      "ChargebeePaymentSourceCard",
      "ChargebeePaymentSourceDirectDebit",
      "ChargebeePaymentSourceGeneric",
      "ChargebeePaymentSourceGooglePay",
      "ChargebeePaymentSourcePayPal"
    ],
    "ChargebeeSubscriptionInterface": [
      "ChargebeeEstimateSubscription",
      "ChargebeeSubscription"
    ],
    "ContactDetails": [
      "OdooChildContact",
      "OdooContact"
    ],
    "MutationResponse": [
      "AdminAddDeviceOperationalStatusMutationResponse",
      "AdminAddServiceRequestMutationResponse",
      "AdminAddUserCommentResponse",
      "AdminArchiveOdooDeliveryAddressForUserMutationResponse",
      "AdminChangeDeviceIdentifiersMutationResponse",
      "AdminChangeDeviceSpeedLimitsMutationResponse",
      "AdminCreateFinalRedemptionMutationResponse",
      "AdminCreateMaintenanceContractSubscriptionMutationResponse",
      "AdminCreateOdooDeliveryAddressForUserMutationResponse",
      "AdminDeleteUserCommentResponse",
      "AdminRemoveDeviceOperationalStatusMutationResponse",
      "AdminValidateOdooShippingMutationResponse",
      "AdminValidateOdooShippingsMutationResponse",
      "ClaimDeviceOwnershipMutationResponse",
      "CreateChargebeePaymentIntentForEstimateFutureSubscriptionMutationResponse",
      "CreateShopifyTokenForAuthenticationMutationResponse",
      "RenameDeviceMutationResponse",
      "SendSmsMutationResponse",
      "UpdateChargebeeBillingDateForSubscriptionMutationResponse",
      "UpdateChargebeeBillingDetailsForSubscriptionMutationResponse",
      "UpdateChargebeeCreditCheckStatusForSubscriptionsMutationResponse",
      "UpdateChargebeePaymentIntentForEstimateFutureSubscriptionMutationResponse",
      "UpdateShopifyCustomerForLogoutMutationResponse",
      "UpdateTinkReportIdMutationResponse",
      "UpdateUserCredentialsMutationResponse",
      "UpdateUserNotificationPermissionsMutationResponse"
    ],
    "QueryResponse": [
      "ChargebeeSubscriptionBillingDateEstimateQueryResponse",
      "CheckDeviceOwnershipQueryResponse",
      "NewChargebeeSubscriptionRestrictionsQueryResponse",
      "SubscriptionsPendingKycWithPaginationQueryResponse"
    ]
  }
};
      export default result;
    