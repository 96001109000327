import { ArrowRight } from "@augment-frontend/mui-icons";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { HeadingContentProps } from "../HeadingContent";
import type { LinkProps } from "@mui/material";

import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockBrowseMoreProps<T extends React.ElementType> {
  headingContent: HeadingContentProps;
  /** Linked pages */
  items: {
    /** Title of the linked page */
    title: string;
    /** Image shown in the background */
    image: React.ReactNode;
    linkProps: LinkProps<T>;
  }[];
}

export const BlockBrowseMore = <T extends React.ElementType>(props: BlockBrowseMoreProps<T>) => {
  const theme = useTheme();

  return (
    <GridContainer>
      <Grid item mobile={12}>
        <HeadingContent
          {...props.headingContent}
          boxProps={{ width: { tablet: "50%", laptop: "33%" } }}
        />
      </Grid>
      <Grid item mobile={12} mt={{ tablet: 2.25, laptop: 5.25 }}>
        <Stack direction="row" spacing="10px" sx={{ overflowX: "auto" }}>
          {props.items.map((item, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                height: theme.spacing(30),
                flex: `0 0 ${theme.spacing(30)}`,
              }}
            >
              <Link {...item.linkProps}>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                >
                  {item.image}
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    display: "flex",
                    background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)",
                  }}
                >
                  <Stack width="100%" direction="column" alignSelf="end" p={2}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        variant="subheadingBold"
                        mr={2}
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {item.title}
                      </Typography>
                      <Stack direction="column" justifyContent="end">
                        <ArrowRight fontSize="small" sx={{ color: theme.palette.primary.main }} />
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </Link>
            </Box>
          ))}
        </Stack>
      </Grid>
    </GridContainer>
  );
};
