import type { FC } from "react";
import { useState } from "react";

import { Message } from "@augment-frontend/mui-icons";
import Mailcheck from "mailcheck";
import isEmail from "validator/lib/isEmail";

import type { MediumModalProps } from "./MediumModal";

import { MediumModal } from "./MediumModal";

Mailcheck.defaultDomains.push("augment.eco");

export type EmailModalProps = Omit<
  MediumModalProps,
  "mediumInputMode" | "mediumIcon" | "validateMediumValue"
>;

const emailSuggestionInitialState = " "; // single space to prevent layout shift

export const EmailModal: FC<EmailModalProps> = (props) => {
  const [emailSuggestion, setEmailSuggestion] = useState(emailSuggestionInitialState);

  const getEmailSuggestion = (email: string): string => {
    const result = Mailcheck.run({ email });
    return result?.full || emailSuggestionInitialState;
  };

  return (
    <MediumModal
      {...props}
      mediumInputMode="email"
      mediumIcon={Message}
      validateMediumValue={(email) => {
        const isValidEmail = isEmail(email, { domain_specific_validation: true });
        if (isValidEmail) {
          setEmailSuggestion(getEmailSuggestion(email));
        } else {
          setEmailSuggestion(emailSuggestionInitialState);
        }
        return isValidEmail;
      }}
      mediumSuggestedValue={emailSuggestion}
    />
  );
};
