import type { FC } from "react";
import { useState } from "react";

import { Snackbar, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import type { DeviceProps } from "./Device";

import { resolveDateFnsLocale, resolveLocaleText } from "../../../utils/locale";
import { Alert } from "../../Alert";
import { SwipeableTabs } from "../../Tab";
import { Device } from "./Device";

type SharedDevicesProps = Pick<DeviceProps, "btMac" | "infoGrid" | "mileage" | "name">[];

type MyDevicesProps = SharedDevicesProps & Pick<DeviceProps, "shareInfo" | "sharingReceivers">[];

export type MyAccountDevicesProps = {
  /** Locale of the date-picker's LocalizationProvider */
  locale: Parameters<typeof resolveDateFnsLocale>[0];
  /** Devices of the user */
  myDevices: MyDevicesProps;
  /** Devices shared to user */
  sharedDevices: SharedDevicesProps;
  translations: {
    /** 1. SwipeableTab label, e.g. "My e-scooters" */
    myDevicesTabLabel: string;
    /** 2. SwipeableTab label, e.g. "Shared with me" */
    sharedDevicesTabLabel: string;
    /** Message to show in alert on cancel sharing success, e.g. "Access removed" */
    cancelSharingSuccess: string;
    /** Message to show when no device e.g. "You have no e-scooter" */
    noDevices: string;
    /** Message to show when no shared device e.g. "You have no shared devices" */
    noSharedDevices: string;
  } & DeviceProps["translations"];
} & NonNullable<
  Pick<
    DeviceProps,
    | "onMileageSelectionSubmit"
    | "onNameChangeSubmit"
    | "onGenerateShareLink"
    | "onCancelSharingSubmit"
    | "onSharingChangeSubmit"
  >
>;

export const MyAccountDevices: FC<MyAccountDevicesProps> = (props) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const theme = useTheme();
  const { myDevices, sharedDevices } = props;

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
    setError("");
  };

  const DeviceTranslations: DeviceProps["translations"] = {
    nameModal: props.translations.nameModal,
    mileage: props.translations.mileage,
    editSharingButton: props.translations.editSharingButton,
    shareLinkButton: props.translations.shareLinkButton,
    cancelSharingButton: props.translations.cancelSharingButton,
    shareLinkModal: props.translations.shareLinkModal,
    sharing: props.translations.sharing,
    cancelSharingModal: props.translations.cancelSharingModal,
  };

  return (
    <>
      <SwipeableTabs
        tabs={[
          {
            label: props.translations.myDevicesTabLabel,
            component: (
              <>
                <Stack direction="column" spacing={theme.spacing(2)}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={resolveDateFnsLocale(props.locale)}
                    localeText={resolveLocaleText(props.locale)}
                  >
                    {myDevices.length > 0 ? (
                      myDevices.map((device) => (
                        <Device
                          key={device.btMac}
                          {...device}
                          onMileageSelectionSubmit={props.onMileageSelectionSubmit}
                          onGenerateShareLink={props.onGenerateShareLink}
                          onNameChangeSubmit={props.onNameChangeSubmit}
                          onSharingChangeSubmit={props.onSharingChangeSubmit}
                          onError={(errorMessage) => setError(errorMessage)}
                          translations={DeviceTranslations}
                        />
                      ))
                    ) : (
                      <Typography>{props.translations.noDevices}</Typography>
                    )}
                  </LocalizationProvider>
                </Stack>
              </>
            ),
          },
          {
            label: props.translations.sharedDevicesTabLabel,
            component: (
              <>
                <Stack
                  direction="column"
                  spacing={theme.spacing(2)}
                  data-testid="sharedDevices"
                  data-testid-count={sharedDevices.length}
                >
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={resolveDateFnsLocale(props.locale)}
                    localeText={resolveLocaleText(props.locale)}
                  >
                    {sharedDevices.length > 0 ? (
                      sharedDevices.map((device) => (
                        <Device
                          key={device.btMac}
                          {...device}
                          onMileageSelectionSubmit={props.onMileageSelectionSubmit}
                          onCancelSharingSubmit={props.onCancelSharingSubmit}
                          onCancelSharingSuccess={() => setSuccess(true)}
                          onError={(errorMessage) => setError(errorMessage)}
                          translations={DeviceTranslations}
                        />
                      ))
                    ) : (
                      <Typography>{props.translations.noSharedDevices}</Typography>
                    )}
                  </LocalizationProvider>
                </Stack>
              </>
            ),
          },
        ]}
      />
      <Snackbar open={success || !!error} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          severity={success ? "success" : "error"}
          description={success ? props.translations.cancelSharingSuccess : error}
        />
      </Snackbar>
    </>
  );
};
