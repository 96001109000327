import { useEffect, useState } from "react";

import { Grid } from "@mui/material";

import type {
  BirthdayInputProps,
  CouponInputProps,
  SocialSecurityInputProps,
} from "../../../../Inputs";
import type { IdentityDocumentNumberInputProps } from "../../../../Inputs/IdentityDocumentNumberInput";
import type { CountryCode } from "@augment-frontend/constants";

import { BirthdayInput, CouponInput, SocialSecurityInput } from "../../../../Inputs";
import { IdentityDocumentNumberInput } from "../../../../Inputs/IdentityDocumentNumberInput";

/** Countries that require SSN */
const SSN_COUNTRIES: CountryCode[] = ["DK", "ES", "FI", "IT", "SE"];
/** Countries that require birthday */
const BIRTHDAY_COUNTRIES: CountryCode[] = ["AT", "BE", "CH", "ES", "FR", "LU"];
/** Countries that require identity number */
const IDENTITY_NUMBER_COUNTRIES: CountryCode[] = ["IT"];

type PersonalInfo = {
  ssn?: string;
  identityDocumentNumber?: string;
  dateOfBirth?: Date;
};

export type PersonalInfoResult = {
  personalInfo: PersonalInfo;
  valid: boolean;
};

export interface PersonalInfoWithCouponProps<T extends CountryCode> {
  countryCode: T;
  locale: string;
  personalInfo: PersonalInfo;
  disabled?: boolean;
  /** Callback for when a value of an input changes */
  onChange: (result: PersonalInfoResult) => void;
  onValidateCoupon: CouponInputProps["onValueValidation"];
  couponPlaceholder?: CouponInputProps["placeholder"];
  hideCouponPlaceholder?: CouponInputProps["hidePlaceholder"];
  translations: {
    ssnInput: SocialSecurityInputProps["translations"];
    identityDocumentNumberInput: IdentityDocumentNumberInputProps["translations"];
    birthdayInput: BirthdayInputProps["translations"];
    couponInput: CouponInputProps["translations"];
  };
}

export const PersonalInfoWithCoupon = <T extends CountryCode>(
  props: PersonalInfoWithCouponProps<T>
) => {
  const ssnEnabled = SSN_COUNTRIES.includes(props.countryCode);
  const identityDocumentNumberEnabled = IDENTITY_NUMBER_COUNTRIES.includes(props.countryCode);
  const dateOfBirthEnabled = BIRTHDAY_COUNTRIES.includes(props.countryCode);

  const [personalInfo, setPersonalInfo] = useState({
    ssn: props.personalInfo.ssn || "",
    ssnValid: !ssnEnabled,
    identityDocumentNumber: props.personalInfo.identityDocumentNumber || "",
    identityDocumentNumberValid: !identityDocumentNumberEnabled,
    dateOfBirth: props.personalInfo.dateOfBirth || null,
    dateOfBirthValid: !dateOfBirthEnabled,
  });

  useEffect(() => {
    props.onChange({
      personalInfo: {
        ssn: personalInfo.ssn,
        dateOfBirth: personalInfo.dateOfBirth || undefined,
        identityDocumentNumber: personalInfo.identityDocumentNumber,
      },
      valid:
        personalInfo.ssnValid &&
        personalInfo.identityDocumentNumberValid &&
        personalInfo.dateOfBirthValid,
    });
  }, [personalInfo]);

  return (
    <Grid container spacing={2}>
      {identityDocumentNumberEnabled && (
        <Grid item mobile={12}>
          <IdentityDocumentNumberInput
            required
            identityDocumentNumber={props.personalInfo.identityDocumentNumber}
            immutable={true}
            disabled={props.disabled}
            onChange={({ value, valid }) =>
              setPersonalInfo((prev) => ({
                ...prev,
                identityDocumentNumber: value,
                identityDocumentNumberValid: valid,
              }))
            }
            translations={props.translations.identityDocumentNumberInput}
          />
        </Grid>
      )}
      {ssnEnabled && (
        <Grid item mobile={12} tablet={12} laptop={dateOfBirthEnabled ? 6 : 12}>
          <SocialSecurityInput
            required
            countryCode={props.countryCode}
            ssn={props.personalInfo.ssn}
            immutable={true}
            disabled={props.disabled}
            onChange={({ standardizedSSN, valid }) =>
              setPersonalInfo((prev) => ({
                ...prev,
                ssn: standardizedSSN || "",
                ssnValid: valid,
              }))
            }
            translations={props.translations.ssnInput}
          />
        </Grid>
      )}
      {dateOfBirthEnabled && (
        <Grid item mobile={12} tablet={12} laptop={ssnEnabled ? 6 : 12}>
          <BirthdayInput
            fullWidth
            required
            locale={props.locale}
            value={props.personalInfo.dateOfBirth || null}
            immutable={true}
            disabled={props.disabled}
            onChange={({ birthday, valid }) =>
              setPersonalInfo((prev) => ({
                ...prev,
                dateOfBirth: birthday,
                dateOfBirthValid: valid,
              }))
            }
            translations={props.translations.birthdayInput}
          />
        </Grid>
      )}
      <Grid item mobile={12}>
        <CouponInput
          onValueValidation={props.onValidateCoupon}
          translations={props.translations.couponInput}
          disabled={props.disabled}
          placeholder={props.couponPlaceholder}
          hidePlaceholder={props.hideCouponPlaceholder}
        />
      </Grid>
    </Grid>
  );
};
