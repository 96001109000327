import type { FC } from "react";
import { useEffect, useRef, useState } from "react";

import { IOSArrowDown, IOSArrowLeft, IOSArrowUp } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Button, Collapse, Divider, IconButton, Stack } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import type { LoadingButtonProps } from "@mui/lab";
import type { ButtonProps, StackProps } from "@mui/material";

export interface BottomNavigationProps {
  containerRef?: React.Ref<HTMLDivElement>;
  /** Triggered for forward-click */
  onForward?: LoadingButtonProps["onClick"];
  /** Triggered for backward-click */
  onBackward?: ButtonProps["onClick"];
  /** Defines content width / buttons right side position */
  width?: StackProps["width"];
  /** Content to display always on mobile */
  mobileAlwaysVisibleContent?: React.ReactNode;
  /**
   * This content is hidden by default and can be toggled by clicking the arrow icon.
   * When displayed, this content is displayed topmost and a divider is added between
   * the content and the mobileAlwaysVisibleContent.
   */
  mobileAdditionalContent?: React.ReactNode;
  /** Display backward-button on mobile */
  mobileBackward?: boolean;
  /** Disable forward-button */
  disableForward?: boolean;
  /** Display loading indicator on forward-button */
  loadingForward?: boolean;
  /** Hide forward-button */
  hideForward?: boolean;
  /** Hide backward-button */
  hideBackward?: boolean;
  /** E.g. "Continue" */
  forwardButton?: string;
  /** Targeted form id to submit. If given, the forwardButton type will be changed from button to submit */
  formIdToSubmit?: string;
}

const StyledContainer = styled("div")((props) => ({
  boxSizing: "border-box",
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  padding: props.theme.spacing(2),
  [props.theme.breakpoints.down("tablet")]: {
    padding: props.theme.spacing(2, 3),
  },
  backgroundColor: props.theme.palette.secondary.A200,
  zIndex: 2,
}));

export const BottomNavigation: FC<BottomNavigationProps> = (props) => {
  const [displayMobileAdditionalContent, setDisplayMobileAdditionalContent] = useState(false);
  const [mobileViewportState, setMobileViewportState] = useState({
    viewportHeightReduced: false,
    visualViewportHeightReduced: false,
  });
  const initialViewportHeight = useRef(window.innerHeight);
  const initialVisualViewportHeight = useRef(window.visualViewport?.height);

  const theme = useTheme();

  // no consistent return because this effect is only needed on mobile
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const onViewportHeightChange = () => {
      // if viewport height reduces more than 20%, then we consider that the mobile keyboard is open
      const isViewportReduced = window.innerHeight < initialViewportHeight.current * 0.8;
      setMobileViewportState((prevState) => ({
        ...prevState,
        viewportHeightReduced: isViewportReduced,
      }));
    };

    const onVisualViewportHeightChange = () => {
      if (initialVisualViewportHeight?.current && window.visualViewport) {
        const isVisualViewportReduced =
          window.visualViewport.height < initialVisualViewportHeight.current * 0.8;
        setMobileViewportState((prevState) => ({
          ...prevState,
          visualViewportHeightReduced: isVisualViewportReduced,
        }));
      }
    };

    const isMobile = window.innerWidth < theme.breakpoints.values.tablet;
    if (isMobile) {
      window.addEventListener("resize", onViewportHeightChange);
      window.visualViewport?.addEventListener("resize", onVisualViewportHeightChange);

      return () => {
        window.removeEventListener("resize", onViewportHeightChange);
        window.visualViewport?.removeEventListener("resize", onVisualViewportHeightChange);
      };
    }
  }, []);

  return (
    <StyledContainer id="bottom-navigation" ref={props.containerRef} data-testid="bottomNavigation">
      {props.mobileAlwaysVisibleContent &&
        !mobileViewportState.viewportHeightReduced &&
        !mobileViewportState.visualViewportHeightReduced && (
          /* Content above Button(s), only displayed in mobile (if mobile keyboard isn't open) */
          <Stack
            direction="column"
            display={{ mobile: "flex", tablet: "none" }}
            marginBottom={props.hideForward && !props.mobileBackward ? 0 : theme.spacing(2)}
          >
            {props.mobileAdditionalContent && (
              <IconButton
                size="small"
                onClick={() => setDisplayMobileAdditionalContent((prev) => !prev)}
                sx={{
                  alignSelf: "center",
                  top: "-18px",
                  position: "absolute",
                  transition: theme.transitions.create(
                    ["background-color", "box-shadow", "border-color", "color"],
                    {
                      duration: theme.transitions.duration.short,
                    }
                  ),
                  background: theme.palette.secondary[200],
                  ":hover": { background: theme.palette.secondary[200] },
                  border: `1px solid ${theme.palette.secondary[400]}`,
                }}
              >
                {displayMobileAdditionalContent ? <IOSArrowDown /> : <IOSArrowUp />}
              </IconButton>
            )}
            <Collapse in={displayMobileAdditionalContent}>
              <div>
                {props.mobileAdditionalContent}
                <Divider
                  sx={{ borderColor: theme.palette.text["800"], marginY: theme.spacing(2) }}
                />
              </div>
            </Collapse>
            {props.mobileAlwaysVisibleContent}
          </Stack>
        )}
      <Stack
        direction="row"
        justifyContent={{ tablet: "end" }}
        width={props.width}
        margin="auto"
        spacing={{ mobile: props.mobileBackward ? theme.spacing(2) : 0, tablet: theme.spacing(2) }}
      >
        {!props.hideBackward && (
          /* Backward-button */
          <Button
            aria-label="Go backward"
            variant="outlined"
            color="secondary"
            onClick={props.onBackward}
            sx={{
              backgroundColor: theme.palette.secondary.A200,
              display: { mobile: props.mobileBackward ? "flex" : "none", tablet: "flex" },
              width: { mobile: props.hideForward ? "100%" : "auto", tablet: "auto" },
              padding: { mobile: "8px", tablet: "12px" },
              minWidth: "auto",
            }}
          >
            <IOSArrowLeft sx={{ color: theme.palette.text.secondary }} />
          </Button>
        )}
        {!props.hideForward && (
          /* Forward-button */
          <LoadingButton
            type={props.formIdToSubmit ? "submit" : "button"}
            form={props.formIdToSubmit ? props.formIdToSubmit : undefined}
            data-testid="forward-button"
            disabled={props.disableForward}
            loading={props.loadingForward}
            onClick={props.onForward}
            sx={{
              width: { mobile: "100%", tablet: "auto" },
              padding: { mobile: "8px 14px", tablet: "12px 16px" },
            }}
          >
            {props.forwardButton}
          </LoadingButton>
        )}
      </Stack>
    </StyledContainer>
  );
};
