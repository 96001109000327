import type { FC } from "react";
import { useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";

import type { SelectChangeEvent } from "@mui/material";

import { Select } from "../../Select";
import { TextField } from "../../TextField";

/** Country code prefixes to choose from, used only when initial phone number is not passed */
const prefixes = [
  { label: "AT", value: "+43" },
  { label: "BE", value: "+32" },
  { label: "CH", value: "+41" },
  { label: "DK", value: "+45" },
  { label: "ES", value: "+34" },
  { label: "FI", value: "+358" },
  { label: "FR", value: "+33" },
  { label: "IT", value: "+39" },
  { label: "LU", value: "+352" },
  { label: "SE", value: "+46" },
  { label: "", value: "---" },
  { label: "AD", value: "+376" },
  { label: "BA", value: "+387" },
  { label: "BG", value: "+359" },
  { label: "CZ", value: "+420" },
  { label: "DE", value: "+49" },
  { label: "EE", value: "+372" },
  { label: "GB", value: "+44" },
  { label: "GR", value: "+30" },
  { label: "HR", value: "+385" },
  { label: "HU", value: "+36" },
  { label: "IE", value: "+353" },
  { label: "IS", value: "+354" },
  { label: "LI", value: "+423" },
  { label: "LT", value: "+370" },
  { label: "LV", value: "+371" },
  { label: "MC", value: "+377" },
  { label: "ME", value: "+382" },
  { label: "MK", value: "+389" },
  { label: "NL", value: "+31" },
  { label: "NO", value: "+47" },
  { label: "PL", value: "+48" },
  { label: "PT", value: "+351" },
  { label: "RO", value: "+40" },
  { label: "RS", value: "+381" },
  { label: "SI", value: "+386" },
  { label: "SK", value: "+421" },
  { label: "SM", value: "+378" },
] as const;

type PrefixCountryCode = (typeof prefixes)[number]["label"];

export type PhoneResult = {
  phoneNumber: string;
  valid: boolean;
};

export interface PhoneNumberInputProps {
  /** Phone number initial value */
  phoneNumber?: string;
  /** Phone number prefix initial value based on the country code */
  initialCountryCode?: PrefixCountryCode;
  /** Sets input in immutable state, i.e. initial value cannot be changed if it passes validation */
  immutable?: boolean;
  /** Sets input in disabled state, e.g. when parent is loading */
  disabled?: boolean;
  /** Callback for when a value of an input changes */
  onChange: (result: PhoneResult) => void;
  /** Callback for phone number validation */
  onValidate: (phoneNumber: string) => boolean;
  translations: {
    /** Phone number prefix input label, e.g. "Country code" */
    prefixLabel: string;
    /** Phone number input label */
    phoneNumberLabel: string;
    /** Text that indicates international format, e.g. "International format, begins with +" */
    helperTextInternational: string;
    /** Text shown below the inputs, e.g. "You will receive tracking updates via SMS" */
    helperText?: string;
  };
}

/**
 * Renders phone number inputs. Needs a parent <Grid container> component.
 */
export const PhoneNumberInput: FC<PhoneNumberInputProps> = (props) => {
  const [phoneNumberState, setPhoneNumberState] = useState({
    prefix: prefixes.find((p) => p.label === props.initialCountryCode)?.value || "", // country code
    number: "", // actual phone number without prefix
    fullNumber: {
      value: props.phoneNumber ?? "",
      valid: props.phoneNumber === undefined ? undefined : props.onValidate(props.phoneNumber),
    },
  });

  // If initial value set immutable does not pass validation, allow editing it, and
  // set immutable state false on edit to control input's disabled state correctly
  const [immutable, setImmutable] = useState(props.immutable || false);
  const [disabled, setDisabled] = useState(props.disabled || false);

  useEffect(() => {
    props.onChange({
      phoneNumber: phoneNumberState.fullNumber.value,
      // Validate also on initial load
      valid: !!phoneNumberState.fullNumber.valid,
    });
  }, [phoneNumberState]);

  useEffect(() => {
    setDisabled(props.disabled || false);
  }, [props.disabled]);

  const onPrefixChange = (event: SelectChangeEvent<unknown>) => {
    const prefix = event.target.value as string;

    setPhoneNumberState((prev) => ({
      ...prev,
      prefix,
      fullNumber: {
        value: `${prefix}${prev.number}`,
        valid: props.onValidate(`${prefix}${prev.number.replace(/^0/, "")}`),
      },
    }));
  };

  const onNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const number = event.target.value.replace(/\D/g, "");

    setPhoneNumberState((prev) => {
      const fullNumber = `${prev.prefix}${number.replace(/^0/, "")}`;
      return {
        ...prev,
        number,
        fullNumber: {
          value: fullNumber,
          valid: !!phoneNumberState.prefix && props.onValidate(fullNumber),
        },
      };
    });
  };

  const onFullNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fullNumber = event.target.value.replace(/[^+\d]/g, "");

    setImmutable(false);
    setPhoneNumberState((prev) => ({
      ...prev,
      fullNumber: {
        value: fullNumber,
        valid: props.onValidate(fullNumber),
      },
    }));
  };

  if (props.phoneNumber) {
    return (
      <>
        <Grid item mobile={12}>
          <TextField
            fullWidth
            id="fullNumber"
            type="tel"
            inputProps={{ "data-testid": "fullNumber" }}
            value={phoneNumberState.fullNumber.value}
            error={
              phoneNumberState.fullNumber.valid === undefined
                ? undefined
                : !phoneNumberState.fullNumber.valid
            }
            label={props.translations.phoneNumberLabel}
            onChange={onFullNumberChange}
            disabled={disabled || (immutable && !!phoneNumberState.fullNumber.valid)}
          />
        </Grid>
        {(!phoneNumberState.fullNumber.valid || props.translations.helperText) && (
          <Grid item mobile={12}>
            <Typography variant="caption" display="block">
              {!phoneNumberState.fullNumber.valid && props.translations.helperTextInternational}
            </Typography>
            <Typography variant="caption" display="block">
              {props.translations.helperText}
            </Typography>
          </Grid>
        )}
      </>
    );
  }

  return (
    <>
      <Grid item mobile={5}>
        <Select
          fullWidth
          id="prefix"
          name="prefix"
          inputProps={{ "data-testid": "prefix" }}
          value={phoneNumberState.prefix}
          onChange={onPrefixChange}
          disabled={disabled || (immutable && !!phoneNumberState.fullNumber.valid)}
          menuItems={
            prefixes.map((prefix) => ({
              value: prefix.value,
              children: `${prefix.label} ${prefix.value}`,
            })) || []
          }
          label={props.translations.prefixLabel}
        />
      </Grid>
      <Grid item mobile={7}>
        <TextField
          fullWidth
          id="number"
          name="number"
          type="tel"
          inputProps={{ "data-testid": "number" }}
          value={phoneNumberState.number}
          error={
            phoneNumberState.fullNumber.valid === undefined
              ? undefined
              : !phoneNumberState.fullNumber.valid
          }
          label={props.translations.phoneNumberLabel}
          onChange={onNumberChange}
          disabled={disabled || (immutable && !!phoneNumberState.fullNumber.valid)}
        />
      </Grid>
      {props.translations.helperText && (
        <Grid item mobile={12}>
          <Typography variant="caption" display="block">
            {props.translations.helperText}
          </Typography>
        </Grid>
      )}
    </>
  );
};
