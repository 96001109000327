import type { FC } from "react";

import type { InvoicesProps } from "./Invoices";
import type { SubscriptionsProps } from "./Subscriptions";

import { SwipeableTabs } from "../../Tab/SwipeableTabs";
import { Invoices } from "./Invoices";
import { Subscriptions } from "./Subscriptions";

export interface MyAccountOrdersProps {
  subscriptions: SubscriptionsProps["subscriptions"];
  invoices: InvoicesProps["invoices"];
  translations: {
    subscriptions: SubscriptionsProps["translations"];
    invoices: InvoicesProps["translations"];
  };
}

export const MyAccountOrders: FC<MyAccountOrdersProps> = (props) => (
  <SwipeableTabs
    tabs={[
      {
        label: props.translations.subscriptions.subscriptionsTitle,
        component: (
          <Subscriptions
            subscriptions={props.subscriptions}
            translations={props.translations.subscriptions}
          />
        ),
      },
      {
        label: props.translations.invoices.invoicesTitle,
        component: (
          <Invoices invoices={props.invoices} translations={props.translations.invoices} />
        ),
      },
    ]}
  />
);
