import type { FC } from "react";

import { CloseRound } from "@augment-frontend/mui-icons";
import { IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { DatePickerProps } from "../../../../../../DatePicker";

import { DatePicker } from "../../../../../../DatePicker";

export interface ReceiverProps {
  /** Callback for event when end date is changed */
  onChange: (endDate: Date) => void;
  /** Callback for event when receiver is removed */
  onRemove: () => void;
  /** Name of the receiver */
  name: string;
  /** Start date of the share right */
  startDate: Date;
  /** End date of the share right. Can be null initially, but can't be changed to null afterwards */
  endDate: Date | null;
  /** Disable DatePicker i.e. during loading */
  disabled?: boolean;
  translations: {
    /** E.g. "Start date" */
    startDate: DatePickerProps["translations"]["label"];
    /** E.g. "End date" */
    endDate: DatePickerProps["translations"]["label"];
  };
}

export const Receiver: FC<ReceiverProps> = (props) => {
  const theme = useTheme();

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginBottom={theme.spacing(2)}
      >
        <Typography variant="bodyText">{props.name}</Typography>
        <IconButton
          onClick={props.onRemove}
          disabled={props.disabled}
          sx={{ ":disabled": { opacity: 0.5 } }}
        >
          <CloseRound />
        </IconButton>
      </Stack>
      <Stack
        direction={{ mobile: "column", tablet: "row" }}
        justifyContent="space-between"
        spacing={theme.spacing(2)}
        data-testid="shareAccessDates"
      >
        <DatePicker
          onChange={() => {}}
          disabled={true}
          value={props.startDate}
          translations={{
            label: props.translations.startDate,
          }}
        />
        <DatePicker
          onChange={(date) => {
            if (date) {
              date.setHours(23, 59, 59, 999);
              props.onChange(date);
            }
          }}
          disabled={props.disabled}
          disablePast
          value={props.endDate}
          translations={{
            label: props.translations.endDate,
          }}
        />
      </Stack>
    </>
  );
};
