import type { FC } from "react";

import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { BoxProps } from "@mui/material";

export interface StarsProps extends BoxProps {
  count: number;
}

export const Stars: FC<StarsProps> = (props) => {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={theme.spacing(0.5)}>
      {[...Array(Math.round(props.count))].map((_, index) => (
        <Box key={index} {...props}>
          ★
        </Box>
      ))}
    </Stack>
  );
};
