import { Fragment, useEffect, useState } from "react";

import { AlertRound } from "@augment-frontend/mui-icons";
import { Button, Grid, Stack, Typography } from "@mui/material";

import type { ScrollModalProps } from "../../../../Modal";

import { ScrollModal } from "../../../../Modal";
import { RadioGroupCardColumn } from "../../../../Radio";

interface AddressValidationModalTranslations {
  acceptButtonLabel: string;
  cancelButtonLabel: string;
  myAddressLabel: string;
  suggestedLabel: string;
  addressSuggestionLabel: string;
  errors: {
    noHouseNumberMessage: string;
    nonMainlandSpain: string;
    partialMatchingMessage: string;
    addressNotFoundMessage: string;
    wrongCountryMessage: string;
    unsupportedProvince: string;
  };
}
export interface AddressValidationModalProps extends Omit<ScrollModalProps, "children"> {
  onAccept: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, isAccepted: boolean) => void;
  onCancel: () => void;
  /** E.g. "There is no house number" */
  errorMessage?: string;
  /** E.g. "Invalid address" */
  warningMessage?: string;
  originalFormatedAddress?: string;
  suggestedFormatedAddress?: string;
  translations: AddressValidationModalTranslations;
}

/**
 * Modal that renders address inputs. Needs a parent <Grid container> component.
 */
export const AddressValidationModal = (props: AddressValidationModalProps) => {
  const missingSuggestion =
    !props.suggestedFormatedAddress ||
    props.suggestedFormatedAddress === props.originalFormatedAddress ||
    props.errorMessage;
  const [value, setValue] = useState<string>(missingSuggestion ? "original" : "suggested");
  const [warningMessage, setWarningMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (props.errorMessage !== undefined) {
      setErrorMessage(props.errorMessage);
    }
    if (props.warningMessage !== undefined) {
      setWarningMessage(props.warningMessage);
    }
  }, [props.errorMessage, props.warningMessage]);

  useEffect(() => {
    setValue(missingSuggestion ? "original" : "suggested");
  }, [props.suggestedFormatedAddress, props.originalFormatedAddress, props.errorMessage]);

  /**
   * @param address - Address to format
   * @returns Address with line breaks based on commas
   */
  const formatAddressLineBreaks = (address?: string) => {
    return address?.split(",").map((line, index) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ));
  };

  const optionJSXElement = (label?: string, address?: string) => {
    return (
      <>
        <Typography variant="bodyTextBold">{label}:</Typography>
        <Typography variant="bodyText" sx={{ fontSize: { mobile: "0.8rem", tablet: "1rem" } }}>
          {formatAddressLineBreaks(address)}
        </Typography>
      </>
    );
  };

  const onAccept = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    props.onAccept(event, value === "suggested");
  };
  const options = [
    {
      value: "original",
      content: optionJSXElement(props.translations.myAddressLabel, props.originalFormatedAddress),
    },
    ...(!missingSuggestion
      ? [
          {
            value: "suggested",
            content: optionJSXElement(
              props.translations.suggestedLabel,
              props.suggestedFormatedAddress
            ),
          },
        ]
      : []),
  ];

  return (
    <ScrollModal open={props.open} onClose={props.onClose} sx={{ maxWidth: "500px" }}>
      <Grid container>
        <Grid item mobile={12} marginBottom={2} textAlign="center">
          <Typography variant="subheadingBold">
            {props.translations.addressSuggestionLabel}
          </Typography>
        </Grid>
        <Grid item mobile={12}>
          <RadioGroupCardColumn initialValue={value} options={options} onChange={setValue} />
        </Grid>
        {errorMessage || warningMessage ? (
          <Stack mt={2} direction="row" spacing={1}>
            <AlertRound color={errorMessage ? "error" : "warning"} />
            <Typography mt={2} variant="bodyText">
              {errorMessage || warningMessage}
            </Typography>
          </Stack>
        ) : null}

        <Grid container mt={2}>
          <Grid item mobile={errorMessage ? 12 : 6} padding={1}>
            <Button color="secondary" fullWidth onClick={props.onCancel}>
              {props.translations.cancelButtonLabel}
            </Button>
          </Grid>
          {!errorMessage && (
            <Grid item mobile={6} padding={1}>
              <Button
                data-testid="acceptButton"
                fullWidth
                onClick={onAccept}
                color={warningMessage ? "warning" : "primary"}
              >
                {props.translations.acceptButtonLabel}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </ScrollModal>
  );
};
