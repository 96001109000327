import { Box, Container, Grid, Link, List, ListItem, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { AppBadgesProps } from "../AppBadges/AppBadges";
import type { LocaleViewerProps } from "../Locale";
import type { SocialMediaProps } from "../SocialMedia";
import type { LinkProps } from "@mui/material";

import { AppBadges } from "../AppBadges/AppBadges";
import { LocaleViewer } from "../Locale";
import { SocialMedia } from "../SocialMedia";

export interface FooterProps<T extends React.ElementType> {
  /** Augment logo image */
  logo: React.ReactNode;
  /** Trustpilot widget */
  trustpilot?: React.ReactNode;
  localeViewer: LocaleViewerProps<T>;
  socialMedia: SocialMediaProps;
  /** Array of link lists */
  linkLists: {
    /** Title of the link list, rendered capitalized */
    title: string;
    /** Array of links */
    links: LinkProps[];
  }[];
  /** Links shown at the bottom of the footer, before copyright */
  bottomLinks: LinkProps[];
  translations: {
    appBadgesTitle: AppBadgesProps["title"];
    /** Copyright text, e.g. All Rights Reserved. */
    copyright: string;
  };
}

export const Footer = <T extends React.ElementType>(props: FooterProps<T>) => {
  const theme = useTheme();

  const localeViewerLabelSx = {
    color: theme.palette.text.secondary,
  };

  return (
    <Box component="footer" sx={{ backgroundColor: theme.palette.secondary.A200 }}>
      <Container
        disableGutters
        sx={{
          backgroundColor: theme.palette.secondary.A200,
          py: { mobile: 3, tablet: 9 },
          px: { mobile: 3, tablet: 0 },
          maxWidth: {
            mobile: "100%",
            tablet: `${theme.breakpoints.values.tablet - 2 * 64}px`,
            laptop: `${theme.breakpoints.values.laptop - 2 * 144}px`,
          },
        }}
      >
        <Stack direction="column">
          {/* Logos on mobile */}
          <Box
            sx={{
              display: { mobile: "flex", tablet: "none" },
              justifyContent: "flex-start",
            }}
          >
            <Box maxWidth="180px">
              <Box sx={{ mb: 2 }}>{props.logo}</Box>
              {props.trustpilot}
            </Box>
          </Box>
          {/* All */}
          <Stack
            direction={{ mobile: "column", tablet: "row" }}
            spacing={{ tablet: 6, laptop: 12 }}
            mb={4}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { mobile: "row", tablet: "column" },
                justifyContent: "flex-start",
                mt: { tablet: 2.75, laptop: 0 },
                mb: { mobile: 2, tablet: 0 },
              }}
            >
              {/* Logos on tablet & laptop */}
              <Box sx={{ display: { mobile: "none", tablet: "block" }, width: "200px" }}>
                <Box sx={{ mb: 2 }}>{props.logo}</Box>
                {props.trustpilot}
              </Box>
              {/* All */}
              {/* Negative margin to compensate for Trustpilot widget's fixed height */}
              <Box mt={props.trustpilot ? { mobile: -4, tablet: -2 } : 2}>
                <AppBadges compact title={props.translations.appBadgesTitle.toUpperCase()} />
              </Box>
            </Box>
            <Grid container rowSpacing={2}>
              {props.linkLists.map((linkList, linkListIndex) => (
                <Grid key={linkListIndex} item mobile={6} laptop={4}>
                  <Typography
                    variant="captionBold"
                    sx={{ color: theme.palette.text.secondary, display: "block", mb: 1.25 }}
                  >
                    {linkList.title.toUpperCase()}
                  </Typography>
                  <Box component="nav">
                    <List sx={{ p: 0 }}>
                      {linkList.links.map((link, linkIndex) => (
                        <ListItem key={linkIndex} sx={{ mb: theme.spacing(1.25), p: 0 }}>
                          <Link
                            key={linkIndex}
                            {...link}
                            variant="captionSemibold"
                            sx={{
                              color: theme.palette.text.secondary,
                              textDecoration: "none",
                              pr: 2,
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Stack>
          <Stack
            direction="row"
            sx={{
              borderTop: `1px solid ${theme.palette.secondary.light}`,
              borderBottom: `1px solid ${"transparent"}`,
              justifyContent: { mobile: "center", tablet: "space-between" },
            }}
          >
            <Box sx={{ display: { mobile: "none", tablet: "flex" } }}>
              <LocaleViewer {...props.localeViewer} labelSx={localeViewerLabelSx} />
            </Box>
            <SocialMedia {...props.socialMedia} />
          </Stack>
          <Box
            sx={{ display: { mobile: "flex", tablet: "none" }, justifyContent: "center", mt: 4 }}
          >
            <LocaleViewer
              {...props.localeViewer}
              labelSx={localeViewerLabelSx}
              localeSelector={{
                ...props.localeViewer.localeSelector,
                boxProps: { pb: 6 },
              }}
            />
          </Box>
          <Stack
            direction={{ mobile: "column", tablet: "row" }}
            spacing={theme.spacing(1.25)}
            justifyContent="center"
            alignItems="center"
            mt={{ mobile: 3, tablet: 4 }}
            mb={{ mobile: 2, tablet: 0 }}
          >
            {props.bottomLinks.map((link, index) => (
              <Link
                key={index}
                {...link}
                variant="captionSemibold"
                sx={{ color: theme.palette.text[800], textDecoration: "none" }}
              />
            ))}
          </Stack>
          <Typography
            variant="caption"
            textAlign="center"
            sx={{ mt: 1, color: theme.palette.text[800] }}
          >
            © 2020 - {new Date().getFullYear()} Augment. {props.translations.copyright}
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
};
