import type { FC } from "react";
import { useState } from "react";

import { Copy, Done } from "@augment-frontend/mui-icons";
import { useTheme } from "@mui/material/styles";

import { TextField } from "../../../../../TextField";

export interface CopyFieldProps {
  /** E.g. "https://augment.eco/?action=consume_otp_token&bt_mac=b2:a1:b6:a1:a1:a1&token=nsd2d281bababas" */
  textToCopy: string;
}

export const CopyField: FC<CopyFieldProps> = (props) => {
  const [success, setSuccess] = useState(false);
  const theme = useTheme();

  return (
    <TextField
      fullWidth
      multiline
      success={success}
      value={props.textToCopy}
      onClick={async () => {
        await navigator.clipboard.writeText(props.textToCopy);
        setSuccess(true);
      }}
      InputProps={{
        inputProps: {
          style: { fontSize: "large", padding: 0, cursor: success ? "text" : "pointer" },
          disabled: true,
          "data-testid": "copyField",
        },
        endAdornment: success ? (
          <Done sx={{ color: theme.palette.success.main }} />
        ) : (
          <Copy sx={{ color: theme.palette.text.primary, cursor: "pointer" }} />
        ),
      }}
      sx={{ marginBottom: theme.spacing(4) }}
    />
  );
};
