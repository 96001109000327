import type { FC } from "react";
import { useState } from "react";

import { ArrowDiagonalRightUp, ArrowDown } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Surface } from "../../Surface";
import { getStatusColor, StatusType } from "../util";

export interface InvoiceProps {
  id: string;
  status: string;
  statusType: StatusType;
  date: string;
  /** IDs of related orders of the invoice. */
  relatedOrders: string[];
  amount: string;
  paymentMethod?: string;
  /**
   * E.g. Gatsby Link or a component rendered in button for invoice action.
   * The button will be rendered only if actionComponent or onActionClick is provided.
   */
  actionComponent?: React.ElementType;
  /** Disable loading button associated with actionComponent */
  actionComponentDisabled?: boolean;
  /**
   * Called when invoice action is triggered. Error is catched.
   * The button will be rendered only if actionComponent or onActionClick is provided.
   */
  onActionClick?: () => Promise<void>;
  translations: {
    typeTitle: string;
    typeContent: string;
    dateTitle: string;
    relatedOrdersTitle: string;
    amountTitle: string;
    paymentMethodTitle: string;
    actionTitleDownload?: string;
    actionTitlePayNow?: string;
  };
}

export const Invoice: FC<InvoiceProps> = (props) => {
  const [actionLoading, setActionLoading] = useState(false);
  const theme = useTheme();
  const typographyColor = theme.palette.text.primary;

  return (
    <>
      <Surface
        sx={{
          bgcolor: theme.palette.secondary[200],
          border: `1px solid ${theme.palette.text.primary}`,
          padding: {
            tablet: theme.spacing(3),
          },
        }}
      >
        <Surface
          sx={{
            display: "flex",
            bgcolor: {
              mobile: theme.palette.secondary[200],
              tablet: theme.palette.secondary.light,
            },
            padding: 0,
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="largeTextBold">&#8470; {props.id}</Typography>
          <Typography
            variant="bodyTextBold"
            color={getStatusColor(
              props.actionComponentDisabled ? StatusType.Neutral : props.statusType,
              theme
            )}
            sx={{
              "&:before": {
                content: '"\\2022"',
              },
            }}
          >
            &nbsp;
            {props.status}
          </Typography>
        </Surface>
        <Grid container spacing={{ mobile: 1.5, tablet: 2 }}>
          <Grid
            item
            mobile={12}
            tablet={4}
            display="flex"
            component="div"
            sx={{
              justifyContent: { mobile: "space-between", tablet: "flex-start" },
              flexDirection: { tablet: "column" },
            }}
          >
            <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
              {props.translations.typeTitle}
            </Typography>
            <Typography variant="bodyTextBold">{props.translations.typeContent}</Typography>
          </Grid>
          <Grid
            item
            mobile={12}
            tablet={4}
            display="flex"
            component="div"
            sx={{
              justifyContent: { mobile: "space-between", tablet: "flex-start" },
              flexDirection: { tablet: "column" },
            }}
          >
            <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
              {props.translations.dateTitle}
            </Typography>
            <Typography variant="bodyTextBold">{props.date}</Typography>
          </Grid>
          <Grid
            item
            mobile={12}
            tablet={4}
            display="flex"
            component="div"
            sx={{
              justifyContent: { mobile: "space-between", tablet: "flex-start" },
              flexDirection: { tablet: "column" },
            }}
          >
            <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
              {props.translations.relatedOrdersTitle}
            </Typography>
            <div>
              {props.relatedOrders.map((relatedOrder) => (
                <Typography key={relatedOrder} variant="bodyTextBold">
                  {relatedOrder}
                </Typography>
              ))}
            </div>
          </Grid>
          <Grid
            item
            mobile={12}
            tablet={4}
            display="flex"
            component="div"
            sx={{
              justifyContent: { mobile: "space-between", tablet: "flex-start" },
              flexDirection: { tablet: "column" },
            }}
          >
            <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
              {props.translations.amountTitle}
            </Typography>
            <Typography variant="bodyTextBold">{props.amount}</Typography>
          </Grid>
          {/* TODO invoice payment method */}
          {props.paymentMethod && (
            <Grid
              item
              mobile={12}
              tablet={4}
              display="flex"
              component="div"
              sx={{
                justifyContent: { mobile: "space-between", tablet: "flex-start" },
                flexDirection: { tablet: "column" },
              }}
            >
              <Typography color={typographyColor} mb={{ tablet: 0.5 }}>
                {props.translations.paymentMethodTitle}
              </Typography>
              <Typography
                variant="bodyTextBold"
                sx={{ textAlign: { mobile: "right", tablet: "left" } }}
              >
                {props.paymentMethod}
              </Typography>
            </Grid>
          )}
          {(props.actionComponent || props.onActionClick) && (
            <Grid
              item
              mobile={12}
              tablet={4}
              display="flex"
              component="div"
              sx={{
                justifyContent: { mobile: "space-between", tablet: "flex-start" },
                flexDirection: { tablet: "column" },
              }}
            >
              <LoadingButton
                fullWidth
                variant="outlined"
                color={
                  props.statusType === StatusType.Positive || props.actionComponentDisabled
                    ? "inherit"
                    : "error"
                }
                disabled={props.actionComponentDisabled}
                sx={{ display: "flex", justifyContent: "space-between" }}
                endIcon={
                  props.statusType === StatusType.Positive ? (
                    <ArrowDown sx={{ color: "inherit" }} />
                  ) : (
                    <ArrowDiagonalRightUp sx={{ color: "inherit" }} />
                  )
                }
                loading={actionLoading}
                onClick={async () => {
                  if (props.onActionClick && !props.actionComponentDisabled) {
                    setActionLoading(true);
                    try {
                      await props.onActionClick();
                    } catch {
                      // TODO show catched error in snackbar
                    } finally {
                      setActionLoading(false);
                    }
                  }
                }}
                // @ts-expect-error TODO remove when MUI types are corrected on Button element
                component={props.actionComponent}
              >
                {props.statusType === StatusType.Positive
                  ? props.translations.actionTitleDownload
                  : props.translations.actionTitlePayNow}
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Surface>
    </>
  );
};
