import type { FC } from "react";

import { Stack } from "@mui/material";

import type { ConfigurationItem, ConfigurationItemOption } from "../../types";

import { updateOptions } from "../../utils";
import { SubHeader } from "../SubHeader";
import { ItemSection } from "./ItemSection/ItemSection";

export type OptionsProps = {
  /**
   * All configuration items (and their options, {@link ConfigurationItemOption})
   * For each configuration item, {@link ItemSection} is rendered to display individual item and their options as a RadioGroupCardRow.
   */
  configurationItems: ConfigurationItem[];
  /** Selected option of each configurationItem */
  selectedOptions: ConfigurationItemOption[];
  /** Triggered when option changes */
  onOptionChange: (
    allSelectedOptions: ConfigurationItemOption[],
    lastSelectedOption: ConfigurationItemOption
  ) => void;
  translations: {
    /** Title of the SubHeader, e.g. "Tailor your ride" */
    title: string;
  };
};

export const Options: FC<OptionsProps> = (props) => {
  const onChangeHandler = (selectedOption: ConfigurationItemOption) => {
    const allSelectedOptions = updateOptions(selectedOption, props.selectedOptions);
    props.onOptionChange(allSelectedOptions, selectedOption);
  };

  return (
    <Stack direction="column" spacing={1} id="modelOptions" component="form">
      <SubHeader title={props.translations.title} />
      <Stack direction="column" spacing={2}>
        {[...props.configurationItems].map((configurationItem, index) => (
          <ItemSection
            {...configurationItem}
            key={index}
            id={configurationItem.id}
            initialValue={props.selectedOptions.find((value) => value.key === configurationItem.id)}
            isEqualValue={(a, b) => a.value === b?.value}
            onChange={onChangeHandler}
            options={configurationItem.itemOptions.map((itemOption) => ({
              ...itemOption,
              value: itemOption,
              content: itemOption.content,
            }))}
          />
        ))}
      </Stack>
    </Stack>
  );
};
