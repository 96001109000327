import { useState } from "react";

import { Info } from "@augment-frontend/mui-icons";
import { Grid, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { ScrollModal } from "../../Modal";

export interface InfoBoxProps {
  /** Title of the info e.g. "How it works?" */
  title: string;
  /** Content of the info e.g. <Typography>Learn more on how our products work</Typography> */
  content: React.ReactNode;
  /** Content of the modal opened when clicking icon e.g. <Typography>Learn more on how our products work</Typography> */
  modalContent?: React.ReactNode;
  /** Loading status of the component, replace content with skeleton if present */
  loading?: boolean;
}

export const InfoBox = (props: InfoBoxProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Stack
        direction="column"
        spacing={{ mobile: theme.spacing(1), tablet: theme.spacing(2) }}
        data-testid="infoBoxComponent"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Typography variant="largeTextBold">{props.title}</Typography>
          {props.modalContent && (
            <IconButton
              onClick={() => setModalOpen(true)}
              sx={{ color: theme.palette.text.primary, margin: theme.spacing(-1) }}
            >
              <Info sx={{ color: "inherit" }} />
            </IconButton>
          )}
        </Stack>
        {props.loading ? (
          <div>
            {Array.from({ length: 5 }, (x, index) => (
              <Skeleton key={index} variant="text" width={`${85 + (index % 1.7) * 10}%`} />
            ))}
          </div>
        ) : (
          props.content
        )}
      </Stack>
      {/* Modal */}
      <ScrollModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        sx={{ backgroundColor: theme.palette.primary[600] }}
      >
        <Grid container>
          <Grid item mobile={12} textAlign="center">
            <Typography variant="subheadingBold" mb={2}>
              {props.title}
            </Typography>
            {props.modalContent}
          </Grid>
        </Grid>
      </ScrollModal>
    </>
  );
};
