import type { FC, ReactNode } from "react";

import { Modal } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { BoxProps, ModalProps } from "@mui/material";

import { ScrollbarBox } from "../Layout/ScrollbarBox";

export type ScrollModalProps = {
  /** View to show in modal */
  children: ReactNode;
} & Pick<ModalProps, "open" | "onClose" | "keepMounted"> &
  Pick<BoxProps, "sx">;

/**
 * Simple modal wrapper with scrollbar in case the content is long.
 */
export const ScrollModal: FC<ScrollModalProps> = (props) => {
  const theme = useTheme();

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      keepMounted={props.keepMounted}
      slotProps={{ backdrop: { sx: { background: "rgba(0, 0, 0, 0.6)" } } }}
      id="scroll-modal"
    >
      <ScrollbarBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 400,
          bgcolor: theme.palette.background.paper,
          borderRadius: `${theme.shape.borderRadius}px`,
          p: 2.5,
          boxSizing: "border-box",
          maxHeight: "90%",
          overflow: "auto",
          ...props.sx,
        }}
      >
        {props.children}
      </ScrollbarBox>
    </Modal>
  );
};
