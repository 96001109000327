import { Box, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { BoxProps, TypographyProps } from "@mui/material";

import { formatText } from "../../utils";

export interface HeadingContentProps {
  /** Component shown on top of the text content; text e.g. "We will always keep you moving" */
  title: string | JSX.Element;
  /** Title style; applied to titles of type 'string' */
  titleSx?: TypographyProps["sx"];
  /** Title element type for SEO */
  titleComponent?: React.ElementType;
  /** Reduce title size, e.g. in leveled <BlockTextImagePair/> */
  titleSizeReduced?: boolean;
  /** Component shown as the 2nd element of the text content; longer text fitting for SEO */
  description?: string | JSX.Element;
  /** Adds margin below divider, e.g. in block Hero */
  dividerExtraMargin?: boolean;
  /** Props for the heading content container */
  boxProps?: BoxProps;
  /** Heading content is centered on tablet and laptop viewports */
  centered?: boolean;
  /** Content below divider, e.g. contact info. */
  bottomContent?: React.ReactNode;
  /** Set {@link description}'s marginTop to 0, otherwise use HeadingContent's default value */
  removeDescriptionMt?: boolean;
}
export const Title = styled(Typography, {
  shouldForwardProp: (prop) => {
    if (prop === "titleSizeReduced") {
      return false;
    }
    return true;
  },
})<
  TypographyProps & {
    component: HeadingContentProps["titleComponent"];
    titleSizeReduced?: HeadingContentProps["titleSizeReduced"];
  }
>((props) => {
  if (props.titleSizeReduced) {
    return {
      ...props.theme.typography.h3Bold,
    };
  }

  return {
    ...props.theme.typography.h2Bold,
  };
});

export const HeadingContent = (props: HeadingContentProps) => {
  return (
    <Box position="relative">
      <Box
        {...props.boxProps}
        sx={{
          ...props.boxProps?.sx,
          mx: { tablet: props.centered ? "auto" : undefined },
          textAlign: { tablet: props.centered ? "center" : undefined },
        }}
      >
        {typeof props.title === "object" ? (
          props.title
        ) : (
          <Title
            component={props.titleComponent}
            titleSizeReduced={props.titleSizeReduced}
            sx={{
              ...props.titleSx,
              wordBreak: {
                mobile: "break-word",
                tablet: "unset",
              },
            }}
          >
            {formatText(props.title)}
          </Title>
        )}
        {props.description && typeof props.description === "object" && (
          <Box
            mt={props.removeDescriptionMt ? 0 : 2}
            sx={props.removeDescriptionMt ? { "&>p:first-of-type": { mt: 0 } } : {}}
          >
            {props.description}
          </Box>
        )}
        {props.description && typeof props.description === "string" && (
          <Typography mt={props.removeDescriptionMt ? 0 : 2}>{props.description}</Typography>
        )}
        <Divider
          sx={{
            mt: { mobile: 1, tablet: typeof props.description === "object" ? 1 : 2 },
            mb: { tablet: props.dividerExtraMargin ? 4 : undefined },
            mx: { tablet: props.centered ? "auto" : undefined },
            width: { mobile: "33%", tablet: "20%" },
            borderWidth: 1,
            // Border is drawn transparent to keep layout exact for both themes
            borderColor: "transparent",
          }}
        />
        {props.bottomContent}
      </Box>
    </Box>
  );
};
