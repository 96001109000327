import type { FC } from "react";
import { useState } from "react";

import { Lock, VisibilityOff, VisibilityOn } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Box, InputAdornment, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { ProgrammaticModalProps } from "../../../../Modal";

import { validatePassword } from "../../../../../utils/password";
import { Alert } from "../../../../Alert";
import { ProgrammaticModal } from "../../../../Modal";
import { TextField } from "../../../../TextField";

export interface PasswordModalProps {
  /**
   * Triggered when new password is submitted.
   * Thrown error is catched and its message is shown.
   */
  onPasswordSubmit: (oldPassword: string, newPassword: string) => Promise<Error | void>;
  onClose: NonNullable<ProgrammaticModalProps["onClose"]>;
  /**
   * Triggered when operation succeeded.
   */
  onCompleted: () => void;
  open: ProgrammaticModalProps["open"];
  translations: {
    /** E.g. "Change password" */
    passwordTitle: string;
    /** E.g. "Min. 6 characters including one number" */
    passwordHint: string;
    /**
     * E.g. "Passwords do not match".
     * This illustrates a scenario when customer mistyped the repeated password.
     */
    passwordsDoNotMatch: string;
    /** E.g. "Current password" */
    oldPasswordLabel: string;
    /** E.g. "New password" */
    newPasswordLabel: string;
    /** E.g. "Re-enter password" */
    newPasswordRepeatLabel: string;
    /** E.g. "Save" */
    passwordButton: string;
  };
}

const initialState = {
  oldPassword: "",
  oldPasswordError: false,
  newPassword: "",
  newPasswordError: false,
  newPasswordRepeat: "",
  newPasswordRepeatError: false,
  newPasswordRepeatHelperText: "",
  loading: false,
};

export const PasswordModal: FC<PasswordModalProps> = (props) => {
  const [index, setIndex] = useState(0);
  const [passwordsVisible, setPasswordsVisible] = useState<boolean>(false);
  const [password, setPassword] = useState<typeof initialState>(initialState);
  const [onSubmitError, setOnSubmitError] = useState<string>("");
  const theme = useTheme();

  const reset = () => {
    setIndex(0);
    setPassword(initialState);
  };

  const handlePasswordChange =
    (field: "oldPassword" | "newPassword" | "newPasswordRepeat") =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Trim whitespace from start (Cognito supports whitespace in the middle of password)
      const value = event.target.value.trimStart();
      const error = !validatePassword(value);

      setPassword((prev) => {
        let { newPasswordRepeatHelperText } = prev;

        if (field === "newPassword" || field === "newPasswordRepeat") {
          if (
            (field === "newPassword" && value !== prev.newPasswordRepeat) ||
            (field === "newPasswordRepeat" && value !== prev.newPassword)
          ) {
            newPasswordRepeatHelperText = props.translations.passwordsDoNotMatch;
          } else {
            newPasswordRepeatHelperText = "";
          }
        }

        return {
          ...prev,
          [field]: value,
          [`${field}Error`]: error,
          newPasswordRepeatHelperText,
        };
      });
    };

  const handlePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnSubmitError("");
    setPassword((prev) => ({ ...prev, loading: true }));
    try {
      await props.onPasswordSubmit(password.oldPassword, password.newPassword);
      props.onCompleted();
      reset();
    } catch (err) {
      setOnSubmitError(err instanceof Error ? err.message : "Unknown error");
    }
    setPassword((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  return (
    <ProgrammaticModal
      open={props.open}
      index={index}
      onClose={(event, reason) => {
        props.onClose(event, reason);
        reset();
      }}
      views={[
        <Box
          key="password"
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          component="form"
          onSubmit={handlePasswordSubmit}
        >
          <Lock sx={{ fontSize: "64px", mb: 1 }} />
          <Typography variant="subheadingBold" textAlign="center" sx={{ mb: 3 }}>
            {props.translations.passwordTitle}
          </Typography>
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            type={passwordsVisible ? "text" : "password"}
            label={props.translations.oldPasswordLabel}
            value={password.oldPassword}
            error={password.oldPasswordError}
            success={password.oldPassword.length > 0 && !password.oldPasswordError}
            disabled={password.loading}
            onChange={handlePasswordChange("oldPassword")}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setPasswordsVisible((prev) => !prev)}
                  sx={{ cursor: "pointer" }}
                >
                  {passwordsVisible ? (
                    <VisibilityOff sx={{ color: theme.palette.text.primary }} />
                  ) : (
                    <VisibilityOn sx={{ color: theme.palette.text.primary }} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            type={passwordsVisible ? "text" : "password"}
            label={props.translations.newPasswordLabel}
            value={password.newPassword}
            error={password.newPasswordError}
            success={password.newPassword.length > 0 && !password.newPasswordError}
            disabled={password.loading}
            onChange={handlePasswordChange("newPassword")}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setPasswordsVisible((prev) => !prev)}
                  sx={{ cursor: "pointer" }}
                >
                  {passwordsVisible ? (
                    <VisibilityOff sx={{ color: theme.palette.text.primary }} />
                  ) : (
                    <VisibilityOn sx={{ color: theme.palette.text.primary }} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            type={passwordsVisible ? "text" : "password"}
            label={props.translations.newPasswordRepeatLabel}
            helperText={password.newPasswordRepeatHelperText}
            value={password.newPasswordRepeat}
            error={
              password.newPasswordRepeatError || password.newPassword !== password.newPasswordRepeat
            }
            success={password.newPasswordRepeat.length > 0 && !password.newPasswordRepeatError}
            disabled={password.loading}
            onChange={handlePasswordChange("newPasswordRepeat")}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => setPasswordsVisible((prev) => !prev)}
                  sx={{ cursor: "pointer" }}
                >
                  {passwordsVisible ? (
                    <VisibilityOff sx={{ color: theme.palette.text.primary }} />
                  ) : (
                    <VisibilityOn sx={{ color: theme.palette.text.primary }} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Typography sx={{ mb: 2 }}>{props.translations.passwordHint}</Typography>
          {onSubmitError && (
            <Alert
              severity="error"
              description={onSubmitError}
              sx={{ mb: 2, alignSelf: "stretch" }}
            />
          )}
          <LoadingButton
            type="submit"
            fullWidth
            disabled={
              password.oldPassword.length === 0 ||
              password.oldPasswordError ||
              password.newPassword.length === 0 ||
              password.newPasswordError ||
              password.newPasswordRepeat.length === 0 ||
              password.newPasswordRepeatError ||
              password.newPassword !== password.newPasswordRepeat
            }
            loading={password.loading}
          >
            {props.translations.passwordButton}
          </LoadingButton>
        </Box>,
      ]}
    />
  );
};
