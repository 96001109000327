import { createSvgIcon } from "@mui/material/utils";

import type { SvgIconProps } from "@mui/material";

export const GlobeBase = createSvgIcon(
  <>
    <path
      strokeWidth="26"
      stroke="currentColor"
      fill="transparent"
      d="M209,15a195,195 0 1,0 2,0z"
    />
    <path
      strokeWidth="18"
      stroke="currentColor"
      fill="transparent"
      d="m210,15v390m195-195H15M59,90a260,260 0 0,0 302,0 m0,240 a260,260 0 0,0-302,0M195,20a250,250 0 0,0 0,382 m30,0 a250,250 0 0,0 0-382"
    />
  </>,
  "Globe"
);

export const Globe = (props: SvgIconProps): JSX.Element => (
  <GlobeBase {...props} viewBox="0 0 420 420" />
);
