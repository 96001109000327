import type { FC } from "react";

import {
  AmericanExpress,
  ApplePay,
  CreditCard,
  DinersClub,
  Discover,
  GenericCard,
  GooglePay,
  Jcb,
  Mastercard,
  PayPal,
  Sepa,
  Visa,
} from "@augment-frontend/mui-icons";
import { Box, Button, Chip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { RadioGroupCardColumnProps } from "../../Radio";
import type { PaymentMethod, PaymentMethodBrand, PaymentMethodType } from "../types";
import type { SvgIconProps } from "@mui/material";

import { RadioGroupCardColumn } from "../../Radio";

const PAYMENT_METHOD_BRAND_LOGO: Partial<
  Record<PaymentMethodBrand, React.ElementType<SvgIconProps>>
> & { OTHER: React.ElementType<SvgIconProps> } = {
  VISA: Visa,
  MASTERCARD: Mastercard,
  AMERICAN_EXPRESS: AmericanExpress,
  DISCOVER: Discover,
  JCB: Jcb,
  DINERS_CLUB: DinersClub,
  OTHER: GenericCard,
};

const PAYMENT_METHOD_TYPE_LOGO: Partial<
  Record<PaymentMethodType, React.ElementType<SvgIconProps>>
> & {
  OTHER: React.ElementType<SvgIconProps>;
} = {
  APPLE_PAY: ApplePay,
  GOOGLE_PAY: GooglePay,
  PAYPAL: PayPal,
  SEPA_DIRECT_DEBIT: Sepa,
  OTHER: CreditCard,
};

export const getPaymentMethodLogo = (
  type: PaymentMethodType,
  brand: PaymentMethodBrand
): React.ElementType<SvgIconProps> => {
  if (type === "CARD") {
    return PAYMENT_METHOD_BRAND_LOGO[brand] || PAYMENT_METHOD_BRAND_LOGO.OTHER;
  }

  return PAYMENT_METHOD_TYPE_LOGO[type] || PAYMENT_METHOD_TYPE_LOGO.OTHER;
};

/**
 * Render given payment methods in a list.
 * Uses {@link RadioGroupCardColumn} under the hood, to allow wasy selection of a payment method.
 * Delete action of payment method may be provided optionally, allowing to delete payment method.
 */
export interface PaymentMethodListProps {
  /** Will be rendered as list. */
  paymentMethods: PaymentMethod[];
  onChange:
    | NonNullable<RadioGroupCardColumnProps<PaymentMethod>["onChange"]>
    | NonNullable<RadioGroupCardColumnProps<PaymentMethod>["onChangeAsync"]>;
  /**
   * Called when delete button is clicked.
   * The button is rendered only if:
   * 1. this prop is provided and
   * 2. translations.deleteButton is provided and
   * 3. payment method is not primary or backup.
   */
  onDeleteClick?: (paymentMethod: PaymentMethod) => void;
  /** Disable selection in the list. */
  disabled?: boolean;
  translations: {
    /** E.g. Primary */
    primary: string;
    /** E.g. Backup */
    backup: string;
    /** E.g. Expires */
    expiration: string;
    /** E.g. Delete */
    deleteButton?: string;
  };
}

export const PaymentMethodList: FC<PaymentMethodListProps> = (props) => {
  const theme = useTheme();

  const radioCardGroupOptions: RadioGroupCardColumnProps<PaymentMethod>["options"] =
    props.paymentMethods.map((paymentMethod) => {
      const Logo = getPaymentMethodLogo(paymentMethod.type, paymentMethod.brand || "OTHER");

      return {
        value: paymentMethod,
        hoverDisabled: paymentMethod.isPrimary || props.disabled,
        disabled: props.disabled,
        content: (
          <>
            <Logo
              sx={{
                fontSize: "2.5rem",
                verticalAlign: "middle",
                color: theme.palette.text.primary,
                mr: 1,
                width: paymentMethod.type === "PAYPAL" ? "80px" : undefined,
              }}
            />
            <Typography
              component="span"
              variant="bodyText"
              sx={{ verticalAlign: "middle", textTransform: "capitalize", whiteSpace: "nowrap" }}
            >
              {paymentMethod.type === "PAYPAL"
                ? ""
                : `${
                    !paymentMethod.brand || paymentMethod.brand === "OTHER"
                      ? ""
                      : paymentMethod.brand.toLowerCase().replace("_", " ")
                  } **${paymentMethod.last4 || "????"}`}
            </Typography>
            {paymentMethod.expiryMonth && paymentMethod.expiryYear && (
              <Typography>
                {`${props.translations.expiration} ${paymentMethod.expiryMonth} / ${paymentMethod.expiryYear}`}
              </Typography>
            )}
            {paymentMethod.bankName && <Typography>{paymentMethod.bankName}</Typography>}
            {paymentMethod.payPalEmail && <Typography>{paymentMethod.payPalEmail}</Typography>}
            {paymentMethod.isPrimary && (
              <Chip
                variant="outlined"
                label={props.translations.primary}
                sx={{ mt: 0.5 }}
                data-testid="primaryPaymentMethodChip"
              />
            )}
            {paymentMethod.isBackup && (
              <Chip
                variant="outlined"
                label={props.translations.backup}
                sx={{ mt: 0.5 }}
                data-testid="backupPaymentMethodChip"
              />
            )}
          </>
        ),
        action: props.onDeleteClick &&
          props.translations.deleteButton &&
          !paymentMethod.isPrimary &&
          !paymentMethod.isBackup && (
            <>
              <Button
                variant="text"
                sx={{
                  padding: theme.spacing(1.5),
                  marginTop: theme.spacing(-0.5),
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  props.onDeleteClick?.(paymentMethod);
                }}
                data-testid="deleteButton"
              >
                <Typography variant="bodyTextSemibold">
                  {props.translations.deleteButton}
                </Typography>
              </Button>
            </>
          ),
      };
    }) || [];

  return props.paymentMethods.length > 0 ? (
    <Box mb={{ mobile: 0, tablet: 2 }}>
      <RadioGroupCardColumn
        initialValue={props.paymentMethods.find((paymentMethod) => paymentMethod.isPrimary) || null}
        isEqualValue={(a, b) => a.id === b?.id}
        options={radioCardGroupOptions}
        onChange={props.onChange as unknown as undefined}
        onChangeAsync={
          props.onChange as NonNullable<RadioGroupCardColumnProps<PaymentMethod>["onChangeAsync"]>
        }
      />
    </Box>
  ) : null;
};
