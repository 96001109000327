import { Box, Grid } from "@mui/material";

import type { TableProps } from "../../Table";
import type { ActionButtonsProps } from "../ActionButtons";
import type { HeadingContentProps } from "../HeadingContent";

import { Table } from "../../Table";
import { ActionButtons } from "../ActionButtons";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockTableWithImageProps {
  headingContent: HeadingContentProps;
  callToAction?: Pick<ActionButtonsProps, "buttons">;
  table: TableProps;
  image: React.ReactNode;
}

export const BlockTableWithImage = (props: BlockTableWithImageProps) => (
  <GridContainer>
    <Grid item mobile={12} tablet={6} sx={{ order: { mobile: 1, tablet: 0 } }}>
      <HeadingContent
        {...props.headingContent}
        bottomContent={
          <Box mt={2}>
            <Table {...props.table} />
            {props.callToAction && (
              <ActionButtons {...props.callToAction} direction="column" grow={true} />
            )}
          </Box>
        }
      />
    </Grid>
    <Grid
      item
      mobile={12}
      tablet={6}
      sx={{ display: "flex", alignSelf: "stretch", order: { mobile: 0, tablet: 1 } }}
    >
      {/* max-height: 500px comes from 337px column width on tablet and 3x2 crop ratio */}
      <Box sx={{ width: "100%", maxHeight: { tablet: "500px", laptop: "none" } }}>
        {props.image}
      </Box>
    </Grid>
  </GridContainer>
);
