import { type FC, useEffect, useState } from "react";

import { AlertRound, CloseRound } from "@augment-frontend/mui-icons";
import {
  Button,
  type ButtonProps,
  Fab,
  fabClasses,
  type FabProps,
  IconButton,
  Snackbar,
  type SnackbarProps,
} from "@mui/material";

import { Alert, type AlertProps } from "../Alert";

export interface BannerProps {
  /** Title of the banner */
  title?: string;
  /** Description of the banner */
  description: string;
  /** Show banner expanded */
  expanded: boolean;
  /** Callback on banner collapse; don't provide if uncollapsible */
  onCollapse?: () => void;
  /** Callback on banner expand; don't provide if unextendable */
  onExpand?: () => void;
  /** Call to action */
  ctaButtonProps?: Omit<ButtonProps, "sx" | "onClick">;
  severity: AlertProps["severity"];
  snackbarSx?: SnackbarProps["sx"];
  fabSx?: FabProps["sx"];
}

export const Banner: FC<BannerProps> = (props) => {
  const [hydrated, setHydrated] = useState(false); // This is to prevent hydration mismatch

  useEffect(() => {
    setHydrated(true);
  }, []);

  if (!hydrated) {
    // Render nothing or a placeholder until the client has mounted
    return null;
  }

  return (
    <>
      {props.expanded ? (
        <Snackbar open anchorOrigin={{ vertical: "top", horizontal: "left" }} sx={props.snackbarSx}>
          <Alert
            severity={props.severity}
            title={props.title}
            description={props.description}
            sx={{ width: "100%" }}
            action={
              props.ctaButtonProps && (
                <Button {...props.ctaButtonProps} variant="outlined" onClick={props.onCollapse} />
              )
            }
            topRightCornerAction={
              <IconButton
                sx={(theme) => ({ color: theme.palette.text.primary })}
                onClick={props.onCollapse}
              >
                <CloseRound sx={{ m: "-6px" }} />
              </IconButton>
            }
          />
        </Snackbar>
      ) : (
        <Fab
          size="medium"
          sx={{
            position: "fixed",
            bgcolor: "warning.main",
            zIndex: 9999,
            [`&.${fabClasses.root}`]: { bgcolor: "warning.main" },
            ...props.fabSx,
          }}
          onClick={props.onExpand}
        >
          <AlertRound fontSize="large" />
        </Fab>
      )}
    </>
  );
};
