import type { FC } from "react";

import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { StackProps } from "@mui/material";

interface InfoRowProps extends Omit<StackProps, "content"> {
  /** Icon to show on the left side of the component */
  icon: React.ReactNode;
  /** Title to show after icon on the left side of the component, e.g. "Motor power" */
  title: string;
  /** Content to show on the right hand side of the component */
  content?: React.ReactNode;
}

/**
 * Render icon, title, and text- or custom content to display compact information on single row. Set
 * icon and title to the left side of the component, and text- or custom content to the right side.
 */
export const InfoRow: FC<InfoRowProps> = (props) => {
  const { icon, title, content, ...rest } = props;
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flexGrow={1}
      {...rest}
    >
      <>
        <Stack direction="row" spacing={theme.spacing(1.25)}>
          {icon}
          <Typography variant="bodyTextBold">{title}</Typography>
        </Stack>
        {content}
      </>
    </Stack>
  );
};
