import type { SupportedCountries } from "./constant";
import type { SSNResult } from "./types";
import type { LiteralUnion } from "type-fest";

import { danishSSN } from "./danishSSN";
import { finnishSSN } from "./finnishSSN";
import { frenchSSN } from "./frenchSSN";
import { italianSSN } from "./italianSSN";
import { spanishSSN } from "./spanishSSN";
import { swedishSSN } from "./swedishSSN";
import { swissSSN } from "./swissSSN";

/**
 * Calculate the validity of SSN for a given country and the age of the person.
 * @param ssn The SSN to validate in a country specific format.
 * @param countryCode Case-insensitive two-letter ISO 3166-1 alpha-2 country code.
 * @returns Validity and age, or -1 as age if the SSN is invalid, or if the country is not supported.
 */
export const validateSSN = (
  ssn: string,
  countryCode: LiteralUnion<SupportedCountries, string>
): SSNResult => {
  const uppercasedCountryCode = countryCode.toUpperCase() as SupportedCountries;
  switch (uppercasedCountryCode) {
    case "CH":
      return swissSSN(ssn);
    case "DK":
      return danishSSN(ssn);
    case "ES":
      return spanishSSN(ssn);
    case "FI":
      return finnishSSN(ssn);
    case "FR":
      return frenchSSN(ssn);
    case "IT":
      return italianSSN(ssn);
    case "SE":
      return swedishSSN(ssn);
    default:
      return { valid: false, age: -1, standardizedSSN: null };
  }
};
