import type { FC } from "react";
import { useState } from "react";

import { User } from "@augment-frontend/mui-icons";
import { Box, Stack, Typography } from "@mui/material";

import type { EmailInputProps, PasswordInputProps } from "../../../../Inputs";

import { Alert } from "../../../../Alert";
import { EmailInput, PasswordInput } from "../../../../Inputs";

export interface RegisterOrLoginProps {
  /** Username (email) */
  username: string | null;
  /**
   * Triggered when new register or login attempt is submitted.
   */
  onSubmit: (username: string, password: string) => Promise<void>;
  /**
   * Triggered when credentials change, pass information about the validity of email and password.
   */
  onCredentialsChange: (emailValid: boolean, passwordValid: boolean) => void;
  /** Disabled status of the component, e.g. when loading */
  disabled?: boolean;
  /** Error message/alert of the component */
  errorMessage?: string;
  translations: {
    email: EmailInputProps["translations"];
    password: PasswordInputProps["translations"];
    /** E.g You are logged in as: */
    statusMessage: string;
  };
}

export const RegisterOrLogin: FC<RegisterOrLoginProps> = (props) => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    emailValid: false,
    passwordValid: false,
  });

  return props.username ? (
    <>
      <Typography mb={1}>{props.translations.statusMessage}</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { mobile: "center", tablet: "flex-start" },
          mb: 3,
        }}
        data-testid="username"
      >
        <User sx={{ fontSize: 32, marginRight: { mobile: 2, tablet: 4 } }} />
        <Typography variant="bodyTextBold" sx={{ overflowWrap: "anywhere" }}>
          {props.username}
        </Typography>
      </Box>
    </>
  ) : (
    <Stack
      component="form"
      id="registerOrLoginForm"
      onSubmit={async (event) => {
        event.preventDefault();
        await props.onSubmit(credentials.email, credentials.password);
      }}
      spacing={1}
      aria-label="form" // for testing, see https://github.com/testing-library/dom-testing-library/issues/474
    >
      <EmailInput
        showValidationStatus
        onChange={({ email, valid }) => {
          setCredentials((prev) => ({ ...prev, email, emailValid: valid }));
          props.onCredentialsChange(valid, credentials.passwordValid);
        }}
        disabled={props.disabled}
        translations={props.translations.email}
      />
      <PasswordInput
        showValidationStatus
        onChange={({ value, valid }) => {
          setCredentials((prev) => ({ ...prev, password: value, passwordValid: valid }));
          props.onCredentialsChange(credentials.emailValid, valid);
        }}
        disabled={props.disabled}
        translations={props.translations.password}
      />
      {props.errorMessage && (
        <Alert
          severity="error"
          description={props.errorMessage}
          sx={{ mt: 1.5, alignSelf: "stretch" }}
        />
      )}
    </Stack>
  );
};
