import { createSvgIcon } from "@mui/material/utils";

export const IOSArrowUp = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M17.3593 14.7679C17.5463 14.9239 17.7733 14.9999 17.9993 14.9999C18.2863 14.9999 18.5703 14.8769 18.7683 14.6399C19.1213 14.2159 19.0643 13.5849 18.6403 13.2319L12.6403 8.23192C12.2743 7.92592 11.7433 7.92192 11.3733 8.22092L5.37326 13.0489C4.94226 13.3959 4.87426 14.0249 5.22026 14.4549C5.56726 14.8859 6.19726 14.9539 6.62626 14.6069L11.9883 10.2919L17.3593 14.7679Z"
  />,
  "IOSArrowUp"
);
