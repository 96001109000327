import type { FC } from "react";
import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { isAfter, isBefore, subDays } from "date-fns";

import type { DatePickerProps } from "../../../../../DatePicker";

import { DatePicker } from "../../../../../DatePicker";
import { ProgrammaticModal } from "../../../../../Modal";

export interface MileagePeriodSelectorProps {
  /** Callback for onSubmit event */
  onSubmit: (value: { startDate: Date; endDate: Date }) => Promise<Error | void>;
  /** Callback for onClose event of the modal */
  onClose: () => void;
  /** Controls modal's open state */
  open: boolean;
  /** Selected start and end dates */
  value?: { startDate: Date; endDate: Date };
  translations: {
    /** E.g. "Show mileage statistics by period" */
    title: string;
    /** E.g. "Select" */
    selectButton: string;
    /** E.g. "Start date" */
    startDate: DatePickerProps["translations"]["label"];
    /** E.g. "End date" */
    endDate: DatePickerProps["translations"]["label"];
  };
}

export const MileagePeriodSelector: FC<MileagePeriodSelectorProps> = (props) => {
  const initialDates = {
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  };
  const initialError = "";
  const [selectedDates, setSelectedDates] = useState(props.value ? props.value : initialDates);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(initialError);

  const theme = useTheme();

  const handleClose = () => {
    props.onClose();
    setErrorMessage(initialError);
  };

  const handleSubmit: React.FormEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await props.onSubmit({ ...selectedDates });
      setLoading(false);
      props.onClose();
    } catch (err) {
      setErrorMessage(err instanceof Error ? err.message : "Unknown error");
      setLoading(false);
    }
  };

  return (
    <ProgrammaticModal
      index={0}
      open={props.open}
      onClose={handleClose}
      views={[
        <Box
          key="periodSelecterModal"
          onSubmit={handleSubmit}
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subheadingBold"
            textAlign="center"
            sx={{ marginBottom: theme.spacing(4) }}
          >
            {props.translations.title}
          </Typography>
          <Stack
            direction={{ mobile: "column", tablet: "row" }}
            spacing={theme.spacing(2)}
            marginBottom={theme.spacing(4)}
            sx={{ width: "100%" }}
          >
            <DatePicker
              onChange={(date) => {
                if (date) {
                  setErrorMessage(initialError);
                  setSelectedDates((prev) => ({
                    ...prev,
                    startDate: new Date(date.setHours(0, 0, 0, 0)),
                  }));
                }
              }}
              disabled={loading}
              shouldDisableDate={(date) => isAfter(date, selectedDates.endDate)}
              disableFuture
              value={selectedDates.startDate}
              translations={{
                label: props.translations.startDate,
              }}
            />
            <DatePicker
              onChange={(date) => {
                if (date) {
                  setErrorMessage(initialError);
                  setSelectedDates((prev) => ({
                    ...prev,
                    endDate: new Date(date.setHours(23, 59, 59, 999)),
                  }));
                }
              }}
              disabled={loading}
              shouldDisableDate={(date) => isBefore(date, selectedDates.startDate)}
              disableFuture
              value={selectedDates.endDate}
              translations={{
                label: props.translations.endDate,
              }}
            />
          </Stack>
          {errorMessage && (
            <Typography sx={{ color: theme.palette.error.main }} marginBottom={theme.spacing(2)}>
              {errorMessage}
            </Typography>
          )}
          <LoadingButton fullWidth type="submit" loading={loading} disabled={!!errorMessage}>
            {props.translations.selectButton}
          </LoadingButton>
        </Box>,
      ]}
    />
  );
};
