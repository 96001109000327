import { createSvgIcon } from "@mui/material/utils";

import type { SvgIconProps } from "@mui/material";

const SepaBase = createSvgIcon(
  <g>
    <path
      fill="#00489A"
      d="M-131.8,364.8l6.1-0.6c0.4,2.1,1.1,3.6,2.3,4.6c1.1,1,2.7,1.4,4.6,1.4c2,0,3.6-0.4,4.6-1.3c1-0.9,1.6-1.9,1.6-3c0-0.7-0.2-1.4-0.7-1.9c-0.4-0.5-1.2-1-2.3-1.4c-0.7-0.3-2.4-0.7-5.1-1.4c-3.4-0.8-5.8-1.9-7.1-3.1c-1.9-1.7-2.9-3.8-2.9-6.3c0-1.6,0.5-3.1,1.4-4.5c0.9-1.4,2.2-2.4,3.9-3.2c1.7-0.7,3.7-1.1,6.1-1.1c3.9,0,6.9,0.9,8.9,2.6c2,1.7,3,4,3.1,6.9l-6.3,0.2c-0.3-1.6-0.9-2.8-1.7-3.5c-0.9-0.7-2.2-1.1-4-1.1c-1.8,0-3.3,0.4-4.3,1.1c-0.7,0.5-1,1.1-1,1.9c0,0.7,0.3,1.4,0.9,1.9c0.8,0.7,2.7,1.4,5.8,2.1c3.1,0.7,5.3,1.5,6.8,2.3c1.5,0.8,2.6,1.8,3.5,3.2c0.8,1.4,1.2,3,1.2,5c0,1.8-0.5,3.5-1.5,5.1c-1,1.6-2.4,2.7-4.2,3.5c-1.8,0.8-4.1,1.1-6.8,1.1c-4,0-7-0.9-9.1-2.8C-130.1,371-131.4,368.3-131.8,364.8"
    />
    <path fill="#00489A" d="M-99.7,343.7h6v5.5h-6V343.7z M-99.7,352.3h6V375h-6V352.3z" />
    <path
      fill="#00489A"
      d="M-65.6,375h-6v-11.6c0-2.5-0.1-4-0.4-4.8c-0.3-0.7-0.7-1.3-1.2-1.7c-0.6-0.4-1.3-0.6-2.1-0.6c-1,0-2,0.3-2.8,0.9c-0.8,0.6-1.4,1.3-1.7,2.3c-0.3,0.9-0.4,2.7-0.4,5.2V375h-6v-22.7h5.6v3.3c2-2.6,4.5-3.9,7.5-3.9c1.3,0,2.5,0.2,3.6,0.7c1.1,0.5,1.9,1.1,2.5,1.8c0.6,0.7,1,1.6,1.2,2.5c0.2,0.9,0.3,2.3,0.3,4V375"
    />
    <path
      fill="#00489A"
      d="M-58.8,376.5l6.8,0.7c0.1,0.9,0.4,1.4,0.8,1.8c0.6,0.5,1.5,0.7,2.7,0.7c1.6,0,2.8-0.2,3.5-0.7c0.5-0.3,0.9-0.8,1.2-1.5c0.2-0.5,0.3-1.4,0.3-2.8v-3.3c-1.8,2.4-4,3.6-6.8,3.6c-3.1,0-5.5-1.3-7.2-3.9c-1.4-2-2.1-4.6-2.1-7.6c0-3.8,0.9-6.7,2.7-8.7c1.8-2,4.1-3,6.8-3c2.8,0,5.1,1.2,6.9,3.7v-3.1h5.6v20.4c0,2.7-0.2,4.7-0.7,6c-0.4,1.3-1.1,2.4-1.9,3.1c-0.8,0.7-1.9,1.3-3.2,1.8c-1.3,0.4-3,0.6-5.1,0.6c-3.9,0-6.6-0.7-8.2-2c-1.6-1.3-2.4-3-2.4-5.1C-58.8,377-58.8,376.8-58.8,376.5 M-53.4,363.2c0,2.4,0.5,4.2,1.4,5.3c0.9,1.1,2.1,1.7,3.4,1.7c1.4,0,2.7-0.6,3.7-1.7c1-1.1,1.5-2.8,1.5-5.1c0-2.4-0.5-4.1-1.4-5.2c-0.9-1.1-2.2-1.7-3.6-1.7c-1.4,0-2.6,0.5-3.5,1.7C-52.9,359.2-53.4,360.9-53.4,363.2"
    />
    <rect x="-30.2" y="343.7" fill="#00489A" width="6" height="31.3" />
    <path
      fill="#00489A"
      d="M-3.5,367.9l6,1c-0.8,2.2-2,3.8-3.7,5c-1.7,1.2-3.7,1.7-6.2,1.7c-4,0-6.9-1.3-8.8-3.9c-1.5-2.1-2.3-4.7-2.3-7.9c0-3.8,1-6.7,3-8.9c2-2.1,4.5-3.2,7.5-3.2c3.4,0,6.1,1.1,8,3.3c2,2.3,2.9,5.7,2.8,10.3h-15.1c0,1.8,0.5,3.2,1.5,4.2c0.9,1,2.1,1.5,3.5,1.5c1,0,1.7-0.2,2.4-0.8C-4.3,369.8-3.8,369-3.5,367.9 M-3.2,361.8c0-1.8-0.5-3.1-1.4-4s-1.9-1.4-3.1-1.4c-1.3,0-2.4,0.5-3.3,1.4c-0.9,1-1.3,2.3-1.3,4H-3.2z"
    />
    <polygon
      fill="#00489A"
      points="22.5,375 22.5,343.7 45.7,343.7 45.7,349 28.9,349 28.9,355.9 44.5,355.9 44.5,361.2 28.9,361.2 28.9,369.8 46.3,369.8 46.3,375"
    />
    <path
      fill="#00489A"
      d="M67.9,375v-3.4c-0.8,1.2-1.9,2.2-3.3,2.9c-1.4,0.7-2.8,1.1-4.3,1.1c-1.5,0-2.9-0.3-4.1-1c-1.2-0.7-2.1-1.6-2.6-2.8c-0.5-1.2-0.8-2.9-0.8-5v-14.4h6v10.4c0,3.2,0.1,5.1,0.3,5.9c0.2,0.7,0.6,1.3,1.2,1.7c0.6,0.4,1.3,0.6,2.2,0.6c1,0,1.9-0.3,2.8-0.8c0.8-0.6,1.4-1.3,1.7-2.1c0.3-0.8,0.4-2.9,0.4-6.1v-9.6h6V375H67.9"
    />
    <path
      fill="#00489A"
      d="M86.7,375h-6v-22.7h5.6v3.2c1-1.5,1.8-2.5,2.6-3c0.8-0.5,1.6-0.7,2.6-0.7c1.4,0,2.7,0.4,4,1.1l-1.8,5.2c-1-0.7-2-1-2.8-1c-0.8,0-1.6,0.2-2.1,0.7c-0.6,0.5-1.1,1.3-1.4,2.5c-0.3,1.2-0.5,3.8-0.5,7.6V375"
    />
    <path
      fill="#00489A"
      d="M97.8,363.4c0-2,0.5-3.9,1.5-5.8c1-1.9,2.4-3.3,4.2-4.3c1.8-1,3.8-1.5,6-1.5c3.4,0,6.3,1.1,8.4,3.3c2.2,2.3,3.3,5.1,3.3,8.5c0,3.5-1.1,6.3-3.3,8.6c-2.2,2.3-5,3.4-8.4,3.4c-2.1,0-4.1-0.5-6-1.4c-1.9-0.9-3.3-2.3-4.3-4.1C98.3,368.2,97.8,366,97.8,363.4 M104,363.7c0,2.3,0.5,4,1.6,5.2c1.1,1.2,2.4,1.8,4,1.8c1.6,0,2.9-0.6,3.9-1.8c1.1-1.2,1.6-2.9,1.6-5.2c0-2.2-0.5-3.9-1.6-5.1c-1.1-1.2-2.4-1.8-3.9-1.8c-1.6,0-2.9,0.6-4,1.8C104.5,359.7,104,361.4,104,363.7"
    />
    <path
      fill="#00489A"
      d="M140.9,375v-31.3H151c3.9,0,6.3,0.2,7.5,0.5c1.8,0.5,3.3,1.5,4.5,3.1c1.2,1.6,1.8,3.6,1.8,6.1c0,1.9-0.3,3.5-1,4.9c-0.7,1.3-1.6,2.3-2.6,3.1c-1.1,0.7-2.2,1.2-3.3,1.5c-1.5,0.3-3.7,0.5-6.6,0.5h-4.1V375H140.9 M147.2,349v8.9h3.5c2.5,0,4.1-0.2,5-0.5c0.8-0.4,1.5-0.9,2-1.6c0.5-0.7,0.7-1.5,0.7-2.4c0-1.2-0.3-2.1-1-2.8c-0.7-0.7-1.5-1.2-2.5-1.4c-0.8-0.2-2.3-0.2-4.6-0.2H147.2z"
    />
    <path
      fill="#00489A"
      d="M175.7,359.2l-5.4-1c0.6-2.2,1.7-3.8,3.1-4.9c1.5-1.1,3.7-1.6,6.7-1.6c2.7,0,4.7,0.3,6,0.9c1.3,0.6,2.2,1.4,2.8,2.4c0.5,1,0.8,2.8,0.8,5.4l-0.1,7c0,2,0.1,3.5,0.3,4.4c0.2,0.9,0.5,2,1.1,3h-6c-0.2-0.4-0.3-1-0.6-1.7c-0.1-0.4-0.2-0.6-0.2-0.7c-1,1-2.1,1.8-3.3,2.3c-1.2,0.5-2.4,0.8-3.7,0.8c-2.3,0-4.2-0.6-5.5-1.9c-1.3-1.3-2-2.9-2-4.8c0-1.3,0.3-2.4,0.9-3.4s1.5-1.8,2.6-2.3c1.1-0.5,2.7-1,4.8-1.4c2.8-0.5,4.7-1,5.8-1.5v-0.6c0-1.2-0.3-2-0.9-2.5c-0.6-0.5-1.7-0.7-3.3-0.7c-1.1,0-1.9,0.2-2.5,0.6C176.6,357.4,176.1,358.2,175.7,359.2 M183.8,364.1c-0.8,0.3-2,0.6-3.7,0.9c-1.7,0.3-2.7,0.7-3.3,1.1c-0.8,0.6-1.2,1.3-1.2,2.1s0.3,1.6,0.9,2.2c0.6,0.6,1.4,0.9,2.4,0.9c1.1,0,2.1-0.4,3.1-1.1c0.7-0.5,1.2-1.2,1.4-2c0.2-0.5,0.2-1.5,0.2-2.9V364.1z"
    />
    <path
      fill="#00489A"
      d="M194,352.3h6.4l5.4,16.1l5.3-16.1h6.2l-8,21.8l-1.5,4c-0.5,1.3-1,2.3-1.5,3c-0.5,0.7-1,1.3-1.6,1.7c-0.6,0.4-1.4,0.8-2.3,1c-0.9,0.2-1.9,0.4-3.1,0.4c-1.2,0-2.3-0.1-3.4-0.4l-0.6-4.7c0.9,0.2,1.8,0.3,2.6,0.3c1.4,0,2.4-0.4,3.1-1.2c0.7-0.8,1.2-1.9,1.5-3.2L194,352.3"
    />
    <path
      fill="#00489A"
      d="M222.1,352.3h5.5v3.1c2-2.4,4.4-3.7,7.1-3.7c1.5,0,2.7,0.3,3.8,0.9c1.1,0.6,1.9,1.5,2.6,2.7c1-1.2,2.1-2.1,3.2-2.7c1.2-0.6,2.4-0.9,3.7-0.9c1.7,0,3.1,0.3,4.2,1c1.1,0.7,2,1.7,2.6,3c0.4,1,0.6,2.5,0.6,4.7V375h-6v-13c0-2.3-0.2-3.7-0.6-4.4c-0.6-0.9-1.4-1.3-2.6-1.3c-0.8,0-1.6,0.2-2.4,0.8c-0.7,0.5-1.3,1.3-1.6,2.3c-0.3,1-0.5,2.6-0.5,4.7V375h-6v-12.4c0-2.2-0.1-3.7-0.3-4.3c-0.2-0.7-0.5-1.1-1-1.4c-0.5-0.3-1.1-0.5-1.8-0.5c-0.9,0-1.7,0.2-2.5,0.7c-0.7,0.5-1.3,1.2-1.6,2.2c-0.3,0.9-0.5,2.5-0.5,4.7v11h-6V352.3"
    />
    <path
      fill="#00489A"
      d="M276,367.9l6,1c-0.8,2.2-2,3.8-3.7,5c-1.7,1.2-3.7,1.7-6.2,1.7c-4,0-6.9-1.3-8.8-3.9c-1.5-2.1-2.3-4.7-2.3-7.9c0-3.8,1-6.7,3-8.9c2-2.1,4.5-3.2,7.5-3.2c3.4,0,6.1,1.1,8,3.3c2,2.3,2.9,5.7,2.8,10.3h-15.1c0,1.8,0.5,3.2,1.5,4.2c0.9,1,2.1,1.5,3.5,1.5c1,0,1.8-0.2,2.4-0.8C275.2,369.8,275.7,369,276,367.9 M276.3,361.8c0-1.8-0.5-3.1-1.4-4s-1.9-1.4-3.1-1.4c-1.3,0-2.4,0.5-3.3,1.4c-0.9,1-1.3,2.3-1.3,4H276.3z"
    />
    <path
      fill="#00489A"
      d="M309.1,375h-6v-11.6c0-2.5-0.1-4-0.4-4.8c-0.3-0.7-0.7-1.3-1.2-1.7s-1.3-0.6-2.1-0.6c-1,0-2,0.3-2.8,0.9c-0.8,0.6-1.4,1.3-1.7,2.3c-0.3,0.9-0.4,2.7-0.4,5.2V375h-6v-22.7h5.6v3.3c2-2.6,4.5-3.9,7.5-3.9c1.3,0,2.5,0.2,3.6,0.7c1.1,0.5,1.9,1.1,2.5,1.8c0.6,0.7,1,1.6,1.2,2.5c0.2,0.9,0.3,2.3,0.3,4V375"
    />
    <path
      fill="#00489A"
      d="M326.9,352.3v4.8h-4.1v9.2c0,1.9,0,3,0.1,3.3c0.1,0.3,0.2,0.5,0.5,0.8c0.3,0.2,0.6,0.3,1,0.3c0.5,0,1.3-0.2,2.4-0.6l0.5,4.6c-1.4,0.6-3,0.9-4.7,0.9c-1.1,0-2.1-0.2-2.9-0.5c-0.9-0.4-1.5-0.9-1.9-1.4c-0.4-0.6-0.7-1.4-0.8-2.3c-0.1-0.7-0.2-2.1-0.2-4.2v-9.9H314v-4.8h2.7v-4.5l6-3.5v8.1H326.9"
    />
    <path
      fill="#00489A"
      d="M330.3,368.6l6-0.9c0.2,1.2,0.8,2.1,1.6,2.6c0.8,0.6,1.9,0.9,3.3,0.9c1.6,0,2.7-0.3,3.5-0.9c0.5-0.4,0.8-0.9,0.8-1.6c0-0.5-0.1-0.8-0.4-1.1c-0.3-0.3-1-0.5-2-0.8c-4.9-1.1-8-2.1-9.3-3c-1.8-1.2-2.7-2.9-2.7-5.1c0-2,0.8-3.6,2.3-5c1.6-1.3,4-2,7.3-2c3.1,0,5.4,0.5,7,1.5c1.5,1,2.6,2.5,3.2,4.5l-5.7,1.1c-0.2-0.9-0.7-1.6-1.4-2c-0.7-0.5-1.7-0.7-2.9-0.7c-1.6,0-2.7,0.2-3.4,0.7c-0.5,0.3-0.7,0.7-0.7,1.2c0,0.4,0.2,0.8,0.6,1.1c0.5,0.4,2.4,1,5.6,1.7c3.2,0.7,5.4,1.6,6.7,2.7c1.3,1.1,1.9,2.6,1.9,4.5c0,2.1-0.9,3.9-2.6,5.4c-1.8,1.5-4.3,2.3-7.7,2.3c-3.1,0-5.5-0.6-7.3-1.9C332,372.5,330.8,370.8,330.3,368.6"
    />
    <path
      fill="#00489A"
      d="M399.8,375h-6.8l-2.7-7.1h-12.6L375,375h-6.7l12.1-31.3h6.7L399.8,375z M388.1,362.7l-4.4-11.6l-4.2,11.6H388.1z"
    />
    <path
      fill="#00489A"
      d="M410.1,375h-6v-22.7h5.6v3.2c1-1.5,1.8-2.5,2.6-3c0.8-0.5,1.6-0.7,2.6-0.7c1.4,0,2.7,0.4,4,1.1l-1.8,5.2c-1-0.7-2-1-2.8-1c-0.8,0-1.6,0.2-2.1,0.7c-0.6,0.5-1.1,1.3-1.4,2.5c-0.3,1.2-0.5,3.8-0.5,7.6V375"
    />
    <path
      fill="#00489A"
      d="M435.9,367.9l6,1c-0.8,2.2-2,3.8-3.7,5c-1.7,1.2-3.7,1.7-6.2,1.7c-4,0-6.9-1.3-8.8-3.9c-1.5-2.1-2.3-4.7-2.3-7.9c0-3.8,1-6.7,3-8.9c2-2.1,4.5-3.2,7.5-3.2c3.4,0,6.1,1.1,8,3.3c2,2.3,2.9,5.7,2.8,10.3h-15.1c0,1.8,0.5,3.2,1.5,4.2c0.9,1,2.1,1.5,3.5,1.5c1,0,1.8-0.2,2.4-0.8C435.1,369.8,435.6,369,435.9,367.9 M436.2,361.8c0-1.8-0.5-3.1-1.4-4s-1.9-1.4-3.1-1.4c-1.3,0-2.4,0.5-3.3,1.4c-0.9,1-1.3,2.3-1.3,4H436.2z"
    />
    <path
      fill="#00489A"
      d="M452.8,359.2l-5.4-1c0.6-2.2,1.7-3.8,3.1-4.9c1.5-1.1,3.7-1.6,6.7-1.6c2.7,0,4.7,0.3,6,0.9c1.3,0.6,2.2,1.4,2.8,2.4c0.5,1,0.8,2.8,0.8,5.4l-0.1,7c0,2,0.1,3.5,0.3,4.4c0.2,0.9,0.5,2,1.1,3h-6c-0.2-0.4-0.3-1-0.6-1.7c-0.1-0.4-0.2-0.6-0.2-0.7c-1,1-2.1,1.8-3.3,2.3c-1.2,0.5-2.4,0.8-3.7,0.8c-2.3,0-4.2-0.6-5.5-1.9c-1.3-1.3-2-2.9-2-4.8c0-1.3,0.3-2.4,0.9-3.4s1.5-1.8,2.6-2.3c1.1-0.5,2.7-1,4.8-1.4c2.8-0.5,4.7-1,5.8-1.5v-0.6c0-1.2-0.3-2-0.9-2.5c-0.6-0.5-1.7-0.7-3.3-0.7c-1.1,0-1.9,0.2-2.5,0.6C453.7,357.4,453.2,358.2,452.8,359.2 M460.9,364.1c-0.8,0.3-2,0.6-3.7,0.9c-1.7,0.3-2.7,0.7-3.3,1.1c-0.8,0.6-1.2,1.3-1.2,2.1s0.3,1.6,0.9,2.2c0.6,0.6,1.4,0.9,2.4,0.9c1.1,0,2.1-0.4,3.1-1.1c0.7-0.5,1.2-1.2,1.4-2c0.2-0.5,0.2-1.5,0.2-2.9V364.1z"
    />
    <path
      fill="#00489A"
      d="M1.3,220.6h-41c0-6.7-0.8-11.2-2.4-13.6c-2.4-3.4-9.2-5.1-20.2-5.1c-10.7,0-17.8,1-21.3,2.9c-3.5,2-5.2,6.3-5.2,12.8c0,6,1.5,9.9,4.6,11.8c2.2,1.3,5.1,2.1,8.7,2.4l8.3,0.6c17.7,1.2,28.8,2,33.2,2.5c14,1.4,24.2,5.1,30.5,11.2c5,4.7,7.9,10.9,8.9,18.5c0.5,4.5,0.8,9.7,0.8,15.4c0,13.2-1.3,22.9-3.8,29c-4.6,11.2-14.8,18.3-30.8,21.3c-6.7,1.2-16.9,1.9-30.7,1.9c-23,0-39-1.4-48-4.1c-11.1-3.4-18.4-10.2-22-20.5c-2-5.7-3-15.2-3-28.6h41c0,1.5,0,2.6,0,3.4c0,7.1,2.1,11.7,6.1,13.7c2.8,1.4,6.3,2.1,10.3,2.2h15.1c7.7,0,12.6-0.4,14.7-1.2c3.8-1.5,6.2-3.9,7.4-7.2c0.6-2,0.9-4.7,0.9-7.9c0-7.2-2.6-11.6-7.9-13.2c-2-0.6-11.1-1.5-27.5-2.6c-13.1-0.9-22.2-1.8-27.3-2.7c-13.4-2.5-22.4-7.7-26.9-15.5c-3.9-6.7-5.9-16.8-5.9-30.3c0-10.3,1.1-18.5,3.2-24.7c2.1-6.2,5.6-10.9,10.3-14.1c6.9-4.9,15.8-7.9,26.5-8.7c9-0.8,18.9-1.2,29.9-1.2c17.2,0,29.5,1,36.8,3c17.9,4.8,26.8,18.2,26.8,40.3C1.7,214.3,1.6,217,1.3,220.6"
    />
    <path
      fill="#00489A"
      d="M181.8,332.3V168.4h64.3h10.1h7.8c11.3,0,19.9,0.9,25.8,2.8c13.5,4.2,22.6,12.9,27.2,26c2.4,6.9,3.6,17.2,3.6,31.1c0,16.6-1.3,28.6-4,35.9c-5.3,14.4-16.1,22.7-32.5,24.8c-1.9,0.3-10.1,0.6-24.6,0.8l-7.3,0.2h-26.3v42.3H181.8 M225.9,251.9h27.5c8.7-0.3,14-1,15.9-2c2.6-1.4,4.4-4.3,5.2-8.6c0.5-2.9,0.8-7.1,0.8-12.8c0-6.9-0.5-12.1-1.7-15.4c-1.6-4.7-5.4-7.6-11.4-8.6c-1.2-0.2-4-0.2-8.6-0.2h-27.9V251.9z"
    />
    <path
      fill="#00489A"
      d="M414.8,303.9h-59l-7.9,28.3h-45.7l49.3-163.9H418l49.9,163.9h-44.7L414.8,303.9z M406,272l-20.7-71l-20.1,71H406z"
    />
    <path
      fill="#FDC300"
      d="M120.9,191.4c18.2,0,34.4,8.4,45.1,21.5l9.5-20.4c-14.9-14-35.1-22.7-57.4-22.7c-34.8,0-64.5,21-76.7,50.7H22.9l-11,23.5h23.9c-0.2,2.2-0.3,4.4-0.3,6.6c0,2.7,0.2,5.3,0.4,8h-12l-11,23.5h29c12.5,29,41.9,49.3,76.1,49.3c17.2,0,33.2-5.2,46.5-14v-28.8c-10.7,12.2-26.3,19.8-43.7,19.8c-20.3,0-38.2-10.5-48.7-26.4h60.8l10.9-23.5H63.3c-0.4-2.8-0.7-5.7-0.7-8.6c0-2,0.1-4,0.3-5.9h87.8l11-23.5H70.6C80.7,203.1,99.4,191.4,120.9,191.4"
    />
    <path
      fill="#00489A"
      d="M120.9,192.1c17.3,0,33.6,7.7,44.6,21.3l0.7,0.9l0.5-1l9.5-20.4l0.2-0.5L176,192c-15.6-14.8-36.2-22.9-57.9-22.9c-17,0-33.3,5-47.2,14.4c-13.6,9.1-24,21.9-30.1,36.8l0.7-0.4H22.9h-0.5l-0.2,0.4l-11,23.5l-0.5,1h1.1h23.9l-0.7-0.8c-0.2,2.4-0.3,4.6-0.3,6.6c0,2.6,0.1,5.3,0.4,8.1l0.7-0.8h-12h-0.4l-0.2,0.4l-11,23.5l-0.5,1h1.1h29l-0.7-0.4c13.1,30.2,43.2,49.7,76.8,49.7c16.8,0,33-4.9,46.9-14.1l0.3-0.2v-0.4v-28.8v-1.9l-1.2,1.4c-10.9,12.4-26.6,19.6-43.1,19.6c-19.4,0-37.4-9.7-48.1-26l-0.6,1.1h60.8h0.4l0.2-0.4l10.9-23.5l0.5-1H144H63.4l0.7,0.6c-0.4-2.8-0.6-5.7-0.6-8.6c0-1.9,0.1-3.9,0.3-5.8l-0.7,0.6h87.8h0.5l0.2-0.4l10.9-23.5l0.5-1h-1.1H70.6l0.6,1.1C81.5,203.1,100.5,192.1,120.9,192.1 M70.6,221.2h91.2l-0.6-1l-11,23.5l0.6-0.4H63h-0.6l-0.1,0.6c-0.2,2-0.3,4-0.3,6c0,2.9,0.2,5.8,0.7,8.8l0.1,0.6h0.6H144l-0.6-1l-10.9,23.5l0.6-0.4H72.3H71l0.7,1.1c10.9,16.7,29.4,26.7,49.2,26.7c16.9,0,33-7.3,44.2-20l-1.2-0.5v28.8l0.3-0.6c-13.6,9.1-29.6,13.9-46.1,13.9c-33,0-62.6-19.2-75.5-48.9l-0.2-0.4H42h-29l0.7,1l10.9-23.5l-0.6,0.4h12h0.8l-0.1-0.8c-0.3-2.6-0.4-5.3-0.4-7.9c0-2,0.1-4.1,0.3-6.5l0.1-0.8h-0.8H11.9l0.6,1l11-23.5l-0.6,0.4h18.5h0.5l0.2-0.4c6-14.7,16.3-27.2,29.6-36.2c13.7-9.2,29.7-14.1,46.4-14.1c21.3,0,41.6,8,57,22.5l-0.2-0.8l-9.5,20.4l1.2-0.2c-11.2-13.8-27.9-21.8-45.7-21.8c-20.9,0-40.4,11.3-50.9,29.5l-0.6,1H70.6z"
    />
  </g>,
  "Sepa"
);

export const Sepa = (props: SvgIconProps): JSX.Element => (
  <SepaBase {...props} viewBox="-153 -46 652 652" />
);
