import { useEffect, useState } from "react";

import { Box } from "@mui/material";

import type { TextFieldProps } from "../../../TextField";
import type {
  CardBaseEvent,
  CardCVV,
  CardElementType,
  CardExpiry,
  CardNumber,
} from "@chargebee/chargebee-js-react-wrapper";

import { TextField } from "../../../TextField";
import { ChargebeeInput } from "./ChargebeeInput";

export type ChargebeeFieldProps = TextFieldProps & {
  component: typeof CardNumber | typeof CardExpiry | typeof CardCVV;
};

/**
 * Chargebee hosted field integrated to TextField.
 */
export function ChargebeeField<Field = CardElementType>(
  props: React.PropsWithoutRef<ChargebeeFieldProps>
) {
  const { component, InputProps, inputProps, ...rest } = props;
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [shrink, setShrink] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setShrink(isFocus || !isEmpty);
  }, [isFocus, isEmpty]);

  return (
    <Box position="relative">
      <Box
        sx={{
          display: props.disabled ? "block" : "none",
          position: "absolute",
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          opacity: 0.5,
          zIndex: 1,
        }}
      />
      <TextField
        fullWidth
        InputLabelProps={{ shrink }}
        InputProps={{
          ...InputProps,
          inputProps: {
            ...inputProps,
            component,
            onFocus: () => {
              setIsFocus(true);
            },
            onBlur: () => {
              setIsFocus(false);
            },
            onChange: (e) => {
              const event = e as unknown as CardBaseEvent<Field, "number" | "expiry" | "cvv">;
              setIsEmpty(event.empty);
              setSuccess(event.complete && !event.error);
              setError(event.complete && !!event.error);
            },
          },
          inputComponent: ChargebeeInput,
        }}
        success={success}
        error={error}
        {...rest}
      />
    </Box>
  );
}
