import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { SwipeableTabsProps } from "../../Tab/SwipeableTabs";
import type { TableProps } from "../../Table";
import type { ActionButtonsProps } from "../ActionButtons";
import type { HeadingContentProps } from "../HeadingContent";

import { SwipeableTabs } from "../../Tab/SwipeableTabs";
import { Table } from "../../Table";
import { ActionButtons } from "../ActionButtons";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockTabTableProps {
  headingContent: HeadingContentProps;
  callToAction?: Pick<ActionButtonsProps, "buttons">;
  /** Swipable tabs */
  tabs: {
    /** Tab label */
    label: SwipeableTabsProps["tabs"][0]["label"];
    table: Omit<TableProps, "topTitle">;
  }[];
}

export const BlockTabTable = (props: BlockTabTableProps) => {
  const theme = useTheme();

  return (
    <GridContainer>
      <Grid
        item
        mobile={12}
        tablet={props.tabs.some((tab) => tab.table.rows[0].length >= 4) ? 4 : 6}
        laptop={props.tabs.some((tab) => tab.table.rows[0].length >= 4) ? 5 : 6}
        alignSelf="flex-start"
      >
        <HeadingContent
          {...props.headingContent}
          bottomContent={
            props.callToAction?.buttons.length && (
              <ActionButtons
                {...props.callToAction}
                direction="column"
                grow={true}
                display={{ mobile: "none", tablet: "block" }}
                boxProps={{ mt: { tablet: 2 } }}
              />
            )
          }
        />
      </Grid>
      <Grid
        item
        mobile={12}
        tablet={props.tabs.some((tab) => tab.table.rows[0].length >= 4) ? 8 : 6}
        laptop={props.tabs.some((tab) => tab.table.rows[0].length >= 4) ? 7 : 6}
        alignSelf="flex-start"
      >
        <SwipeableTabs
          tabProps={{ sx: { p: theme.spacing(2) } }}
          tabIndicatorProps={{
            style: {
              display: undefined,
              borderBottom: `${theme.spacing(0.5)} solid ${theme.palette.background.default}`,
            },
          }}
          tabPanelBoxProps={{ sx: { p: 0, backgroundColor: theme.palette.background.default } }}
          tabs={props.tabs.map((tab) => ({
            label: tab.label,
            component: <Table {...tab.table} />,
          }))}
        />
        {props.callToAction && (
          <ActionButtons
            {...props.callToAction}
            direction="column"
            grow={true}
            display={{ mobile: "block", tablet: "none" }}
          />
        )}
      </Grid>
    </GridContainer>
  );
};
