import { IOSArrowLeft, IOSArrowRight } from "@augment-frontend/mui-icons";
import { Box, IconButton, MobileStepper as MuiStepper } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { InfoContentProps } from "../InfoContent";
import type { BoxProps, IconButtonProps } from "@mui/material";

import { InfoContent } from "../InfoContent";

export interface StepperProps {
  /** Array of stepper steps */
  steps: Omit<InfoContentProps, "onClose">[];
  /** Active step index set just before stepper is opened */
  activeStep: number | undefined;
  /** Triggered when close button is clicked */
  onClose: IconButtonProps["onClick"];
  /** Triggered when back button is clicked */
  onBackClick: IconButtonProps["onClick"];
  /** Triggered when next button is clicked */
  onNextClick: IconButtonProps["onClick"];
  /** Additional container style props */
  boxProps?: BoxProps;
}

export const Stepper = (props: StepperProps) => {
  const theme = useTheme();

  return (
    <Box {...props.boxProps}>
      {props.steps.map((step, index) => (
        <Box
          key={index}
          sx={{
            display: index === props.activeStep ? "block" : "none",
            bgcolor: theme.palette.primary[600],
            borderRadius: {
              mobile: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
            },
            minHeight: "180px",
          }}
        >
          <InfoContent {...step} onClose={props.onClose} />
        </Box>
      ))}
      <MuiStepper
        variant="dots"
        steps={props.steps.length}
        position="static"
        activeStep={props.activeStep}
        backButton={
          <IconButton onClick={props.onBackClick}>
            <IOSArrowLeft sx={{ color: theme.palette.text.primary }} />
          </IconButton>
        }
        nextButton={
          <IconButton onClick={props.onNextClick}>
            <IOSArrowRight sx={{ color: theme.palette.text.primary }} />
          </IconButton>
        }
        sx={{
          display: typeof props.activeStep !== "undefined" ? "flex" : "none",
          backgroundColor: theme.palette.primary[600],
          borderRadius: {
            mobile: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
          },
          pt: 0,
        }}
      />
    </Box>
  );
};
