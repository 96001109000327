import { CloseRound, Info } from "@augment-frontend/mui-icons";
import { IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { IconButtonProps, SvgIcon } from "@mui/material";

export interface InfoContentProps {
  /** Info content title text placed next to icon */
  title: string;
  /** Info content description part placed under icon and title */
  description: React.ReactNode;
  /** Step icon; if not provided, a generic (i) is used */
  icon?: typeof SvgIcon;
  /** Triggered when info content element closes */
  onClose?: IconButtonProps["onClick"];
}

export const InfoContent = (props: InfoContentProps) => {
  const theme = useTheme();
  const Icon = props.icon || Info;

  return (
    <Stack
      direction="column"
      sx={{
        bgcolor: theme.palette.primary[600],
        borderRadius: theme.spacing(1),
        p: 2,
      }}
    >
      <Stack direction="row" justifyContent="space-between" mb={1}>
        <Stack direction="row">
          <Icon sx={{ mr: 1 }} />
          <Typography component="span" variant="largeTextBold">
            {props.title}
          </Typography>
        </Stack>
        <IconButton
          sx={{
            display: { mobile: "flex", tablet: "none" },
            cursor: "pointer",
            m: theme.spacing(-1),
          }}
          onClick={props.onClose}
        >
          <CloseRound sx={{ color: theme.palette.text.primary }} />
        </IconButton>
      </Stack>
      <Typography sx={{ mt: 1, mb: 0 }}>{props.description}</Typography>
    </Stack>
  );
};
