import { Facebook, Instagram, Linkedin, Twitter, Youtube } from "@augment-frontend/mui-icons";
import { Link, Stack } from "@mui/material";

import type { SvgIcon } from "@mui/material";

import { Surface } from "../Surface";

export interface SocialMediaProps {
  /** URLs of the external social media sites */
  urls: URL[];
}

const ICONS: Record<URL["hostname"], typeof SvgIcon> = {
  "facebook.com": Facebook,
  "instagram.com": Instagram,
  "linkedin.com": Linkedin,
  "twitter.com": Twitter,
  "youtube.com": Youtube,
};

export const SocialMedia = (props: SocialMediaProps) => (
  <Stack direction="row" spacing={2} py={2}>
    {props.urls.map((url, index) => {
      const Icon = ICONS[url.hostname.replace("www.", "")];

      if (!Icon) {
        return null;
      }

      return (
        <Link key={index} href={url.href} target="_blank" rel="noopener noreferrer">
          <Surface
            sx={(theme) => ({
              display: "inline-flex",
              p: "10px",
              borderRadius: "50%",
              bgcolor: theme.palette.primary.main,
            })}
          >
            <Icon sx={{ color: (theme) => theme.palette.text.primary }} />
          </Surface>
        </Link>
      );
    })}
  </Stack>
);
