import type { FC } from "react";

import { Box, Stack } from "@mui/material";

import type { RadioGroupCardRowProps } from "../../../../../Radio";

import { RadioGroupCardColumn, RadioGroupCardRow } from "../../../../../Radio";
import { SubHeader } from "../SubHeader";

type ProductCategoriesProps = {
  onCategoryChange: (category: string) => void;
  /** All available Product Categories */
  options: RadioGroupCardRowProps<string>["options"];
  /** Value of the initially selected category */
  initialCategory: string;
  translations: {
    /** E.g. "Choose your core" */
    title: string;
  };
};

/**
 * Render Title & Selection for Product Categories, when 2-3 options are given.
 * If only 1 option is given (or more than 3), this doesn't render anything
 */
export const ProductCategories: FC<ProductCategoriesProps> = (props) => {
  return (
    props.options.length >= 2 &&
    props.options.length <= 3 && (
      <Stack
        direction="column"
        spacing={3}
        maxWidth={{ mobile: "560px", laptop: props.options.length === 2 ? "560px" : "900px" }}
        width="100%"
        data-testid="productCategories"
      >
        <SubHeader title={props.translations.title} />
        <Box display={{ mobile: "none", laptop: "block" }}>
          <RadioGroupCardRow
            laptopCardSpacing={2}
            largerOptionTitle
            onChange={(value) => props.onCategoryChange(value)}
            options={props.options}
            initialValue={props.initialCategory}
          />
        </Box>
        <Box display={{ mobile: "block", laptop: "none" }}>
          <RadioGroupCardColumn
            onChange={(value) => props.onCategoryChange(value)}
            options={props.options}
            initialValue={props.initialCategory}
          />
        </Box>
      </Stack>
    )
  );
};
