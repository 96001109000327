import type { SSNHandler, SSNResult } from "./types";

/**
 * Validate Swiss SSN (AHV/AVS/OASI)
 * The Swiss SSN is composed of 13 digits and optional dots (756.xxxx.xxxx.xc)
 * The first 3 digits represents Swizerland country code and are always 756 (ISO code)
 * The last digit is a check digit calculated based on the preceding digits
 * @returns Validity of SSN, -1 for age (not available for Swiss SSN), and standardized SSN with dots (if valid)
 */
export const swissSSN: SSNHandler = (ssn) => {
  const result: SSNResult = {
    valid: false,
    age: -1,
    standardizedSSN: null,
  };

  // Check if ssn satisfies format 756.dddd.dddd.dd | 756dddddddddd
  // - 13 characters, only numbers (and optional dots)
  // - starts with 756
  // - dots are in the correct positions if present
  if (!/^756(\.\d{4}){2}\.\d{2}$/.test(ssn) && !/^756\d{10}$/.test(ssn)) {
    return result;
  }

  const ssnWithoutDots = ssn.replace(/\./g, "");
  const ssnArray = ssnWithoutDots.split("").map((char) => parseInt(char, 10));

  // Calculate check digit based on the first 12 digits:
  // The digits at odd positions (0-indexed) are taken as they are
  // The digits at even positions (0-indexed) are multiplied by 3
  // The total sum is then subtracted from the next higher multiple of 10
  // The result is the check digit
  const sum = ssnArray.slice(0, 12).reduce((accumulator, currentValue, index) => {
    if (index % 2 === 0) {
      return accumulator + currentValue;
    }
    return accumulator + currentValue * 3;
  }, 0);

  const checkDigit = sum % 10 === 0 ? 0 : 10 - (sum % 10);

  // Check if calculated check digit matches the last digit of the SSN
  if (checkDigit === ssnArray[12]) {
    result.valid = true;
    // Add dots (back) to the standardized SSN (756.xxxx.xxxx.xc)
    result.standardizedSSN = `${ssnWithoutDots.slice(0, 3)}.${ssnWithoutDots.slice(
      3,
      7
    )}.${ssnWithoutDots.slice(7, 11)}.${ssnWithoutDots.slice(11)}`;
  }

  return result;
};
