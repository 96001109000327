import type { SSNHandler, SSNResult } from "./types";

import { ageInYears } from "./util";

/**
 * Calculate the validity of a Italian SSN and the age of the person.
 * @returns Validity and age, or -1 as age if the SSN is invalid.
 */
export const italianSSN: SSNHandler = (ssn) => {
  const SSN_REGEX =
    /^(?:[B-DF-HJ-NP-TV-Z](?:[AEIOU]{2}|[AEIOU]X)|[AEIOU]{2}X|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[\dLMNP-V][1-9MNP-V]|[1-9MNP-V][0L]))[A-Z]$/i;
  const MONTH_MAP = new Map([
    ["A", 1],
    ["B", 2],
    ["C", 3],
    ["D", 4],
    ["E", 5],
    ["H", 6],
    ["L", 7],
    ["M", 8],
    ["P", 9],
    ["R", 10],
    ["S", 11],
    ["T", 12],
  ]);

  const result: SSNResult = {
    valid: false,
    age: -1,
    standardizedSSN: null,
  };

  result.valid = SSN_REGEX.test(ssn);

  if (!result.valid) {
    return result;
  }

  const today = new Date();

  const ssnYearTwoLast = parseInt(ssn.substring(6, 8), 10);
  const todayYearTwoLast = parseInt(today.getFullYear().toString().substring(2), 10);
  const year = ssnYearTwoLast > todayYearTwoLast ? 1900 + ssnYearTwoLast : 2000 + ssnYearTwoLast;
  const month = MONTH_MAP.get(ssn.substring(8, 9)) as number; // Regex validates the month
  let day = parseInt(ssn.substring(9, 11), 10);

  if (day > 31) {
    day -= 40; // women
  }

  result.age = ageInYears(new Date(year, month - 1, day, 0, 0, 0, 0), today);
  result.standardizedSSN = result.valid ? ssn : null;

  return result;
};
