import type { FC } from "react";
import { useState } from "react";

import { CloseRound } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Grid, IconButton, Link, Snackbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import isEmail from "validator/es/lib/isEmail";

import { Alert } from "../../Alert";
import { TextField } from "../../TextField";
import { Container } from "../Container";

export interface AuthForgotPasswordProps {
  onSubmit: (email: string) => Promise<Error | void>;
  /** E.g. Gatsby Link. */
  loginLinkComponent: React.ElementType;
  translations: {
    /** E.g. Forgot password? */
    title: string;
    /** E.g. "Email" */
    emailLabel: string;
    /** E.g. Reset password */
    resetButton: string;
    /** E.g. Login */
    login: string;
    /** E.g. Oh no */
    errorTitle: string;
  };
}

const credentialsInitialState = {
  username: "",
  usernameError: false,
};

export const AuthForgotPassword: FC<AuthForgotPasswordProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [credentials, setCredentials] =
    useState<typeof credentialsInitialState>(credentialsInitialState);
  const [onSubmitError, setOnSubmitError] = useState<string>("");
  const [displaySnackbar, setDisplaySnackbar] = useState<boolean>(false);
  const theme = useTheme();

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value.toLowerCase().replace(/\s/g, "");
    setCredentials({
      username: value,
      usernameError: !isEmail(value, { domain_specific_validation: true }),
    });
  };

  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOnSubmitError("");
    setLoading(true);
    try {
      await props.onSubmit(credentials.username);
    } catch (e: unknown) {
      setOnSubmitError(e instanceof Error ? e.message : "Unknown error");
      setDisplaySnackbar(true);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setDisplaySnackbar(false);
  };

  return (
    <>
      <Container>
        <Typography variant="subheadingBold" mb={4}>
          {props.translations.title}
        </Typography>
        <Grid container spacing={2} direction="column" component="form" onSubmit={onSubmitHandler}>
          <Grid item>
            <TextField
              fullWidth
              type="text"
              inputProps={{ inputMode: "email" }}
              name="username"
              error={credentials.usernameError}
              label={props.translations.emailLabel}
              value={credentials.username}
              onChange={onChangeHandler}
              disabled={loading}
              data-testid="usernameInput"
            />
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              fullWidth
              disabled={credentials.usernameError || !credentials.username}
              loading={loading}
              data-testid="resetButton"
            >
              {props.translations.resetButton}
            </LoadingButton>
          </Grid>
          <Grid item alignSelf="center">
            <Typography variant="bodyText" sx={{ textAlign: "center" }}>
              <Link
                component={props.loginLinkComponent}
                sx={{ color: theme.palette.text.primary, textDecoration: "underline" }}
              >
                {props.translations.login}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={displaySnackbar} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          severity="error"
          title={props.translations.errorTitle}
          description={onSubmitError}
          action={
            <IconButton sx={{ color: theme.palette.text.primary }} onClick={handleClose}>
              <CloseRound />
            </IconButton>
          }
          forceActionRight
          sx={{
            m: 1,
            width: {
              mobile: "100%",
              tablet: "unset",
            },
          }}
        />
      </Snackbar>
    </>
  );
};
