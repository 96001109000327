import { useEffect, useRef, useState } from "react";

import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useIntersectionObserver, useWindowSize } from "usehooks-ts";

import type { BottomNavigationProps } from "../../Navigation";
import type { InfoBoxProps } from "../Info";
import type { Breakpoint } from "@mui/material/styles";

import { BottomNavigation } from "../../Navigation";
import { Surface } from "../../Surface";
import { InfoBox } from "../Info";

export interface StepProps {
  /** Title of the step */
  title: string;
  /** Step specific component on the centre (third on mobile) */
  stepComponent: {
    /** Custom Grid size E.g. mobile={4} */
    viewports?: {
      [key in Breakpoint]?: number;
    };
    component: React.ReactNode;
  };
  /** Contents of the left panel (second on mobile) */
  leftPanel?: {
    /** Custom Grid size E.g. mobile={4} */
    viewports?: {
      [key in Breakpoint]?: number;
    };
    /** E.g. Summary component (not displayed on mobile) */
    summary: React.ReactNode;
    /** InfoBox component (displayed on every viewport) */
    furtherDetails?: InfoBoxProps;
  };
  /** Contents of the right panel (first on mobile) */
  rightPanel?: {
    /** Custom Grid size E.g. mobile={4} */
    viewports?: {
      [key in Breakpoint]?: number;
    };
    infoBox1?: InfoBoxProps;
    infoBox2?: InfoBoxProps;
  };
  bottomNavigation: Omit<BottomNavigationProps, "containerRef">;
  /**
   * If true, on mobile, InfoBox2 will be displayed at the bottom of the page instead of
   * right after InfoBox1. This can be useful for de-prioritizing InfoBox2 contents.
   */
  showInfoBox2EndOfPageOnMobile?: boolean;
  /** Hide furtherDetails on mobile */
  hideFurtherDetailsOnMobile?: boolean;
  translations: {
    /** E.g. "Scroll to continue" */
    forwardButtonScrollDown: string;
  };
}

export const Step = (props: StepProps) => {
  const [paddingBottom, setPaddingBottom] = useState(0);
  const [isBottomReached, setIsBottomReached] = useState(false);
  const navigationRef = useRef<HTMLDivElement>(null);
  const { ref: bottomRef, entry: bottomObserver } = useIntersectionObserver({
    freezeOnceVisible: true,
    rootMargin: "50px",
    threshold: 0,
  });
  const { width, height } = useWindowSize();
  const theme = useTheme();

  const rightPanelGrids = props.rightPanel?.viewports;
  const leftPanelGrids = props.leftPanel?.viewports;
  const stepComponentGrids = props.stepComponent.viewports;

  useEffect(() => {
    if (navigationRef.current) {
      const padding = navigationRef.current.clientHeight;
      setPaddingBottom(padding);

      // TODO custom launcher is required, to change position on mobile
      // Currently, the launcher defaults to 20px padding on mobile, after
      // the Intercom window is closed, thus custom launcher is needed.
      const openLauncher = document.getElementsByClassName("intercom-launcher-frame").item(0);
      const closedLauncher = document
        .getElementsByClassName("intercom-lightweight-app-launcher")
        .item(0);
      const element = openLauncher || closedLauncher;

      if (element) {
        // @ts-expect-error TODO find alternative: TS complains that style is missing, but it works
        element.style.bottom = `${padding}px`;
        window.intercomSettings = { ...window.intercomSettings, vertical_padding: padding };
        window.Intercom?.("update", window.intercomSettings);
      }
    }
  }, [width, height]);

  useEffect(() => {
    if (bottomObserver) {
      // this attribute is only for e2e testing purposes to know if the observer is mounted
      document.querySelector('[data-testid="bottomObserver"]')?.removeAttribute("data-observer");
      if (bottomObserver.isIntersecting) {
        setIsBottomReached(true);
      }
    }
  }, [bottomObserver]);

  return (
    <>
      <Grid
        container
        rowSpacing={{ mobile: 2, laptop: 4 }}
        columnSpacing={{ mobile: 2, tablet: 4 }}
        pb={`${paddingBottom}px`}
      >
        {/* Title text is displayed on mobile as a separated item */}
        <Grid item mobile={12} display={{ mobile: "block", tablet: "none" }}>
          <Typography variant="subheadingBold" textAlign="center">
            {props.title}
          </Typography>
        </Grid>

        {/* Left Panel */}
        {props.leftPanel && (
          <Grid
            item
            mobile={leftPanelGrids?.mobile || 12}
            tablet={leftPanelGrids?.tablet || 6}
            laptop={leftPanelGrids?.laptop || 3}
            order={{ mobile: 2, laptop: 1 }}
            sx={{
              display: {
                // Hide the left panel on mobile in the following scenarios:
                // 1. No further details are provided - this prevents extra spacing.
                // 2. hideFurtherDetailsOnMobile is true - furtherDetails may be displayed
                // in bottom navigation summary instead.
                mobile:
                  !props.leftPanel.furtherDetails || props.hideFurtherDetailsOnMobile
                    ? "none"
                    : "block",
                tablet: "block",
              },
            }}
          >
            {/* Spacing 0 is needed on mobile, since summary's display is none. */}
            <Stack spacing={{ mobile: 0, tablet: 2, laptop: 4 }}>
              {/* Summary is hidden in mobile, since it is
               * displayed within absolute positioned navigation.
               */}
              <Surface
                sx={{
                  display: { mobile: "none", tablet: "block" },
                  bgcolor: { tablet: "transparent" },
                  p: { mobile: undefined, laptop: 0 },
                }}
              >
                {props.leftPanel.summary}
              </Surface>
              {props.leftPanel.furtherDetails && (
                <Surface
                  sx={{
                    display: {
                      mobile: props.hideFurtherDetailsOnMobile ? "none" : "block",
                      tablet: "block",
                    },
                    backgroundColor: {
                      mobile: theme.palette.secondary.dark,
                      tablet: theme.palette.secondary[200],
                      laptop: "transparent",
                    },
                    p: { mobile: undefined, laptop: 0 },
                  }}
                >
                  <InfoBox {...props.leftPanel.furtherDetails} />
                </Surface>
              )}
            </Stack>
          </Grid>
        )}
        {/* Central Panel [Step] */}
        <Grid
          item
          mobile={stepComponentGrids?.mobile || 12}
          tablet={stepComponentGrids?.tablet || 6}
          laptop={stepComponentGrids?.laptop || 6}
          order={{ mobile: 3, laptop: 2 }}
        >
          {/* Title text is displayed up from tablet inside the step component's surface */}
          {props.stepComponent.component}
        </Grid>
        {/* Right Panel */}
        {props.rightPanel && (
          <>
            {/* Tablet & Laptop */}
            <Grid
              item
              tablet={rightPanelGrids?.tablet || 6}
              laptop={rightPanelGrids?.laptop || 3}
              display={{ mobile: "none", tablet: "block" }}
              order={{ tablet: 1, laptop: 3 }}
            >
              <Stack spacing={{ tablet: 2, laptop: 4 }}>
                {props.rightPanel.infoBox1 && (
                  <Surface>
                    <InfoBox {...props.rightPanel.infoBox1} />
                  </Surface>
                )}
                {props.rightPanel.infoBox2?.content && (
                  <Surface
                    sx={{
                      bgcolor: {
                        tablet: theme.palette.primary[600],
                        laptop: "transparent",
                      },
                      p: { laptop: 0 },
                    }}
                  >
                    <InfoBox {...props.rightPanel.infoBox2} />
                  </Surface>
                )}
              </Stack>
            </Grid>
            {/* Mobile */}
            {props.showInfoBox2EndOfPageOnMobile ? (
              <>
                <Grid
                  item
                  mobile={rightPanelGrids?.mobile || 12}
                  display={{ mobile: "block", tablet: "none" }}
                  order={1}
                >
                  <Stack spacing={2}>
                    {props.rightPanel.infoBox1 && (
                      <Surface>
                        <InfoBox {...props.rightPanel.infoBox1} />
                      </Surface>
                    )}
                  </Stack>
                </Grid>
                <Grid
                  item
                  mobile={rightPanelGrids?.mobile || 12}
                  display={{ mobile: "block", tablet: "none" }}
                  order={4}
                >
                  <Stack spacing={2}>
                    {props.rightPanel.infoBox2?.content && (
                      <Surface>
                        <InfoBox {...props.rightPanel.infoBox2} />
                      </Surface>
                    )}
                  </Stack>
                </Grid>
              </>
            ) : (
              <Grid
                item
                mobile={rightPanelGrids?.mobile || 12}
                display={{ mobile: "block", tablet: "none" }}
                order={1}
              >
                <Stack spacing={2}>
                  {props.rightPanel.infoBox1 && (
                    <Surface>
                      <InfoBox {...props.rightPanel.infoBox1} />
                    </Surface>
                  )}
                  {props.rightPanel.infoBox2?.content && (
                    <Surface>
                      <InfoBox {...props.rightPanel.infoBox2} />
                    </Surface>
                  )}
                </Stack>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Box ref={bottomRef} data-testid="bottomObserver" data-observer="not-mounted" />
      <BottomNavigation
        {...props.bottomNavigation}
        containerRef={navigationRef}
        disableForward={props.bottomNavigation.disableForward || !isBottomReached}
        forwardButton={
          !isBottomReached
            ? props.translations.forwardButtonScrollDown
            : props.bottomNavigation.forwardButton
        }
      />
    </>
  );
};
