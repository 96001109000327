import type { ChangeEvent, FC, HTMLProps } from "react";

import { styled } from "@mui/material/styles";

const Label = styled("label")<HTMLProps<HTMLLabelElement> & { checked?: boolean }>((props) => ({
  display: "flex",
  flexDirection: "column",
  width: "24px",
  cursor: "pointer",
  ...(props.checked === true && {
    "span:nth-of-type(1)": {
      transformOrigin: "bottom",
      width: "50%",
      transform: "rotatez(45deg) translate(3px, 0px)",
    },
    "span:nth-of-type(2)": {
      transformOrigin: "top",
      width: "100%",
      transform: "rotatez(-45deg)",
    },
    "span:nth-of-type(3)": {
      transformOrigin: "bottom",
      transform: "translate(10.5px, -3.5px) rotatez(45deg)",
    },
  }),
}));

const Input = styled("input")({
  display: "none",
});

const Line = styled("span")((props) => ({
  background: props.theme.palette.text.secondary,
  borderRadius: "8px",
  height: "3px",
  margin: "2px 0",
  transition: "0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6)",
  ":nth-of-type(1)": {
    width: "100%",
  },
  ":nth-of-type(2)": {
    width: "75%",
  },
  ":nth-of-type(3)": {
    width: "50%",
  },
}));

export interface MenuIconProps {
  /** Whether the icon is in open state. Mutating this prop will animate the icon. */
  open: boolean;
  /** Callback illustrates whether icon is in open state. */
  onClick: (open: boolean) => void;
}

export const MenuIcon: FC<MenuIconProps> = (props) => {
  const handleClick = (event: ChangeEvent<HTMLInputElement>) => {
    const bool = event.currentTarget.checked;
    props.onClick(bool);
  };

  return (
    <Label htmlFor="check" checked={props.open} data-testid="newLayoutMenuIcon">
      <Input type="checkbox" id="check" checked={props.open} onChange={handleClick} />
      <Line />
      <Line />
      <Line />
    </Label>
  );
};
