import { useState } from "react";

import { Checkbox, FormControlLabel, FormGroup, Grid, useTheme } from "@mui/material";

import type { AddressInputProps } from "../../../../Inputs";
import type { UserInformation } from "../../types";
import type { CountryCode } from "@augment-frontend/constants";

import { AddressInput } from "../../../../Inputs";

export type BillingAddressInfoTranslations = {
  billingInformationDifferent: string;
};

export interface BillingAddressInfoProps<T extends CountryCode> {
  countryCode: T;
  allDisabled?: boolean;
  translations: BillingAddressInfoTranslations & {
    addressComponent: Omit<AddressInputProps["translations"], "shippingInformationTitle">;
  };
  billingInformation?: Partial<UserInformation["billingInformation"]>;
  onChangeBillingAddressHandler: AddressInputProps["onChange"];
  onChangeBillingAddressDifferentHandler: (checked: boolean) => void;
}

/**
 * Billing address information form
 * */
export const BillingAddressInfo = <T extends CountryCode>(props: BillingAddressInfoProps<T>) => {
  const theme = useTheme();
  const [billingAddressDifferent, setBillingAddressDifferent] = useState(
    Boolean(props.billingInformation)
  );

  const billingAddressDifferentHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setBillingAddressDifferent(checked);
    props.onChangeBillingAddressDifferentHandler(checked);
  };
  return (
    <>
      <FormGroup sx={{ marginTop: { mobile: -1 } }}>
        <FormControlLabel
          control={
            <Checkbox
              data-testid="billingAddressDifferent"
              defaultChecked={Boolean(props.billingInformation)}
              disabled={props.allDisabled}
              onChange={billingAddressDifferentHandler}
            />
          }
          label={props.translations.billingInformationDifferent}
        />
      </FormGroup>
      {billingAddressDifferent && (
        <Grid mt={-1} container spacing={theme.spacing(1)} data-testid="billingAddressContainer">
          <AddressInput
            translations={props.translations.addressComponent}
            address={{
              line1: props.billingInformation?.street1,
              line2: props.billingInformation?.street2,
              houseNumber: props.billingInformation?.houseNumber,
              city: props.billingInformation?.city,
              state: {
                name: props.billingInformation?.state || "",
              },
              postalCode: props.billingInformation?.postalCode,
              country: { code: props.countryCode },
            }}
            disabled={props.allDisabled}
            countryDisabled={true}
            onChange={props.onChangeBillingAddressHandler}
          />
        </Grid>
      )}
    </>
  );
};
