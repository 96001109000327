import { Grid } from "@mui/material";

import type { HeadingContentProps } from "../HeadingContent";

import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockRichTextProps {
  headingContent: HeadingContentProps;
  richTextContent: React.ReactNode;
}

export const BlockRichText = (props: BlockRichTextProps) => (
  <GridContainer>
    {props.headingContent && (
      <Grid item mobile={12}>
        <HeadingContent {...props.headingContent} boxProps={{ width: { tablet: "50%" } }} />
      </Grid>
    )}
    <Grid item mobile={12}>
      {props.richTextContent}
    </Grid>
  </GridContainer>
);
