import type { FC } from "react";
import { useEffect, useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

import type { ProgrammaticModalProps } from "../../../Modal";
import type { PaymentMethodModalHeaderProps } from "../PaymentMethodModalHeader";

import { Alert } from "../../../Alert";
import { ProgrammaticModal } from "../../../Modal";
import { PaymentMethodModalHeader } from "../PaymentMethodModalHeader";

type PaymentMethodRole = "primary" | "backup";

export type ChangePaymentMethodModalProps = {
  /**
   * Triggered when a payment method is clicked.
   * Thrown error is catched and its message is shown.
   */
  onChangeRole: (paymentMethodId: string, role: PaymentMethodRole) => Promise<Error | void>;
  onClose: NonNullable<ProgrammaticModalProps["onClose"]>;
  /**
   * Triggered when operation succeeded.
   */
  onCancel: () => void;
  onCompleted: (role: PaymentMethodRole) => void;
  open: ProgrammaticModalProps["open"];
  translations: {
    paymentMethodModalHeader: PaymentMethodModalHeaderProps["translations"];
    /** E.g. "Primary" */
    primary: string;
    /** E.g. "Backup" */
    backup: string;
    /** E.g. "Backup payment method is used if primary payment method fails" */
    backupAdvice: string;
    /** E.g. "Set" */
    submitButton: string;
    /** E.g. "Cancel" */
    cancelButton: string;
  };
} & Omit<PaymentMethodModalHeaderProps, "translations">;

export const ChangePaymentMethodModal: FC<ChangePaymentMethodModalProps> = (props) => {
  const [role, setRole] = useState<PaymentMethodRole>(
    props.paymentMethod.isBackup ? "backup" : "primary"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [onSubmitError, setOnSubmitError] = useState<string>("");

  useEffect(() => {
    if (props.open) {
      setRole(props.paymentMethod.isBackup ? "backup" : "primary");
    }
  }, [props.open]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setOnSubmitError("");
    setLoading(true);
    try {
      await props.onChangeRole(props.paymentMethod.id, role);
      props.onCompleted(role);
      setRole("primary");
    } catch (err) {
      setOnSubmitError(err instanceof Error ? err.message : "Unknown error");
    }
    setLoading(false);
  };

  return (
    <ProgrammaticModal
      open={props.open}
      index={0}
      onClose={props.onClose}
      views={[
        <Box
          key="paymentMethod"
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          component="form"
          onSubmit={handleSubmit}
        >
          <PaymentMethodModalHeader
            paymentMethod={props.paymentMethod}
            icon={props.icon}
            translations={props.translations.paymentMethodModalHeader}
          />
          <Box pl={1} alignSelf="stretch">
            <FormControl sx={{ mt: 2 }}>
              <RadioGroup
                value={role}
                onChange={(event, value) => {
                  setRole(value as PaymentMethodRole);
                }}
                name="payment-method-types-group"
              >
                <FormControlLabel
                  value="primary"
                  control={<Radio />}
                  label={props.translations.primary}
                  data-testid="primaryRadio"
                />
                <FormControlLabel
                  value="backup"
                  control={<Radio />}
                  label={props.translations.backup}
                  disabled={props.paymentMethod.isBackup}
                  data-testid="backupRadio"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Typography my={1} mb={2}>
            {props.translations.backupAdvice}
          </Typography>
          {onSubmitError && (
            <Alert
              severity="error"
              description={onSubmitError}
              sx={{ mb: 2, alignSelf: "stretch" }}
            />
          )}
          <Stack direction="row" alignSelf="stretch">
            <Button fullWidth variant="outlined" onClick={props.onCancel} sx={{ mr: 2 }}>
              {props.translations.cancelButton}
            </Button>
            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              disabled={
                (role === "primary" && props.paymentMethod.isPrimary) ||
                (role === "backup" && props.paymentMethod.isBackup)
              }
              data-testid="confirmChangePaymentMethodButton"
            >
              {props.translations.submitButton}
            </LoadingButton>
          </Stack>
        </Box>,
      ]}
    />
  );
};
