import type { FC, ReactNode } from "react";

import { IOSArrowRight } from "@augment-frontend/mui-icons";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { ButtonProps } from "@mui/material";

export interface BlogGridItemProps {
  /** Image shown first in the BlogGridItem */
  image: ReactNode;
  /** Publish information of the blog, e.g. "3/17/2021 by Augment" */
  publishInfo: string;
  /** Title of the blog */
  title: string;
  /** Description of the blog */
  description: ReactNode;
  /** Button props for the read more button */
  readMoreButtonProps: ButtonProps;
}

export const BlogGridItem: FC<BlogGridItemProps> = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: "100%",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary[600],
        borderRadius: `${theme.shape.borderRadius}px`,
      }}
    >
      <Stack direction="column" justifyContent="space-between" height="100%">
        <Stack direction="column" spacing={theme.spacing(2)} marginBottom={theme.spacing(2)}>
          <Box height="280px">{props.image}</Box>
          <Typography variant="subheadingBold">{props.title}</Typography>
          <Typography variant="caption">{props.publishInfo}</Typography>
          {props.description}
        </Stack>
        <Button
          {...props.readMoreButtonProps}
          fullWidth
          variant="outlined"
          endIcon={<IOSArrowRight />}
          sx={{ justifyContent: "space-between" }}
        />
      </Stack>
    </Box>
  );
};
