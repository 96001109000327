import type { SSNHandler, SSNResult } from "./types";

import { ageInYears } from "./util";

const isValidSSN = (ssn: string): boolean => {
  const MULTIPLIERS = [4, 3, 2, 7, 6, 5, 4, 3, 2, 1];

  const numbers = ssn
    .replace("-", "")
    .split("")
    .map((char) => Number(char));

  return (
    numbers.reduce((sum, digit, index) => sum + digit * (MULTIPLIERS[index] as number), 0) % 11 ===
    0
  );
};

const getDateOfBirth = (ssn: string): Date => {
  const day = parseInt(ssn.substring(0, 2), 10);
  const month = parseInt(ssn.substring(2, 4), 10);
  const year = parseInt(ssn.substring(4, 6), 10);
  const digit7 = parseInt(ssn[7] as string, 10);
  let century = 1900;

  // century correction
  if (digit7 === 4 || digit7 === 9) {
    // 1900 or 2000
    if (year <= 36) {
      century = 2000;
    }
  } else if (digit7 > 4) {
    // 1800 or 2000
    if (year > 57) {
      century = 1800;
    } else {
      century = 2000;
    }
  }

  return new Date(century + year, month - 1, day, 0, 0, 0, 0);
};

/**
 * Calculate the validity of a Danish SSN and the age of the person.
 * @returns Validity and age, or -1 as age if the SSN is invalid.
 */
export const danishSSN: SSNHandler = (ssn) => {
  const value = ssn.charAt(6) === "-" ? ssn : `${ssn.substring(0, 6)}-${ssn.substring(6)}`;
  const SSN_REGEX = /^\d{6}-\d{4}$/;

  const result: SSNResult = {
    valid: false,
    age: -1,
    standardizedSSN: null,
  };

  if (!SSN_REGEX.test(value)) {
    return result;
  }

  result.valid = isValidSSN(value);
  result.age = result.valid ? ageInYears(getDateOfBirth(value)) : -1;
  result.standardizedSSN = result.valid ? value : null;

  return result;
};
