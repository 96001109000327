import { Box, Grid, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import type { ActionButtonsProps } from "../ActionButtons";
import type { HeadingContentProps } from "../HeadingContent";
import type { SvgIcon } from "@mui/material";

import { Surface } from "../../Surface";
import { ActionButtons } from "../ActionButtons";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

const StyledItemDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  [theme.breakpoints.up("tablet")]: { ...theme.typography.bodyText },
}));

export interface BlockChainProps {
  headingContent: HeadingContentProps;
  callToAction?: Pick<ActionButtonsProps, "buttons">;
  /** Items in the chained list */
  items: {
    /** Title of the item */
    title: string;
    /** Text content */
    description: string;
    /** Icon */
    icon: typeof SvgIcon;
    /** Indented items belong together in a secondary chain */
    indented: boolean;
  }[];
}

export const BlockChain = (props: BlockChainProps) => {
  const theme = useTheme();

  return (
    <GridContainer>
      <Grid item mobile={12} tablet={6} sx={{ order: { mobile: 1, tablet: 0 } }}>
        <Surface
          sx={{
            p: { mobile: 0, tablet: 4 },
            bgcolor: {
              mobile: theme.palette.background.default,
              tablet: theme.palette.primary[600],
            },
            mb: { mobile: 2, tablet: 0 },
          }}
        >
          <Box mb={{ mobile: 4, tablet: 0 }}>
            {props.items.map((item, index) => {
              const Icon = item.icon;
              const hasIndentedStyleTop =
                // First item
                (index === 0 && item.indented) ||
                // Any other item
                (index > 0 && !props.items[index - 1].indented);
              const hasIndentedStyleBottom =
                // Last item
                (index === props.items.length - 1 && item.indented) ||
                // Any other item
                (index < props.items.length - 1 && !props.items[index + 1].indented);

              return (
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  sx={{
                    bgcolor: item.indented ? theme.palette.secondary.A200 : undefined,
                    mb: hasIndentedStyleBottom ? 2 : 0,
                    "&:last-child": { mb: 0, "&>div>p": { mb: hasIndentedStyleBottom ? 2 : 0 } },
                    borderRadius: `${hasIndentedStyleTop ? theme.spacing(1) : 0} ${
                      hasIndentedStyleTop ? theme.spacing(1) : 0
                    } ${hasIndentedStyleBottom ? theme.spacing(1) : 0} ${
                      hasIndentedStyleBottom ? theme.spacing(1) : 0
                    }`,
                  }}
                >
                  <Stack sx={{ ml: item.indented ? `${theme.spacing(2)} !important` : 0 }}>
                    <Surface
                      sx={{
                        display: "inline-flex",
                        p: "10px",
                        mt: item.indented ? 2 : 0,
                        mb: 2,
                        bgcolor: theme.palette.primary.main,
                        border: item.indented
                          ? undefined
                          : `1px solid ${theme.palette.primary[200]}`,
                      }}
                    >
                      <Icon />
                    </Surface>
                    <Stack
                      flexGrow={1}
                      sx={{
                        width: "50%",
                        borderRight: `2px solid ${
                          item.indented ? theme.palette.text.secondary : theme.palette.text.primary
                        }`,
                        mb:
                          index < props.items.length - 1 &&
                          item.indented !== props.items[index + 1].indented
                            ? 2
                            : 0,
                        display: index === props.items.length - 1 ? "none" : "block",
                      }}
                    ></Stack>
                  </Stack>
                  <Stack pr={item.indented ? 2 : 0}>
                    <Typography
                      component="p"
                      variant="subheadingBold"
                      mt={item.indented ? "25px" : "9px"}
                      color={
                        item.indented ? theme.palette.text.secondary : theme.palette.text.primary
                      }
                    >
                      {item.title}
                    </Typography>
                    <StyledItemDescription
                      // @ts-expect-error TODO fix types, component may be passed as a prop
                      component="p"
                      mt={2}
                      mb={
                        index < props.items.length - 1 &&
                        item.indented !== props.items[index + 1].indented
                          ? 4
                          : 2
                      }
                      color={
                        item.indented ? theme.palette.text.secondary : theme.palette.text.primary
                      }
                    >
                      {item.description}
                    </StyledItemDescription>
                  </Stack>
                </Stack>
              );
            })}
          </Box>
        </Surface>
        {props.callToAction && (
          <ActionButtons
            {...props.callToAction}
            direction="column"
            grow={true}
            display={{ mobile: "block", tablet: "none", laptop: "none" }}
          />
        )}
      </Grid>
      <Grid
        item
        mobile={12}
        tablet={6}
        sx={{
          order: { mobile: 0, tablet: 1 },
          alignSelf: { tablet: "flex-start", laptop: "center" },
        }}
      >
        <Box ml={{ laptop: 4 }}>
          <HeadingContent
            {...props.headingContent}
            bottomContent={
              props.callToAction?.buttons.length && (
                <ActionButtons
                  {...props.callToAction}
                  direction={{ mobile: "column", tablet: "column", laptop: "row" }}
                  grow={true}
                  display={{ mobile: "none", tablet: "block", laptop: "block" }}
                  boxProps={{ mt: { tablet: 2 } }}
                />
              )
            }
          />
        </Box>
      </Grid>
    </GridContainer>
  );
};
