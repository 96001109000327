import type { FC } from "react";

import { Link as LinkIcon } from "@augment-frontend/mui-icons";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { ProgrammaticModalProps } from "../../../../../../Modal";
import type { CopyFieldProps } from "../CopyField";

import { ProgrammaticModal } from "../../../../../../Modal";
import { CopyField } from "../CopyField";

export type LinkProps = {
  /** Triggered when close button is clicked. */
  onCancel: () => void;
  shareLink: CopyFieldProps["textToCopy"];
  translations: {
    /** E.g. "Share e-scooter link" */
    title: string;
    /** E.g. "A generated link is valid for 24 hours." */
    description: string;
    /** E.g. "Close" */
    closeButton: string;
  };
} & Required<Pick<ProgrammaticModalProps, "onClose" | "open">>;

export const Link: FC<LinkProps> = (props) => {
  const theme = useTheme();

  return (
    <ProgrammaticModal
      index={0}
      open={props.open}
      onClose={props.onClose}
      views={[
        <Box
          key="shareLinkModal"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <LinkIcon sx={{ fontSize: "64px", marginBottom: theme.spacing(1) }} />
          <Typography
            variant="subheadingBold"
            textAlign="center"
            sx={{ marginBottom: theme.spacing(2) }}
          >
            {props.translations.title}
          </Typography>
          <Typography variant="bodyText" textAlign="center" sx={{ marginBottom: theme.spacing(4) }}>
            {props.translations.description}
          </Typography>
          <CopyField textToCopy={props.shareLink} />
          <Button fullWidth onClick={props.onCancel}>
            {props.translations.closeButton}
          </Button>
        </Box>,
      ]}
    />
  );
};
