/**
 * Masks all characters of a string except the last 3 characters.
 *
 * @param {string} stringToMask - The string to mask.
 * @returns {string} - The masked string with all but the last 3 characters replaced by asterisks (*).
 */
export const maskString = (stringToMask: string) => {
  const unmaskedChars = 3;
  const maskLength = stringToMask.length - unmaskedChars;

  if (stringToMask.length <= unmaskedChars) {
    return stringToMask;
  }

  const masked = stringToMask.slice(0, maskLength).replace(/./g, "*");
  const unmasked = stringToMask.slice(-unmaskedChars);

  return masked + unmasked;
};
