import type { FC, ReactNode } from "react";

import { Container as MaterialContainer } from "@mui/material";

import { Surface } from "../Surface";

/**
 * The container specifies safe margins for Authentication components.
 */
export const Container: FC<{ children?: ReactNode }> = (props) => (
  <MaterialContainer
    maxWidth="tablet"
    disableGutters
    sx={{
      my: { mobile: 3, tablet: 4, laptop: 4 },
      px: { mobile: 3, tablet: 23.7, laptop: 23.7 },
    }}
  >
    <Surface sx={{ p: 4 }}>{props.children}</Surface>
  </MaterialContainer>
);
