import type { FC } from "react";
import { useState } from "react";

import { Escooter } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";

import type { ProgrammaticModalProps } from "../../../../../Modal";

import { ProgrammaticModal } from "../../../../../Modal";
import { TextField } from "../../../../../TextField";

export type NameModalProps = {
  /** Name of device */
  name: string;
  /**
   * Triggered when new name is submitted.
   * Thrown error is catched and its message is shown.
   */
  onSubmit: (value: string) => Promise<Error | void>;
  /**
   * Triggered when operation succeeded.
   */
  onCompleted: () => void;
  translations: {
    /** E.g. "Change name" */
    description: string;
    /** E.g. "Name" */
    label: string;
    /** E.g. "Save" */
    confirmButton: string;
    /** Showed if typed name doesn't pass validation, e.g. "Invalid input" */
    invalidInput: string;
  };
} & Required<Pick<ProgrammaticModalProps, "onClose" | "open">>;

const initialState = { value: "", error: "", loading: false };

export const NameModal: FC<NameModalProps> = (props) => {
  const nameInitialState: typeof initialState = { ...initialState, value: props.name };
  const [name, setName] = useState<typeof initialState>(nameInitialState);

  const reset = () => {
    setName(nameInitialState);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    event
  ) => {
    const value = event.target.value.trimStart();
    const error = /^[A-Za-z0-9 ]{1,32}$/.test(value) ? "" : props.translations.invalidInput;
    setName((prev) => ({ ...prev, value, error }));
  };

  const handleSubmit: React.FormEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();
    setName((prev) => ({ ...prev, loading: true }));
    let error = "";
    try {
      await props.onSubmit(name.value);
      props.onCompleted();
    } catch (e: unknown) {
      error = e instanceof Error ? e.message : "Unknown error";
    }
    setName((prev) => ({ ...prev, error, loading: false }));
  };

  return (
    <ProgrammaticModal
      index={0}
      open={props.open}
      onClose={(...args) => {
        props.onClose(...args);
        reset();
      }}
      views={[
        <Box
          key="name-modal"
          component="form"
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          onSubmit={handleSubmit}
        >
          <Escooter sx={{ fontSize: "64px", mb: 1 }} />
          <Typography variant="subheadingBold" textAlign="center" sx={{ mb: 3 }}>
            {props.translations.description}
          </Typography>
          <TextField
            fullWidth
            type="text"
            sx={{ mb: 2 }}
            label={props.translations.label}
            helperText={name.error}
            value={name.value}
            error={!!name.error}
            onChange={handleChange}
            inputProps={{ "data-testid": "nameInput" }}
          />
          <LoadingButton
            fullWidth
            type="submit"
            loading={name.loading}
            disabled={props.name === name.value || name.value.length === 0 || !!name.error}
            data-testid="saveButton"
          >
            {props.translations.confirmButton}
          </LoadingButton>
        </Box>,
      ]}
    />
  );
};
