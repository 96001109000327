import { useState } from "react";

import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { CookiesConsentModalProps } from "./CookiesConsentModal";
import type { LinkProps } from "@mui/material";
import type { SxProps } from "@mui/material/styles";

import { CookiesConsentModal } from "./CookiesConsentModal";

export type CookieItems = {
  [key: string]: "denied" | "granted";
};

export interface CookiesConsentProps {
  /** Triggered when the user accepts selected or pre-granted cookies */
  onAccept: (cookies: CookieItems) => void;
  /** Cookie categories to be shown/updated on modal */
  cookieCategories: {
    key: string;
    title: string;
    description: string;
  }[];
  /** Privacy Link to provide Privacy policy information */
  privacyLink: LinkProps;
  /** Override banner content styles */
  sx?: SxProps;
  translations: {
    /* E.g. Cookies personalize content, ads, and analyze traffic. We share data with ads and analytics partners for a better experience. */
    bannerMessage: string;
    /** E.g. Customize */
    customizeButton: string;
    modal: CookiesConsentModalProps["translations"];
  };
}

/** Cookies consent component managing cookies structured to work with GTM */
export const CookiesConsent = (props: CookiesConsentProps) => {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();

  /**
   * Pre-granted cookies, format:
   * {
   *  analytics: "granted",
   *  marketing: "granted",
   * }
   */
  const preGrantedCookies = props.cookieCategories.reduce((acc, category) => {
    acc[category.key] = "granted";
    return acc;
  }, {} as CookieItems);

  return (
    <Box sx={{ ...props.sx, backgroundColor: theme.palette.secondary.A200 }}>
      <Container
        maxWidth="laptop"
        sx={{
          backgroundColor: theme.palette.secondary.A200,
          py: { mobile: 3, tablet: 8 },
          px: { mobile: 2 },
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item mobile={12} tablet={8}>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.text.secondary, display: "block" }}
            >
              {props.translations.bannerMessage}
            </Typography>
            <Link
              key="privacyLink"
              {...props.privacyLink}
              variant="captionSemibold"
              sx={{ color: theme.palette.text[800] }}
            />
          </Grid>
          <Grid
            item
            mobile={12}
            tablet={2}
            order={{ mobile: 2, tablet: 1 }}
            display="flex"
            flexWrap="nowrap"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Button
              data-testid="customizeConsentCookies"
              fullWidth
              variant="text"
              size="small"
              color="secondary"
              onClick={() => setOpenModal(true)}
            >
              {props.translations.customizeButton}
            </Button>
          </Grid>
          <Grid
            item
            mobile={12}
            order={{ mobile: 1, tablet: 2 }}
            tablet={2}
            display="flex"
            flexWrap="nowrap"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Button
              data-testid="acceptConsentCookies"
              fullWidth
              onClick={() => props.onAccept(preGrantedCookies)}
            >
              {props.translations.modal.acceptButton}
            </Button>
          </Grid>
        </Grid>
      </Container>
      <CookiesConsentModal
        open={openModal}
        cookieCategories={props.cookieCategories}
        onCancel={() => setOpenModal(false)}
        onAccept={(cookies: CookieItems) => {
          props.onAccept(cookies);
          setOpenModal(false);
        }}
        translations={props.translations.modal}
      />
    </Box>
  );
};
