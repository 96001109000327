import { useState } from "react";

import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";

import type { InfoModalProps } from "../Modal";
import type { CookieItems, CookiesConsentProps } from "./CookiesConsent";

import { InfoModal } from "../Modal";

export interface CookiesConsentModalProps {
  open: InfoModalProps["open"];
  onAccept: CookiesConsentProps["onAccept"];
  /** Triggered when user clicks on cancel */
  onCancel: () => void;
  cookieCategories: {
    key: string;
    title: string;
    description: string;
  }[];
  /** Icon shown above modal title */
  translations: {
    /** E.g. Cookie Preferences */
    title: string;
    /** E.g. We respect your privacy and want to provide you with full control over your online
             experience. Below is the list of optional cookie categories. */
    description: string;
    /** E.g. Essential */
    essential: string;
    /** E.g. Enable core functions such as page navigation, customer support and secure access on the website. */
    essentialDescription: string;
    /** E.g. Save */
    acceptButton: string;
    /** E.g. Cancel */
    cancelButton: string;
  };
}

/** Cookies consent modal component that shows a list of passed categories to allow the user enable/disable them.
 */
export const CookiesConsentModal = (props: CookiesConsentModalProps) => {
  /**
   * User-selected cookies, format:
   * {
   *  analytics: "granted",
   *  marketing: "denied",
   * }
   */
  const [cookies, setCookies] = useState<CookieItems>(
    props.cookieCategories.reduce((acc, category) => {
      acc[category.key] = "granted";
      return acc;
    }, {} as CookieItems)
  );

  return (
    <InfoModal
      sx={{ maxWidth: "600px" }}
      open={props.open}
      icon={undefined}
      title={props.translations.title}
      description={
        <>
          <Grid container>
            <Typography variant="bodyText" sx={{ mb: 2 }}>
              {props.translations.description}
            </Typography>
            <FormGroup>
              <FormControlLabel
                key="required"
                control={
                  <span>
                    <Checkbox checked disabled />
                  </span>
                }
                label={
                  <>
                    <b>{props.translations.essential}: </b>
                    <span>{props.translations.essentialDescription}</span>
                  </>
                }
              />
              {props.cookieCategories.map((category) => (
                <FormControlLabel
                  key={category.key}
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        setCookies(
                          (prev): CookieItems => ({
                            ...prev,
                            [category.key]: checked ? "granted" : "denied",
                          })
                        );
                      }}
                    />
                  }
                  label={
                    <>
                      <b>{category.title}: </b>
                      <span>{category.description}</span>
                    </>
                  }
                />
              ))}
            </FormGroup>
          </Grid>

          <Grid container pt={4} columnSpacing={1} rowSpacing={1}>
            <Grid item mobile={6} tablet={4}>
              <Button fullWidth color="secondary" onClick={props.onCancel}>
                {props.translations.cancelButton}
              </Button>
            </Grid>
            <Grid item mobile={6} tablet={8}>
              <Button
                data-testid="acceptCustomizedConsentCookies"
                fullWidth
                onClick={() => props.onAccept(cookies)}
              >
                {props.translations.acceptButton}
              </Button>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};
