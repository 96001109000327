import { keyframes } from "@emotion/react";
import { styled } from "@mui/material/styles";

const StyledCircularProgress = styled("div")(({ theme }) => {
  const rotate = keyframes`
    0% { transform: rotate(0deg) scale(0.8) }
    50% { transform: rotate(360deg) scale(1.2) }
    100% { transform: rotate(720deg) scale(0.8) }
  `;

  const ball1 = keyframes`
    0% { box-shadow: 30px 0 0 ${theme.palette.primary.main} }
    50% { box-shadow: 0 0 0 ${theme.palette.primary.main}; margin-bottom: 0; transform: translate(15px, 15px) }
    100% { box-shadow: 30px 0 0 ${theme.palette.primary.main}; margin-bottom: 10px }
  `;

  const ball2 = keyframes`
    0% { box-shadow: 30px 0 0 ${theme.palette.background.paper} }
    50% { box-shadow: 0 0 0 ${theme.palette.background.paper}; margin-top: -20px; transform: translate(15px, 15px) }
    100% { box-shadow: 30px 0 0 ${theme.palette.background.paper}; margin-top: 0 }
  `;

  return {
    height: "50px",
    width: "50px",
    animation: `${rotate} 1s infinite`,
    "&::before, &::after": {
      borderRadius: "50%",
      content: '""',
      display: "block",
      height: "20px",
      width: "20px",
    },
    "&::before": {
      animation: `${ball1} 1s infinite`,
      backgroundColor: theme.palette.background.paper,
      boxShadow: `30px 0 0 ${theme.palette.primary.main}`,
      marginBottom: "10px",
    },
    "&::after": {
      animation: `${ball2} 1s infinite`,
      backgroundColor: theme.palette.primary.main,
      boxShadow: `30px 0 0 ${theme.palette.background.paper}`,
    },
  };
});

export const CircularProgress = () => {
  return <StyledCircularProgress />;
};
