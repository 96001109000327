import type { FC } from "react";

import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { PaymentMethod } from "../../PaymentMethods";

export interface PaymentMethodModalHeaderProps {
  /** PaymentMethod whose details are displayed */
  paymentMethod: PaymentMethod;
  /** Icon to display */
  icon: React.ElementType;
  translations: {
    /** E.g. "Expiration" */
    expiration: string;
  };
}

export const PaymentMethodModalHeader: FC<PaymentMethodModalHeaderProps> = (props) => {
  const theme = useTheme();
  const Icon = props.icon;

  return (
    <>
      <Icon
        sx={{
          fontSize: "64px",
          mb: theme.spacing(1),
          width: props.paymentMethod.type === "PAYPAL" ? "150px" : undefined,
        }}
      />
      <Typography
        variant="subheadingBold"
        sx={{ textTransform: "capitalize", mb: theme.spacing(1) }}
      >
        {props.paymentMethod.type === "PAYPAL"
          ? ""
          : `${
              !props.paymentMethod.brand || props.paymentMethod.brand === "OTHER"
                ? ""
                : props.paymentMethod.brand.toLowerCase().replace("_", " ")
            } **${props.paymentMethod.last4}`}
      </Typography>
      {props.paymentMethod.expiryMonth && props.paymentMethod.expiryYear && (
        <Typography>
          {`${props.translations.expiration} ${props.paymentMethod.expiryMonth} /
      ${props.paymentMethod.expiryYear}`}
        </Typography>
      )}
      {props.paymentMethod.bankName && <Typography>{props.paymentMethod.bankName}</Typography>}
      {props.paymentMethod.payPalEmail && (
        <Typography>{props.paymentMethod.payPalEmail}</Typography>
      )}
    </>
  );
};
