import { fetchAuthSession } from "aws-amplify/auth";

import { toError } from "./failure";

/**
 * Fetches the current authentication token from the session
 *
 * Attempts to retrieve the current user's authentication token by calling `fetchAuthSession`.
 * If successful, it returns the ID token as a string, which contains user identity information
 * like username and email.
 *
 * The consumer of this function is responsible for handling any errors
 * that may occur during the token retrieval process
 *
 * @returns A promise that resolves to the authentication token as a string
 *
 * @throws Throws an error if no token is found or if any other issue occurs during
 * the token retrieval process. The consumer of this function should catch and handle these errors
 * appropriately.
 */
export const fetchAmplifyAuthToken = async (): Promise<string> => {
  try {
    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
    if (!authToken) {
      throw new Error("No Amplify ID token found");
    }
    return authToken;
  } catch (error) {
    throw toError(error);
  }
};
