import type { FC } from "react";

import { CreditCard } from "@augment-frontend/mui-icons";
import { Box, Typography } from "@mui/material";

import type { ProgrammaticModalProps } from "../../../Modal";
import type { PaymentMethodControllerProps } from "../../../PaymentMethods/PaymentMethodController";

import { ProgrammaticModal } from "../../../Modal";
import { PaymentMethodController } from "../../../PaymentMethods/PaymentMethodController";

export type AddPaymentMethodModalProps = {
  translations: {
    /** E.g. "Add payment method" */
    title: string;
    paymentMethodController: PaymentMethodControllerProps["translations"];
  };
} & Required<Pick<ProgrammaticModalProps, "onClose" | "open">> &
  Pick<
    PaymentMethodControllerProps,
    | "cbInstance"
    | "locale"
    | "onSubmit"
    | "onPaymentSuccess"
    | "onSecondaryButtonClick"
    | "onUnknownPaymentError"
    | "tooltipImages"
  >;

/**
 * Add new payment method by confirming payment intent with Chargebee.js.
 */
export const AddPaymentMethodModal: FC<AddPaymentMethodModalProps> = (props) => {
  const { open, onClose, translations, locale, ...controllerProps } = props;

  return (
    <ProgrammaticModal
      open={open}
      index={0}
      onClose={(...args) => {
        onClose(...args);
      }}
      views={[
        <Box
          key="paymentMethod"
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <CreditCard sx={{ fontSize: "64px", mb: 1 }} />
          <Typography variant="subheadingBold" sx={{ mb: 3 }}>
            {translations.title}
          </Typography>
          <PaymentMethodController
            {...controllerProps}
            walletTypes={[]}
            wallet={{
              googlePayButtonVariant: "plain",
              applePayButtonVariant: "plain",
              payPalButtonVariant: "paypal",
            }}
            types={["card"]}
            secondaryButtonFullWidth={true}
            translations={translations.paymentMethodController}
          />
        </Box>,
      ]}
    />
  );
};
