import type { FC } from "react";

import { Done, IOSArrowDown } from "@augment-frontend/mui-icons";
import {
  FormControl,
  InputBase,
  inputBaseClasses,
  InputLabel,
  inputLabelClasses,
  MenuItem,
  Select as MuiSelect,
  selectClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import type { MenuItemProps, SelectProps as MuiSelectProps } from "@mui/material";

export type SelectProps = MuiSelectProps & {
  /** Initial value; can be an empty string "" */
  value: string;
  /** Label of the component */
  label: string;
  /** List of select's menu items */
  menuItems: MenuItemProps[];
  /** Callback function when the value changes */
  onChange: NonNullable<MuiSelectProps["onChange"]>;
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  // Styles follow Textfield
  [`& .${inputBaseClasses.input}`]: {
    backgroundColor: theme.palette.secondary[200],
    borderRadius: theme.shape.borderRadius,
    padding: "23px 12px 6px",
    boxShadow: `inset 0px 0px 0px 1px ${theme.palette.text.primary}`,
    [`&:hover:not(&.${inputBaseClasses.disabled}), &:focus:not(&.${inputBaseClasses.disabled})`]: {
      boxShadow: `inset 0px 0px 0px 2px ${theme.palette.text.primary}`,
    },
    "&:focus": {
      backgroundColor: theme.palette.secondary[200],
      borderRadius: theme.shape.borderRadius,
    },
  },
  [`&.${inputBaseClasses.disabled}`]: {
    "&.Mui-disabled": {
      color: theme.palette.text.primary,
      opacity: 0.5,
      [`& + .${inputLabelClasses.root}`]: {
        color: theme.palette.text.primary,
        opacity: 0.5,
      },
    },
    [`& .${selectClasses.iconFilled}`]: {
      "&.Mui-disabled": {
        color: "unset",
        opacity: 0.5,
      },
    },
  },
  [`& .${inputBaseClasses.disabled}`]: {
    backgroundColor: theme.palette.secondary[200],
  },
  [`& .${selectClasses.icon}`]: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
    fontSize: "1.25rem",
  },
  [`& .${selectClasses.iconOpen}`]: {
    color: theme.palette.primary[600],
  },
  "& .selector-selected-icon": {
    display: "none", // Hides the icon in the selector but not in the menu items list
  },
}));

export const Select: FC<SelectProps> = (props) => {
  const { menuItems, label, ...rest } = props;

  return (
    <FormControl fullWidth variant="filled">
      <MuiSelect
        {...rest}
        labelId={`select-${label}`}
        id="select"
        value={props.value}
        onChange={props.onChange}
        input={<BootstrapInput />}
        IconComponent={IOSArrowDown}
        data-testid="select"
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            sx={(theme) => ({
              m: theme.spacing(1),
              p: theme.spacing(1),
              mt: index === 0 ? 0 : undefined,
              mb: index === menuItems.length - 1 ? 0 : undefined,
              borderRadius: theme.spacing(1),
              justifyContent: "space-between",
            })}
            data-testid="select-menu-item"
          >
            {item.children}
            {item.value === props.value && <Done className="selector-selected-icon" />}
          </MenuItem>
        ))}
      </MuiSelect>
      {/* InputLabel follows MuiSelect so that next sibling selector can be used in styles */}
      <InputLabel
        id={`select-${label}`}
        sx={(theme) => ({
          // Styles follow Textfield
          fontSize: theme.typography.caption.fontSize,
          fontWeight: 500,
          color: theme.palette.text.primary,
          [`&.${inputLabelClasses.shrink}`]: {
            transform: "translate(14px, 4px)",
            color: theme.palette.text.primary,
          },
        })}
      >
        {label}
      </InputLabel>
    </FormControl>
  );
};
