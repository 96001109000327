import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { ActionButtonsProps } from "../ActionButtons";
import type { HeadingContentProps } from "../HeadingContent";
import type { SvgIcon } from "@mui/material";

import { Surface } from "../../Surface";
import { ActionButtons } from "../ActionButtons";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockFlowProps {
  headingContent: HeadingContentProps;
  callToAction?: Pick<ActionButtonsProps, "buttons">;
  items: {
    icon: typeof SvgIcon;
    description: string;
  }[];
}

export const BlockFlow = (props: BlockFlowProps) => {
  const theme = useTheme();

  return (
    <GridContainer>
      <Grid
        item
        mobile={12}
        tablet={6}
        laptop={8}
        sx={{
          mt: {
            tablet: 4, // same as right column's mt to keep both contents vertically centered
          },
        }}
      >
        <HeadingContent {...props.headingContent} boxProps={{ width: { laptop: "75%" } }} />
      </Grid>
      <Grid
        item
        mobile={12}
        tablet={6}
        laptop={4}
        sx={{
          maxHeight: { tablet: "350px", laptop: "650px" },
          overflowY: { tablet: "auto" },
          mt: { tablet: 4 },
          pt: { tablet: "0 !important" },
          pr: { tablet: 3 }, // to avoid scrollbar overlapping buttons
          pb: { tablet: 15 },
          mask: {
            tablet: `linear-gradient(to bottom, transparent, black ${theme.spacing(
              15
            )}, black calc(100% - ${theme.spacing(15)}), transparent)`,
          },
        }}
      >
        <Box mb={{ mobile: 5, tablet: 0 }}>
          {props.items.map((item, index) => {
            const Icon = item.icon;

            return (
              <Box key={index}>
                <Box position="relative">
                  <Typography
                    variant="extraLargeHighlight"
                    sx={{ opacity: 0.25, ml: { mobile: 1.5, tablet: 0 } }}
                  >
                    {String(index + 1).padStart(2, "0")}
                  </Typography>
                  <Surface
                    sx={{
                      position: "absolute",
                      display: "inline-flex",
                      p: "10px",
                      bottom: { mobile: theme.spacing(-2), tablet: theme.spacing(2) },
                      left: 0,
                      bgcolor: theme.palette.primary.main,
                    }}
                  >
                    <Icon />
                  </Surface>
                </Box>
                <Typography
                  variant="largeTextSemibold"
                  mt={{ mobile: 3, tablet: 0 }}
                  mb={{ mobile: 3, tablet: 2, laptop: 7 }}
                >
                  {item.description}
                </Typography>
              </Box>
            );
          })}
        </Box>
        {props.callToAction && (
          <ActionButtons {...props.callToAction} direction="column" grow={true} />
        )}
      </Grid>
    </GridContainer>
  );
};
