import { Globe } from "@augment-frontend/mui-icons";
import { Box, Link, Stack, Typography } from "@mui/material";

import type { BoxProps, LinkProps, SvgIconProps } from "@mui/material";

export interface LocaleSelectorProps<T extends React.ElementType> {
  /** Logo shown on top centered */
  logo?: React.ReactNode;
  /** Title text, e.g. "Select your location and language" */
  title?: string;
  /** Array of locales */
  locales: {
    /** Flag icon; optional due to "international" locale */
    flag?: React.ElementType<SvgIconProps>;
    /** Country name */
    country: string;
    /** Array of language links */
    links: LinkProps<T>[];
  }[];
  /** Additional locale selector container style props */
  boxProps?: BoxProps;
}

export const LocaleSelector = <T extends React.ElementType>(props: LocaleSelectorProps<T>) => (
  <>
    <Box mb={props.logo || props.title ? 4 : undefined}>
      {props.logo && <Box mb={2}>{props.logo}</Box>}
      <Typography variant="largeTextSemibold" align="center">
        {props.title}
      </Typography>
    </Box>
    <Box
      component="nav"
      display="flex"
      flexDirection="column"
      sx={{
        "&>div": {
          my: 1.5,
          "&:first-of-type": { mt: 0 },
          "&:last-of-type": { mb: 0 },
        },
      }}
      {...props.boxProps}
    >
      {props.locales.map((locale, index) => {
        const Flag = locale.flag || Globe;

        return (
          <Stack key={index} direction="row" justifyContent="space-between">
            <Link
              {...locale.links[0]}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                textDecoration: "underline",
                textDecorationThickness: "0.5px",
                "&:hover": {
                  textDecorationThickness: "auto",
                },
              }}
            >
              <Flag sx={(theme) => ({ color: theme.palette.text.primary })} />
              <Typography
                component="span"
                sx={(theme) => ({
                  display: "inline-block",
                  fontSize: {
                    mobile: theme.typography.caption.fontSize,
                    tablet: theme.typography.bodyText.fontSize,
                  },
                  ml: 1,
                })}
              >
                {locale.country}
              </Typography>
            </Link>
            <Box display="flex" justifyContent="flex-end" alignContent="center" flexWrap="wrap">
              {locale.links.map((link, linkIndex) => (
                <Link
                  key={linkIndex}
                  {...link}
                  sx={(theme) => ({
                    ml: 1,
                    fontSize: {
                      mobile: theme.typography.caption.fontSize,
                      tablet: theme.typography.bodyText.fontSize,
                    },
                    color: theme.palette.text.primary,
                    textDecoration: "underline",
                    textDecorationThickness: "0.5px",
                    "&:hover": {
                      textDecorationThickness: "auto",
                    },
                  })}
                />
              ))}
            </Box>
          </Stack>
        );
      })}
    </Box>
  </>
);
