import type { FC } from "react";

import { Stack, Typography } from "@mui/material";

export type ProductCategoryOptionProps = {
  /** Contract length in months, e.g. "24" */
  contractLength: string | number;
  /** Starting price without currency, e.g. "23.95" */
  startingPrice: string | number;
  translations: {
    /** This differs per ProductCategoryOption, e.g. "Balanced choice" | "Economical" */
    featureValue: string;
    /** These translations are same for every ProductCategoryOption */
    common: {
      keys: {
        /** E.g. "Contract" */
        second: string;
        /** E.g. "Starting from" */
        third: string;
      };
      values: {
        /** E.g. "months" */
        second: string;
        /** E.g. "€/month" */
        third: string;
      };
    };
  };
};

export const ProductCategoryOption: FC<ProductCategoryOptionProps> = (props) => {
  return (
    <Stack direction="column" spacing={0.5} marginTop={{ mobile: 1.5, laptop: 1.25 }}>
      <Typography variant="caption">{props.translations.featureValue}</Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="caption">{props.translations.common.keys.second}</Typography>
        <Typography variant="captionBold">
          {props.contractLength} {props.translations.common.values.second}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="caption">{props.translations.common.keys.third}</Typography>
        <Typography variant="captionBold">
          {props.startingPrice} {props.translations.common.values.third}
        </Typography>
      </Stack>
    </Stack>
  );
};
