import { loadingButtonClasses } from "@mui/lab";
import {
  accordionClasses,
  accordionSummaryClasses,
  alertClasses,
  buttonBaseClasses,
  buttonClasses,
  checkboxClasses,
  circularProgressClasses,
  formControlLabelClasses,
  formHelperTextClasses,
  formLabelClasses,
  inputClasses,
  inputLabelClasses,
  linearProgressClasses,
  menuItemClasses,
  mobileStepperClasses,
  outlinedInputClasses,
  radioClasses,
  stepConnectorClasses,
  stepIconClasses,
  stepLabelClasses,
  svgIconClasses,
  tabClasses,
  typographyClasses,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { pickersDayClasses, pickersMonthClasses, pickersYearClasses } from "@mui/x-date-pickers";

import type { Breakpoints, Components, PaletteOptions } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";

const primary: PaletteOptions["primary"] = {
  main: "#daff00", // A100
  light: "#e8f5c8", // 400
  dark: "#0b6361", // 200
  100: "#2aad94",
  200: "#0b6361", // dark
  400: "#e8f5c8", // light
  600: "#f5f5dc",
  800: "#ffce00",
  A100: "#daff00", // main
  A200: "#6cc6cb",
};

const secondary: PaletteOptions["secondary"] = {
  main: "#b5b4b4", // 600
  light: "#f9f9f7", // 200
  dark: "#0f0e0e", // A200
  200: "#f9f9f7", // light
  400: "#e6e5e5",
  600: "#b5b4b4", // main
  800: "#929292",
  A100: "#666666",
  A200: "#0f0e0e", // dark
};

const background: PaletteOptions["background"] = {
  default: secondary[200],
  paper: secondary[200],
};

const text: PaletteOptions["text"] = {
  primary: "#0f0f0e", // 200
  secondary: "#f9f9f7", // A100, 600, 800, A100, A200
  disabled: "#0f0f0e", // Override this in the component level
  200: "#0f0f0e", // primary, A100
  400: "#929292",
  600: "#f9f9f7",
  800: "#f9f9f7", // A100, 600, 800, A100, A200
  A100: "#f9f9f7", // A100, 600, 800, A100, A200
  A200: "#f9f9f7", // A100, 600, 800, A100, A200
};

const error: PaletteOptions["error"] = {
  main: "#cc006b",
  light: "#ff3300", // 100-400
  dark: "#db003a", // 600-A100
  100: "#ff3300",
  200: "#ff3300",
  400: "#ff3300",
  600: "#db003a",
  800: "#db003a",
  A100: "#db003a",
};

const warning: PaletteOptions["warning"] = {
  main: "#ffce00", // all same
  dark: "#ffce00",
  A100: "#ffce00",
  A200: "#ffce00",
};

const success: PaletteOptions["success"] = { main: primary[100] as string, dark: primary.main };

const secondaryFontFamilies: Array<string> = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

const baseFontFamily: string = ['"Gotham A"', ...secondaryFontFamilies].join(", ");
const headersFontFamily: string = ['"Stolzl"', ...secondaryFontFamilies].join(", ");

type TypographyBaseStyleVariant = {
  fontSize: string;
  lineHeight: number;
  letterSpacing?: string | number;
};

const typographyBaseStyleCaption: TypographyBaseStyleVariant = {
  fontSize: "0.875rem",
  lineHeight: 1.43,
  letterSpacing: "unset",
};

const typographyBaseStyleBodyText: TypographyBaseStyleVariant = {
  fontSize: "1rem",
  lineHeight: 1.5,
};

const breakpoints = {
  values: {
    mobile: 0,
    tablet: 834,
    laptop: 1280,
  } as Breakpoints["values"],
};

const createdTheme = createTheme({
  breakpoints: { ...breakpoints },
  palette: {
    mode: "light",
    primary,
    secondary,
    background,
    error,
    warning,
    success,
    text,
    contrastThreshold: 4.5,
  },
  typography: {
    allVariants: {
      fontFamily: baseFontFamily,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: 8,
          [`.${alertClasses.icon}`]: {
            padding: 0,
            marginRight: 8,
          },
          [`.${alertClasses.message}`]: {
            padding: 0,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "center",
          },
          [`& .${svgIconClasses.root}`]: {
            color: text.primary,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained",
      },
      styleOverrides: {
        root: {
          fontSize: "1.125rem",
          fontWeight: 700,
          lineHeight: 1.33,
          padding: "12px 16px",
          textTransform: "unset",
          borderRadius: "25px", // min half of height
          [`&.${buttonClasses.sizeSmall}`]: {
            ...typographyBaseStyleBodyText,
            padding: "3px 9px",
          },
          [`&.${buttonClasses.containedPrimary}`]: {
            color: text.primary,
            border: `1px solid ${primary.main}`,
            "&:hover": {
              backgroundColor: primary[800],
              border: `1px solid ${primary[800]}`,
            },
            "&:disabled, &.Mui-disabled": {
              backgroundColor: secondary[400],
              border: `1px solid ${secondary[400]}`,
              color: text[400],
              [`& .${buttonClasses.startIcon} .${svgIconClasses.root}, .${buttonClasses.endIcon} .${svgIconClasses.root}`]:
                {
                  color: text[400],
                },
            },
          },
          [`&.${buttonClasses.containedSecondary}`]: {
            backgroundColor: secondary[400],
            color: text.primary,
            border: `1px solid ${secondary[400]}`,
            "&:hover": {
              backgroundColor: secondary[600],
            },
            "&:disabled, &.Mui-disabled": {
              color: text[400],
              [`& .${buttonClasses.startIcon} .${svgIconClasses.root}, .${buttonClasses.endIcon} .${svgIconClasses.root}`]:
                {
                  color: text[400],
                },
            },
          },
          [`&.${buttonClasses.outlinedPrimary}`]: {
            color: text.primary,
            border: `1px solid ${text.primary}`,
            "&:hover": {
              border: `1px solid ${text.primary}`,
              backgroundColor: "rgba(222, 222, 222, 0.4)",
            },
          },
          [`&.${buttonClasses.outlinedSecondary}`]: {
            color: text[800],
            border: `1px solid ${text[800]}`,
            "&:hover": {
              border: `1px solid ${text[800]}`,
              backgroundColor: "rgba(222, 222, 222, 0.2)",
            },
            [`& .${buttonClasses.startIcon} .${svgIconClasses.root}, .${buttonClasses.endIcon} .${svgIconClasses.root}`]:
              {
                color: text.secondary,
              },
          },
          [`&.${buttonClasses.outlinedPrimary}, &.${buttonClasses.outlinedSecondary}`]: {
            backgroundColor: "transparent",
            "&:disabled": {
              color: text[400],
              borderColor: text[400],
              [`& .${buttonClasses.startIcon} .${svgIconClasses.root}, .${buttonClasses.endIcon} .${svgIconClasses.root}`]:
                {
                  color: text[400],
                },
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "10px",
          [`&.${radioClasses.disabled}`]: {
            opacity: 0.5,
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: primary[100],
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          ".Mui-disabled": {
            color: `${text.primary} !important`,
            opacity: 0.5,
            pointerEvents: "none",
          },
          [`.${buttonBaseClasses.root}`]: {
            color: text.primary,
          },
          [`.${typographyClasses.overline}, .${typographyClasses.caption}`]: {
            color: text.primary,
            opacity: 0.5,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          [`& .${formControlLabelClasses.label}.${formControlLabelClasses.disabled}`]: {
            color: text.primary,
            opacity: 0.5,
          },
          alignItems: "flex-start",
          marginTop: "10px",
          [`& .${checkboxClasses.root}, .${radioClasses.root}`]: {
            marginTop: "-10px",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          [`&.${formHelperTextClasses.disabled}`]: {
            opacity: 0.5,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: text.primary,
          [`&.${formLabelClasses.focused}`]: {
            color: text.primary,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: 2,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: primary[200],
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: primary[100],
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: primary[200],
          textDecoration: "underline",
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained",
      },
      styleOverrides: {
        root: {
          [`&.${buttonClasses.containedPrimary}`]: {
            [`& .${loadingButtonClasses.loadingIndicator}`]: {
              [`& .${circularProgressClasses.root}`]: {
                color: text.primary,
              },
            },
          },
          [`&.${buttonClasses.containedSecondary}`]: {
            [`& .${loadingButtonClasses.loadingIndicator}`]: {
              [`& .${circularProgressClasses.root}`]: {
                color: text.primary,
              },
            },
          },
          [`&.${buttonClasses.outlinedPrimary}`]: {
            [`& .${loadingButtonClasses.loadingIndicator}`]: {
              [`& .${circularProgressClasses.root}`]: {
                color: text.primary,
              },
            },
          },
          [`&.${buttonClasses.outlinedSecondary}`]: {
            [`& .${loadingButtonClasses.loadingIndicator}`]: {
              [`& .${circularProgressClasses.root}`]: {
                color: text.secondary,
              },
            },
          },
          [`&.${loadingButtonClasses.loading}`]: {
            "&:disabled, &.Mui-disabled": {
              color: "transparent",
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: primary[400],
          },
          [`&&.${menuItemClasses.selected}`]: {
            backgroundColor: primary[400],
            "&:hover": {
              backgroundColor: primary[400],
            },
          },
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          [`.${pickersDayClasses.root}.${pickersDayClasses.selected},
          .${pickersMonthClasses.root}.${pickersMonthClasses.selected},
          .${pickersYearClasses.yearButton}.${pickersYearClasses.selected}`]: {
            backgroundColor: primary[100],
            "&:hover, :focus": {
              backgroundColor: primary[100],
            },
          },
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          [` .${mobileStepperClasses.dots}`]: {
            flexGrow: 1,
          },
          [` .${mobileStepperClasses.dot}`]: {
            width: undefined,
            flexGrow: 1,
            borderRadius: "4px",
            [`&.${mobileStepperClasses.dotActive}`]: {
              backgroundColor: primary[200],
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "10px",
          [`&.${radioClasses.disabled}`]: {
            opacity: 0.5,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: secondary[600],
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          [`&.${stepConnectorClasses.disabled} .${stepConnectorClasses.line}`]: {
            borderColor: secondary.A200,
          },
        },
        line: {
          borderColor: secondary.A200,
          borderTopWidth: "2px",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fontWeight: "700",
          color: secondary.light,
          border: `1px solid ${secondary.A200}`,
          borderRadius: "50%",
          [`&.${stepIconClasses.active}`]: {
            color: primary.main,
          },
          [`&.${stepIconClasses.completed}`]: {
            color: secondary.A200,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          [`&.${stepLabelClasses.disabled} .${stepIconClasses.text}`]: {
            fill: text[200],
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: secondary.A200,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: text[200],
          [`&.${tabClasses.selected}`]: {
            backgroundColor: primary[400],
            color: text.primary,
          },
          [`&.${tabClasses.disabled}`]: {
            opacity: 0.5,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          padding: "8px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: text.primary,
        },
        root: {
          [`& .${svgIconClasses.root}`]: {
            color: "inherit",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        InputProps: {
          notched: false,
        },
      },
      styleOverrides: {
        root: {
          /* Standard variant TextField */
          [`.${inputClasses.root}`]: {
            [`&.${inputClasses.disabled}`]: {
              opacity: 0.5,
            },
            [`&.${inputClasses.underline}`]: {
              "&:after": {
                borderBottom: "2px solid black",
              },
            },
          },
          /* Outlined variant TextField */
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            border: "none",
          },
          [`.${outlinedInputClasses.root}`]: {
            ...typographyBaseStyleBodyText,
            boxShadow: `inset 0px 0px 0px 1px ${secondary.A200}`,
            fontWeight: 400,
            backgroundColor: secondary[200],
            /* Hover/Focused => Show dark border [except: disabled] */
            [`&:hover:not(.${outlinedInputClasses.disabled}):not(.${outlinedInputClasses.error}), &.${outlinedInputClasses.focused}`]:
              {
                boxShadow: `inset 0px 0px 0px 2px ${secondary.A200}`,
              },
            /* Disabled => Decrease opacity */
            [`&.${outlinedInputClasses.disabled}`]: {
              opacity: 0.5,
            },
            /* Error => Show red outline */
            [`&.${outlinedInputClasses.error}`]: {
              boxShadow: `inset 0px 0px 0px 2px ${error.main}`,
            },
            [`.${outlinedInputClasses.input}`]: {
              padding: "25px 14px 4px",
            },
          },
          [`.${inputLabelClasses.root}`]: {
            ...typographyBaseStyleCaption,
            fontWeight: 500,
            [`&.${inputLabelClasses.shrink}`]: {
              transform: "translate(14px, 4px)",
              [`&.${inputLabelClasses.standard}`]: {
                transform: "unset",
              },
            },
            [`&.${inputLabelClasses.disabled}`]: {
              opacity: 0.5,
            },
            [`&.${inputLabelClasses.error}`]: {
              color: error.main,
            },
          },
          [`.${formHelperTextClasses.root}`]: {
            color: text.primary,
            marginLeft: 0,
            marginRight: 0,
            wordBreak: "break-word",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: primary[600],
          color: text.primary,
        },
        arrow: {
          color: primary[600],
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: text.primary,
        variantMapping: {
          bodyText: "p",
          bodyTextSemibold: "p",
          bodyTextBold: "p",
          largeText: "p",
          largeTextSemibold: "p",
          largeTextBold: "p",
          subheading: "h4",
          subheadingBold: "h4",
          h3Bold: "h3",
          h2Bold: "h2",
          h1Bold: "h1",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            backgroundClip: "text", // Hides browser autofill background on inputs
          },
        },
      },
    },
  },
});

const responsiveTypographyBaseStyleLargeText: TypographyBaseStyleVariant = {
  lineHeight: 1.33,
  fontSize: "1.125rem",
  [createdTheme.breakpoints.up("tablet")]: {
    fontSize: "1.2rem",
  },
  [createdTheme.breakpoints.up("laptop")]: {
    fontSize: "1.3rem",
  },
};

const responsiveTypographyBaseStyleSubheading: TypographyBaseStyleVariant = {
  lineHeight: 1,
  fontSize: "1.5rem",
  [createdTheme.breakpoints.up("tablet")]: {
    fontSize: "1.65rem",
  },
  [createdTheme.breakpoints.up("laptop")]: {
    fontSize: "1.8rem",
  },
};

const responsiveTypographyBaseStyleH3: TypographyBaseStyleVariant = {
  lineHeight: 1,
  fontSize: "2rem",
  [createdTheme.breakpoints.up("tablet")]: {
    fontSize: "2.25rem",
  },
  [createdTheme.breakpoints.up("laptop")]: {
    fontSize: "2.5rem",
  },
};

const responsiveTypographyBaseStyleH2: TypographyBaseStyleVariant = {
  lineHeight: 1,
  fontSize: "2.5rem",
  [createdTheme.breakpoints.up("tablet")]: {
    fontSize: "2.75rem",
  },
  [createdTheme.breakpoints.up("laptop")]: {
    fontSize: "3rem",
  },
};

const responsiveTypographyBaseStyleH1: TypographyBaseStyleVariant = {
  lineHeight: 1,
  fontSize: "3rem",
  [createdTheme.breakpoints.up("tablet")]: {
    fontSize: "3.3rem",
  },
  [createdTheme.breakpoints.up("laptop")]: {
    fontSize: "3.6rem",
  },
};

/* ↓ Typographies ↓ */

createdTheme.typography.caption = {
  ...typographyBaseStyleCaption,
  fontWeight: 400,
};
createdTheme.typography.captionSemibold = {
  ...typographyBaseStyleCaption,
  fontWeight: 500,
};
createdTheme.typography.captionBold = {
  ...typographyBaseStyleCaption,
  fontWeight: 700,
};

createdTheme.typography.bodyText = {
  ...typographyBaseStyleBodyText,
  fontWeight: 400,
};

createdTheme.typography.bodyTextSemibold = {
  ...typographyBaseStyleBodyText,
  fontWeight: 500,
};

createdTheme.typography.bodyTextBold = {
  ...typographyBaseStyleBodyText,
  fontWeight: 700,
};

createdTheme.typography.largeText = {
  ...responsiveTypographyBaseStyleLargeText,
  fontWeight: 400,
};

createdTheme.typography.largeTextSemibold = {
  ...responsiveTypographyBaseStyleLargeText,
  fontWeight: 500,
};

createdTheme.typography.largeTextBold = {
  ...responsiveTypographyBaseStyleLargeText,
  fontWeight: 700,
};

createdTheme.typography.subheading = {
  ...responsiveTypographyBaseStyleSubheading,
  fontFamily: headersFontFamily,
  fontWeight: 400,
};

createdTheme.typography.subheadingBold = {
  ...responsiveTypographyBaseStyleSubheading,
  fontFamily: headersFontFamily,
  fontWeight: 700,
};

createdTheme.typography.h3 = {
  ...responsiveTypographyBaseStyleH3,
  fontFamily: headersFontFamily,
  fontWeight: 400,
};

createdTheme.typography.h3Bold = {
  ...responsiveTypographyBaseStyleH3,
  fontFamily: headersFontFamily,
  fontWeight: 700,
};

createdTheme.typography.h2 = {
  ...responsiveTypographyBaseStyleH2,
  fontFamily: headersFontFamily,
  fontWeight: 400,
};

createdTheme.typography.h2Bold = {
  ...responsiveTypographyBaseStyleH2,
  fontFamily: headersFontFamily,
  fontWeight: 700,
};

createdTheme.typography.h1 = {
  ...responsiveTypographyBaseStyleH1,
  fontFamily: headersFontFamily,
  fontWeight: 400,
};

createdTheme.typography.h1Bold = {
  ...responsiveTypographyBaseStyleH1,
  fontFamily: headersFontFamily,
  fontWeight: 700,
};

createdTheme.typography.extraLargeHighlight = {
  fontFamily: headersFontFamily,
  lineHeight: 1,
  fontWeight: 400,
  fontSize: "4.5rem",
  [createdTheme.breakpoints.up("tablet")]: {
    fontSize: "6.25rem",
  },
  [createdTheme.breakpoints.up("laptop")]: {
    fontSize: "8.375rem",
  },
};
/* ↑ Typographies ↑ */

(createdTheme.components as Components<typeof createdTheme>).MuiAccordion = {
  styleOverrides: {
    root: {
      backgroundColor: secondary[200],
      backgroundImage: "unset",
      margin: "8px 0",
      borderRadius: "8px",
      boxShadow: "none",
      [createdTheme.breakpoints.up("tablet")]: {
        borderRadius: "16px",
        margin: "16px 0",
      },
      "&::before": {
        backgroundColor: "unset",
      },
      "&:first-of-type, &:last-of-type": {
        borderRadius: "8px",
        [createdTheme.breakpoints.up("tablet")]: {
          borderRadius: "16px",
        },
      },
      [`&.${accordionClasses.expanded}`]: {
        margin: "8px 0",
        "&:first-of-type": {
          marginTop: "8px",
          [createdTheme.breakpoints.up("tablet")]: {
            marginTop: "16px",
          },
        },
        "&:last-of-type": {
          marginBottom: "8px",
          [createdTheme.breakpoints.up("tablet")]: {
            marginBottom: "16px",
          },
        },
      },
    },
  },
};

(createdTheme.components as Components<typeof createdTheme>).MuiAccordionDetails = {
  styleOverrides: {
    root: {
      backgroundColor: primary[400],
      borderRadius: "8px",
      padding: "0.01px 16px",
      marginTop: "8px",
      [createdTheme.breakpoints.up("tablet")]: {
        borderRadius: "16px",
        padding: "16px 32px",
        marginTop: "16px",
      },
    },
  },
};

(createdTheme.components as Components<typeof createdTheme>).MuiAccordionSummary = {
  styleOverrides: {
    root: {
      backgroundColor: secondary[200],
      borderRadius: "8px",
      border: `1px solid ${secondary.A200}`,
      padding: "0 16px",
      [createdTheme.breakpoints.up("tablet")]: {
        borderRadius: "16px",
        padding: "0 32px",
      },
      [`&.${accordionSummaryClasses.expanded}`]: {
        minHeight: 0,
      },
      [`& .${accordionSummaryClasses.content}`]: {
        margin: "16px 0",
        [`&.${accordionSummaryClasses.expanded}`]: {
          margin: "16px 0",
        },
        [createdTheme.breakpoints.up("tablet")]: {
          margin: "32px 0",
          [`&.${accordionSummaryClasses.expanded}`]: {
            margin: "32px 0",
          },
        },
      },
    },
  },
};

export const websiteTheme = createdTheme;
