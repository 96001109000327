import type { FC } from "react";

import { Done, Info } from "@augment-frontend/mui-icons";
import { Box, Button, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import type { TypographyProps } from "@mui/material";

import { Alert } from "../../../Alert";
import { Surface } from "../../../Surface";

const StyledTitle = styled(Typography)<Omit<TypographyProps, "variant">>((props) => ({
  ...props.theme.typography.subheadingBold,
  [props.theme.breakpoints.down("tablet")]: {
    ...props.theme.typography.largeTextBold,
  },
}));

export interface MyAccountAccessoriesInsuranceProps {
  /** Component to use in navigating to checkout, e.g. Gatsby Link. */
  checkoutLinkComponent: React.ElementType;
  /** E.g. "Basic" */
  planType: string;
  /** E.g. "1.04€ / month*" */
  price: string;
  /** Insurance plan details displayed with Done-icon */
  details: string[];
  /** URL of Terms and Conditions pdf file */
  termsAndConditionsPdfUrl: string;
  /** Disable purchase of the product */
  disabled?: boolean;
  /** Reason of disable */
  disableReason?: string;
  translations: {
    /** E.g. "Insurance plan" */
    title: string;
    /** E.g. "*Price depends on amount of subscriptions" */
    smallprint: string;
    /** E.g. "Terms and Conditions" */
    termsAndConditions: string;
    /** E.g. "*Purchase insurance" */
    purchaseButton: string;
  };
}

export const MyAccountAccessoriesInsurance: FC<MyAccountAccessoriesInsuranceProps> = (props) => {
  const theme = useTheme();

  return (
    <div>
      <StyledTitle dangerouslySetInnerHTML={{ __html: props.translations.title }} />
      <Surface
        sx={{
          bgcolor: theme.palette.secondary[200],
          paddingX: 0,
          marginY: 0,
        }}
      >
        <Stack direction="row" justifyContent="space-between" marginBottom={theme.spacing(1)}>
          <Typography variant="largeTextSemibold">{props.planType}</Typography>
          <StyledTitle>{props.price}</StyledTitle>
        </Stack>
        <Box marginBottom={theme.spacing(2)}>
          {props.details.map((detail, index) => (
            <Stack
              key={index}
              direction="row"
              spacing={theme.spacing(0.5)}
              alignItems="center"
              sx={{ marginBottom: theme.spacing(1) }}
            >
              <Done sx={{ color: theme.palette.success.main }} />
              <Typography variant="caption">{detail}</Typography>
            </Stack>
          ))}
        </Box>
        <Button
          variant="outlined"
          fullWidth
          endIcon={<Info />}
          href={props.termsAndConditionsPdfUrl}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ justifyContent: "space-between" }}
        >
          {props.translations.termsAndConditions}
        </Button>
      </Surface>
      <Typography variant="caption">{props.translations.smallprint}</Typography>
      {props.disableReason && (
        <Alert severity="warning" description={props.disableReason} sx={{ mt: 2 }} />
      )}
      <Button
        fullWidth
        component={props.checkoutLinkComponent}
        sx={{ marginTop: { mobile: theme.spacing(2), tablet: theme.spacing(4) } }}
        disabled={props.disabled}
        data-testid="purchaseInsuranceButton"
      >
        {props.translations.purchaseButton}
      </Button>
    </div>
  );
};
