import { Box, Grid, Stack, Typography } from "@mui/material";

import type { HeadingContentProps } from "../HeadingContent";
import type { SvgIcon } from "@mui/material";

import { Surface } from "../../Surface";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockInfoListWithImageProps {
  headingContent: HeadingContentProps;
  items: {
    icon: typeof SvgIcon;
    title: string;
    description: string;
  }[];
  image: React.ReactNode;
}

export const BlockInfoListWithImage = (props: BlockInfoListWithImageProps) => (
  <GridContainer>
    <Grid
      item
      mobile={12}
      tablet={6}
      laptop={8}
      sx={{ alignSelf: { tablet: "flex-start", laptop: "center" } }}
    >
      <HeadingContent {...props.headingContent} boxProps={{ pr: { laptop: 21.75 } }} />
      <Grid container spacing={4} mt={0} pr={{ laptop: 12 }}>
        {props.items.map((item, index) => {
          const Icon = item.icon;

          return (
            <Grid item key={index} mobile={12} laptop={6}>
              <Stack direction={{ mobile: "row", laptop: "column" }} mb={{ mobile: 2, laptop: 0 }}>
                <Box>
                  <Surface
                    sx={{
                      display: "inline-flex",
                      p: "10px",
                      mb: { laptop: 2 },
                      mr: { mobile: 1.25, laptop: 0 },
                      bgcolor: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <Icon />
                  </Surface>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography variant="subheadingBold">{item.title}</Typography>
                </Box>
              </Stack>
              <Typography mt={1}>{item.description}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
    <Grid item mobile={12} tablet={6} laptop={4} sx={{ display: "flex", alignSelf: "stretch" }}>
      {/* max-height: 500/465px come from 339/310px column widths and 3x2 crop ratio */}
      <Box sx={{ width: "100%", maxHeight: { tablet: "500px", laptop: "465px" } }}>
        {props.image}
      </Box>
    </Grid>
  </GridContainer>
);
