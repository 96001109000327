import type { FC, ReactElement } from "react";

import { Box, Typography } from "@mui/material";

import type { ScrollModalProps } from "./ScrollModal";

import { ScrollModal } from "./ScrollModal";

export type InfoModalProps = {
  /** E.g. "Battery" */
  title: string;
  /** E.g. "Your battery capacity options" */
  description: string | ReactElement;
  icon: React.ReactNode;
} & Omit<ScrollModalProps, "children">;

export const InfoModal: FC<InfoModalProps> = (props) => {
  return (
    <ScrollModal {...props}>
      <Box key="info-modal" sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Box>{props.icon}</Box>
        <Typography variant="subheadingBold" textAlign="center" sx={{ mb: 3 }}>
          {props.title}
        </Typography>
        {typeof props.description === "string" ? (
          <Typography textAlign="center" sx={{ mb: 3 }}>
            {props.description}
          </Typography>
        ) : (
          props.description
        )}
      </Box>
    </ScrollModal>
  );
};
