import { Grid, Typography } from "@mui/material";

import type { SocialMediaProps } from "../../SocialMedia";

import { SocialMedia } from "../../SocialMedia";
import { Surface } from "../../Surface";
import { GridContainer } from "../GridContainer";

export interface BlockFollowUsProps {
  title: string;
  description: string;
  socialMedia: SocialMediaProps;
}

export const BlockFollowUs = (props: BlockFollowUsProps) => (
  <GridContainer>
    <Grid item mobile={12}>
      <Surface
        sx={(theme) => ({
          bgcolor: {
            mobile: theme.palette.background.default,
            tablet: theme.palette.primary[600],
          },
          p: { mobile: 0, tablet: 4 },
          pb: { tablet: 2, laptop: 4 },
        })}
      >
        <Grid container>
          <Grid item mobile={12} laptop={props.socialMedia.urls.length > 3 ? 6 : 8}>
            <Typography variant="subheadingBold" textAlign={{ tablet: "center", laptop: "left" }}>
              {props.title}
            </Typography>
            <Typography
              mt={2}
              mb={{ mobile: 2, laptop: 0 }}
              textAlign={{ tablet: "center", laptop: "left" }}
            >
              {props.description}
            </Typography>
          </Grid>
          <Grid
            item
            mobile={12}
            laptop={props.socialMedia.urls.length > 3 ? 6 : 4}
            sx={{
              display: "flex",
              justifyContent: { mobile: "flex-start", tablet: "center", laptop: "flex-end" },
            }}
          >
            <SocialMedia {...props.socialMedia} />
          </Grid>
        </Grid>
      </Surface>
    </Grid>
  </GridContainer>
);
