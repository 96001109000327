import { type FC, Fragment } from "react";

import { Skeleton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { SummaryRowProps } from "./SummaryRow";
import type { SxProps } from "@mui/material";

import { SummaryRow } from "./SummaryRow";

export interface SummaryProps {
  /** E.g. "Summary" */
  title: string;
  /** Items of summary */
  items: {
    /** Title of the item e.g. "Model" */
    title: string;
    /** Value of the item e.g. "Augment ES210" */
    value: string;
    /** Display title as "largeText" and value as "largeTextBold" */
    largeText?: boolean;
    /**
     * Added items of the item, e.g. e-scooter add-ons. Displayed the same way as the item;
     * two strings (start & end) on a single row, space-between
     */
    addedItems?: {
      /** Name of the added item (displayed on start/left), e.g. "+ theft coverage" */
      name: string;
      /** Price of the added item (displayed on end/right), e.g. "2.49€" */
      price: string;
    }[];
  }[];
  /** Loading status of the component, replace item title & value with skeleton if present */
  loading?: boolean;
  /** If exists, display divider, title & value */
  summaryRow?: SummaryRowProps;
  /** Override summary content styles */
  sx?: SxProps;
}

export const Summary: FC<SummaryProps> = (props) => {
  const theme = useTheme();

  return (
    <div data-testid="summaryComponent">
      <Typography
        variant="largeTextBold"
        marginBottom={theme.spacing(2)}
        sx={{
          color: { mobile: theme.palette.text.secondary, tablet: theme.palette.text.primary },
          ...props.sx,
        }}
      >
        {props.title}
      </Typography>
      {props.items.map((item, index) => (
        <Stack key={index} direction="column" sx={{ marginBottom: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant={item.largeText ? "largeText" : "bodyText"}
              sx={{
                color: { mobile: theme.palette.text.secondary, tablet: theme.palette.text.primary },
                ...props.sx,
              }}
            >
              {props.loading ? <Skeleton width={100 + (index % 2.5) * 20} /> : item.title}
            </Typography>
            <Typography
              variant={item.largeText ? "largeTextSemibold" : "bodyTextSemibold"}
              align="right"
              sx={{
                color: { mobile: theme.palette.text.secondary, tablet: theme.palette.text.primary },
                ...props.sx,
              }}
            >
              {props.loading ? <Skeleton width={100 + (index % 1.9) * 40} /> : item.value}
            </Typography>
          </Stack>
          {!props.loading &&
            item.addedItems?.map((addedItem, i) => (
              <Fragment key={i}>
                <Stack direction="row" justifyContent="space-between" data-testid="addedItem">
                  <Typography
                    variant="caption"
                    sx={{
                      color: {
                        mobile: theme.palette.text.secondary,
                        tablet: theme.palette.text.primary,
                      },
                      ...props.sx,
                    }}
                  >
                    {addedItem.name}
                  </Typography>
                  <Typography
                    variant="captionSemibold"
                    sx={{
                      color: {
                        mobile: theme.palette.text.secondary,
                        tablet: theme.palette.text.primary,
                      },
                      ...props.sx,
                    }}
                  >
                    {addedItem.price}
                  </Typography>
                </Stack>
              </Fragment>
            ))}
        </Stack>
      ))}
      {props.summaryRow && (
        <SummaryRow {...props.summaryRow} loading={props.loading} sx={props.sx} />
      )}
    </div>
  );
};
