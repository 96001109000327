import type { FC } from "react";
import { useState } from "react";

import { Copy } from "@augment-frontend/mui-icons";
import { Button, Grid, Snackbar, Typography } from "@mui/material";

import { Alert } from "../../Alert";
import { Surface } from "../../Surface";

export interface MyAccountReferralProps {
  promoCode?: string | null;
  translations: {
    title: string;
    content: string;
    copyCode: string;
    copied: string;
  };
}

export const MyAccountReferral: FC<MyAccountReferralProps> = (props) => {
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  const copyCode = async (code: string) => {
    try {
      await navigator.clipboard.writeText(code);
      setShowSnackbar(true);
    } catch (error) {
      // fail silently
    }
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  return (
    <Surface>
      <Typography variant="largeTextBold" mb={2}>
        {props.translations.title}
      </Typography>
      <Typography
        variant="bodyText"
        component="div"
        dangerouslySetInnerHTML={{ __html: props.translations.content }}
      ></Typography>
      {props.promoCode && (
        <Grid container>
          <Grid item mobile={12} tablet={6} laptop={12}>
            <Button
              variant="outlined"
              sx={{ width: "100%", justifyContent: "space-between" }}
              onClick={() => copyCode(props.promoCode?.toUpperCase() || "")}
              endIcon={<Copy />}
            >
              {props.translations.copyCode}
            </Button>
            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
              <Alert severity="success" description={props.translations.copied} />
            </Snackbar>
          </Grid>
        </Grid>
      )}
    </Surface>
  );
};
