import { Info } from "@augment-frontend/mui-icons";
import { Box, Stack, Tooltip, Typography } from "@mui/material";

export interface SepaCreditTransferProps {
  iban?: string;
  swift?: string;
  bankName?: string | null;
  routingNumber?: string | null;
  translations: {
    /** E.g. SEPA Credit */
    title: string;
    /** E.g. You can make advance payments by transferring money to this account */
    description: string;
    /** E.g. Banking Circle */
    bankName: string;
    /** E.g. Message */
    message: string;
  };
}

export const SepaCreditTransfer = (props: SepaCreditTransferProps) => (
  <Stack>
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="bodyTextBold" component="span">
        {props.translations.title}
      </Typography>
      <Tooltip
        title={<Typography>{props.translations.description}</Typography>}
        enterTouchDelay={0}
        leaveTouchDelay={4000}
        arrow
      >
        <Info fontSize="small" sx={(theme) => ({ color: theme.palette.text.primary })} />
      </Tooltip>
    </Stack>
    <Box>
      <Typography variant="bodyText">IBAN: {props.iban}</Typography>
      <Typography variant="bodyText">BIC/SWIFT: {props.swift}</Typography>
      <Typography variant="bodyText">
        {props.translations.bankName}: {props.bankName}
      </Typography>
      {props.routingNumber && (
        <Typography variant="bodyText">
          {props.translations.message}: {props.routingNumber}
        </Typography>
      )}
    </Box>
  </Stack>
);
