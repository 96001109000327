import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { HeadingContentProps } from "../HeadingContent";
import type { SvgIcon } from "@mui/material";

import { Surface } from "../../Surface";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockFeatureSummaryProps {
  headingContent: HeadingContentProps;
  /** Features */
  items: {
    /** Icon */
    icon: typeof SvgIcon;
    /** Title of the feature */
    title: string;
    /** Description of the feature */
    description: string;
  }[];
}

export const BlockFeatureSummary = (props: BlockFeatureSummaryProps) => {
  const theme = useTheme();

  return (
    <GridContainer>
      <Grid item mobile={12}>
        <HeadingContent
          {...props.headingContent}
          boxProps={{
            width: {
              tablet: "70%",
              laptop: "60%",
            },
          }}
          centered
        />
      </Grid>
      <Grid item mobile={12} display="flex" justifyContent="center">
        <Grid
          container
          columnSpacing={{ mobile: 2, tablet: 4, laptop: 6 }}
          rowSpacing={{ mobile: 4, tablet: 4, laptop: 6 }}
          wrap="wrap"
          justifyContent={{ mobile: "flex-start", tablet: "center" }}
          sx={{ width: { laptop: "80%" }, pt: { tablet: 1, laptop: 5.75 } }}
        >
          {props.items.map((item, index) => {
            const Icon = item.icon;

            return (
              <Grid item key={index} mobile={6} tablet={4} laptop={4}>
                <Box display="flex" justifyContent="center">
                  <Surface
                    sx={{
                      display: "inline-flex",
                      p: "10px",
                      mb: { mobile: 1.25, tablet: 2 },
                      bgcolor: theme.palette.primary.main,
                    }}
                  >
                    <Icon />
                  </Surface>
                </Box>
                <Typography
                  variant="subheadingBold"
                  textAlign="center"
                  sx={{ overflowWrap: "break-word", hyphens: "auto" }}
                >
                  {item.title}
                </Typography>
                <Typography mt={1} textAlign="center">
                  {item.description}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </GridContainer>
  );
};
