import type { FC } from "react";
import { useEffect, useRef, useState } from "react";

import { Box, Grid, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useWindowSize } from "usehooks-ts";

import type { AppBadgesProps } from "../../../AppBadges";
import type { SummaryProps } from "../../Summary";
import type { StepProps } from "../Step";

import { AppBadges } from "../../../AppBadges";
import { BottomNavigation } from "../../../Navigation";
import { InfoBox } from "../../Info";
import { Summary } from "../../Summary";

export interface StepThankYouProps {
  /** Content to display between title and AppBadges */
  content: React.ReactNode;
  summaryItems: Pick<SummaryProps["items"][number], "title" | "value" | "addedItems">[];
  summaryTotal: NonNullable<SummaryProps["summaryRow"]>;
  /** Array of customer delivery information including e.g. name and address. */
  deliveryInformations: { title: string; value: string }[];
  /**  Loading status of the component passed on for the summary and delivery info */
  loading?: boolean;
  /** Triggered for forward-click */
  onForward?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** Defines content width / buttons right side position */
  width?: StepProps["bottomNavigation"]["width"];
  /** Set forward button to a loading state */
  forwardLoading?: StepProps["bottomNavigation"]["loadingForward"];
  translations: {
    /** E.g. "Thank you for joining!" */
    title: string;
    /** E.g. "Summary" */
    summaryTitle: string;
    /** E.g. "Delivery information" */
    deliveryInformationTitle: string;
    appBadgesTitle: AppBadgesProps["title"];
    goToMyAccountTitle?: string;
  };
}

const StyledWrapper = styled("div")((props) => ({
  [props.theme.breakpoints.down("laptop")]: {
    padding: props.theme.spacing(2),
    backgroundColor: props.theme.palette.secondary[200],
    borderRadius: props.theme.shape.borderRadius,
  },
}));

/**
 * Renders a summarized thank you step to indicate completed checkout.
 * Next action may be urged by providing content prop.
 */
export const StepThankYou: FC<StepThankYouProps> = (props) => {
  const navigationRef = useRef<HTMLDivElement>(null);
  const [paddingBottom, setPaddingBottom] = useState(0);
  const { width, height } = useWindowSize();
  const theme = useTheme();
  const appBadgesTitleColor = theme.palette.text.primary;

  useEffect(() => {
    if (navigationRef.current) {
      const padding = navigationRef.current.clientHeight;
      setPaddingBottom(padding);

      const openLauncher = document.getElementsByClassName("intercom-launcher-frame").item(0);
      const closedLauncher = document
        .getElementsByClassName("intercom-lightweight-app-launcher")
        .item(0);
      const element = openLauncher || closedLauncher;

      if (element) {
        // @ts-expect-error TODO find alternative: TS complains that style is missing, but it works
        element.style.bottom = `${padding}px`;
        window.intercomSettings = { ...window.intercomSettings, vertical_padding: padding };
        window.Intercom?.("update", window.intercomSettings);
      }
    }
  }, [width, height]);

  return (
    <Grid container columnSpacing={8} pb={`${paddingBottom}px`}>
      <Grid item tablet={6}>
        {/* Left-half */}
        <Stack direction="column">
          <div>
            <Typography variant="h2Bold" mb={2}>
              {props.translations.title}
            </Typography>
            <Box mb={{ mobile: 2, tablet: 4 }}>{props.content}</Box>
          </div>
          <Box display={{ mobile: "none", tablet: "block" }}>
            <AppBadges title={props.translations.appBadgesTitle} titleColor={appBadgesTitleColor} />
          </Box>
        </Stack>
      </Grid>
      <Grid item tablet={6}>
        {/* Right-half */}
        <Stack direction="column" spacing={{ mobile: 2, laptop: 4 }}>
          <Grid item mobile={12} laptop={6}>
            <StyledWrapper>
              <Summary
                title={props.translations.summaryTitle}
                items={props.summaryItems}
                summaryRow={props.summaryTotal}
                loading={props.loading}
                sx={{ color: theme.palette.text.primary, borderColor: theme.palette.text.primary }}
              />
            </StyledWrapper>
          </Grid>
          <StyledWrapper>
            <InfoBox
              title={props.translations.deliveryInformationTitle}
              content={
                <Grid container justifyContent="space-between">
                  {props.deliveryInformations.map(({ title, value }, i) => (
                    <Grid
                      key={i}
                      item
                      mobile={12}
                      laptop={6}
                      mt={{ mobile: i === 0 ? 1 : 0, tablet: 0 }}
                    >
                      <Typography variant="bodyText">{title}</Typography>
                      <Typography
                        variant="bodyTextSemibold"
                        mb={i === props.deliveryInformations.length - 1 ? 0 : 2}
                      >
                        {value}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              }
              loading={props.loading}
            />
          </StyledWrapper>
          <Box display={{ mobile: "block", tablet: "none" }}>
            <AppBadges title={props.translations.appBadgesTitle} titleColor={appBadgesTitleColor} />
          </Box>
        </Stack>
      </Grid>
      {props.onForward && (
        <BottomNavigation
          hideBackward
          forwardButton={props.translations.goToMyAccountTitle}
          onForward={props.onForward}
          width={props.width}
          loadingForward={props.forwardLoading}
          containerRef={navigationRef}
        />
      )}
    </Grid>
  );
};
