import { Fragment, useRef, useState } from "react";

import { Info } from "@augment-frontend/mui-icons";
import { Grid, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { RadioGroupCardRowProps } from "../../../../../../Radio";
import type { ConfigurationItem, ConfigurationItemOption } from "../../../types";

import { InfoModal } from "../../../../../../Modal";
import { RadioGroupCardRow } from "../../../../../../Radio";
import { InfoRow } from "../../Details/InfoRow/InfoRow";

/**
 * Props for the ItemSection. ConfigurationItem["configurationItemOptions"] is omitted here, because
 * they are needed in a form of RadioGroupCardRowProps<ConfigurationItemOption>, so the
 * RadioGroupCardRowProps["options"] are used instead in their place.
 */
export type ItemSectionProps = Omit<ConfigurationItem, "itemOptions"> &
  RadioGroupCardRowProps<ConfigurationItemOption>;

/**
 * Display header of the item and below item options on a row
 */
export function ItemSection(props: React.PropsWithoutRef<ItemSectionProps>) {
  const { icon, itemTitle, tooltipContent, options, ...radioGroupProps } = props;

  const [tooltipModalOpen, setTooltipModalOpen] = useState(false);
  const itemRef = useRef(null);
  const theme = useTheme();

  return (
    <div data-testid="itemSection">
      <InfoRow
        icon={icon}
        title={itemTitle}
        content={
          tooltipContent && (
            <Fragment>
              <IconButton
                aria-label={itemTitle}
                sx={{ marginY: theme.spacing(-1) }}
                onClick={() => setTooltipModalOpen(true)}
              >
                <Info sx={{ color: theme.palette.text.primary }} />
              </IconButton>
            </Fragment>
          )
        }
        marginBottom={1}
      />
      <Grid ref={itemRef} item>
        {tooltipContent && (
          <InfoModal
            icon={icon}
            title={itemTitle}
            description={tooltipContent}
            open={tooltipModalOpen}
            onClose={() => setTooltipModalOpen(false)}
            sx={{ bgcolor: theme.palette.primary[600] }}
          />
        )}
        <RadioGroupCardRow
          {...radioGroupProps}
          options={options.map((option) => ({
            ...option,
            title: option.value.optionTitle,
          }))}
          laptopCardSpacing={2}
        />
      </Grid>
    </div>
  );
}
