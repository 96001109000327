import type { GatsbyBrowser } from "gatsby";

import "./src/storybook/src/assets/css/typography.css";
import PageElementWrapper from "./src/PageElementWrapper";
import RootElementWrapper from "./src/RootElementWrapper";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = (props) => (
  <RootElementWrapper {...props} />
);

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = (props) => (
  <PageElementWrapper {...props} />
);

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  const paths = location.pathname.split("/"); // E.g. / or /en/ or /fi-fi/
  const language = paths[1]?.slice(0, 2);
  const maybeCountry = paths[1]?.slice(-2); // International site has no country definition and it must be overriden to undefined
  const country = maybeCountry === "en" ? undefined : maybeCountry;

  // Intercom chat
  window.Intercom?.("update", { last_request_at: Math.floor(Date.now() / 1000) });

  window.dataLayer?.push({
    country,
    language,
  });
};

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  // provide branch value, so gatsby-plugin-google-tagmanager can pass it to GTM for A/B testing
  window.__GATSBY_BRANCH__ = process.env.GATSBY_BRANCH || "master";
  window.dataLayer?.push({
    branch: window.__GATSBY_BRANCH__,
  });
};
