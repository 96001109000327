import { Box, Container, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { ActionButtonsProps } from "../ActionButtons";
import type { HeadingContentProps } from "../HeadingContent";
import type { Breakpoint } from "@mui/material";

import { ActionButtons } from "../ActionButtons";
import { HeadingContent } from "../HeadingContent";

export interface BlockHeroProps {
  title: HeadingContentProps["title"];
  subtitle?: HeadingContentProps["description"];
  callToAction?: Pick<ActionButtonsProps, "buttons">;
  /** Image shown in the background of the block */
  image: React.ReactNode;
  /** Control styles of the very first block vs those further down on a page */
  firstBlock?: boolean;
  /** Disable default darkening overlay */
  disableDarkOverlay?: boolean;
  /** Contents are centered vertically and horizontally on tablet and laptop */
  centered?: boolean;
  /** Remove all margin above the block; useful when hero blocks need to flow from one to another without a gap */
  removeBlockMarginAbove?: boolean;
}

export const BlockHero = (props: BlockHeroProps) => {
  const theme = useTheme();
  let overlay: string | Record<Breakpoint, string> | undefined;

  if (!props.disableDarkOverlay) {
    const gradientColor = theme.palette.secondary.A200;

    if (props.centered) {
      overlay = {
        mobile: `linear-gradient(0deg, ${gradientColor} 0%, transparent 75%)`,
        tablet: "rgba(26, 29, 28, 0.4)",
        laptop: "rgba(26, 29, 28, 0.4)",
      };
    } else {
      overlay = {
        mobile: `linear-gradient(0deg, ${gradientColor} 0%, transparent 75%)`,
        tablet: `linear-gradient(90deg, ${gradientColor} 0%, transparent 65%)`,
        laptop: `linear-gradient(90deg, ${gradientColor} 0%, transparent 65%)`,
      };
    }
  }

  return (
    <Box
      component="section"
      sx={{
        width: "100%",
        height: props.firstBlock ? "100vh" : { mobile: "90vh", tablet: "75vh" },
        maxWidth: "1728px", // MBP Pro 16" (2021)
        maxHeight: props.firstBlock ? { tablet: "700px", laptop: "1000px" } : { tablet: "500px" },
        position: "relative",
        // eslint-disable-next-line no-nested-ternary
        mt: props.firstBlock
          ? // Negative margin to compensate for the header with layout component
            { mobile: "-48px", laptop: "-64px" }
          : props.removeBlockMarginAbove
            ? { mobile: -10, tablet: -8, laptop: -14 } // Negate any previous block's margin-bottom
            : { mobile: 10, tablet: 8, laptop: 14 }, // Same as in GridContainer
        mb: props.firstBlock
          ? // Negative margin to compensate for the second block's top margin
            { mobile: -10, tablet: 0 }
          : { mobile: 10, tablet: 8, laptop: 14 }, // Same as in GridContainer
        mx: "auto", // Center horizontally when maxWidth reached
      }}
    >
      {props.image}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: overlay,
        }}
      >
        <Container
          sx={{
            height: "100%",
            px: { mobile: 3, tablet: 0 },
            maxWidth: {
              mobile: "100%",
              tablet: `${theme.breakpoints.values.tablet - 2 * 64}px`,
              laptop: `${theme.breakpoints.values.laptop - 2 * 144}px`,
            },
          }}
        >
          <Stack
            sx={{
              justifyContent: { mobile: "flex-end", tablet: "center" },
              height: "100%",
              width: props.centered
                ? { mobile: "100%", tablet: "70%", laptop: "45%" }
                : { mobile: "100%", tablet: props.firstBlock ? "60%" : "50%", laptop: "45%" },
              mt: { tablet: props.centered ? undefined : "-4%" },
              pb: { mobile: 3, tablet: 0 },
              mx: props.centered ? "auto" : undefined,
            }}
          >
            <HeadingContent
              title={props.title}
              titleSx={{ color: theme.palette.text.secondary }}
              description={props.subtitle}
              dividerExtraMargin
              centered={props.centered}
              bottomContent={
                props.callToAction && (
                  <ActionButtons
                    {...props.callToAction}
                    direction={{
                      mobile: "column",
                      // eslint-disable-next-line no-nested-ternary
                      tablet: props.firstBlock
                        ? "row"
                        : props.callToAction.buttons.length > 1
                          ? "column"
                          : "row",
                      laptop: "row",
                    }}
                    grow={{
                      mobile: true,
                      tablet: props.callToAction.buttons.length > 1, // direction column, so grow
                      laptop: !props.centered, // left-aligned, so grow
                    }}
                    centered={props.centered}
                  />
                )
              }
            />
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};
