import type { FC } from "react";
import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";

import type { ProgrammaticModalProps } from "../../../../Modal";
import type { SvgIcon } from "@mui/material";

import { ProgrammaticModal } from "../../../../Modal";
import { TextField } from "../../../../TextField";

export interface MediumModalProps {
  /** Value of medium */
  mediumValue: string;
  /** Display suggestion to user if it is provided. NOTE: use with translation.mediumSuggestion  */
  mediumSuggestedValue?: string;
  /** Whether the medium is verified  */
  mediumVerified: boolean;
  mediumInputMode: "email" | "tel";
  mediumIcon: typeof SvgIcon;
  /**
   * Validate function which is called when the medium changes.
   * Return true to pass the validation for the provided value.
   */
  validateMediumValue: (medium: string) => boolean;
  /**
   * Triggered when new medium is submitted.
   * Thrown error is catched and its message is shown.
   */
  onMediumSubmit: (medium: string) => Promise<Error | void>;
  /**
   * Triggered when confirmation code for medium is submitted.
   * Thrown error is catched and its message is shown.
   */
  onMediumCodeSubmit: (code: string) => Promise<Error | void>;
  onClose: NonNullable<ProgrammaticModalProps["onClose"]>;
  /**
   * Triggered when operation succeeded.
   */
  onCompleted: () => void;
  open: ProgrammaticModalProps["open"];
  translations: {
    /** E.g. "Choose your email" */
    mediumTitle: string;
    /** E.g. "E-mail" */
    mediumLabel: string;
    /** E.g. "Confirm" */
    mediumButton: string;
    /** E.g. "Enter the code that we sent to your email" */
    mediumCodeTitle: string;
    /** E.g. "Code" */
    mediumCodeLabel: string;
    /** E.g. "Confirm" */
    mediumCodeButton: string;
    /** E.g. "Did you mean" NOTE: use with mediumSuggestedValue */
    mediumSuggestion?: string;
    /** Helper text for medium e.g. "F.ex. +358501234567" as telephone helper */
    mediumHelper?: string;
  };
}

const initialState = { value: "", helperText: "", error: false, loading: false };

/**
 * Update medium with modal view.
 */
export const MediumModal: FC<MediumModalProps> = (props) => {
  const [index, setIndex] = useState(0);
  const mediumInitialState: typeof initialState = { ...initialState, value: props.mediumValue };
  const [medium, setMedium] = useState<typeof initialState>(mediumInitialState);
  const [mediumCode, setMediumCode] = useState<typeof initialState>(initialState);

  const reset = () => {
    setIndex(0);
    setMedium(mediumInitialState);
    setMediumCode(initialState);
  };

  const handleMediumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    if (event.target.inputMode === "email") {
      value = value.toLowerCase().replace(/\s/g, "");
    } else if (event.target.inputMode === "tel") {
      // eslint-disable-next-line no-useless-escape
      value = value.replace(/[^\d\+]/g, "");
    }
    setMedium({
      value,
      helperText:
        event.target.inputMode === "tel" &&
        !props.validateMediumValue(value) &&
        props.translations.mediumHelper
          ? props.translations.mediumHelper
          : "",
      error: !props.validateMediumValue(value),
      loading: false,
    });
  };

  const handleMediumSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMedium((prev) => ({ ...prev, loading: true }));
    let helperText = "";
    let error = false;
    try {
      await props.onMediumSubmit(medium.value);
      setIndex(1);
    } catch (err) {
      helperText = "Unknown error";
      if (err instanceof Error) {
        helperText = err.message;
      }
      error = true;
    }
    setMedium((prev) => ({ ...prev, helperText, error, loading: false }));
  };

  const handleMediumCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmed = event.target.value.trim().replace(/\D/g, "");
    setMediumCode((prev) => {
      const value = trimmed.length > 6 ? prev.value : trimmed;
      return {
        value,
        helperText: "",
        error: !/^\d{6}$/.test(value),
        loading: false,
      };
    });
  };

  const handleMediumCodeSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMediumCode((prev) => ({ ...prev, loading: true }));
    let helperText = "";
    let error = false;
    try {
      await props.onMediumCodeSubmit(mediumCode.value);
      props.onCompleted();
      reset();
    } catch (err) {
      helperText = "Unknown error";
      if (err instanceof Error) {
        helperText = err.message;
      }
      error = true;
    }
    setMediumCode((prev) => ({ ...prev, helperText, error, loading: false }));
  };

  const Icon = props.mediumIcon;

  return (
    <ProgrammaticModal
      open={props.open}
      index={index}
      onClose={(event, reason) => {
        props.onClose(event, reason);
        reset();
      }}
      views={[
        <Box
          key={props.mediumInputMode}
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          component="form"
          onSubmit={handleMediumSubmit}
        >
          <Icon sx={{ fontSize: "64px", mb: 1 }} />
          <Typography variant="subheadingBold" textAlign="center" sx={{ mb: 3 }}>
            {props.translations.mediumTitle}
          </Typography>
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            type="text"
            inputProps={{ inputMode: props.mediumInputMode }}
            label={props.translations.mediumLabel}
            helperText={
              props.mediumSuggestedValue && props.mediumSuggestedValue.length > 1 && !medium.error
                ? `${props.translations.mediumSuggestion} ${props.mediumSuggestedValue}?`
                : medium.helperText || " "
            }
            value={medium.value}
            error={medium.error}
            success={medium.value.length > 0 && !medium.error}
            disabled={medium.loading}
            onChange={handleMediumChange}
            FormHelperTextProps={
              props.mediumSuggestedValue &&
              props.mediumSuggestedValue.length > 1 &&
              !medium.error &&
              !medium.loading
                ? {
                    sx: () => ({ cursor: "pointer", textDecoration: "underline" }),
                    onClick: () => {
                      setMedium((prev) => ({
                        ...prev,
                        value: props.mediumSuggestedValue || " ",
                      }));
                      props.validateMediumValue(props.mediumSuggestedValue || "");
                    },
                  }
                : undefined
            }
          />
          <LoadingButton
            type="submit"
            fullWidth
            disabled={
              (props.mediumValue === medium.value && props.mediumVerified) ||
              medium.value.length === 0 ||
              medium.error
            }
            loading={medium.loading}
          >
            {props.translations.mediumButton}
          </LoadingButton>
        </Box>,
        <Box
          key={`${props.mediumInputMode}-confirm`}
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          component="form"
          onSubmit={handleMediumCodeSubmit}
        >
          <Icon sx={{ fontSize: "64px", mb: 1 }} />
          <Typography variant="subheadingBold" textAlign="center" sx={{ mb: 3 }}>
            {props.translations.mediumCodeTitle}
          </Typography>
          <TextField
            fullWidth
            sx={{ mb: 2 }}
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            label={props.translations.mediumCodeLabel}
            helperText={mediumCode.helperText}
            value={mediumCode.value}
            error={mediumCode.error}
            success={mediumCode.value.length > 0 && !mediumCode.error}
            disabled={mediumCode.loading}
            onChange={handleMediumCodeChange}
          />
          <LoadingButton
            type="submit"
            fullWidth
            disabled={mediumCode.value.length === 0 || mediumCode.error}
            loading={mediumCode.loading}
          >
            {props.translations.mediumCodeButton}
          </LoadingButton>
        </Box>,
      ]}
    />
  );
};
