import type { FC } from "react";

import { LinearProgress as MuiLinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { LinearProgressProps } from "@mui/material";

const StyledLinearProgress = styled(MuiLinearProgress)(() => ({
  height: 12,
  borderRadius: 6,
}));

export type { LinearProgressProps };

export const LinearProgress: FC<LinearProgressProps> = (props) => (
  <StyledLinearProgress {...props} data-testid="linearProgress" />
);
