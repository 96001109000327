import type { FC } from "react";

import {
  AppBar,
  Box,
  Button,
  Container as MaterialContainer,
  Slide,
  Stack,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import type { MenuIconProps } from "./MenuIcon";
import type { LinkProps } from "@mui/material";

import { MenuIcon } from "./MenuIcon";

export interface HeaderProps {
  logo: React.ReactNode;
  menuOpen: MenuIconProps["open"];
  onMenuClick: MenuIconProps["onClick"];
  /**
   * Navigation links to render on Header (laptop only). Only the first 5 links will be rendered
   * because of the limited space on Header.
   */
  links: LinkProps[];
  /** Component for the call to action button, e.g. Gatsby Link (laptop only) */
  ctaLinkComponent?: React.ElementType;
  translations: {
    /** Call to action button, e.g. "Subscribe" (laptop only) */
    ctaButton: string;
  };
}

const HeaderContainer = styled(AppBar)((props) => ({
  backgroundColor: props.theme.palette.secondary.A200,
  width: "100%",
  height: "48px",
  paddingLeft: props.theme.spacing(1.5),
  paddingRight: props.theme.spacing(1.5),
  [props.theme.breakpoints.up("laptop")]: {
    height: "64px",
    paddingLeft: props.theme.spacing(4),
    paddingRight: props.theme.spacing(4),
  },
}));

const HeaderContent = styled(MaterialContainer)({
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const HeaderSide = styled("div")(() => ({
  flex: 1,
  display: "flex",
  justifyContent: "flex-end",
}));

const HideOnScroll = (props: { children: JSX.Element }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
};

export const Header: FC<HeaderProps> = (props) => {
  const theme = useTheme();

  // limit the number of displayed links to 5 (rest will be rendered in the Drawer)
  const headerLinks = props.links.slice(0, 5);

  return (
    <HideOnScroll>
      <HeaderContainer>
        <HeaderContent disableGutters maxWidth="laptop">
          <HeaderSide />
          {props.logo}
          <Box
            sx={{
              display: { mobile: "none", laptop: "flex" },
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <HeaderSide />
            <Stack
              direction="row"
              spacing={theme.spacing(4)}
              component="nav"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {headerLinks.map((link, index) => (
                <Typography
                  key={index}
                  {...link}
                  sx={{
                    display: "block",
                    textDecoration: "none",
                    ":hover": {
                      textDecoration: "underline",
                    },
                    color: theme.palette.text.secondary,
                  }}
                />
              ))}
            </Stack>
            <HeaderSide />
            {props.ctaLinkComponent && (
              <Button
                component={props.ctaLinkComponent}
                sx={{ marginRight: theme.spacing(3), paddingY: "4px", paddingX: "12px" }}
              >
                <Typography variant="bodyTextSemibold">{props.translations.ctaButton}</Typography>
              </Button>
            )}
          </Box>
          <HeaderSide>
            <MenuIcon open={props.menuOpen} onClick={props.onMenuClick} />
          </HeaderSide>
        </HeaderContent>
      </HeaderContainer>
    </HideOnScroll>
  );
};
