import { type FC, Fragment } from "react";

import { Box, Grid, Stack } from "@mui/material";

import type { ActionButtonsProps } from "../ActionButtons";
import type { HeadingContentProps } from "../HeadingContent";

import { ActionButtons } from "../ActionButtons";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockTextImagePairProps {
  /** Call to action buttons, displayed only on mobile */
  callToAction?: Pick<ActionButtonsProps, "buttons">;
  /** Text and image pairs to display */
  items: {
    /** Text content of the pair. Uses HeadingContent component. Displayed first/on the left */
    headingContent: HeadingContentProps;
    /** Image of the pair. Displayed after the text content on the right */
    image: React.ReactNode;
  }[];
  /** Uses leveled view where image and text content are one over the other */
  leveled?: boolean;
}

export const BlockTextImagePair: FC<BlockTextImagePairProps> = (props) => (
  <GridContainer>
    {props.leveled
      ? props.items.map((item, index) => (
          <Grid item key={index} mobile={12} tablet={6} alignSelf="stretch">
            <Stack direction="column" alignItems="flex-start">
              <Box
                display="flex"
                order={{ mobile: 1, tablet: 0 }}
                mt={{ mobile: 4, tablet: 0 }}
                mb={4}
              >
                {item.image}
              </Box>
              <Box display="flex" order={{ mobile: 0, tablet: 1 }}>
                <HeadingContent
                  {...item.headingContent}
                  removeDescriptionMt={item.headingContent.removeDescriptionMt !== false}
                  titleSizeReduced
                />
              </Box>
            </Stack>
          </Grid>
        ))
      : props.items.map((item, index) => (
          <Fragment key={index}>
            <Grid item mobile={12} tablet={6} sx={{ order: index }}>
              <HeadingContent
                {...item.headingContent}
                removeDescriptionMt={item.headingContent.removeDescriptionMt !== false}
              />
            </Grid>
            <Grid
              item
              mobile={12}
              tablet={6}
              sx={{
                order: { mobile: index, tablet: index % 2 !== 0 ? index - 1 : index },
                paddingBottom: (theme) => ({ mobile: theme.spacing(4), tablet: 0 }),
              }}
            >
              {item.image}
            </Grid>
          </Fragment>
        ))}
    {props.callToAction && (
      <Grid item mobile={12} order={props.items.length}>
        <ActionButtons
          {...props.callToAction}
          direction={{ mobile: "column", tablet: "row", laptop: "row" }}
          grow={{ mobile: true, tablet: true, laptop: false }}
          centered
        />
      </Grid>
    )}
  </GridContainer>
);
