import type { FC } from "react";

import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";

import type { LoadingButtonProps } from "@mui/lab";
import type { BoxProps } from "@mui/material";

export interface FullSizeErrorProps {
  /** E.g. "Error occured" try to keep max two words. */
  title: string;
  /** E.g. "There was an error while processing your request." */
  description?: string;
  /** E.g. "Try again" */
  buttonText: string;
  boxProps?: BoxProps;
  /** Icon to be shown on top of title. */
  icon?: React.ReactNode;
  /** Position where the message is displayed. Default: "top" */
  position?: "top" | "middle";
  buttonProps?: Omit<LoadingButtonProps, "fullwidth" | "variant" | "sx">;
}

/**
 * Render Error like information box that spans to full width and height of parent element.
 */
export const FullSizeError: FC<FullSizeErrorProps> = (props) => {
  const { sx, ...boxProps } = props.boxProps || {};

  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        justifyContent: "center",
        height: props.position === "middle" ? "80vh" : "auto",
        alignItems: "center",
      }}
      {...boxProps}
      data-testid="fullSizeError"
    >
      <Stack direction="column" alignItems="center" spacing={4}>
        {props.icon}
        <Typography variant="h2Bold" textAlign="center">
          {props.title}
        </Typography>
        {props.description && (
          <Box sx={{ maxWidth: "410px" }}>
            <Typography textAlign="center">{props.description}</Typography>
          </Box>
        )}
        <LoadingButton
          {...props.buttonProps}
          fullWidth
          variant="outlined"
          sx={{ maxWidth: "310px" }}
        >
          {props.buttonText}
        </LoadingButton>
      </Stack>
    </Box>
  );
};
