import type { FC } from "react";

import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import type { SxProps, TypographyProps } from "@mui/material";

export interface SummaryRowProps {
  /** Title e.g. "Total" */
  title: string;
  /** Value e.g. "640 €" */
  totalValue?: string;
  /** Rows to display under the Total as captions with values, e.g. "First payment 67.85 €", "Every 2 weeks 17.90 €" */
  captions?: { title: string; value?: string }[];
  /** Divider position line. Default: "top" */
  dividerPosition?: "top" | "bottom" | "hidden";
  /** Optional information block.(E.g. B2B Checkout Switch Message)  */
  bottomElement?: React.ReactNode;
  /** Loading status of the component, replace item value with skeleton if present */
  loading?: boolean;
  /** Override summary content styles */
  sx?: SxProps;
}

const StyledPrice = styled(Typography)<TypographyProps & { color?: string; bold?: boolean }>(
  (props) => ({
    color: props.color ?? props.theme.palette.text.secondary,
    ...props.theme.typography.bodyTextBold,
    [props.theme.breakpoints.up("tablet")]: {
      ...props.theme.typography.largeTextBold,
      color: props.theme.palette.text.primary,
    },
  })
);

const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => {
    if (prop === "bold") {
      return false;
    }
    return true;
  },
})<TypographyProps & { bold?: boolean }>((props) => ({
  ...(props.bold ? props.theme.typography.captionBold : props.theme.typography.caption),
  [props.theme.breakpoints.up("tablet")]: {
    ...(props.bold ? props.theme.typography.largeTextBold : props.theme.typography.largeText),
  },
}));

export const SummaryRow: FC<SummaryRowProps> = (props) => {
  const theme = useTheme();
  const dividerPosition = props.dividerPosition ?? "top";
  const responsiveTextColor = {
    mobile: theme.palette.text.secondary,
    tablet: theme.palette.text.primary,
  };

  const captionsLength = props.captions?.length || 0;
  const hasCaptionWithValue = props.captions?.some((caption) => caption.value !== undefined);
  const showPriceOnTop = !props.loading && (hasCaptionWithValue || !captionsLength);

  const TotalValueElement = (sx: SxProps) => (
    <StyledPrice
      data-testid="total-price"
      // FIXME: sx type
      // @ts-expect-error This is OK when passed from ThankYou page
      color={props.sx?.color}
      sx={{ ...sx }}
    >
      {props.totalValue}
    </StyledPrice>
  );

  return (
    <>
      {dividerPosition === "top" && (
        <Divider
          sx={{ marginY: theme.spacing(2), borderColor: responsiveTextColor, ...props.sx }}
        />
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ alignItems: captionsLength > 0 ? "end" : "center" }}
      >
        <Stack direction="column" width="100%">
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <StyledText noWrap bold sx={{ color: responsiveTextColor, ...props.sx }}>
              {props.title}
            </StyledText>
            {showPriceOnTop && <TotalValueElement />}
          </Stack>

          {!props.loading ? (
            props.captions?.map((captionWithValue, index) => (
              <Stack
                key={index}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Typography
                  variant="caption"
                  noWrap
                  sx={{ color: responsiveTextColor, ...props.sx }}
                >
                  {captionWithValue.title}
                </Typography>
                {captionWithValue.value && (
                  <Typography
                    variant="bodyTextBold"
                    sx={{ color: responsiveTextColor, ...props.sx }}
                  >
                    {captionWithValue.value}
                  </Typography>
                )}
                {!showPriceOnTop && captionsLength === index + 1 && (
                  <TotalValueElement mt={theme.spacing(-1)} />
                )}
              </Stack>
            ))
          ) : (
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
              <Skeleton width={100} height={theme.typography.bodyText.fontSize} sx={{ my: 0.25 }} />
              <Skeleton width={100} height={theme.typography.bodyText.fontSize} sx={{ my: 0.25 }} />
            </Stack>
          )}
        </Stack>
      </Stack>
      {dividerPosition === "bottom" && (
        <Divider
          sx={{ marginY: theme.spacing(2), borderColor: theme.palette.text.secondary, ...props.sx }}
        />
      )}
      {props.bottomElement}
    </>
  );
};
