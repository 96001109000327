import { createSvgIcon } from "@mui/material/utils";

export const IOSArrowRight = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9.3595 18.7681C9.5465 18.9241 9.7735 19.0001 9.9995 19.0001C10.2865 19.0001 10.5705 18.8771 10.7685 18.6401L15.7685 12.6401C16.0735 12.2741 16.0775 11.7441 15.7795 11.3731L10.9515 5.37307C10.6045 4.94307 9.9755 4.87507 9.5445 5.22107C9.1145 5.56707 9.0465 6.19707 9.3925 6.62707L13.7075 11.9891L9.2315 17.3601C8.8785 17.7841 8.9355 18.4151 9.3595 18.7681Z"
  />,
  "IOSArrowRight"
);
