import type { FC } from "react";
import { useEffect, useState } from "react";

import { Grid, Stack } from "@mui/material";

import type { LocaleInformation } from "../../../types";
import type { ConfigurationItemOption, ConfigurationItems, Model } from "../types";
import type { ProductCategoryOptionProps } from "./ProductCategories";

import { createNewCategoryHistory, getActiveOptions, getMatchingModel } from "../utils";
import { Details } from "./Details";
import { Options } from "./Options";
import { ProductCategories, ProductCategoryOption } from "./ProductCategories";
import { SubHeader } from "./SubHeader";

type ProductCategory = {
  /** Value of the Product Category, e.g. "210" */
  value: string;
  /** Title of the Product Category, e.g. "ES Series" */
  title: string;
  /** Feature of the Product Category, e.g. "Balanced choice". This needs to be translated */
  feature: string;
  /** Contract length of the Product Category, e.g. "24" */
  contractLength: string | number;
  /** Starting price of the Product Category without currency, e.g. "23.95" */
  startingPrice: string;
  /** All models that belongs to this Product Category */
  models: Model[];
  /** Tag of the Product Category, e.g. "Pre-owned". This needs to be translated  */
  tag?: string;
};

export type CategoryHistoryItem = {
  /** Value of the category which the history item belongs to */
  categoryValue: string;
  /** Indicating if this category/history item should be active */
  isActiveCategory: boolean;
  /** Model of the history item. Details are omited due to the DOM elements */
  model: Omit<Model, "details">;
  /** Options of the history item. Contents are omited due to the DOM elements */
  options: Omit<ConfigurationItemOption, "content">[];
};

/** Used for type safety: this ensures that 1-3 Product Categories are always given */
type UpToThreeProductCategories =
  | [ProductCategory]
  | [ProductCategory, ProductCategory]
  | [ProductCategory, ProductCategory, ProductCategory];

export type ConfigurationProps = {
  /** Product Categories (including models) to render on given order, up to 3 categories */
  productCategories: UpToThreeProductCategories;
  /** Initially selected ProductCategory */
  initialCategory: ProductCategory;
  /** All configuration items of every Model from all Product Categories (including both types "config" & "addon") */
  configurationItems: ConfigurationItems;
  localeInformation: LocaleInformation;
  /** Triggered when the configurations changes (including mount) */
  onChange: (
    newModel: Model,
    newOptions: ConfigurationItemOption[],
    categoryHistory: CategoryHistoryItem[]
  ) => void;
  /** Initial Model */
  initialModel?: Model;
  /** Initial Configuration (including both types "config" & "addon") */
  initialConfiguration?: Model["supportedConfigurationItemOptions"];
  /** Initial Category History. If provided, will be used as a starting point for category selection history, which this component will update */
  initialCategoryHistory?: CategoryHistoryItem[];
  translations: {
    /** E.g. "Total" */
    total: string;
    /** E.g. "month" */
    appendedText: string;
    /** E.g. "from" */
    prependedText: string;
    /** E.g. "Choose your core" */
    productCategoryTitle: string;
    /** E.g. "Tailor your ride" */
    modelOptionsTitle: string;
    /** E.g. "Review your" */
    modelInfoPrependedTitle: string;
    /** Last part of the title, if the translation requires it. Will be set after model name,
     * so full title will be "Review your {modelName}{appendedTitle}"
     * (put space directly in the translation if needed)
     */
    modelInfoAppendedTitle?: string;
    /** Common translations for every Product Category option */
    productCategoryCommon: ProductCategoryOptionProps["translations"]["common"];
  };
};

/** This component allows model configuration and displays its details */
export const Configuration: FC<ConfigurationProps> = (props) => {
  const modelOnLoad = props.initialModel || props.initialCategory.models[0];
  const latestActiveCategoryFromHistory = props.initialCategoryHistory?.find(
    (history) => history.isActiveCategory
  );

  const categoryOnLoad =
    props.productCategories.find(
      (category) => category.value === latestActiveCategoryFromHistory?.categoryValue
    ) || props.initialCategory;

  const [activeCategory, setActiveCategory] = useState(categoryOnLoad);
  const [activeModel, setActiveModel] = useState(modelOnLoad);
  const [activeOptions, setActiveOptions] = useState<ConfigurationItemOption[]>(
    getActiveOptions(
      props.configurationItems,
      activeModel.supportedConfigurationItemOptions,
      activeCategory.value,
      props.initialConfiguration
    )
  );

  const [categoryHistory, setCategoryHistory] = useState(props.initialCategoryHistory || []);
  // key to force re-render of the Options component, used when no matching model is found and we need to reset the options
  const [optionsComponentKey, setOptionsComponentKey] = useState(0);

  /** Central function to update the active model, selected options, total price, and trigger the onChange callback */
  const updateActiveValues = (newModel: Model, newOptions: ConfigurationItemOption[]) => {
    setActiveModel(newModel);
    setActiveOptions(newOptions);
    const { details, ...modelWithoutDetails } = newModel;
    const optionsWithoutContent = newOptions.map(({ content, ...option }) => option);
    const newCategoryHistory = createNewCategoryHistory(
      activeCategory.value,
      modelWithoutDetails,
      optionsWithoutContent,
      categoryHistory
    );
    setCategoryHistory(newCategoryHistory);
    props.onChange(newModel, newOptions, newCategoryHistory);
  };

  useEffect(() => {
    // trigger the onChange callback on mount
    props.onChange(activeModel, activeOptions, categoryHistory);
  }, []);

  useEffect(() => {
    if (!activeCategory.models.includes(activeModel)) {
      // the (new) active model isn't included in the new active category
      const categoryHistoryItem = categoryHistory.find(
        (history) => history.categoryValue === activeCategory.value
      );
      if (categoryHistoryItem) {
        // return previous choices from category history
        const categoryModel =
          activeCategory.models.find((model) => model.id === categoryHistoryItem.model.id) ||
          activeCategory.models[0];
        updateActiveValues(categoryModel, categoryHistoryItem.options);
      } else {
        // no category history available, set the first model of the category as an active
        updateActiveValues(
          activeCategory.models[0],
          getActiveOptions(
            props.configurationItems,
            activeCategory.models[0].supportedConfigurationItemOptions,
            activeCategory.value
          )
        );
      }
    }
  }, [activeCategory]);

  const activeModelConfigurationItems =
    props.configurationItems[activeCategory.value]?.filter((configurationItem) =>
      activeModel.supportedConfigurationItemOptions.find(
        (supportedConfiguration) => supportedConfiguration.key === configurationItem.id
      )
    ) || [];

  /**
   * Update the related values when the model changes.
   *
   * Use newOptions when they are provided, if not, use the default options of newActiveModel.
   * Always keep the active supported addons.
   */
  const handleModelChange = (newActiveModel: Model, newOptions?: ConfigurationItemOption[]) => {
    const optionsToUse =
      newOptions ||
      getActiveOptions(
        props.configurationItems,
        newActiveModel.supportedConfigurationItemOptions,
        activeCategory.value,
        activeOptions.filter((option) => option.type === "addon")
      );

    // get keys of supported addons by the new model
    const supportedAddonKeys = new Set(
      newActiveModel.supportedConfigurationItemOptions
        .filter((option) => option.type === "addon")
        .map((addon) => addon.key)
    );
    // filter to include only all non-addon options, and addons which the new model supports
    const supportedOptions = optionsToUse.filter(
      (option) => option.type !== "addon" || supportedAddonKeys.has(option.key)
    );
    updateActiveValues(newActiveModel, supportedOptions);
  };

  /** Function to handle the change of the configuration items on Option component */
  const onChangeConfigHandler = (
    allSelectedOptions: ConfigurationItemOption[],
    lastSelectedOption: ConfigurationItemOption
  ) => {
    const { model: newActiveModel, fullMatch } = getMatchingModel(
      allSelectedOptions,
      lastSelectedOption,
      activeCategory.models
    );
    if (!newActiveModel) {
      // no model found, force re-render of Options component to reset the activeOptions
      setOptionsComponentKey((prevKey) => prevKey + 1);
      return;
    }

    if (newActiveModel.id !== activeModel.id) {
      // model changed (ConfigurationItemOption type "config" changed)
      if (fullMatch) {
        // use active options for fully matched model
        handleModelChange(newActiveModel, allSelectedOptions);
      } else {
        // use default options for partially matched model
        handleModelChange(newActiveModel);
      }
    } else {
      // model didn't change (ConfigurationItemOption type "addon" changed)
      updateActiveValues(newActiveModel, allSelectedOptions);
    }
  };

  return (
    <Stack direction="column" spacing={3} alignItems="center">
      <ProductCategories
        initialCategory={categoryOnLoad.value}
        onCategoryChange={(newCategoryValue) =>
          setActiveCategory(
            props.productCategories.find((category) => category.value === newCategoryValue) ||
              activeCategory
          )
        }
        options={props.productCategories.map((category) => ({
          value: category.value,
          title: category.title,
          tag: category.tag,
          content: (
            <ProductCategoryOption
              contractLength={category.contractLength}
              startingPrice={category.startingPrice}
              translations={{
                featureValue: category.feature,
                common: props.translations.productCategoryCommon,
              }}
            />
          ),
        }))}
        translations={{ title: props.translations.productCategoryTitle }}
      />
      <Stack direction="column" spacing={3} maxWidth="560px" width="100%">
        {activeModelConfigurationItems.length > 0 && (
          <Options
            {...props}
            key={optionsComponentKey}
            configurationItems={activeModelConfigurationItems}
            selectedOptions={activeOptions}
            onOptionChange={onChangeConfigHandler}
            translations={{ title: props.translations.modelOptionsTitle }}
          />
        )}
        <SubHeader
          title={`${props.translations.modelInfoPrependedTitle} ${activeModel.name}${props.translations.modelInfoAppendedTitle || ""}`}
          dataTestId="modelDetailsHeader"
          testData={activeModel.name}
        />
        <Grid container>
          <Details details={activeModel.details} localeInformation={props.localeInformation} />
        </Grid>
      </Stack>
    </Stack>
  );
};
