import type { FC } from "react";

import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { LocaleInformation } from "../../../../types";

import { InfoRow } from "./InfoRow/InfoRow";

export interface DetailsProps {
  details: {
    /**
     * Unique identifier of model detail e.g. "motor".
     * Used on higher level to modify correct value
     */
    key: string;
    /** Icon of model detail */
    icon: React.ReactNode;
    /** Title of model detail e.g. "Motor power" */
    title: string;
    /** Value of model detail e.g. "450" */
    value: string;
    /** Value of model detail e.g. "units" */
    label?: string;
  }[];
  localeInformation: LocaleInformation;
  showSummary?: boolean;
}

/**
 * Display details of model, based on selections
 */
export const Details: FC<DetailsProps> = (props) => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="column"
      spacing={{ mobile: theme.spacing(1), tablet: theme.spacing(2) }}
    >
      {props.details.map((detail, index) => (
        <Grid item key={index}>
          <InfoRow
            icon={detail.icon}
            title={detail.title}
            content={
              <Typography variant="bodyText">
                {detail.value} {detail.label}
              </Typography>
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};
