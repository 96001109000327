import type { FC } from "react";
import { useEffect, useState } from "react";

import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Amplify } from "aws-amplify";
import fetch from "cross-fetch";
import { Script } from "gatsby";

import type { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from "gatsby";

// Contexts
import {
  graphqlEndpoint,
  graphqlPublicEndpoint,
  intercomAppId,
  userPoolId,
  userPoolWebClientId,
} from "./config/env";
import { UserAttributesProvider } from "./contexts/UserContext";
import introspection from "./generated-fragment-types";
import { websiteTheme } from "./storybook";
import { fetchAmplifyAuthToken } from "./util/amplify";

// Allow migration lambda to work with USER_PASSWORD_AUTH
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId,
      userPoolClientId: userPoolWebClientId,
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const authToken = await fetchAmplifyAuthToken();
    return {
      headers: { ...headers, Authorization: authToken },
    };
  } catch {
    return { headers };
  }
});

const endpointLink = createHttpLink({
  fetch: async (_, options) => {
    let uri = graphqlEndpoint;
    try {
      await fetchAmplifyAuthToken();
    } catch {
      uri = graphqlPublicEndpoint;
    }
    return fetch(uri, options);
  },
});

const client = new ApolloClient({
  name: "website",
  version: process.env.GATSBY_COMMIT_REF,
  link: from([authLink, endpointLink]),
  cache: new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
    typePolicies: {
      ChargebeeIDVerificationDetails: {
        keyFields: ["idDocumentRequired", "idDocumentUploadLink"],
      },
    },
  }),
});

const RootElementWrapper: FC<WrapRootElementNodeArgs | WrapRootElementBrowserArgs> = ({
  element,
}) => {
  const [intercomLoaded, setIntercomLoaded] = useState(false);

  useEffect(() => {
    if (intercomLoaded) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get("openchat") === "true") {
        window.Intercom?.("show");
      }
    }
  }, [intercomLoaded]);

  return (
    <>
      <UserAttributesProvider>
        <ApolloProvider client={client}>
          <ThemeProvider theme={websiteTheme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            {element}
          </ThemeProvider>
        </ApolloProvider>
      </UserAttributesProvider>
      <Script
        src={`https://widget.intercom.io/widget/${intercomAppId}`}
        strategy="idle"
        onLoad={() => setIntercomLoaded(true)}
      />
      {intercomLoaded && (
        <Script strategy="idle">
          {`
            window.intercomSettings = {
              api_base: "https://api-iam.intercom.io",
              app_id: "${intercomAppId}",
            };
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;}})();
          `}
        </Script>
      )}
    </>
  );
};

export default RootElementWrapper;
