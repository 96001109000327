import type { FC } from "react";
import { Fragment, useState } from "react";

import { CarePlan, Info, Link as LinkIcon } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { LinkProps } from "./Link";
import type { ReceiverProps } from "./Receiver";

import { Alert } from "../../../../../Alert";
import { ProgrammaticModal } from "../../../../../Modal";
import { CopyField } from "./CopyField";
import { Receiver } from "./Receiver";

interface ReceiverDetails {
  /** Unique ID of the receiver */
  id: string;
  name: ReceiverProps["name"];
  startDate: ReceiverProps["startDate"];
  endDate: ReceiverProps["endDate"];
}

export interface SharingProps {
  /**
   * Callback for submitting receivers of sharing.
   * Thrown error is catched and its message is shown.
   */
  onSubmit: (
    receivers: {
      id: ReceiverDetails["id"];
      endDate: ReceiverDetails["endDate"];
    }[]
  ) => Promise<void | Error>;
  /** Callback for onClose event of modal */
  onClose: () => void;
  /** Callback for generating share link, should return link as a string. Thrown error is shown */
  onGenerateShareLink: () => Promise<string>;
  /** Receivers which have the right to use the Device */
  receivers: ReceiverDetails[];
  /** Controls modal open state */
  open: boolean;
  /** Name of the device */
  deviceName: string;
  translations: {
    /** E.g. "Sharing settings for" */
    title: string;
    /** E.g. "E-scooter sharing link" */
    shareDescription: string;
    shareTooltip: LinkProps["translations"]["description"];
    /** E.g. "Generate link" */
    shareButton: string;
    /** E.g. "Confirm" */
    confirmButton: string;
    receiver: ReceiverProps["translations"];
  };
}

export const Sharing: FC<SharingProps> = (props) => {
  const [receiversToDisplay, setReceiversToDisplay] = useState(props.receivers);
  const [receiversToSubmit, setReceiversToSubmit] = useState(receiversToDisplay);
  const [shareLink, setShareLink] = useState("");
  const [loadingLinkGeneration, setLoadingLinkGeneration] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState("");
  const theme = useTheme();

  /**
   * Handle update of one receiver's end date changes. As a receiver can be removed (from UI) on
   * {@link handleRemove} but it's not confirmed yet, two states needs to be updated;
   * one for the receivers to submit, and one for the receivers to display.
   *
   * @param id ID of the receiver which endDate is updated
   * @param endDate new endDate for the receiver
   */
  const handleReceiverUpdate = (id: ReceiverDetails["id"], endDate: ReceiverDetails["endDate"]) => {
    const indexOfReceiver = {
      submit: receiversToSubmit.findIndex((item) => item.id === id),
      display: receiversToDisplay.findIndex((item) => item.id === id),
    };
    const updatedReceivers = {
      submit: receiversToSubmit.map((item) => ({ ...item })),
      display: receiversToDisplay.map((item) => ({ ...item })),
    };

    updatedReceivers.submit[indexOfReceiver.submit].endDate = endDate;
    setReceiversToSubmit(updatedReceivers.submit);

    updatedReceivers.display[indexOfReceiver.display].endDate = endDate;
    setReceiversToDisplay(updatedReceivers.display);
  };

  const handleSubmit = async () => {
    setError("");
    setLoadingSubmit(true);
    try {
      await props.onSubmit(receiversToSubmit);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Unknown error");
    }
    setLoadingSubmit(false);
  };

  const handleClose = () => {
    // restore receivers to unmodified state
    setReceiversToDisplay(props.receivers);
    props.onClose();
  };

  /**
   * Handle receiver remove. Set receiver's endDate to past (for API) on {@link receiversToSubmit},
   * and hide receiver from UI by filtering it out of {@link receiversToDisplay}.
   *
   * @param id ID of the receiver to remove
   */
  const handleRemove = (id: string) => {
    setReceiversToSubmit((prev) =>
      prev.map((receiver) =>
        receiver.id === id
          ? {
              ...receiver,
              endDate: new Date(-1),
            }
          : { ...receiver }
      )
    );
    setReceiversToDisplay((prev) => prev.filter((item) => item.id !== id));
  };

  const generateShareLink = async () => {
    setError("");
    setLoadingLinkGeneration(true);
    try {
      const link = await props.onGenerateShareLink();
      setShareLink(link);
    } catch (err) {
      setError(err instanceof Error ? err.message : "Unknown error");
    }
    setLoadingLinkGeneration(false);
  };

  return (
    <ProgrammaticModal
      index={0}
      open={props.open}
      onClose={handleClose}
      views={[
        <Box
          key="shareDetailsModal"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CarePlan sx={{ fontSize: "64px", marginBottom: theme.spacing(1) }} />
          <Typography
            variant="subheadingBold"
            textAlign="center"
            sx={{ marginBottom: theme.spacing(6) }}
          >
            {`${props.translations.title} ${props.deviceName}`}
          </Typography>
          <Box marginBottom={theme.spacing(6)} width="100%">
            {receiversToDisplay.map((item, index) => (
              <Fragment key={item.id}>
                <Receiver
                  name={item.name}
                  startDate={item.startDate}
                  endDate={item.endDate}
                  onRemove={() => handleRemove(item.id)}
                  onChange={(date) => handleReceiverUpdate(item.id, date)}
                  disabled={loadingLinkGeneration || loadingSubmit}
                  translations={props.translations.receiver}
                />
                {index < receiversToDisplay.length - 1 && (
                  <Divider
                    sx={{
                      width: "100%",
                      borderColor: theme.palette.text[200],
                      marginY: theme.spacing(2),
                    }}
                  />
                )}
              </Fragment>
            ))}
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            marginBottom={theme.spacing(2)}
            width="100%"
          >
            <Typography variant="bodyTextSemibold">
              {props.translations.shareDescription}
            </Typography>
            <Tooltip title={props.translations.shareTooltip} placement="top-end">
              <Info sx={{ color: theme.palette.text.primary }} />
            </Tooltip>
          </Stack>
          {shareLink ? (
            <CopyField textToCopy={shareLink} />
          ) : (
            <LoadingButton
              onClick={generateShareLink}
              loading={loadingLinkGeneration}
              disabled={loadingSubmit}
              fullWidth
              variant="outlined"
              endIcon={<LinkIcon />}
              sx={{ marginBottom: theme.spacing(4), justifyContent: "space-between" }}
            >
              {props.translations.shareButton}
            </LoadingButton>
          )}
          {error && (
            <Alert
              severity="error"
              description={error}
              sx={{ marginBottom: theme.spacing(2), alignSelf: "stretch" }}
            />
          )}
          <LoadingButton
            fullWidth
            onClick={handleSubmit}
            loading={loadingSubmit}
            disabled={loadingLinkGeneration || receiversToSubmit === props.receivers}
            data-testid="confirmSharingEditButton"
          >
            {props.translations.confirmButton}
          </LoadingButton>
        </Box>,
      ]}
    />
  );
};
