import { Box, Grid, tableContainerClasses } from "@mui/material";

import type { TableProps } from "../../Table";
import type { ActionButtonsProps } from "../ActionButtons";
import type { HeadingContentProps } from "../HeadingContent";

import { Table } from "../../Table";
import { ActionButtons } from "../ActionButtons";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockTableProps {
  headingContent: HeadingContentProps;
  callToAction?: Pick<ActionButtonsProps, "buttons">;
  tables: TableProps[];
}

export const BlockTable = (props: BlockTableProps) => (
  <GridContainer>
    <Grid item mobile={12}>
      <HeadingContent
        {...props.headingContent}
        boxProps={{ width: { tablet: "60%", laptop: "50%" } }}
        centered
      />
    </Grid>
    <Grid
      item
      mobile={12}
      sx={(theme) => ({
        [`&>.${tableContainerClasses.root}`]: {
          mt: { mobile: theme.spacing(2), tablet: theme.spacing(4) },
          "&:first-of-type": { mt: 0 },
        },
      })}
    >
      {props.tables.map((table, index) => (
        <Table key={index} {...table} />
      ))}
      {props.callToAction && (
        <Box mt={{ tablet: 4 }}>
          <ActionButtons
            {...props.callToAction}
            direction={{ mobile: "column", tablet: "row", laptop: "row" }}
            grow={{ mobile: true, tablet: true, laptop: false }}
            centered
          />
        </Box>
      )}
    </Grid>
  </GridContainer>
);
