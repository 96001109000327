import type { FC } from "react";
import { useEffect } from "react";

import { useApolloClient } from "@apollo/client";
import * as Sentry from "@sentry/gatsby";
import { fetchUserAttributes } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";

import type { HubCallback } from "@aws-amplify/core";

import { intercomAppId } from "../../config/env";
import { useI18n } from "../../contexts/I18nContext";
import { useUserAttributes } from "../../contexts/UserContext";
import { useMeIntercomLazyQuery } from "../../generated-types";

export const AuthWrapper: FC<{ children?: React.ReactNode }> = (props) => {
  const { locale } = useI18n();
  const { setUserAttributes } = useUserAttributes();
  const client = useApolloClient();

  const [getIntercom] = useMeIntercomLazyQuery();

  const initUser = async () => {
    let intercomSettings: Window["intercomSettings"] = {};
    let sentrySettings: Sentry.User | null = null;
    try {
      const userAttributes = await fetchUserAttributes();
      setUserAttributes(userAttributes);
      const result = await getIntercom();
      intercomSettings = {
        user_id: userAttributes.sub,
        email: userAttributes.email,
        user_hash: result.data?.me.intercomHexDigestWeb,
      };
      sentrySettings = {
        id: userAttributes.sub,
        email: userAttributes.email,
      };
    } catch {
      setUserAttributes(null);
    } finally {
      if (typeof window !== "undefined") {
        // Add Intercom settings to window
        window.intercomSettings = intercomSettings;
        if (typeof window.Intercom === "function") {
          // If Intercom function is found, Intercom has been booted and update is required
          window.Intercom?.("update", intercomSettings);
        }
        Sentry.setUser(sentrySettings);
      }
    }
  };

  const logoutUser = async () => {
    setUserAttributes(null);
    if (typeof window !== "undefined") {
      window.intercomSettings = { app_id: intercomAppId };
      if (typeof window.Intercom === "function") {
        window.Intercom?.("shutdown");
        window.Intercom?.("boot", {
          app_id: intercomAppId,
        });
      }
    }
    Sentry.setUser(null);
    await client.clearStore();
    window.location.replace(`/${locale}/`);
    // Session storage must be cleared after navigating to prevent application storing stale data
    sessionStorage.clear();
  };

  const authListener: HubCallback = async (data) => {
    switch (data.payload.event) {
      case "signedIn":
        return initUser();
      case "signedOut":
        return logoutUser();
      default:
        return null;
    }
  };

  useEffect(() => {
    const initialize = async () => {
      await initUser();
    };

    initialize();
    const hubListenerCancel = Hub.listen("auth", authListener);

    return () => {
      hubListenerCancel();
    };
  }, []);

  return <>{props.children}</>;
};
