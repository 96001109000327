import { useState } from "react";

import { CloseRound } from "@augment-frontend/mui-icons";
import { Checkbox, FormControlLabel, FormGroup, IconButton, Typography } from "@mui/material";

import type { InfoBoxProps } from "../../Info";
import type { SummaryProps } from "../../Summary";
import type { StepProps } from "../Step";
import type { RegisterOrLoginProps } from "./RegisterOrLogin";

import { Alert } from "../../../Alert";
import { Summary } from "../../Summary";
import { SummaryRow } from "../../Summary/SummaryRow";
import { Step } from "../Step";
import { RegisterOrLogin } from "./RegisterOrLogin";

export type StepAccountProps = {
  summaryItems: Pick<SummaryProps["items"][number], "title" | "value" | "addedItems">[];
  summaryTotal: NonNullable<SummaryProps["summaryRow"]>;
  /**
   * Wether the nameInput should be immutable.
   * @default true
   */
  nameIsImmutable?: boolean;
  /**
   * The username (email) for the currently logged in user.
   * If the user is not logged in, this value should be null, and register / login form will be shown.
   */
  username: string | null;
  infoBox1: InfoBoxProps;
  infoBox2?: InfoBoxProps;
  /**
   * Triggered when the user performs register or login operation.
   *
   * The form will be in loading state until the Promise either resolves or rejects.
   * If the Promise rejects, the message attribute of the error will be shown to the user.
   *
   * @param username Username (email) for new or existin account.
   * @param password Password for new or existing account.
   * @param generalMarketingConsent Whether the user has opted in to receive special offers.
   * @returns Promise that resolves to void.
   */
  onRegisterOrLogin: (
    username: string,
    password: string,
    generalMarketingConsent: boolean
  ) => Promise<void>;
  /**  Loading status of the component passed on for the summary */
  loading?: boolean;
  translations: {
    /** E.g. "Shipping information" */
    title: StepProps["title"];
    /** E.g. "Summary" */
    summaryTitle: string;
    /** E.g. "Continue" */
    forwardButtonLabel: NonNullable<StepProps["bottomNavigation"]["forwardButton"]>;
    /** E.g."I don't want to receive Augment special offers." */
    optOutSpecialOffersLabel: string;
    registerOrLogin: RegisterOrLoginProps["translations"];
    /** BottomNavigation forwardButton label if user isn't authenticated, e.g. "Register" or "Login" */
    registerOrLoginForwardButtonLabel: string;
    stepTranslations: StepProps["translations"];
  };
} & Pick<
  StepProps["bottomNavigation"],
  "onForward" | "onBackward" | "disableForward" | "loadingForward" | "width"
>;

/**
 * Renders a shipping info form to collect mandatory information for the user.
 */
export const StepYourAccount = (props: StepAccountProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loadingForward, setLoadingForward] = useState(props.loadingForward);
  const [forwardEvent, setForwardEvent] =
    useState<React.MouseEvent<HTMLButtonElement, MouseEvent>>();
  const [generalMarketingConsent, setGeneralMarketingConsent] = useState(true);

  const [areCredentialsValid, setAreCredentialsValid] = useState(false);
  const [registerOrLoginErrorMessage, setRegisterOrLoginErrorMessage] = useState("");

  const summaryProps = {
    title: props.translations.summaryTitle,
    items: props.summaryItems,
    loading: props.loading,
  };

  const forwardAction = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      setLoadingForward(true);
      await props.onForward?.(event);
    } catch (e: unknown) {
      setErrorMessage(e instanceof Error ? e.message : "Unknown error");
    }
    setLoadingForward(false);
  };

  /** Handler for RegisterOrLogin submit */
  const onRegisterOrLoginSubmitHandler = async (email: string, password: string) => {
    setLoadingForward(true);
    setRegisterOrLoginErrorMessage("");
    try {
      await props.onRegisterOrLogin(email, password, generalMarketingConsent);
      await forwardAction(forwardEvent as React.MouseEvent<HTMLButtonElement, MouseEvent>);
    } catch (e: unknown) {
      setRegisterOrLoginErrorMessage(e instanceof Error ? e.message : "Unknown error");
    }
    setLoadingForward(false);
  };

  const onChangePermissionHandler = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setGeneralMarketingConsent(!checked);
  };

  return (
    <Step
      title={props.translations.title}
      stepComponent={{
        component: (
          <>
            <Typography
              variant="subheadingBold"
              display={{ mobile: "none", tablet: "block" }}
              mb={2}
            >
              {props.translations.title}
            </Typography>
            <RegisterOrLogin
              username={props.username}
              onSubmit={onRegisterOrLoginSubmitHandler}
              onCredentialsChange={(emailValid, passwordValid) => {
                setAreCredentialsValid(emailValid && passwordValid);
              }}
              disabled={loadingForward}
              errorMessage={registerOrLoginErrorMessage}
              translations={props.translations.registerOrLogin}
            />
            {!props.username && (
              <FormGroup sx={{ marginTop: { mobile: 1, tablet: "auto" } }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="generalMarketingConsent"
                      checked={!generalMarketingConsent}
                      onChange={onChangePermissionHandler}
                      disabled={loadingForward}
                    />
                  }
                  label={props.translations.optOutSpecialOffersLabel}
                />
              </FormGroup>
            )}
            {errorMessage && (
              <Alert
                severity="error"
                description={errorMessage}
                sx={{ marginTop: 2 }}
                action={
                  <IconButton
                    onClick={() => setErrorMessage("")}
                    sx={{ color: (theme) => theme.palette.text.primary }}
                  >
                    <CloseRound />
                  </IconButton>
                }
              />
            )}
          </>
        ),
      }}
      leftPanel={{
        summary: <Summary {...summaryProps} summaryRow={props.summaryTotal} />,
      }}
      rightPanel={{
        infoBox1: props.infoBox1,
        infoBox2: props.infoBox2,
      }}
      bottomNavigation={{
        mobileAlwaysVisibleContent: (
          <SummaryRow dividerPosition="hidden" {...props.summaryTotal} loading={props.loading} />
        ),
        mobileAdditionalContent: <Summary {...summaryProps} />,
        mobileBackward: true,
        onBackward: props.onBackward,
        onForward: props.username ? forwardAction : setForwardEvent,
        forwardButton: props.username
          ? props.translations.forwardButtonLabel
          : props.translations.registerOrLoginForwardButtonLabel,
        loadingForward,
        disableForward: (!props.username && !areCredentialsValid) || props.disableForward,
        width: props.width,
        formIdToSubmit: props.username ? undefined : "registerOrLoginForm",
      }}
      translations={props.translations.stepTranslations}
    />
  );
};
