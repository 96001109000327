import type { FC } from "react";

import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { BlogGridItemProps } from "./Item";
import type { TypographyProps } from "@mui/material";

import { GridContainer } from "../../GridContainer";
import { BlogGridItem } from "./Item";

export interface BlockBlogGridProps {
  /** Blogs to display on BlogGrid */
  blogs: BlogGridItemProps[];
  /** Title to show on tablet & laptop, e.g. "Other articles" */
  title?: string;
}

const StyledTitle = styled(Typography)<TypographyProps>((props) => ({
  ...props.theme.typography.h3Bold,
  [props.theme.breakpoints.up("laptop")]: {
    ...props.theme.typography.h2Bold,
  },
}));

export const BlockBlogGrid: FC<BlockBlogGridProps> = (props) => (
  <GridContainer>
    {props.title && (
      <Grid item mobile={12} display={{ mobile: "none", tablet: "block" }}>
        <StyledTitle>{props.title}</StyledTitle>
      </Grid>
    )}
    <Grid container margin={0} spacing={4} alignItems="stretch">
      {props.blogs.map((item, index) => (
        <Grid item key={index} mobile={12} tablet={6}>
          <BlogGridItem {...item} />
        </Grid>
      ))}
    </Grid>
  </GridContainer>
);
