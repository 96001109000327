import type { FC } from "react";
import { useState } from "react";

import { Link, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";

import type { PaymentMethodControllerProps } from "./PaymentMethodController";
import type { PaymentMethodListProps } from "./PaymentMethodList";
import type { PaymentMethod } from "./types";
import type { TypographyProps } from "@mui/material";

import { PaymentMethodController } from "./PaymentMethodController";
import { PaymentMethodList } from "./PaymentMethodList";

const MaybeDisabledTypography = styled(Typography)<
  TypographyProps & { component: string; disabled?: boolean }
>((props) => ({
  ...(props.disabled && { opacity: 0.5 }),
}));

export type PaymentMethodListOrControllerProps = {
  translations: {
    /** E.g. "Want to use another payment method?" */
    actionPreText: string;
    /** E.g. "Click here to take action." */
    actionText: string;
    list: PaymentMethodListProps["translations"];
    controller: PaymentMethodControllerProps["translations"];
  };
} & Pick<PaymentMethodListProps, "paymentMethods"> &
  Pick<
    PaymentMethodControllerProps,
    | "cbInstance"
    | "types"
    | "walletTypes"
    | "onSubmit"
    | "onPaymentSuccess"
    | "onPaymentFailure"
    | "onPaymentLoading"
    | "onUnknownPaymentError"
    | "disabled"
    | "disabledSubmit"
    | "tooltipImages"
    | "wallet"
    | "locale"
  >;

type RenderControllerProps = PaymentMethodListOrControllerProps & {
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  setControllerLoading: React.Dispatch<React.SetStateAction<boolean>>;
  activePaymentMethod: PaymentMethod | undefined;
};

/**
 * Render controller by props. Useful to e.g. create one controller with wallets, and other without
 */
const RenderController = (renderControllerProps: RenderControllerProps) => (
  <PaymentMethodController
    types={renderControllerProps.types}
    walletTypes={renderControllerProps.walletTypes}
    cbInstance={renderControllerProps.cbInstance}
    wallet={renderControllerProps.wallet}
    onSubmit={(...args) => {
      renderControllerProps.setControllerLoading(true);
      // type cast is required here, as the type of args is not inferred by this component
      return renderControllerProps
        .onSubmit(...(args as Parameters<PaymentMethodControllerProps["onSubmit"]>))
        .finally(() => {
          // executed once wallet has been mounted
          renderControllerProps.setControllerLoading(false);
        });
    }}
    onPaymentSuccess={async (...args) => {
      await renderControllerProps.onPaymentSuccess(...args);
      renderControllerProps.setControllerLoading(false);
    }}
    onPaymentFailure={async (...args) => {
      await renderControllerProps.onPaymentFailure?.(...args);
      renderControllerProps.setControllerLoading(false);
    }}
    onUnknownPaymentError={async (error) => {
      await renderControllerProps.onUnknownPaymentError?.(error);
      renderControllerProps.setControllerLoading(false);
    }}
    onPaymentLoading={renderControllerProps.onPaymentLoading}
    // Only the 1st view will use the saved payment method
    savedPaymentMethod={
      renderControllerProps.index === 0 ? renderControllerProps.activePaymentMethod : undefined
    }
    onSecondaryButtonClick={
      renderControllerProps.paymentMethods.length > 0 && renderControllerProps.index === 1
        ? () => {
            renderControllerProps.setIndex(0);
          }
        : undefined
    }
    disabled={renderControllerProps.disabled}
    disabledSubmit={renderControllerProps.disabledSubmit}
    tooltipImages={renderControllerProps.tooltipImages}
    locale={renderControllerProps.locale}
    translations={renderControllerProps.translations.controller}
  />
);

/**
 * Renders a list of payment methods or a payment method element to fulfill a payment.
 */
export const PaymentMethodListOrController: FC<PaymentMethodListOrControllerProps> = (props) => {
  const [index, setIndex] = useState(props.paymentMethods.length === 0 ? 1 : 0);
  const [activePaymentMethod, setActivePaymentMethod] = useState<PaymentMethod | undefined>(
    props.paymentMethods.find((paymentMethod) => paymentMethod.isPrimary)
  );
  const [controllerLoading, setControllerLoading] = useState(false);
  const theme = useTheme();

  // wallets always to first view's controller
  const firstViewController = RenderController({
    ...props,
    index,
    setIndex,
    setControllerLoading,
    activePaymentMethod,
    disabled: props.disabled,
    disabledSubmit: controllerLoading || props.disabledSubmit,
  });
  // no wallets to second view's controller, if there are saved payment methods
  // if we provide wallets to the second view's controller (and one exists on the first),
  // it will render 2 Google Pay buttons to the same first element, and none for the latter
  const secondViewController =
    props.paymentMethods.length > 0
      ? RenderController({
          ...props,
          index,
          setIndex,
          setControllerLoading,
          activePaymentMethod,
          disabled: props.disabled,
          disabledSubmit: controllerLoading || props.disabledSubmit,
          wallet: undefined,
        })
      : RenderController({
          ...props,
          index,
          setIndex,
          setControllerLoading,
          activePaymentMethod,
          disabled: props.disabled,
          disabledSubmit: controllerLoading || props.disabledSubmit,
        });

  return (
    <SwipeableViews
      index={index}
      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
      disabled={true}
    >
      <>
        <PaymentMethodList
          paymentMethods={props.paymentMethods}
          onChange={(paymentMethod) => {
            setActivePaymentMethod(paymentMethod);
          }}
          disabled={controllerLoading || props.disabled}
          translations={props.translations.list}
        />
        <MaybeDisabledTypography
          variant="largeTextSemibold"
          component="p"
          sx={{ my: 2, cursor: controllerLoading || props.disabled ? "default" : "pointer" }}
          onClick={() => {
            if (!controllerLoading && !props.disabled) {
              setIndex(1);
            }
          }}
          disabled={controllerLoading || props.disabled}
        >
          {props.translations.actionPreText}{" "}
          <Link component="span" underline={controllerLoading || props.disabled ? "none" : "hover"}>
            {props.translations.actionText}
          </Link>
        </MaybeDisabledTypography>
        {/*
          Conditional rendering is done to prevent rendering duplicate iframes,
          since the iframes are not rendered, if there are saved payment methods.
          However, if there are no saved PMs, we show only the second view's controller,
          but without this condition, we would render the payment fields as hidden duplicate.
        */}
        {props.paymentMethods.length > 0 && firstViewController}
      </>
      {secondViewController}
    </SwipeableViews>
  );
};
