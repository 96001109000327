import type { FC } from "react";

import { Link, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { LinkProps } from "@mui/material";

import { scrollToAnchor } from "../../utils/scroll";

interface NavigationLink {
  href: NonNullable<LinkProps["href"]>;
  label: string;
}

export interface FixedNavigationProps {
  links: NavigationLink[];
  headerOffset?: number;
}

const ButtonLink = styled(Link)<LinkProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text[800],
  textAlign: "center",
  padding: theme.spacing(1, 2),
  width: 200,
  borderRadius: theme.shape.borderRadius,
  wordBreak: "break-word",
  fontSize: "0.95rem",
  fontWeight: "bold",
  textDecoration: "unset",
  ":hover": {
    textDecoration: "unset",
  },
}));

export const FixedNavigation: FC<FixedNavigationProps> = (props) => (
  <Stack spacing={0.5} position="fixed">
    {props.links.map((link, index) => (
      <ButtonLink
        key={index}
        href={link.href}
        onClick={(e) => {
          e.preventDefault();
          scrollToAnchor(link.href, props.headerOffset);
        }}
      >
        {link.label}
      </ButtonLink>
    ))}
  </Stack>
);
