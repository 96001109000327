import type { FC, ReactNode } from "react";

import { useTheme } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";

import type { ScrollModalProps } from "./ScrollModal";
import type { SwipeableViewsProps } from "react-swipeable-views";

import { ScrollModal } from "./ScrollModal";

interface TabPanelProps {
  index: number;
  value: number;
  children?: ReactNode;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...rest } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...rest}>
      {value === index && children}
    </div>
  );
};

export type ProgrammaticModalProps = {
  /** Views to show in modal */
  views: ReactNode[];
  /** Index of current visible view */
  index: number;
} & Pick<SwipeableViewsProps, "onChangeIndex"> &
  Omit<ScrollModalProps, "children">;

/**
 * The modal is controlled programmatically by triggering open state and changing the value of index
 */
export const ProgrammaticModal: FC<ProgrammaticModalProps> = (props) => {
  const { index, onChangeIndex, views, ...scrollModalProps } = props;
  const theme = useTheme();

  return (
    <ScrollModal {...scrollModalProps}>
      <SwipeableViews
        index={index}
        onChangeIndex={onChangeIndex}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        disabled={true}
      >
        {views.map((view, i) => (
          <TabPanel key={i} value={index} index={i}>
            {view}
          </TabPanel>
        ))}
      </SwipeableViews>
    </ScrollModal>
  );
};
