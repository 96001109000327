import type { FC } from "react";

import { Typography } from "@mui/material";

type RadioGroupCardTagProps = {
  /** Value of the tag, e.g. "Best choice" */
  value: string;
};

/** Render a tag to top-right corner of the Radio Group Card */
export const RadioGroupCardTag: FC<RadioGroupCardTagProps> = (props) => {
  return (
    <Typography
      variant="captionBold"
      sx={(theme) => ({
        paddingY: 0.5,
        paddingX: 1,
        background: theme.palette.primary.main,
        borderTopRightRadius: "7px", // this is intentionally 7px, to fill the gap between the tag and the card border
        borderBottomLeftRadius: "8px",
        position: "absolute",
        right: "0px",
        top: "0px",
      })}
    >
      {props.value}
    </Typography>
  );
};
