import type { FC } from "react";
import { useState } from "react";

import { Trash } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { ProgrammaticModalProps } from "../../../../../../Modal";

import { Alert } from "../../../../../../Alert";
import { ProgrammaticModal } from "../../../../../../Modal";

export type CancelSharingProps = {
  /** Callback for onSubmit event. Thrown error is catched and its message is shown. */
  onSubmit: () => Promise<void | Error>;
  /** Triggered when cancel button is clicked. */
  onCancel: () => void;
  /** Triggered when {@link onSubmit} succeeded. */
  onCompleted: () => void;
  /** Name of device */
  name: string;
  translations: {
    /** E.g. "Remove right to use this e-scooter?" */
    description: string;
    /** E.g. "Cancel" */
    cancelButton: string;
    /** E.g. "Remove" */
    removeButton: string;
  };
} & Required<Pick<ProgrammaticModalProps, "onClose" | "open">>;

export const CancelSharing: FC<CancelSharingProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const theme = useTheme();

  const handleSubmit = async () => {
    setError("");
    setLoading(true);
    try {
      await props.onSubmit();
      setLoading(false);
      props.onCompleted();
    } catch (err) {
      setError(err instanceof Error ? err.message : "Unknown error");
      setLoading(false);
    }
  };

  return (
    <ProgrammaticModal
      index={0}
      open={props.open}
      onClose={props.onClose}
      views={[
        <Box
          key="cancelSharingModal"
          sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <Trash sx={{ fontSize: "64px", marginBottom: theme.spacing(1) }} />
          <Typography
            variant="subheadingBold"
            textAlign="center"
            sx={{ marginBottom: theme.spacing(3) }}
          >
            {props.name}
          </Typography>
          <Typography
            variant="bodyText"
            marginBottom={theme.spacing(3)}
            marginTop={theme.spacing(2)}
            textAlign="center"
          >
            {props.translations.description}
          </Typography>
          {error && (
            <Alert
              severity="error"
              description={error}
              sx={{ marginBottom: theme.spacing(2), alignSelf: "stretch" }}
            />
          )}
          <Stack direction="row" spacing={theme.spacing(2)}>
            <Button color="secondary" onClick={props.onCancel}>
              {props.translations.cancelButton}
            </Button>
            <LoadingButton
              loading={loading}
              onClick={handleSubmit}
              data-testid="removeSharingAccess"
            >
              {props.translations.removeButton}
            </LoadingButton>
          </Stack>
        </Box>,
      ]}
    />
  );
};
