import type { FC } from "react";
import { useEffect, useState } from "react";

import { ageInYears } from "@augment-frontend/ssn";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import type { PossibleLocales } from "../../../utils/locale";
import type { DatePickerProps } from "../../DatePicker";

import { resolveDateFnsLocale, resolveLocaleText } from "../../../utils/locale";
import { DatePicker } from "../../DatePicker";

export interface BirthdayInputProps extends Omit<DatePickerProps, "onChange" | "locale"> {
  onChange: ({ birthday, valid }: BirthdayResult) => void;
  immutable?: boolean;
  locale: PossibleLocales;
}

export type BirthdayResult = {
  birthday: Date | null;
  valid: boolean;
};

export const BirthdayInput: FC<BirthdayInputProps> = (props) => {
  const [selectedDate, setSelectedDate] = useState<BirthdayResult["birthday"]>(
    props.value ? props.value : null
  );
  const [isValid, setIsValid] = useState(
    props.value ? ageInYears(props.value, new Date()) >= 18 : undefined
  );
  const [immutable, setImmutable] = useState(props.immutable || false);

  useEffect(() => {
    if (selectedDate) {
      const age = ageInYears(selectedDate, new Date());
      props.onChange({
        birthday: selectedDate,
        valid: Boolean(isValid && !(age < 18)),
      });
    }
  }, [selectedDate, isValid]);

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  const handleChange = (date: Date | null) => {
    setIsValid(true);
    setImmutable(false);
    setSelectedDate(date);
  };

  const handleOpen = () => {
    if (!selectedDate) {
      setSelectedDate(maxDate);
      setIsValid(true);
      setImmutable(false);
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={resolveDateFnsLocale(props.locale)}
      localeText={resolveLocaleText(props.locale)}
    >
      <DatePicker
        {...props}
        onOpen={handleOpen}
        dateFormatLocale={props.locale}
        translations={props.translations}
        longFormatDate={true}
        views={["year", "month", "day"]}
        label={props.translations.label}
        maxDate={maxDate}
        value={selectedDate}
        openTo="year"
        onChange={handleChange}
        onValid={setIsValid}
        slotProps={{ toolbar: { hidden: true } }}
        disabled={props.disabled || (immutable && !!isValid)}
        maskDaysAndMonths={!!(immutable && !!isValid)}
      />
    </LocalizationProvider>
  );
};
