import { Stack, Typography } from "@mui/material";

import { Alert } from "../Alert";
import { RadioGroupCardColumn } from "../Radio";

export interface PaymentTermsProps<T> {
  /** Listener for selection change */
  onChange: (value: T) => void;
  options: {
    /** Value of the option e.g. "8-payments" */
    value: T;
    /** Title of the option e.g. "Every 3 months" */
    title: string;
    /** Body of the option e.g. "79.95€" */
    body: string;
    /** Bottom left information e.g. "8 payments" */
    leftFooter: string;
    /** Bottom right information e.g. "26.65€/month" */
    rightFooter: string;
    /** Tag of the option e.g. "Best choice!" */
    tag?: string;
    /** Option is disabled for e.g. previously failed KYC check */
    disabled?: boolean;
  }[];
  /** Initial selected value */
  initialValue?: T;
  translations: {
    /** E.g. "Options are limited due to previously rejected order(s)." */
    restricted: string;
  };
}

export function PaymentTerms<T>(props: PaymentTermsProps<T>) {
  return (
    <>
      {props.options.some((option) => option.disabled) && (
        <Alert
          severity="warning"
          description={props.translations.restricted}
          sx={{ mb: 2 }}
          data-testid="paymentTermsRestrictedAlert"
        />
      )}
      <RadioGroupCardColumn
        onChange={props.onChange}
        initialValue={props.initialValue}
        hideRadioIcon
        spacing={1}
        options={props.options.map((option) => ({
          ...option,
          content: (
            <Stack direction="column" spacing={1} marginTop={1}>
              <Typography variant="subheadingBold">{option.body}</Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="largeText">{option.leftFooter}</Typography>
                <Typography variant="bodyText">{option.rightFooter}</Typography>
              </Stack>
            </Stack>
          ),
        }))}
      />
    </>
  );
}
