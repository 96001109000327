import { useEffect, useRef, useState } from "react";

import {
  COUNTRIES,
  HOUSE_NUMBER_COUNTRIES,
  LINE_2_COUNTRIES,
  REVERSED_HOUSE_NUMBER_COUNTRIES,
  SENDCLOUD_COUNTRIES,
  STATES_BY_COUNTRY,
} from "@augment-frontend/constants";
import { Grid } from "@mui/material";
import isPostalCode from "validator/es/lib/isPostalCode";

import type { Country, CountryCode, State } from "@augment-frontend/constants";
import type { SelectChangeEvent } from "@mui/material";

import { Select } from "../../Select";
import { TextField } from "../../TextField";
import { sanitizeValue } from "./utils";

export type Address = {
  /** Short identifier that typically goes with line 1 */
  houseNumber?: string;
  /** Street address line 1; typically street name */
  line1?: string;
  /** Street address line 2; e.g. e.g. apartment number */
  line2?: string;
  /** Postal code */
  postalCode?: string;
  /** City */
  city?: string;
  /** State (province) */
  state?: State;
  /** Country */
  country: Country;
};

type AddressState = {
  [K in NonNullable<keyof Omit<Address, "state" | "country">>]: {
    value: NonNullable<Address[K]>;
    sanitizedValue: string;
    valid?: boolean;
  };
} & {
  state: {
    value: State["name"];
    valid?: boolean;
  };
} & {
  country: {
    value: CountryCode;
    valid?: boolean;
  };
} & {
  valid: boolean;
};

export type AddressResult = { address: Address; valid: boolean };

export interface AddressInputProps {
  /** Address initial value */
  address: Address;
  /** Disable country, e.g. in the subscription flow */
  countryDisabled?: boolean;
  /** Sets input in immutable state, i.e. values cannot be changed if they pass validation */
  immutable?: boolean;
  /** Sets input in disabled state, e.g. when parent is loading */
  disabled?: boolean;
  /** Callback for when a value of an input changes */
  onChange: (result: AddressResult, changedByUser?: boolean) => void;
  translations: {
    /** House number label */
    houseNumberLabel: string;
    /** Line1 label */
    line1Label: string;
    /** Line2 label */
    line2Label: string;
    /** Zip label */
    postalCodeLabel: string;
    /** City label */
    cityLabel: string;
    /** State label */
    stateLabel: string;
    /** Country label */
    countryLabel: string;
  };
}

/**
 * Validates address fields
 * @param field Input id
 * @param value Input value
 * @param country Country code
 * @returns boolean
 */
const validateAddressElement = (
  field: keyof Address,
  value: string,
  country?: CountryCode
): boolean => {
  switch (field) {
    case "houseNumber":
      if (value.length < 1 || value.length > 20) {
        return false;
      }
      return true;
    case "line1":
      if (
        value.length < 3 ||
        (country && SENDCLOUD_COUNTRIES.includes(country) && value.length > 30)
      ) {
        return false;
      }
      return true;
    case "line2":
      if (country && SENDCLOUD_COUNTRIES.includes(country) && value.length > 30) {
        return false;
      }
      return true;
    case "postalCode":
      if (country && !isPostalCode(value, country)) {
        return false;
      }
      return true;
    case "city":
      if (value.length < 3) {
        return false;
      }
      return true;
    case "state":
      if (!value) {
        return false;
      }
      return true;
    case "country":
      if (!value) {
        return false;
      }
      return true;
    default:
      return false;
  }
};

/**
 * Renders address inputs. Needs a parent <Grid container> component.
 */
export const AddressInput = (props: AddressInputProps) => {
  const userInteraction = useRef(false);
  const [addressState, setAddressState] = useState<AddressState>(() => {
    const houseNumberSanitized = sanitizeValue(props.address.houseNumber || "");
    const line1Sanitized = sanitizeValue(props.address.line1 || "");
    const line2Sanitized = sanitizeValue(props.address.line2 || "");
    const postalCodeSanitized = sanitizeValue(props.address.postalCode || "");
    const citySanitized = sanitizeValue(props.address.city || "");

    const houseNumberValid =
      props.address.houseNumber === undefined
        ? undefined
        : validateAddressElement("houseNumber", houseNumberSanitized);
    const line1Valid =
      props.address.line1 === undefined
        ? undefined
        : validateAddressElement("line1", line1Sanitized);
    const line2Valid =
      props.address.line2 === undefined
        ? undefined
        : validateAddressElement("line2", line2Sanitized);
    const postalCodeValid =
      props.address.postalCode === undefined
        ? undefined
        : validateAddressElement("postalCode", postalCodeSanitized, props.address.country.code);
    const cityValid =
      props.address.city === undefined ? undefined : validateAddressElement("city", citySanitized);
    const stateValid =
      props.address.state?.name === undefined
        ? undefined
        : validateAddressElement("state", props.address.state.name);
    const countryValid =
      props.address.country.code === undefined
        ? undefined
        : validateAddressElement("country", props.address.country.code);

    return {
      houseNumber: {
        value: props.address.houseNumber ?? "",
        sanitizedValue: houseNumberSanitized,
        valid: houseNumberValid,
      },
      line1: {
        value: props.address.line1 ?? "",
        sanitizedValue: line1Sanitized,
        valid: line1Valid,
      },
      line2: {
        value: props.address.line2 ?? "",
        sanitizedValue: line2Sanitized,
        valid: line2Valid,
      },
      postalCode: {
        value: props.address.postalCode ?? "",
        sanitizedValue: postalCodeSanitized,
        valid: postalCodeValid,
      },
      city: {
        value: props.address.city ?? "",
        sanitizedValue: citySanitized,
        valid: cityValid,
      },
      state: {
        value: props.address.state?.name ?? "",
        valid: stateValid,
      },
      country: {
        value: props.address.country.code,
        valid: countryValid,
      },
      valid: Boolean(
        (HOUSE_NUMBER_COUNTRIES.includes(props.address.country.code) ? houseNumberValid : true) &&
          line1Valid &&
          (LINE_2_COUNTRIES.includes(props.address.country.code) ? line2Valid : true) &&
          postalCodeValid &&
          cityValid &&
          (STATES_BY_COUNTRY[props.address.country.code].length ? stateValid : true) &&
          countryValid
      ),
    };
  });

  // If initial value set immutable does not pass validation, allow editing it, and
  // set immutable state false on edit to control input's disabled state correctly
  const [immutable, setImmutable] = useState(props.immutable || false);
  const [disabled, setDisabled] = useState(props.disabled || false);

  /** Returns true if house number should be reversed for selected country */
  const isHouseNumberReversed = () => {
    return Boolean(REVERSED_HOUSE_NUMBER_COUNTRIES.includes(addressState.country.value));
  };

  /** Returns true if house number should be shown for selected country */
  const isHouseNumberRequired = () => {
    return HOUSE_NUMBER_COUNTRIES.includes(addressState.country.value);
  };
  useEffect(() => {
    props.onChange(
      {
        address: {
          houseNumber: addressState.houseNumber.sanitizedValue,
          line1: addressState.line1.sanitizedValue,
          line2: addressState.line2.sanitizedValue,
          postalCode: addressState.postalCode.sanitizedValue,
          city: addressState.city.sanitizedValue,
          state: addressState.state.value
            ? {
                name: addressState.state.value,
              }
            : undefined,
          country: {
            code: addressState.country.value,
          },
        },
        // Validate also on initial load
        valid:
          ((isHouseNumberRequired() ? addressState.houseNumber.valid : true) &&
            addressState.line1.valid &&
            (LINE_2_COUNTRIES.includes(addressState.country.value)
              ? addressState.line2.valid || addressState.line2.valid === undefined
              : true) &&
            addressState.postalCode.valid &&
            addressState.city.valid &&
            (STATES_BY_COUNTRY[addressState.country.value].length
              ? addressState.state.valid
              : true) &&
            addressState.country.valid) ||
          false,
      },
      userInteraction.current
    );
    userInteraction.current = false;
  }, [addressState]);

  useEffect(() => {
    setDisabled(props.disabled || false);
  }, [props.disabled]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    userInteraction.current = true;
    const field = event.target.id as keyof Omit<Address, "state" | "country">;
    const { value } = event.target;
    // Uppercase first letter and remove extra spaces
    const sanitizedValue = sanitizeValue(value);
    const valid = validateAddressElement(field, sanitizedValue, addressState.country.value);

    setImmutable(false);

    setAddressState(
      (prev): AddressState => ({
        ...prev,
        [field]: {
          value,
          sanitizedValue,
          valid,
        },
      })
    );
  };

  const handleStateChange = async (event: SelectChangeEvent<unknown>) => {
    userInteraction.current = true;
    const value = event.target.value as State["name"];
    const valid = validateAddressElement("state", value, addressState.country.value);

    setImmutable(false);

    setAddressState(
      (prev): AddressState => ({
        ...prev,
        state: { value, valid },
      })
    );
  };

  const handleCountryChange = async (event: SelectChangeEvent<unknown>) => {
    userInteraction.current = true;
    const value = event.target.value as CountryCode;
    const valid = validateAddressElement("country", value, addressState.country.value);

    setImmutable(false);

    setAddressState(
      (prev): AddressState => ({
        ...prev,
        country: { value, valid },
        // Clear houseNumber when new country is a non-houseNumber country
        houseNumber: {
          value: !HOUSE_NUMBER_COUNTRIES.includes(value) ? "" : prev.houseNumber.value,
          sanitizedValue: !HOUSE_NUMBER_COUNTRIES.includes(value)
            ? ""
            : prev.houseNumber.sanitizedValue,
          valid: !HOUSE_NUMBER_COUNTRIES.includes(value) ? undefined : prev.houseNumber.valid,
        },
        // Clear line2 when new country is a non-line2 country
        line2: {
          value: !LINE_2_COUNTRIES.includes(value) ? "" : prev.line2.value,
          sanitizedValue: !LINE_2_COUNTRIES.includes(value) ? "" : prev.line2.sanitizedValue,
          valid: !LINE_2_COUNTRIES.includes(value) ? undefined : prev.line2.valid,
        },
        // Re-validate postal code when country changes
        postalCode: {
          ...prev.postalCode,
          valid:
            prev.postalCode.value === undefined || prev.postalCode.value === ""
              ? undefined
              : validateAddressElement("postalCode", prev.postalCode.sanitizedValue, value),
        },
        // Clear state (province) when country changes
        state: { value: "", valid: undefined },
      })
    );
  };

  const line1Input = (grid: number) => (
    <Grid item mobile={grid}>
      <TextField
        fullWidth
        id="line1"
        type="text"
        inputProps={{ "data-testid": "line1" }}
        value={addressState.line1.value}
        error={addressState.line1.valid === undefined ? undefined : !addressState.line1.valid}
        label={props.translations.line1Label}
        onChange={handleChange}
        disabled={disabled || (immutable && !!addressState.valid)}
      />
    </Grid>
  );
  const houseNumberInput = (
    <Grid item mobile={4}>
      <TextField
        fullWidth
        id="houseNumber"
        type="text"
        inputProps={{ "data-testid": "houseNumber" }}
        value={addressState.houseNumber.value}
        error={
          addressState.houseNumber.valid === undefined ? undefined : !addressState.houseNumber.valid
        }
        label={props.translations.houseNumberLabel}
        onChange={handleChange}
        disabled={disabled || (immutable && !!addressState.valid)}
      />
    </Grid>
  );

  /** Returns house number and line 1 elements in proper order */
  const houseNumberAndLine1Elements = () => {
    if (isHouseNumberRequired()) {
      if (isHouseNumberReversed()) {
        return (
          <>
            {houseNumberInput} {line1Input(8)}
          </>
        );
      }

      return (
        <>
          {line1Input(8)} {houseNumberInput}
        </>
      );
    }
    return line1Input(12);
  };

  return (
    <>
      {houseNumberAndLine1Elements()}
      {LINE_2_COUNTRIES.includes(addressState.country.value) && (
        <Grid item mobile={12}>
          <TextField
            fullWidth
            id="line2"
            type="text"
            inputProps={{ "data-testid": "line2" }}
            value={addressState.line2.value}
            error={addressState.line2.valid === undefined ? undefined : !addressState.line2.valid}
            label={props.translations.line2Label}
            onChange={handleChange}
            disabled={disabled || (immutable && !!addressState.valid)}
          />
        </Grid>
      )}
      <Grid item mobile={5}>
        <TextField
          fullWidth
          id="postalCode"
          type="text"
          inputProps={{ "data-testid": "postalCode" }}
          value={addressState.postalCode.value}
          error={
            addressState.postalCode.valid === undefined ? undefined : !addressState.postalCode.valid
          }
          label={props.translations.postalCodeLabel}
          onChange={handleChange}
          disabled={disabled || (immutable && !!addressState.valid)}
        />
      </Grid>
      <Grid item mobile={7}>
        <TextField
          fullWidth
          id="city"
          type="text"
          inputProps={{ "data-testid": "city" }}
          value={addressState.city.value}
          error={addressState.city.valid === undefined ? undefined : !addressState.city.valid}
          label={props.translations.cityLabel}
          onChange={handleChange}
          disabled={disabled || (immutable && !!addressState.valid)}
        />
      </Grid>
      {STATES_BY_COUNTRY[addressState.country.value].length ? (
        <Grid item mobile={12}>
          <Select
            fullWidth
            id="state"
            name="state"
            inputProps={{ "data-testid": "state" }}
            value={addressState.state.value ?? ""}
            error={addressState.state.valid === undefined ? undefined : !addressState.state.valid}
            onChange={handleStateChange}
            disabled={disabled || (immutable && !!addressState.valid)}
            menuItems={
              STATES_BY_COUNTRY[addressState.country.value].map((state) => ({
                value: state.name,
                children: state.name,
              })) || []
            }
            label={props.translations.stateLabel}
          />
        </Grid>
      ) : null}
      <Grid item mobile={12}>
        <Select
          fullWidth
          id="country"
          name="country"
          inputProps={{ "data-testid": "country" }}
          value={addressState.country.value}
          error={addressState.country.valid === undefined ? undefined : !addressState.country.valid}
          onChange={handleCountryChange}
          disabled={props.countryDisabled || disabled || (immutable && !!addressState.valid)}
          menuItems={
            COUNTRIES?.map((country) => ({
              value: country.code,
              children: country.name,
            })) || []
          }
          label={props.translations.countryLabel}
        />
      </Grid>
    </>
  );
};
