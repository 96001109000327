import type { FC } from "react";
import { useEffect, useRef, useState } from "react";

import { Bank, CreditCard } from "@augment-frontend/mui-icons";
import { Provider } from "@chargebee/chargebee-js-react-wrapper";
import { Box, Divider, Skeleton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { ChargebeeCardProps } from "./ChargeBee/ChargebeeCard";
import type {
  AbortError,
  ApplePayHandler,
  CardInputElement,
  ChargebeeInstance,
  GooglePayHandler,
  PaymentIntent,
  PayPalHandler,
  WalletPaymentSuccess,
} from "@chargebee/chargebee-js-react-wrapper";
import type { BoxProps } from "@mui/material";

import { ChargebeeCard } from "./ChargeBee/ChargebeeCard";

type PaymentMethodElementType = "card" | "sofort";
type WalletPaymentMethodType = "googlePay" | "payPal" | "applePay";

interface TabPanelProps extends BoxProps {
  /**
   * Currently active {@link PaymentMethodElementType}
   */
  value: PaymentMethodElementType;
  /**
   * The {@link PaymentMethodElementType} to be rendered
   */
  type: PaymentMethodElementType;
  /**
   * If there are multiple, or just one, TabPanels to render.
   * This adds some margin to the bottom of the TabPanel, if there are multiple.
   */
  singular?: boolean;
}

const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, type, singular, ...rest } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== type}
      id={`payment-method-panel-${type}`}
      aria-labelledby={singular ? undefined : `payment-method-tab-${type}`}
    >
      {
        <Box
          sx={{ display: value === type ? "block" : "none", pt: props.singular ? undefined : 2 }}
          {...rest}
        >
          {children}
        </Box>
      }
    </div>
  );
};

export type PaymentMethodElementProps = {
  /** Provide Chargebee instance to be used in the payment element. */
  cbInstance: ChargebeeInstance | null;
  /** Specify which types should be shown in the payment element. */
  types: PaymentMethodElementType[];
  /** Specify which wallet payment methods should be shown in the payment element. */
  walletTypes: WalletPaymentMethodType[];
  /**
   * Listen for changes in the payment element.
   * When the complete field is true, it is safe to call Chargebee instance methods.
   */
  onChange: (type: PaymentMethodElementType, complete: boolean) => void;
  /**
   * If wallet paymentIntent is provided, wallet payment methods are suggested as primary payment methods,
   * and all other payment methods are rendered with tabs to chose from.
   */
  wallet?: {
    /**
     * Payment Intents needs to be present while mounting the wallet payment methods,
     * thus it can't be created on demand, and must be provided while rendering the component.
     *
     * Each different wallet payment method requires its own payment intent.
     *
     * @note Mounting of the wallet payment methods is delayed until the payment intent is provided.
     */
    paymentIntents: {
      /** Payment intent for Google Pay */
      googlePay: PaymentIntent | null;
      /** Payment intent for PayPal */
      payPal: PaymentIntent | null;
      /** Payment intent for Apple Pay */
      applePay: PaymentIntent | null;
    };
    /**
     * Triggered when the wallet payment succeeds.
     */
    onSuccess: (success: WalletPaymentSuccess | PaymentIntent) => void;
    /**
     * Triggered when the wallet payment fails.
     */
    onError: (paymentIntent: PaymentIntent, error: AbortError) => void;
    /**
     * Triggered when the wallet payment button is pressed to allow e.g. setting loading state.
     */
    onClick?: (walletType: PaymentIntent["payment_method_type"]) => void;
    /** To indicate that all wallets are completely ready, including the mount. */
    onWalletsReady?: () => void;
    /** Variant of the Google Pay button. */
    googlePayButtonVariant?: Partial<
      Parameters<GooglePayHandler["mountPaymentButton"]>[1]["buttonType"]
    >;
    /** Variant of the Apple Pay button. */
    applePayButtonVariant?: Partial<
      Parameters<ApplePayHandler["mountPaymentButton"]>[1]["buttonType"]
    >;
    /** Variant of the PayPal button. */
    payPalButtonVariant?: Partial<
      Parameters<PayPalHandler["mountPaymentButton"]>[1]["style"]["label"]
    >;
    /** If provided, only wallet payment methods such as Google Pay are rendered */
    renderOnlyWallets?: boolean;
    /** If provided, interaction with wallets are disabled */
    disabled?: boolean;
  };
  /**
   * E.g. authorizeWith3ds must be called from the underlying Chargebee Card component.
   * Pass ref here to get access to the component.
   */
  cardRef?: React.Ref<CardInputElement>;
  /** Locale to use in the element. Affects e.g. localization of wallet payment methods. */
  locale?: string;
  translations: {
    labels: Record<PaymentMethodElementType, string>;
    card: ChargebeeCardProps["translations"];
    /** Alternative text to differentiate wallet and card payments, e.g. "Or" */
    alternative?: string;
    /** SEPA-payment instructions */
    sepa?: string;
  };
} & Pick<ChargebeeCardProps, "disabled" | "tooltipImages">;

/**
 * Render abstracted element for adding payment methods.
 * To be PCI compliant, the element is rendered by Chargebee.
 * All the logic is handled by Chargbee, so validation must
 * be handled by using the onChange callback.
 *
 * As wallet payment methods require payment intent to be present while mounting the buttons,
 * the payment logic differs between them (use wallet props) and other payment methods.
 */
export const PaymentMethodElement: FC<PaymentMethodElementProps> = (props) => {
  const [activeType, setActiveType] = useState<PaymentMethodElementType>(props.types[0]);
  const [, setCardComplete] = useState<Record<"number" | "expiry" | "cvv", boolean>>({
    number: false,
    expiry: false,
    cvv: false,
  });
  const [complete, setComplete] = useState<Record<PaymentMethodElementType, boolean>>({
    card: false,
    sofort: true,
  });
  const [isGooglePayReady, setIsGooglePayReady] = useState(false);
  const [isPayPalReady, setIsPayPalReady] = useState(false);
  const [isApplePayReady, setIsApplePayReady] = useState(false);
  const googlePayLoadingRef = useRef(false);
  const payPalLoadingRef = useRef(false);
  const applePayLoadingRef = useRef(false);
  const theme = useTheme();

  useEffect(() => {
    const configurateWallets = async () => {
      if (
        props.cbInstance &&
        props.wallet &&
        (props.wallet?.paymentIntents.googlePay ||
          props.wallet?.paymentIntents.payPal ||
          props.wallet?.paymentIntents.applePay)
      ) {
        // update payment intents and mount all wallets simultaniously
        const walletConfigFunctions: Array<() => Promise<void>> = [];

        if (props.wallet.paymentIntents.googlePay && props.walletTypes.includes("googlePay")) {
          walletConfigFunctions.push(async () => {
            if (!googlePayLoadingRef.current) {
              // update handler every time the payment intent changes
              // prevent multiple simultanious loadings/mounts with useRef
              googlePayLoadingRef.current = true;
              const googlePayHandler = await props.cbInstance!.load("google-pay");
              googlePayHandler.setPaymentIntent(props.wallet!.paymentIntents.googlePay!);

              if (!isGooglePayReady) {
                // Must be mounted only once, as otherwise multiple buttons will be rendered
                try {
                  await googlePayHandler.mountPaymentButton("#google-pay-button", {
                    buttonType: props.wallet!.googlePayButtonVariant || "plain",
                    buttonSizeMode: "fill",
                    buttonColor: "black",
                    buttonLocale: props.locale || "en",
                  });

                  // mount has to be successfully done before setting the callbacks
                  googlePayHandler
                    .handlePayment({
                      success: props.wallet?.onSuccess,
                      error: props.wallet?.onError,
                    })
                    .catch(() => {
                      // TODO: Remove this catch once Chargebee updates adyen-web to version >= 5.27.0
                      // https://github.com/Adyen/adyen-web/issues/1532 -> this catch fires once per payment intent
                      // Error must be catched since we are using callbacks,
                      // and unhandled promise rejection would be emitted to console.
                    });
                } catch (e) {
                  // in case some error during mount, fail silently to prevent blocking other wallets
                  // eslint-disable-next-line no-console
                  console.warn("Google Pay mount failed", e);
                } finally {
                  setIsGooglePayReady(true);
                }
              }
              googlePayLoadingRef.current = false;
            }
          });
        }

        if (props.wallet.paymentIntents.payPal && props.walletTypes.includes("payPal")) {
          walletConfigFunctions.push(async () => {
            if (!payPalLoadingRef.current) {
              // update handler every time the payment intent changes
              // prevent multiple simultanious loadings/mounts with useRef
              payPalLoadingRef.current = true;
              const payPalHandler = await props.cbInstance!.load("paypal");
              payPalHandler.setPaymentIntent(props.wallet!.paymentIntents.payPal!);

              if (!isPayPalReady) {
                // Must be mounted only once, as otherwise multiple buttons will be rendered
                try {
                  await payPalHandler.mountPaymentButton("#paypal-button", {
                    style: {
                      label: props.wallet!.payPalButtonVariant || "paypal",
                      color: "gold",
                      shape: "rect",
                      size: "responsive",
                      tagline: false,
                      height: 48,
                    },
                  });

                  // mount has to be successfully done before setting the callbacks
                  // TODO: once Chargebee provides onClick callback for PayPal, use that instead of onChange callback
                  payPalHandler
                    .handlePayment({
                      success: props.wallet?.onSuccess,
                      error: props.wallet?.onError,
                      change: (_paymentIntent, reason) => {
                        if (reason === "requires_challenge") {
                          props.wallet?.onClick?.("paypal_express_checkout");
                          props.wallet?.onWalletsReady?.();
                        }
                      },
                    })
                    .catch(() => {
                      // This is needed for same reason as in Google Pay
                    });
                } catch (e) {
                  // in case some error during mount, fail silently to prevent blocking other wallets
                  // eslint-disable-next-line no-console
                  console.warn("PayPal mount failed", e);
                } finally {
                  setIsPayPalReady(true);
                }
              }
              payPalLoadingRef.current = false;
            }
          });
        }

        if (props.wallet.paymentIntents.applePay && props.walletTypes.includes("applePay")) {
          walletConfigFunctions.push(async () => {
            if (!applePayLoadingRef.current) {
              // update handler every time the payment intent changes
              // prevent multiple simultanious loadings/mounts with useRef
              applePayLoadingRef.current = true;
              const applePayHandler = await props.cbInstance!.load("apple-pay");
              applePayHandler.setPaymentIntent(props.wallet!.paymentIntents.applePay!);

              // Apple Pay must be mounted every time the payment intent changes (it will still render only one button)
              try {
                await applePayHandler.mountPaymentButton("#apple-pay-button", {
                  buttonColor: "black",
                  buttonType: props.wallet!.applePayButtonVariant || "plain",
                  locale: props.locale || "en",
                });

                // mount has to be successfully done before setting the callbacks
                applePayHandler
                  .handlePayment({
                    success: props.wallet?.onSuccess,
                    error: props.wallet?.onError,
                  })
                  .catch(() => {
                    // This is needed for same reason as in Google Pay
                  });
              } catch (e) {
                // in case some error during mount, fail silently to prevent blocking other wallets
                // eslint-disable-next-line no-console
                console.warn("Apple Pay mount failed", e);
              } finally {
                setIsApplePayReady(true);
              }
              applePayLoadingRef.current = false;
            }
          });
        }

        await Promise.allSettled(walletConfigFunctions.map((func) => func()));
        if (!props.walletTypes.includes("googlePay")) {
          // we are not expecting Google Pay to be mounted
          setIsGooglePayReady(true);
        }
        if (!props.walletTypes.includes("payPal")) {
          // we are not expecting PayPal to be mounted
          setIsPayPalReady(true);
        }
        if (
          !props.walletTypes.includes("applePay") ||
          (props.walletTypes.includes("applePay") && props.wallet.paymentIntents.applePay === null)
        ) {
          // we are not expecting ApplePay to be mounted (might be because device doesn't support that)
          setIsApplePayReady(true);
        }
        props.wallet.onWalletsReady?.();
      }
    };

    configurateWallets();
  }, [
    props.cbInstance?.load,
    props.wallet?.paymentIntents.googlePay,
    props.wallet?.paymentIntents.payPal,
    props.wallet?.paymentIntents.applePay,
  ]);

  const handleChange = (event: React.SyntheticEvent, type: PaymentMethodElementType) => {
    setActiveType(type);
    props.onChange(type, complete[type]);
  };

  const getIcon = (type: PaymentMethodElementType): JSX.Element => {
    let Icon: typeof CreditCard;

    if (type === "card") {
      Icon = CreditCard;
    } else {
      Icon = Bank;
    }

    return <Icon />;
  };

  const getComponent = (type: PaymentMethodElementType): JSX.Element => {
    if (type === "card") {
      return (
        <ChargebeeCard
          ref={props.cardRef}
          locale="en"
          onChange={(event) => {
            setCardComplete((prevCard) => {
              const newCard = { ...prevCard, [event.field]: event.complete && !event.error };
              const isComplete = !Object.values(newCard).includes(false);
              setComplete((prevComplete) => ({ ...prevComplete, card: isComplete }));
              props.onChange("card", isComplete);
              return newCard;
            });
          }}
          disabled={props.disabled}
          translations={props.translations.card}
          tooltipImages={props.tooltipImages}
        />
      );
    }

    return <Typography>{props.translations.sepa}</Typography>;
  };

  return (
    <Provider cbInstance={props.cbInstance}>
      <Box sx={{ width: "100%" }}>
        {props.walletTypes.length > 0 && props.wallet && (
          <>
            {/* List of wallet payment methods & divider */}
            <Stack
              direction="column"
              display={isGooglePayReady && isPayPalReady && isApplePayReady ? "block" : "none"}
            >
              {props.walletTypes.includes("googlePay") && (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "48px",
                    borderRadius: "4px",
                    pointerEvents: props.disabled || props.wallet.disabled ? "none" : "auto",
                    marginBottom: 2,
                  }}
                >
                  <div
                    id="google-pay-button"
                    data-testid="google-pay-wallet"
                    style={{ height: "48px" }}
                    onClick={() => {
                      props.wallet?.onClick?.("google_pay");
                      props.wallet?.onWalletsReady?.();
                    }}
                  />
                  {/* Add overlay element to artificially make
                  Google Pay button non-clickable if disabled */}
                  <Box
                    sx={{
                      display: props.disabled || props.wallet.disabled ? "block" : "none",
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                      backgroundColor: theme.palette.secondary.main,
                      opacity: 0.5,
                    }}
                  />
                </Box>
              )}
              {props.walletTypes.includes("payPal") && (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    borderRadius: "4px",
                    pointerEvents: props.disabled || props.wallet.disabled ? "none" : "auto",
                    marginBottom: 2,
                  }}
                >
                  <div id="paypal-button" data-testid="paypal-wallet" />
                  <Box
                    sx={{
                      display: props.disabled || props.wallet.disabled ? "block" : "none",
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                      backgroundColor: theme.palette.secondary.main,
                      opacity: 0.5,
                      zIndex: 101, // mounted PayPal button has z-index 100
                    }}
                  />
                </Box>
              )}
              {props.walletTypes.includes("applePay") && props.wallet.paymentIntents.applePay && (
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "48px",
                    borderRadius: "4px",
                    pointerEvents: props.disabled || props.wallet.disabled ? "none" : "auto",
                    marginBottom: 2,
                  }}
                >
                  <Box
                    key={props.wallet.paymentIntents.applePay.updated_at}
                    id="apple-pay-button"
                    data-testid="apple-pay-wallet"
                    sx={{
                      height: "48px",
                      width: "100%",
                    }}
                    onClick={() => {
                      props.wallet?.onClick?.("apple_pay");
                      props.wallet?.onWalletsReady?.();
                    }}
                  />
                  {/* Add overlay element to artificially make
                  Apple Pay button non-clickable if disabled */}
                  <Box
                    sx={{
                      display: props.disabled || props.wallet.disabled ? "block" : "none",
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                      backgroundColor: theme.palette.secondary.main,
                      opacity: 0.5,
                    }}
                  />
                </Box>
              )}
              {!props.wallet.renderOnlyWallets && (
                <Divider
                  sx={{
                    "&::before, &::after": { borderColor: theme.palette.text.primary },
                    marginBottom: 2,
                    marginTop: 3,
                  }}
                >
                  {props.translations.alternative}
                </Divider>
              )}
            </Stack>
            {/* Loading skeletons of wallet payment methods & divider */}
            {props.walletTypes.includes("googlePay") &&
              (!isGooglePayReady || !isPayPalReady || !isApplePayReady) && (
                <Skeleton
                  variant="rounded"
                  sx={{
                    height: "48px",
                    width: "100%",
                    marginBottom: 2,
                  }}
                  data-testid="walletLoadingSkeleton"
                />
              )}
            {props.walletTypes.includes("payPal") &&
              (!isGooglePayReady || !isPayPalReady || !isApplePayReady) && (
                <Skeleton
                  variant="rounded"
                  sx={{
                    height: "48px",
                    width: "100%",
                    marginBottom: 2,
                  }}
                  data-testid="payPalWalletLoadingSkeleton"
                />
              )}
            {props.walletTypes.includes("applePay") &&
              props.wallet.paymentIntents.applePay &&
              (!isGooglePayReady || !isPayPalReady || !isApplePayReady) && (
                <Skeleton
                  variant="rounded"
                  sx={{
                    height: "48px",
                    width: "100%",
                    marginBottom: 2,
                  }}
                  data-testid="applePayWalletLoadingSkeleton"
                />
              )}
            {!props.wallet.renderOnlyWallets &&
              (!isGooglePayReady || !isPayPalReady || !isApplePayReady) && (
                <Skeleton
                  variant="rounded"
                  sx={{ height: "24px", width: "100%", marginBottom: 2, marginTop: 3 }}
                />
              )}
          </>
        )}
        {/* Other payment methods */}
        {!props.wallet?.renderOnlyWallets && (
          <>
            <Box
              sx={props.types.length > 1 ? { borderBottom: 1, borderColor: "divider" } : undefined}
            >
              {props.types.length > 1 && (
                <Tabs variant="fullWidth" value={activeType} onChange={handleChange}>
                  {props.types.map((type) => (
                    <Tab
                      key={type}
                      id={`payment-method-tab-${type}`}
                      aria-controls={`payment-method-panel-${type}`}
                      label={props.translations.labels[type] || type}
                      icon={getIcon(type)}
                      value={type}
                      disabled={props.disabled}
                    />
                  ))}
                </Tabs>
              )}
            </Box>
            {props.types.map((type) => (
              <TabPanel
                key={type}
                value={activeType}
                type={type}
                singular={props.types.length === 1}
              >
                {getComponent(type) || ""}
              </TabPanel>
            ))}
          </>
        )}
      </Box>
    </Provider>
  );
};
