import { Container, Grid } from "@mui/material";

interface GridContainerProps {
  children: React.ReactNode;
}

export const GridContainer = (props: GridContainerProps) => (
  <Container
    component="section"
    sx={(theme) => ({
      my: { mobile: 10, tablet: 8, laptop: 14 },
      px: { mobile: 3, tablet: 0 },
      maxWidth: {
        mobile: "100%",
        tablet: `${theme.breakpoints.values.tablet - 2 * 64}px`,
        laptop: `${theme.breakpoints.values.laptop - 2 * 144}px`,
      },
    })}
  >
    <Grid container spacing={4} sx={{ alignItems: "center" }}>
      {props.children}
    </Grid>
  </Container>
);
