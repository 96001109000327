import type { FC } from "react";
import { useEffect, useRef, useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack } from "@mui/material";

import type { PaymentMethodElementProps } from "./PaymentMethodElement";
import type { PaymentMethod } from "./types";
import type {
  AdyenRefusalReasonCode,
  ApplePayHandler,
  CardInputElement,
  GooglePayHandler,
  PaymentError,
  PaymentIntent,
  PayPalHandler,
} from "@chargebee/chargebee-js-react-wrapper";
import type { Promisable } from "type-fest";

import { Alert } from "../Alert";
import { PaymentMethodElement } from "./PaymentMethodElement";

export type PaymentMethodControllerProps = {
  /**
   * Triggered when payment intent is needed for saved payment method to complete a payment.
   */
  onSubmit(savedPaymentMethod: PaymentMethod, newPaymentMethod: null): Promise<PaymentIntent>;
  /**
   * Triggered when payment intent is needed for a new payment method to complete a payment.
   * This may happen either when the user clicks the pay button or when the component is mounting,
   * in case a wallet is available for the user.
   */
  onSubmit(
    savedPaymentMethod: null,
    newPaymentMethod: PaymentIntent["payment_method_type"]
  ): Promise<PaymentIntent>;
  /**
   * Triggered when the payment succeeded.
   * Thrown error is catched and its message is shown.
   *
   * If Promise is returned, the form will be in loading state,
   * until the Promise either resolves or rejects.
   *
   * @note Payment intent is always returned in authorized state.
   */
  onPaymentSuccess: (paymentIntent: PaymentIntent) => Promisable<void>;
  /**
   * Triggered when the payment attempt failed. Can be used e.g. to tell the parent component
   * to stop loading on error. As the errors are handled in this component internally,
   * they are not returned on this callback.
   */
  onPaymentFailure?: () => Promisable<void>;
  /**
   * Triggered when any payment is loading. Can be used e.g. to tell the parent component
   */
  onPaymentLoading?: (isLoading: boolean) => void;
  /**
   * Triggered when the secondary button, e.g. cancel button is clicked.
   */
  onSecondaryButtonClick?: () => void;
  /**
   * Triggered when the payment error is not known beforehand. This can happen e.g. if the Adyen
   * payment fails with an unknown refusal reason code, or if the payment intent is broken.
   */
  onUnknownPaymentError?: (error: PaymentError) => void;
  /** Options for wallet payment methods */
  wallet?: {
    /** To pass information that all wallets are completely ready, including mount */
    onWalletsReady?: () => void;
    /** Variant of the Google Pay button */
    googlePayButtonVariant?: Parameters<GooglePayHandler["mountPaymentButton"]>[1]["buttonType"];
    /** Variant of the Apple Pay button */
    applePayButtonVariant?: Parameters<ApplePayHandler["mountPaymentButton"]>[1]["buttonType"];
    /** Variant of the PayPal button */
    payPalButtonVariant?: Parameters<PayPalHandler["mountPaymentButton"]>[1]["style"]["label"];
    /**
     * If given, the wallet will be mounted even if there is savedPaymentMethod. In that case,
     * renderOnlyWallets should be true.
     */
    mountAlways?: boolean;
    /** If provided, only wallet payment methods such as Google Pay are rendered */
    renderOnlyWallets?: boolean;
    /** If changed, wallet payment intents will be updated. Useful to e.g. update payment intents when discount is applied */
    updatePaymentIntents?: unknown;
  };
  /**
   * If provided, the payment will be requested with this payment method.
   */
  savedPaymentMethod?: PaymentMethod;
  /**
   * Whether the secondary button, e.g. cancel button, should be full width. If not provided,
   * the secondary button takes as little space as needed for the content
   */
  secondaryButtonFullWidth?: boolean;
  /**
   * Disable the component so that no interactions may be performed with it.
   */
  disabled?: boolean;
  /**
   * Disable only the submit button (but allow other interactions with the component)
   */
  disabledSubmit?: boolean;
  translations: {
    /** Button for the submit, e.g. "Add" or "Pay" */
    submitButton: string;
    /** Translations for the payment element */
    paymentElement: PaymentMethodElementProps["translations"];
    /** Button for the secondary actions, e.g. "Cancel" */
    secondaryButton?: string;
    /** You can provide custom error messages for any of Adyen refusal reason codes. */
    adyenError?: Partial<Record<AdyenRefusalReasonCode, string>>;
    /** Generic error message to be used on payment error if more specific one isn't given. */
    genericPaymentError: string;
    /** Generic error message when the page was not properly loaded. */
    genericPageError: string;
  };
} & Pick<
  PaymentMethodElementProps,
  "cbInstance" | "types" | "walletTypes" | "locale" | "tooltipImages"
>;

export const PaymentMethodController: FC<PaymentMethodControllerProps> = (props) => {
  const [walletPaymentIntents, setWalletPaymentIntents] = useState<{
    googlePay: PaymentIntent | null;
    payPal: PaymentIntent | null;
    applePay: PaymentIntent | null;
  }>({ googlePay: null, payPal: null, applePay: null });
  const [newPaymentMethod, setNewPaymentMethod] = useState<
    | {
        type: PaymentMethodElementProps["types"][number];
        complete: boolean;
      }
    | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [updatingWalletPaymentIntents, setUpdatingWalletPaymentIntents] = useState(false);
  const [walletErrorMessage, setWalletErrorMessage] = useState("");
  const [cardErrorMessage, setCardErrorMessage] = useState("");
  const cardRef = useRef<CardInputElement | null>(null);

  useEffect(() => {
    props.onPaymentLoading?.(loading);
  }, [loading]);

  // Request payment intent for wallet(s), if the prop is provided
  useEffect(() => {
    const updateWalletPaymentIntents = async () => {
      setUpdatingWalletPaymentIntents(true);
      const walletPaymentIntentPromises: {
        promise: () => Promise<PaymentIntent | null>;
        type: "googlePay" | "payPal" | "applePay";
      }[] = [];

      if (props.walletTypes.includes("googlePay")) {
        walletPaymentIntentPromises.push({
          promise: () => props.onSubmit(null, "google_pay"),
          type: "googlePay",
        });
      }
      if (props.walletTypes.includes("payPal")) {
        walletPaymentIntentPromises.push({
          promise: () => props.onSubmit(null, "paypal_express_checkout"),
          type: "payPal",
        });
      }
      if (props.walletTypes.includes("applePay")) {
        // request payment intent for Apple Pay only if device supports it
        walletPaymentIntentPromises.push({
          promise: () => {
            if (props.cbInstance) {
              return props.cbInstance
                .load("apple-pay")
                .then((applePayHandler) => {
                  return applePayHandler.canMakePayments();
                })
                .then((canMakePayments) => {
                  return canMakePayments ? props.onSubmit(null, "apple_pay") : null;
                });
            }
            return Promise.resolve(null);
          },
          type: "applePay",
        });
      }

      const walletPaymentIntentResults = await Promise.allSettled(
        walletPaymentIntentPromises.map((paymentMethod) => paymentMethod.promise())
      );
      walletPaymentIntentResults.forEach((result, index) => {
        const paymentType = walletPaymentIntentPromises[index].type;
        if (result.status === "fulfilled") {
          setWalletPaymentIntents((prev) => ({ ...prev, [paymentType]: result.value }));
        } else {
          setWalletPaymentIntents((prev) => ({ ...prev, [paymentType]: null }));
        }
      });
      setUpdatingWalletPaymentIntents(false);
    };

    if (props.wallet && props.cbInstance) {
      updateWalletPaymentIntents();
    }
  }, [props.wallet?.updatePaymentIntents, props.cbInstance]);

  // clear card error message when different payment method is provided
  useEffect(() => {
    setCardErrorMessage("");
  }, [props.savedPaymentMethod]);

  const reset = () => {
    setNewPaymentMethod(undefined);
    setLoading(false);
    setUpdatingWalletPaymentIntents(false);
    setCardErrorMessage("");
    setWalletErrorMessage("");
    setWalletPaymentIntents({ googlePay: null, payPal: null, applePay: null });
  };

  /**
   * Handles any other payments than the wallet payments. Will make the payment with the existing
   * or with a new payment method, depending which one is provided.
   *
   * Wallet payments are handled by their own (service provider's internal) handlers.
   */
  const handlePayment: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setLoading(true);
    setCardErrorMessage("");
    setWalletErrorMessage("");

    let paymentIntent: PaymentIntent | undefined;
    try {
      if (!props.cbInstance) {
        // This should not happen, but it is added for type safety & DX.
        throw new Error("Chargebee.js instance is not provided.");
      }

      if (props.savedPaymentMethod) {
        // using existing payment method
        paymentIntent = await props.onSubmit(props.savedPaymentMethod, null);
        if (
          paymentIntent.payment_method_type === "card" ||
          paymentIntent.payment_method_type === "google_pay" ||
          paymentIntent.payment_method_type === "apple_pay"
          // TODO: check if correct & what happens if Apple Pay is saved, and then that payment method is used on non-Apple device?
          // Should work probably as we aren't anymore involved with mounted Apple Pay button?
        ) {
          // existing Google Pay & Apple Pay payment methods will be handled as a card payment as well
          const threeDSHandler = await props.cbInstance.load3DSHandler();
          threeDSHandler.setPaymentIntent(paymentIntent);
          paymentIntent = await threeDSHandler.handleCardPayment();
        } else {
          throw new Error(
            `Payment method type "${paymentIntent.payment_method_type}" is not supported.`
          );
        }
      } else {
        // using new payment method
        if (!newPaymentMethod) {
          // This should not happen, but it is added for type safety
          throw new Error("Invalid payment method details");
        }
        paymentIntent = await props.onSubmit(null, newPaymentMethod.type);
        if (paymentIntent.payment_method_type === "card") {
          if (!cardRef?.current) {
            setCardErrorMessage(props.translations.genericPageError);
            props.onPaymentFailure?.();
            return;
          }
          paymentIntent = await (cardRef.current as CardInputElement).authorizeWith3ds(
            paymentIntent
          );
        } else if (paymentIntent.payment_method_type === "sofort") {
          const sofortHelper = await props.cbInstance.load("sofort");
          await sofortHelper.handlePayment({
            paymentIntent: async () => paymentIntent as PaymentIntent,
            redirectMode: true,
          });
        } else {
          throw new Error(
            `Payment method type "${paymentIntent.payment_method_type}" is not supported.`
          );
        }
      }

      if (paymentIntent.status === "authorized") {
        await props.onPaymentSuccess(paymentIntent);
        reset();
      } else {
        // This should never happen, but error is thrown just in case.
        throw new Error("Payment intent is not authorized.");
      }
    } catch (e: unknown) {
      const error = e as PaymentError;
      let { message } = error;

      if (
        paymentIntent?.gateway === "adyen" &&
        error.code &&
        props.translations.adyenError?.[error.code]
      ) {
        message = props.translations.adyenError[error.code] as string;
      } else if (paymentIntent?.gateway === "stripe" && message) {
        [message] = message.split(";"); // This removes the error code from the message
      } else {
        message = props.translations.genericPaymentError;
        if (props.onUnknownPaymentError) {
          props.onUnknownPaymentError(error);
        }
      }

      setCardErrorMessage(message);
      props.onPaymentFailure?.();
    } finally {
      setLoading(false);
    }
  };

  const handleSecondaryButtonClick = () => {
    setCardErrorMessage("");
    setWalletErrorMessage("");
    props.onSecondaryButtonClick?.();
  };

  return (
    <Box
      component="form"
      onSubmit={handlePayment}
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      {walletErrorMessage && (
        <Alert
          severity="error"
          description={walletErrorMessage}
          sx={{ mb: 2, alignSelf: "stretch" }}
        />
      )}
      {(!props.savedPaymentMethod || props.wallet?.mountAlways) && (
        <Box alignSelf="stretch" mb={2}>
          <PaymentMethodElement
            cbInstance={props.cbInstance}
            cardRef={cardRef}
            types={props.types}
            walletTypes={props.walletTypes}
            onChange={(type, complete) => {
              setNewPaymentMethod({ type, complete });
              setCardErrorMessage("");
              setWalletErrorMessage("");
            }}
            wallet={
              props.wallet
                ? {
                    paymentIntents: {
                      googlePay: walletPaymentIntents.googlePay,
                      payPal: walletPaymentIntents.payPal,
                      applePay: walletPaymentIntents.applePay,
                    },
                    onClick: async (walletType) => {
                      if (walletType !== "apple_pay") {
                        setLoading(true);
                      }
                      setCardErrorMessage("");
                      setWalletErrorMessage("");
                      // we need to call onSubmit to trigger onPreSubmit on higher level (StepPayment), to
                      // update new user's sensitive information
                      await props.onSubmit(null, walletType);
                    },
                    onSuccess: async (result) => {
                      try {
                        const paymentIntent =
                          "paymentIntent" in result ? result.paymentIntent : result;
                        await props.onPaymentSuccess(paymentIntent as PaymentIntent);
                      } catch (e: unknown) {
                        const error = e as PaymentError;
                        const { message } = error;
                        setWalletErrorMessage(message);
                      }
                      setLoading(false);
                    },
                    onError: (paymentIntent, error) => {
                      if (
                        paymentIntent.payment_method_type === "google_pay" &&
                        error.statusCode === "CANCELED"
                      ) {
                        // error.statusCode === "CANCELED": user cancelled Google Pay flow
                      } else if (
                        paymentIntent.payment_method_type === "paypal_express_checkout" &&
                        error.name === "PAYMENT_ATTEMPT_REFUSED"
                      ) {
                        // PAYMENT_ATTEMPT_REFUSED === user cancelled PayPal flow
                      } else if (paymentIntent.gateway === "stripe" && error?.message) {
                        // Codes https://stripe.com/docs/error-codes
                        setWalletErrorMessage(error.message.split(";")[0]);
                        props.onPaymentFailure?.();
                      } else {
                        // other "actual" errors
                        setWalletErrorMessage(props.translations.genericPaymentError);
                        if (props.onUnknownPaymentError) {
                          props.onUnknownPaymentError(error);
                        }
                      }
                      setLoading(false);
                    },
                    onWalletsReady: props.wallet?.onWalletsReady,
                    googlePayButtonVariant: props.wallet.googlePayButtonVariant,
                    applePayButtonVariant: props.wallet.applePayButtonVariant,
                    payPalButtonVariant: props.wallet.payPalButtonVariant,
                    renderOnlyWallets: props.wallet?.renderOnlyWallets,
                    disabled: props.disabledSubmit || loading,
                  }
                : undefined
            }
            locale={props.locale}
            disabled={props.disabled}
            translations={props.translations.paymentElement}
            tooltipImages={props.tooltipImages}
          />
        </Box>
      )}
      {cardErrorMessage && (
        <Alert
          severity="error"
          description={cardErrorMessage}
          sx={{ mb: 2, alignSelf: "stretch" }}
        />
      )}
      <Stack direction="row" alignSelf="stretch">
        {props.onSecondaryButtonClick && (
          <Button
            fullWidth={props.secondaryButtonFullWidth}
            color="secondary"
            onClick={handleSecondaryButtonClick}
            disabled={loading || props.disabled || updatingWalletPaymentIntents}
            sx={{ mr: 2 }}
          >
            {props.translations.secondaryButton}
          </Button>
        )}
        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          disabled={
            (!newPaymentMethod?.complete && !props.savedPaymentMethod) ||
            loading ||
            props.disabled ||
            updatingWalletPaymentIntents ||
            props.disabledSubmit
          }
          data-testid="submitButton"
        >
          {props.translations.submitButton}
        </LoadingButton>
      </Stack>
    </Box>
  );
};
