import { CheckRound, CloseRound } from "@augment-frontend/mui-icons";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { ActionButtonsProps } from "../ActionButtons";
import type { HeadingContentProps } from "../HeadingContent";
import type { TableCellProps } from "@mui/material";

import { ActionButtons } from "../ActionButtons";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockCompareProps {
  headingContent: HeadingContentProps;
  callToAction?: Pick<ActionButtonsProps, "buttons">;
  /** Comparison table */
  table: {
    /** Column titles */
    columnTitles: string[];
    /** Table rows */
    rows: (string | boolean)[][];
    /** Column index that has a highlight style */
    highlightColumn?: number;
    /** Allows overwriting styles for the left-most column; this applies to thead and tbody */
    leftColumnSx?: TableCellProps["sx"];
  };
}

export const BlockCompare = (props: BlockCompareProps) => {
  const theme = useTheme();

  return (
    <GridContainer>
      <Grid item mobile={12} laptop={5}>
        <HeadingContent
          {...props.headingContent}
          bottomContent={
            props.callToAction?.buttons.length && (
              <ActionButtons
                {...props.callToAction}
                direction={{ mobile: "column", tablet: "column", laptop: "row" }}
                grow={false}
                display={{ mobile: "none", tablet: "block" }}
                boxProps={{ mt: { tablet: 2 } }}
              />
            )
          }
        />
      </Grid>
      <Grid item mobile={12} laptop={7}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {props.table.columnTitles.map((columnTitle, index) => {
                  const sx =
                    index === 0 && props.table.leftColumnSx ? props.table.leftColumnSx : {};

                  return (
                    <TableCell
                      key={index}
                      align={index !== 0 ? "center" : undefined}
                      sx={{
                        minWidth: "100px", // Sets min width to match "Augment"
                        border: 0,
                        bgcolor:
                          index === props.table.highlightColumn
                            ? theme.palette.primary[400]
                            : undefined,
                        borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
                        pl: index === 0 ? { mobile: 0, tablet: theme.spacing(1) } : undefined,
                        fontSize: "1.1rem",
                        fontWeight: 500,
                        ...sx,
                      }}
                    >
                      {columnTitle}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.table.rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.values(row).map((value, index) => {
                    const sx =
                      index === 0 && props.table.leftColumnSx ? props.table.leftColumnSx : {};

                    return (
                      <TableCell
                        key={index}
                        sx={{
                          border: 0,
                          pl: index === 0 ? { mobile: 0, tablet: theme.spacing(1) } : undefined,
                          fontSize: "1.1rem",
                          fontWeight: 500,
                          bgcolor:
                            index === props.table.highlightColumn
                              ? theme.palette.primary[400]
                              : undefined,
                          textAlign: "center",
                          borderRadius:
                            rowIndex === props.table.rows.length - 1
                              ? `0 0 ${theme.spacing(1)} ${theme.spacing(1)}`
                              : undefined,
                          ...sx,
                        }}
                      >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {typeof value === "string" ? (
                          value
                        ) : value === true ? (
                          <CheckRound sx={{ color: theme.palette.text.primary }} />
                        ) : (
                          <CloseRound sx={{ color: theme.palette.text.primary }} />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {props.callToAction && (
          <ActionButtons
            {...props.callToAction}
            direction="column"
            grow={true}
            display={{ mobile: "block", tablet: "none" }}
          />
        )}
      </Grid>
    </GridContainer>
  );
};
