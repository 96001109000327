import type { FC } from "react";

import { Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { SvgIcon } from "@mui/material";

export interface InfoGridItemProps {
  /** Title to display in the first row, e.g. "Current speed limit" */
  title: string;
  /** Value of the InfoGridItem next to icon on second row, e.g. "20km/h" */
  value: string;
  /** Icon of the InfoGridItem next to value on second row, e.g. Speed icon */
  icon: typeof SvgIcon;
}

export const InfoGridItem: FC<InfoGridItemProps> = (props) => {
  const theme = useTheme();
  const Icon = props.icon;

  return (
    <Grid item mobile={12} tablet={4}>
      <Typography variant="bodyText" sx={{ marginBottom: theme.spacing(0.5) }}>
        {props.title}
      </Typography>
      <Stack direction="row" spacing={theme.spacing(1)}>
        <Icon sx={{ display: "inline-flex" }} />
        <Typography variant="largeTextBold">{props.value}</Typography>
      </Stack>
    </Grid>
  );
};
