import type { FC } from "react";
import { useState } from "react";

import { Grid, LinearProgress, Radio, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import type { SvgIcon, TypographyProps } from "@mui/material";

import { Surface } from "../../Surface";
import { Title } from "../Title";

const StyledCard = styled("div")<React.HTMLProps<HTMLDivElement> & { checked?: boolean }>((
  props
) => {
  const hoverBackgroundColor = props.checked
    ? props.theme.palette.primary[400]
    : props.theme.palette.primary[600];
  const cursor = props.checked ? "default" : "pointer";

  return {
    boxSizing: "border-box",
    height: "100%",
    textAlign: "center",
    pointerEvents: props["aria-disabled"] ? "none" : "all",
    padding: props.theme.spacing(4),
    [props.theme.breakpoints.down("tablet")]: {
      padding: props.theme.spacing(2),
    },
    backgroundColor: props.checked
      ? props.theme.palette.primary[400]
      : props.theme.palette.secondary[200],
    opacity: props["aria-disabled"] ? 0.5 : 1,
    borderRadius: props.theme.shape.borderRadius,
    cursor,
    boxShadow: `inset 0px 0px 0px 2px ${props.theme.palette.secondary.A200}`,
    transition: `box-shadow 250ms ${props.theme.transitions.easing.easeInOut}, background-color 250ms ${props.theme.transitions.easing.easeInOut}`,
    "&:hover": {
      backgroundColor: hoverBackgroundColor,
    },
  };
});

const StyledCardTitle = styled(Typography)<
  Omit<TypographyProps, "variant"> & { disabled?: boolean }
>((props) => ({
  ...props.theme.typography.bodyTextBold,
  opacity: props.disabled ? 0.5 : undefined,
  [props.theme.breakpoints.up("tablet")]: {
    ...props.theme.typography.subheadingBold,
  },
}));

const StyledIconWrapper = styled("div")((props) => ({
  display: "flex",
  padding: props.theme.spacing(1.25),
  borderRadius: props.theme.shape.borderRadius,
  backgroundColor: props.theme.palette.primary.main,
  opacity: props["aria-disabled"] ? 0.5 : 1,
}));

export interface MyAccountAccessoriesProps {
  /** Items to choose on */
  items: {
    /** Title of the item e.g. "Insurance" */
    title: string;
    /** Icon of the item */
    icon: typeof SvgIcon;
    /** Content of the item to display on selection */
    content: React.ReactNode;
    /** Disable the item */
    disabled?: boolean;
    /**
     * Triggers when item is selected.
     */
    onClick?: () => void;
  }[];
  /**
   * Is in loading state?
   *
   * LinearProgress is shown, if true.
   */
  loading?: boolean;
  translations: {
    /** Header of the component, e.g. "Additional services" */
    title: string;
  };
}

export const MyAccountAccessories: FC<MyAccountAccessoriesProps> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const theme = useTheme();

  return (
    <div>
      <Title>{props.translations.title}</Title>
      <Grid container direction="row" spacing={theme.spacing(2)} marginBottom={theme.spacing(2)}>
        {props.items.map((item, index) => {
          const Icon = item.icon;

          return (
            <Grid key={index} item mobile={4}>
              <StyledCard
                onClick={() => {
                  item.onClick?.();
                  setSelectedIndex(index);
                }}
                checked={index === selectedIndex}
                aria-disabled={!!item.disabled}
              >
                {!item.disabled && (
                  <Radio style={{ opacity: 0, zIndex: -1, position: "absolute" }} />
                )}
                <Stack direction="column" alignItems="center" spacing={theme.spacing(1)}>
                  <StyledIconWrapper aria-disabled={!!item.disabled}>
                    <Icon />
                  </StyledIconWrapper>
                  <StyledCardTitle
                    dangerouslySetInnerHTML={{ __html: item.title }}
                    disabled={item.disabled}
                  />
                </Stack>
              </StyledCard>
            </Grid>
          );
        })}
      </Grid>
      {props.loading && <LinearProgress />}
      {!props.loading && selectedIndex >= 0 && props.items[selectedIndex] && (
        <Surface
          sx={{
            bgcolor: theme.palette.secondary[200],
            padding: { mobile: 0, tablet: theme.spacing(2) },
          }}
        >
          {props.items[selectedIndex].content}
        </Surface>
      )}
    </div>
  );
};
