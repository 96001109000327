import type { FC, ReactNode } from "react";
import { createContext, useContext, useState } from "react";

import type { FetchUserAttributesOutput } from "aws-amplify/auth";

interface UserAttributesContextInterface {
  userAttributes: FetchUserAttributesOutput | null;
  setUserAttributes: React.Dispatch<React.SetStateAction<FetchUserAttributesOutput | null>>;
}

const UserAttributesContext = createContext<UserAttributesContextInterface>({
  userAttributes: null,
  setUserAttributes: () => {},
});

interface UserProviderProps {
  children: ReactNode;
}

export const UserAttributesProvider: FC<UserProviderProps> = (props) => {
  const [userAttributes, setUserAttributes] = useState<FetchUserAttributesOutput | null>(null);

  return (
    <UserAttributesContext.Provider
      value={{
        userAttributes,
        setUserAttributes,
      }}
    >
      {props.children}
    </UserAttributesContext.Provider>
  );
};

export const useUserAttributes = () => {
  const context = useContext(UserAttributesContext);
  if (typeof window !== "undefined" && context === undefined) {
    throw new Error("`useUserAttributes` must be within a `UserAttributesProvider`");
  }
  return context;
};
