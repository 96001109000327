import { Fragment, useEffect, useRef, useState } from "react";

import {
  type CountryCode,
  HOUSE_NUMBER_COUNTRIES,
  LINE_2_COUNTRIES,
  STATES_BY_COUNTRY,
} from "@augment-frontend/constants";
import { AmericanExpress, Done, Info, Mastercard, Visa } from "@augment-frontend/mui-icons";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import type { AddressResult, NameResult, PhoneResult } from "../../../Inputs";
import type { PaymentMethodListOrControllerProps } from "../../../PaymentMethods";
import type { SummaryProps } from "../../Summary";
import type { StepProps } from "../Step";
import type { UserInformation } from "../types";
import type { BillingAddressInfoProps } from "./AddressAndContactInfo/BillingAddressInfo";
import type { ContactInformationProps } from "./AddressAndContactInfo/ContactInfo";
import type { ShippingAddressInfoProps } from "./AddressAndContactInfo/ShippingAddressInfo";
import type { PersonalInfoResult, PersonalInfoWithCouponProps } from "./PersonalInfoWithCoupon";
import type { PartialDeep } from "type-fest";

import { Alert } from "../../../Alert";
import { PaymentMethodListOrController } from "../../../PaymentMethods";
import { Surface } from "../../../Surface";
import { InfoBox } from "../../Info";
import { Summary } from "../../Summary";
import { SummaryRow } from "../../Summary/SummaryRow";
import { Step } from "../Step";
import { BillingAddressInfo } from "./AddressAndContactInfo/BillingAddressInfo";
import { ContactInfo } from "./AddressAndContactInfo/ContactInfo";
import { ShippingAddressInfo } from "./AddressAndContactInfo/ShippingAddressInfo";
import {
  AddressValidationModal,
  type AddressValidationModalProps,
} from "./AddressValidationModal/AddressValidationModal";
import {
  formatOriginalAddress,
  formatSuggestedAddress,
  postSuggestionChecked,
  preSuggestionChecked,
  replaceAddressWithSuggestion,
  validateAddressWithGoogle,
  WARNING_VALIDATOR_FIELDS,
} from "./AddressValidationModal/utils";
import { PersonalInfoWithCoupon } from "./PersonalInfoWithCoupon";

export type StepPaymentProps<T extends CountryCode> = {
  /**
   * Country code in ISO 3166-1 alpha-2 format, in which the checkout is performed.
   * @note This value is used to determine the country-specific checkout form.
   */
  countryCode: T;
  /**
   * Locale in ISO 639-1 format, in which the checkout is performed.
   */
  locale?: string;
  summaryItems: Pick<SummaryProps["items"][number], "title" | "value" | "addedItems">[];
  summaryTotal: NonNullable<SummaryProps["summaryRow"]>;
  /** Array of customer delivery information including e.g. name and address. [Only for accessory checkout] */
  deliveryInformations?: { title: string; value: string }[];
  /** Render e.g. Typography component with inline links to contract. */
  infoBox1: React.ReactNode;
  infoBox1ModalContent?: React.ReactNode;
  /** Render e.g. Typography component with security information */
  infoBox2?: React.ReactNode;
  infoBox2ModalContent?: React.ReactNode;
  /** Render notes which customer is to see before paying */
  notesBeforePayment?: React.ReactNode;
  /**  Loading status of the component passed on for the summary, delivery information and contract terms */
  loading?: boolean;
  /** Element, including contract links, for the checkbox to confirm that the user accepts the contract terms. */
  contractTermAgreement?: React.ReactNode;
  /** Show agreements checkboxes */
  showAgreementChecks?: boolean;
  userInformation?: PartialDeep<UserInformation> | null;
  /**
   * Wether the nameInput should be immutable.
   * @default true
   */
  nameIsImmutable?: boolean;
  /**
   * Google Maps Geocoger instance for address validation.
   * If this value is null, the google address validation will be skipped.
   */
  gmInstance?: google.maps.Geocoder | null;
  /**
   * Triggered when the user submits the form
   *
   * The payload of this callback should be used to save the user information to the backend.
   * The form will be in loading state until the Promise either resolves or rejects.
   * If the Promise resolves, the payment shall be initiated.
   * If the Promise rejects, the message attribute of the error will be shown to the user.
   */

  onPreSubmit?: (userInformation: UserInformation) => Promise<void>;
  onValidateCoupon?: PersonalInfoWithCouponProps<CountryCode>["onValidateCoupon"];
  /** Triggered when the address is validated */
  onValidateAddress?: (shippingInformation: UserInformation["shippingInformation"]) => void;
  couponPlaceholder?: PersonalInfoWithCouponProps<CountryCode>["couponPlaceholder"];
  hideCouponPlaceholder?: PersonalInfoWithCouponProps<CountryCode>["hideCouponPlaceholder"];
  /** Change layout to show/hide elements only suitable on accessory checkout */
  accessoryCheckout?: boolean;
  /* Useful for cases when the customer was redirected to another payment site */
  initialAddressIsValid?: boolean;
  translations: {
    /** E.g. "Checkout" */
    title: StepProps["title"];
    /** E.g. "Summary" */
    summaryTitle: string;
    /** E.g. "Delivery information" */
    deliveryInformationTitle: string;
    /** E.g. "Contract terms" */
    infoBox1Title: string;
    /** E.g. "Security" */
    infoBox2Title: string;
    /** E.g. "Terms of order" */
    agreementsTitle: string;
    /* Text for the checkbox to confirm that the user accepts the contract duration. */
    contractDurationAgreement: string;
    /** Text for the checkbox to confirm that the user understands that the scooter is Augment's property. */
    understandScooterOwnership: string;
    /* E.g. Payment details */
    paymentDetailsTitle: string;
    /** E.g. "Confirm address" */
    validateAddress: string;
    /* E.g. Your shipping address has been confirmed. */
    shippingIsValid: string;
    /* E.g. Please, confirm your shipping address to proceed with payment. */
    shippingPendingValidation: string;
    /* E.g. Your information is securely protected using 256-bit encryption. */
    securityInfo: string;
    /* E.g. Security */
    securityTitle: string;
    validationModal: AddressValidationModalProps["translations"];
    contactInformation: ContactInformationProps<CountryCode>["translations"];
    shippingAddressInformation: ShippingAddressInfoProps<CountryCode>["translations"];
    billingAddressInformation: BillingAddressInfoProps<CountryCode>["translations"];
    paymentMethods: PaymentMethodListOrControllerProps["translations"];
    personalInfoWithCoupon?: PersonalInfoWithCouponProps<CountryCode>["translations"];
    /**
     * Divider text between the {@link PersonalInfoWithCoupon} and {@link PaymentMethodListOrController}
     * e.g. "Payment options"
     */
    divider?: string;
    stepTranslations: StepProps["translations"];
  };
} & Omit<PaymentMethodListOrControllerProps, "translations"> &
  Pick<StepProps["bottomNavigation"], "onBackward" | "width">;

type FormChecks = {
  name: boolean;
  phone: boolean;
  shippingAddress: boolean;
  billingAddress: boolean;
};

/**
 * Renders a summarized payment step to initiate payment on checkout.
 * It is possible to reuse saved payment methods, or add a new one to complete the checkout.
 */
export const StepPayment = <T extends CountryCode>(props: StepPaymentProps<T>) => {
  const billing = props.userInformation?.billingInformation;
  const [walletsLoading, setWalletsLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const isInitialMount = useRef<boolean>(true);

  // Validation modal
  const [shippingAddressIsValid, setShippingAddressIsValid] = useState(
    props.initialAddressIsValid || !props.gmInstance
  );
  const [addressRenderKey, setAddressRenderKey] = useState(1);
  const [defaultShipping, setDefaultShipping] = useState(
    props.userInformation?.shippingInformation || props.userInformation?.billingInformation || {}
  );
  const [loadingValidation, setLoadingValidation] = useState(false);
  const [openValidationModal, setOpenValidationModal] = useState(false);
  const [suggestedShortAddress, setSuggestedShortAddress] = useState<string>();
  const [suggestedFullAddress, setSuggestedFullAddress] =
    useState<google.maps.GeocoderAddressComponent[]>();
  const [errorValidationMessage, setErrorValidationMessage] = useState<string>("");
  const [warningValidationMessage, setWarningValidationMessage] = useState<string>("");
  const [billingImmutable, setBillingImmutable] = useState(
    Boolean(
      billing?.street1 &&
        billing?.city &&
        billing?.postalCode &&
        (billing?.street2 || !LINE_2_COUNTRIES.includes(props.countryCode)) &&
        (billing?.houseNumber || !HOUSE_NUMBER_COUNTRIES.includes(props.countryCode)) &&
        (billing?.state || !STATES_BY_COUNTRY[props.countryCode].length)
    )
  );
  const [personalInformation, setPersonalInformation] = useState<PersonalInfoResult>({
    personalInfo: {
      ssn: props.userInformation?.sensitiveInformation?.ssn || "",
      dateOfBirth: props.userInformation?.sensitiveInformation?.dateOfBirth,
      identityDocumentNumber: props.userInformation?.sensitiveInformation?.identityDocumentNumber,
    },
    valid: false,
  });
  const [contactInformation, setContactInformation] = useState<
    ContactInformationProps<CountryCode>["contactInformation"]
  >({
    ...props.userInformation?.contactInformation,
  });
  const [shippingInformation, setShippingInformation] = useState<
    ShippingAddressInfoProps<CountryCode>["shippingInformation"]
  >({
    ...props.userInformation?.shippingInformation,
    street2: LINE_2_COUNTRIES.includes(props.countryCode)
      ? props.userInformation?.shippingInformation?.street2
      : undefined,
    houseNumber: HOUSE_NUMBER_COUNTRIES.includes(props.countryCode)
      ? props.userInformation?.shippingInformation?.houseNumber
      : undefined,
    state: STATES_BY_COUNTRY[props.countryCode].length
      ? props.userInformation?.shippingInformation?.state
      : undefined,
  });

  const [billingInformation, setBillingInformation] = useState<
    ShippingAddressInfoProps<CountryCode>["shippingInformation"]
  >({
    ...props.userInformation?.billingInformation,
    street2: LINE_2_COUNTRIES.includes(props.countryCode)
      ? props.userInformation?.billingInformation?.street2
      : undefined,
    houseNumber: HOUSE_NUMBER_COUNTRIES.includes(props.countryCode)
      ? props.userInformation?.billingInformation?.houseNumber
      : undefined,
    state: STATES_BY_COUNTRY[props.countryCode].length
      ? props.userInformation?.billingInformation?.state
      : undefined,
  });

  const [formChecks, setFormChecks] = useState<FormChecks>({
    name: false,
    phone: false,
    shippingAddress: false,
    billingAddress: true,
  });
  const [agreementChecks, setAgreementChecks] = useState({
    escooterAugmentProperty: false,
    contractDurationAgreement: false,
    contractTermsAcceptance: false,
  });
  // when this value changes, we will provide updated payment intent for wallets
  const [forceWalletOnSubmit, setForceWalletOnSubmit] = useState("");
  const theme = useTheme();
  const summaryProps = {
    title: props.translations.summaryTitle,
    items: props.summaryItems,
    loading: props.loading,
  };

  // Using useRef to ensure access to the latest `personalInformation` state, preventing stale data in the PayPal
  // change-callback. This can be removed if Chargebee introduces an onClick-callback for the PayPal button.
  const personalInfoRef = useRef(personalInformation);
  useEffect(() => {
    personalInfoRef.current = personalInformation;
  }, [personalInformation]);

  useEffect(() => {
    if (props.gmInstance && !props.initialAddressIsValid) {
      setShippingAddressIsValid(false);
    }
  }, [props.gmInstance]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (shippingAddressIsValid) {
      props.onValidateAddress?.(shippingInformation as Required<typeof shippingInformation>);
    }
  }, [shippingAddressIsValid]);

  /* === CONTACT INFO HANDLERS === */

  const onChangeNameHandler = (value: NameResult) => {
    if (value.valid) {
      setContactInformation((prev): ContactInformationProps<CountryCode>["contactInformation"] => ({
        ...prev,
        firstName: value.firstName,
        lastName: value.lastName,
      }));
    }
    setFormChecks((prev): FormChecks => ({ ...prev, name: value.valid }));
  };

  const onChangePhoneHandler = (value: PhoneResult) => {
    if (value.valid) {
      setContactInformation((prev): ContactInformationProps<CountryCode>["contactInformation"] => ({
        ...prev,
        phoneNumber: value.phoneNumber,
      }));
    }
    setFormChecks((prev): FormChecks => ({ ...prev, phone: value.valid }));
  };

  /* === ADDRESS HANDLERS === */

  const onChangeShippingAddressHandler = (value: AddressResult, userInteraction?: boolean) => {
    if (props.gmInstance && userInteraction) {
      setShippingAddressIsValid(false);
    }
    if (value.valid) {
      const { address } = value;
      setShippingInformation(() => ({
        city: address.city,
        postalCode: address.postalCode,
        street1: address.line1,
        street2: LINE_2_COUNTRIES.includes(props.countryCode) ? address.line2 : undefined,
        houseNumber: HOUSE_NUMBER_COUNTRIES.includes(props.countryCode)
          ? address.houseNumber
          : undefined,
        state: STATES_BY_COUNTRY[props.countryCode].length ? address.state?.name : undefined,
      }));
    }
    setFormChecks((prev): FormChecks => ({ ...prev, shippingAddress: value.valid }));
  };

  const onChangeBillingAddressHandler = (value: AddressResult) => {
    if (value.valid) {
      const { address } = value;
      setBillingInformation(() => ({
        city: address.city,
        postalCode: address.postalCode,
        street1: address.line1,
        street2: LINE_2_COUNTRIES.includes(props.countryCode) ? address.line2 : undefined,
        houseNumber: HOUSE_NUMBER_COUNTRIES.includes(props.countryCode)
          ? address.houseNumber
          : undefined,
        state: STATES_BY_COUNTRY[props.countryCode].length ? address.state?.name : undefined,
      }));
    } else {
      setBillingImmutable(false);
    }
    setFormChecks((prev): FormChecks => ({ ...prev, billingAddress: value.valid }));
  };

  const onChangeBillingAddressDifferentHandler = (value: boolean) => {
    setFormChecks((prev): FormChecks => ({ ...prev, billingAddress: !value }));
  };

  /** Handler for the validation modal that replace form address for the suggested */
  const onAcceptModalHandler = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    useSuggestionAddress: boolean
  ) => {
    if (useSuggestionAddress) {
      setShippingInformation((prev): ShippingAddressInfoProps<T>["shippingInformation"] =>
        replaceAddressWithSuggestion(props.countryCode, prev, suggestedFullAddress)
      );
      setDefaultShipping(
        replaceAddressWithSuggestion(props.countryCode, shippingInformation, suggestedFullAddress)
      );
      setAddressRenderKey((prev) => prev + 1); // Re-render address component with new suggested address
    }
    setShippingAddressIsValid(true);
    setOpenValidationModal(false);
  };

  /**
   * Validate address with Google Maps and Forward
   *
   * - No validator is provided => Forward action.
   * - Suggested address is the same as the original => Forward action.
   * - Suggested address is different => Open confirmation modal.
   */
  const onValidateHandler = async () => {
    const originalFormattedAddress = formatOriginalAddress(shippingInformation);
    setWarningValidationMessage("");
    setErrorValidationMessage("");

    const failedPreSuggestion = preSuggestionChecked(
      shippingInformation.street1,
      shippingInformation.city,
      shippingInformation.houseNumber
    );

    if (failedPreSuggestion) {
      if (failedPreSuggestion === "noHouseNumber") {
        setErrorValidationMessage(props.translations.validationModal.errors.noHouseNumberMessage);
      } else if (failedPreSuggestion === "nonMainland") {
        setErrorValidationMessage(props.translations.validationModal.errors.nonMainlandSpain);
      }
      setOpenValidationModal(true);
    } else if (props.gmInstance) {
      setLoadingValidation(true);
      try {
        const validationResult = await validateAddressWithGoogle(
          props.gmInstance,
          formatOriginalAddress(shippingInformation, true)
        );
        const countrySuggested = validationResult.response.address_components.find((component) =>
          component.types.includes("country")
        )?.short_name;

        const provinceSuggested = validationResult.response.address_components.find((component) =>
          component.types.includes("administrative_area_level_2")
        )?.short_name;

        const failedPostSuggestion = postSuggestionChecked(
          props.countryCode,
          countrySuggested,
          provinceSuggested
        );

        if (failedPostSuggestion) {
          setOpenValidationModal(true);
          setLoadingValidation(false);
          if (failedPostSuggestion === "countryIsDifferent") {
            setErrorValidationMessage(
              props.translations.validationModal.errors.wrongCountryMessage
            );
          } else if (failedPostSuggestion === "nonMainland") {
            setErrorValidationMessage(props.translations.validationModal.errors.nonMainlandSpain);
          } else if (failedPostSuggestion === "unsupportedProvince") {
            setErrorValidationMessage(
              props.translations.validationModal.errors.unsupportedProvince
            );
          }
        } else {
          // Save suggested full address
          setSuggestedFullAddress(validationResult.response.address_components);
          // Save suggested short address
          const suggestedFormattedAddress = formatSuggestedAddress(
            validationResult.response,
            shippingInformation,
            props.countryCode
          );
          setSuggestedShortAddress(suggestedFormattedAddress);

          // Check if suggested address is the same as the original address
          let missingFields = WARNING_VALIDATOR_FIELDS.length;
          validationResult.response.address_components.forEach((component) => {
            WARNING_VALIDATOR_FIELDS.forEach((field) => {
              if (component.types.includes(field)) {
                missingFields -= 1;
              } else if (field === "locality") {
                // Some countries use "postal_town" instead of "locality"
                if (component.types.includes("postal_town")) {
                  missingFields -= 1;
                }
              }
            });
          });

          if (
            suggestedFormattedAddress.toLowerCase() === originalFormattedAddress.toLowerCase() &&
            !missingFields
          ) {
            setLoadingValidation(false);
            setShippingAddressIsValid(true);
          } else {
            if (missingFields) {
              setWarningValidationMessage(
                props.translations.validationModal.errors.partialMatchingMessage
              );
            }
            setLoadingValidation(false);
            setOpenValidationModal(true);
          }
        }
      } catch (error) {
        setLoadingValidation(false);

        if (error === "ZERO_RESULTS") {
          setSuggestedShortAddress("");
          setWarningValidationMessage(
            props.translations.validationModal.errors.addressNotFoundMessage
          );
          setOpenValidationModal(true);
        } else {
          setErrorMessage(error instanceof Error ? error.message : `"Unknown error"`);
        }
      }
    } else {
      setShippingAddressIsValid(true);
    }
  };

  const agreementChecksChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAgreementChecks((prev) => ({ ...prev, [event.target.name]: checked }));
  };

  return (
    <Step
      title={props.translations.title}
      stepComponent={{
        component: (
          <Surface
            sx={{ backgroundColor: theme.palette.secondary[200], p: { mobile: 0, tablet: 2 } }}
          >
            <Snackbar
              open={Boolean(errorMessage)}
              autoHideDuration={6000}
              onClose={() => setErrorMessage("")}
            >
              <Alert severity="error" description={errorMessage} />
            </Snackbar>
            <Typography
              variant="subheadingBold"
              display={{ mobile: "none", tablet: "block" }}
              mb={3}
            >
              {props.translations.title}
            </Typography>
            {!props.accessoryCheckout && (
              <>
                {/* CONTACT INFORMATION */}
                <Stack mb={2}>
                  <ContactInfo
                    countryCode={props.countryCode}
                    allDisabled={props.disabled}
                    contactInformation={props.userInformation?.contactInformation || {}}
                    translations={props.translations.contactInformation}
                    onChangeNameHandler={onChangeNameHandler}
                    onChangePhoneHandler={onChangePhoneHandler}
                    nameIsImmutable={props.nameIsImmutable ?? true}
                  />
                </Stack>
                {/* SHIPPING ADDRESS */}
                <Stack mb={2}>
                  <ShippingAddressInfo
                    key={addressRenderKey}
                    countryCode={props.countryCode}
                    allDisabled={props.disabled || loadingValidation}
                    shippingInformation={defaultShipping}
                    onChangeShippingAddressHandler={onChangeShippingAddressHandler}
                    translations={props.translations.shippingAddressInformation}
                  />
                </Stack>
                <AddressValidationModal
                  open={openValidationModal}
                  translations={props.translations.validationModal}
                  onClose={() => setOpenValidationModal(false)}
                  onCancel={() => setOpenValidationModal(false)}
                  originalFormatedAddress={formatOriginalAddress(shippingInformation)}
                  suggestedFormatedAddress={suggestedShortAddress}
                  errorMessage={errorValidationMessage}
                  warningMessage={warningValidationMessage}
                  onAccept={onAcceptModalHandler}
                />
                {/* BILLING ADDRESS */}
                <Stack mb={2}>
                  <BillingAddressInfo
                    countryCode={props.countryCode}
                    allDisabled={props.disabled || billingImmutable}
                    billingInformation={props.userInformation?.billingInformation}
                    onChangeBillingAddressHandler={onChangeBillingAddressHandler}
                    onChangeBillingAddressDifferentHandler={onChangeBillingAddressDifferentHandler}
                    translations={props.translations.billingAddressInformation}
                  />
                </Stack>
                {props.gmInstance && (
                  <>
                    <LoadingButton
                      data-testid="validateAddress"
                      sx={{ mb: 1 }}
                      disabled={
                        props.disabled ||
                        props.loading ||
                        formChecks.shippingAddress !== true ||
                        shippingAddressIsValid
                      }
                      fullWidth
                      loadingPosition="center"
                      variant="outlined"
                      onClick={onValidateHandler}
                      loading={loadingValidation}
                    >
                      {props.translations.validateAddress}
                    </LoadingButton>

                    <Typography
                      variant="largeText"
                      sx={{
                        alignItems: "flex-start",
                        display: "flex",
                        my: 2,
                        height: props.translations.shippingPendingValidation.length * 0.85,
                      }}
                    >
                      {shippingAddressIsValid ? (
                        <>
                          <Done sx={{ verticalAlign: "top", mr: 1 }} />
                          {props.translations.shippingIsValid}
                        </>
                      ) : (
                        <>
                          <Info sx={{ verticalAlign: "top", mr: 1 }} />
                          {props.translations.shippingPendingValidation}
                        </>
                      )}
                    </Typography>
                  </>
                )}
              </>
            )}
            <Box
              sx={{
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  backgroundColor: theme.palette.background.default,
                  opacity: 0.8,
                  pointerEvents: "auto",
                  zIndex: shippingAddressIsValid ? -1 : 2,
                },
              }}
            >
              {/* PAYMENT DETAILS */}
              {props.userInformation?.sensitiveInformation &&
                props.locale &&
                props.translations.personalInfoWithCoupon && (
                  <>
                    <Typography variant="largeTextBold" mb={1}>
                      {props.translations.paymentDetailsTitle}
                    </Typography>
                    <PersonalInfoWithCoupon
                      countryCode={props.countryCode}
                      locale={props.locale}
                      personalInfo={props.userInformation.sensitiveInformation}
                      onChange={setPersonalInformation}
                      onValidateCoupon={async (...args) => {
                        setCouponLoading(true);
                        try {
                          const result = await props.onValidateCoupon?.(...args);
                          // this is needed to update payment intent for wallets, with updated coupon price
                          if (result) {
                            setForceWalletOnSubmit(args[0]);
                          }
                          return result || false;
                        } finally {
                          setCouponLoading(false);
                        }
                      }}
                      disabled={props.disabled || props.loading || walletsLoading || cardLoading}
                      couponPlaceholder={props.couponPlaceholder}
                      hideCouponPlaceholder={props.hideCouponPlaceholder}
                      translations={props.translations.personalInfoWithCoupon}
                    />
                  </>
                )}
              {props.notesBeforePayment && <Box mb={2}>{props.notesBeforePayment}</Box>}
              {/* AGREEMENT CHECKBOXES  */}
              {props.showAgreementChecks ? (
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    sx={{ fontWeight: theme.typography.bodyTextSemibold.fontWeight, mb: 0.5 }}
                  >{`${props.translations.agreementsTitle}:`}</FormLabel>
                  <FormGroup sx={{ mb: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="contractDurationAgreement"
                          data-testid="contractDurationAgreement"
                          onChange={agreementChecksChanged}
                        />
                      }
                      label={
                        <Typography variant="caption">
                          {props.translations.contractDurationAgreement}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="escooterAugmentProperty"
                          data-testid="escooterAugmentProperty"
                          onChange={agreementChecksChanged}
                        />
                      }
                      label={
                        <Typography variant="caption">
                          {props.translations.understandScooterOwnership}
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="contractTermsAcceptance"
                          data-testid="contractTermsAcceptance"
                          onChange={agreementChecksChanged}
                        />
                      }
                      label={props.contractTermAgreement}
                    />
                  </FormGroup>
                </FormControl>
              ) : undefined}
              {props.translations.divider && (
                <Divider
                  sx={{ mb: 3, "&::before, &::after": { borderColor: theme.palette.text[200] } }}
                >
                  <Typography variant="largeTextSemibold">{props.translations.divider}</Typography>
                </Divider>
              )}

              <PaymentMethodListOrController
                {...props}
                wallet={
                  props.walletTypes.length > 0
                    ? {
                        onWalletsReady: () => {
                          setWalletsLoading(false);
                        },
                        googlePayButtonVariant: props.walletTypes.includes("googlePay")
                          ? "buy"
                          : undefined,
                        applePayButtonVariant: props.walletTypes.includes("applePay")
                          ? "buy"
                          : undefined,
                        payPalButtonVariant: props.walletTypes.includes("payPal")
                          ? "paypal"
                          : undefined,
                        mountAlways: true,
                        renderOnlyWallets: props.paymentMethods.length > 0,
                        updatePaymentIntents: forceWalletOnSubmit,
                      }
                    : undefined
                }
                onSubmit={async (...args) => {
                  if (
                    args[1] === "google_pay" ||
                    args[1] === "paypal_express_checkout" ||
                    args[1] === "apple_pay"
                  ) {
                    setWalletsLoading(true);
                  } else {
                    setCardLoading(true);
                  }
                  try {
                    await props.onPreSubmit?.({
                      contactInformation: contactInformation as Required<typeof contactInformation>,
                      shippingInformation: shippingInformation as Required<
                        typeof shippingInformation
                      >,
                      billingInformation: billingInformation as Required<typeof billingInformation>,
                      sensitiveInformation: personalInfoRef.current.personalInfo,
                    });
                    return await props.onSubmit(
                      ...(args as Parameters<PaymentMethodListOrControllerProps["onSubmit"]>) // Type cast is required because of overloading
                    );
                  } catch (error) {
                    setWalletsLoading(false);
                    setCardLoading(false);
                    throw error;
                  }
                }}
                onPaymentFailure={() => {
                  setWalletsLoading(false);
                  setCardLoading(false);
                  return props.onPaymentFailure?.();
                }}
                onPaymentSuccess={async (...args) => {
                  await props.onPaymentSuccess?.(...args);
                  setWalletsLoading(false);
                  setCardLoading(false);
                }}
                disabledSubmit={
                  (props.userInformation?.sensitiveInformation && !personalInformation.valid) ||
                  couponLoading ||
                  (!props.accessoryCheckout &&
                    (!Object.values(formChecks).every((value) => value === true) ||
                      !shippingAddressIsValid ||
                      (props.showAgreementChecks &&
                        !Object.values(agreementChecks).every((value) => value === true))))
                }
                disabled={props.disabled || props.loading || couponLoading}
                translations={props.translations.paymentMethods}
              />
              <Surface sx={{ mt: 2 }}>
                <InfoBox
                  content={
                    <Typography variant="bodyText" component="span">
                      <Typography variant="bodyText" mb={1}>
                        {props.translations.securityInfo}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Visa />
                        <Mastercard />
                        <AmericanExpress />
                      </Stack>
                    </Typography>
                  }
                  title={props.translations.securityTitle}
                />
              </Surface>
            </Box>
          </Surface>
        ),
      }}
      leftPanel={{
        summary: <Summary {...summaryProps} summaryRow={props.summaryTotal} />,
        furtherDetails:
          props.accessoryCheckout && props.deliveryInformations
            ? {
                title: props.translations.deliveryInformationTitle,
                content: (
                  <div>
                    {props.deliveryInformations.map(({ title, value }, i) => (
                      <Fragment key={i}>
                        <Typography variant="bodyText">{title}</Typography>
                        <Typography
                          variant="bodyTextSemibold"
                          mb={i === (props.deliveryInformations?.length ?? 0) - 1 ? 0 : 1}
                        >
                          {value}
                        </Typography>
                      </Fragment>
                    ))}
                  </div>
                ),
                loading: props.loading,
              }
            : undefined,
      }}
      rightPanel={{
        infoBox1: {
          title: props.translations.infoBox1Title,
          content: props.infoBox1,
          modalContent: props.infoBox1ModalContent,
          loading: props.loading,
        },
        infoBox2: {
          title: props.translations.infoBox2Title,
          content: props.infoBox2,
          modalContent: props.infoBox2ModalContent,
          loading: props.loading,
        },
      }}
      bottomNavigation={{
        hideForward: true,
        mobileAlwaysVisibleContent: (
          <SummaryRow dividerPosition="hidden" {...props.summaryTotal} loading={props.loading} />
        ),
        mobileAdditionalContent: <Summary {...summaryProps} loading={props.loading} />,
        onBackward: props.onBackward,
        width: props.width,
      }}
      hideFurtherDetailsOnMobile={true}
      translations={props.translations.stepTranslations}
    />
  );
};
