import { useState } from "react";

import { Remove as CloseIcon, AddRound as OpenIcon } from "@augment-frontend/mui-icons";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import type { HeadingContentProps } from "../HeadingContent";
import type { TypographyProps } from "@mui/material";

import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockFaqProps {
  headingContent: HeadingContentProps;
  /** FAQ question-answer pairs */
  items: {
    /** Question text; e.g. "How the payment procedure works" */
    question: string;
    /** Answer text element */
    answer: JSX.Element;
  }[];
}

export const Question = styled(Typography)<TypographyProps>((props) => ({
  ...props.theme.typography.bodyTextSemibold,
  [props.theme.breakpoints.up("tablet")]: {
    ...props.theme.typography.largeTextBold,
  },
}));

export const BlockFaq = (props: BlockFaqProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <GridContainer>
      <Grid item mobile={12}>
        <HeadingContent
          {...props.headingContent}
          boxProps={{ width: { tablet: "50%", laptop: "33%" } }}
        />
      </Grid>
      <Grid item mobile={12} mt={{ tablet: 2.25, laptop: 5.25 }}>
        {props.items.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panel${index}` ? (
                  <CloseIcon fontSize="small" />
                ) : (
                  <OpenIcon fontSize="small" />
                )
              }
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Question>{item.question}</Question>
            </AccordionSummary>
            <AccordionDetails>{item.answer}</AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </GridContainer>
  );
};
