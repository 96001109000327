import { Box, Grid } from "@mui/material";

import type { YouTubeProps } from "../../Media";
import type { HeadingContentProps } from "../HeadingContent";

import { YouTube } from "../../Media";
import { GridContainer } from "../GridContainer";
import { HeadingContent } from "../HeadingContent";

export interface BlockVideoProps {
  headingContent: HeadingContentProps;
  youtube: YouTubeProps;
}

export const BlockVideo = (props: BlockVideoProps) => (
  <GridContainer>
    <Grid item mobile={12}>
      <HeadingContent {...props.headingContent} boxProps={{ width: { tablet: "50%" } }} />
    </Grid>
    <Grid item mobile={12}>
      <Box
        sx={{
          position: "relative",
          paddingBottom: "56.25%", // 16:9
          "& iframe": {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          },
        }}
      >
        <YouTube {...props.youtube} />
      </Box>
    </Grid>
  </GridContainer>
);
